import React, { useEffect } from "react";
import { TestSteps, SideBarOfActionElementsSharedSteps } from "../components";
import { observer } from "mobx-react";
import {
  useRouteMatch,
  Switch,
  Route,
  useParams,
  Redirect,
} from "react-router-dom";
import { useRootStoreContext } from "../hooks";

const SharedStep: React.FC = observer(() => {
  const { path, isExact, url } = useRouteMatch();
  const {
    projectId = "",
    suiteId = "",
    testId = "",
  } = useParams<{
    projectId: string;
    testId: string;
    suiteId: string;
  }>();
  const {
    tests,
    testSteps,
    actions,
    elements,
    environments,
    variables,
    users: { profile },
  } = useRootStoreContext();

  useEffect(() => {
    tests.loadOneTest({ projectId: parseInt(projectId, 10), suiteId, testId });
    testSteps.loadSharedSteps(parseInt(projectId, 10));
    actions.load();
    elements.load({ projectId: parseInt(projectId, 10) });
    environments.load({ projectId: parseInt(projectId, 10) });
    variables.load({ projectId: parseInt(projectId, 10) });
    profile.load();
  }, [
    tests,
    testSteps,
    elements,
    actions,
    environments,
    variables,
    projectId,
    suiteId,
    testId,
    profile,
  ]);

  if (!profile.isAuthenticated) return <Redirect to="/sign-in" />;

  if (isExact) {
    return <Redirect to={`${url}/steps`} />;
  }

  return (
    <Switch>
      <Route path={`${path}/steps`}>
        <TestSteps useSharedSteps={false} />
        <SideBarOfActionElementsSharedSteps useSharedSteps={false} />
      </Route>
    </Switch>
  );
});

export default SharedStep;
