import moment from "moment";
import {
  types,
  Instance,
  SnapshotOut,
  destroy,
  clone,
  applySnapshot,
  getSnapshot,
  flow,
  getRoot,
  getParentOfType,
} from "mobx-state-tree";
import { find, map, filter, isEmpty } from "lodash";
import i18next from "i18next";
import WithSelected from "./WithSelected";
import ProjectEnvironment, { IProjectEnvironment } from "./ProjectEnvironment";
import ProjectInvite, {
  IProjectInvite,
  IProjectInviteSnapshotOut,
} from "./ProjectInvite";
import { IUser, IRoot } from ".";
import Users, { IUsers, User } from "./User";
import Projects from "./Projects";
import { handleFetch } from "./utils";

//ToDo move to a separate store
//ToDo fix backend to remove data without nesting
const ProjectUser = types.model({
  id: types.identifierNumber,
  User: types.model({
    maxProjects: types.number,
    id: types.identifierNumber,
    email: types.string,
    firstLastName: types.maybeNull(types.string),
    role: types.string,
  }),
});

//ToDo move to a separate store
const Organisation = types.model({
  id: types.identifierNumber,
  name: types.number,
  company: types.string,
  Users: types.array(
    types.model({
      maxProjects: types.number,
      id: types.number,
      email: types.string,
      firstLastName: types.maybeNull(types.string),
    })
  ),
});

const Project = types
  .compose(
    types.model({
      id: 0,
      name: "",
      OrganizationId: types.maybeNull(types.number),
      website: types.maybeNull(types.string),
      githubLink: types.maybeNull(types.string),
      about: types.maybeNull(types.string),
      lastApiCallAt: types.maybeNull(types.string),
      createdAt: "",
      updatedAt: "",
      TestCount: "",
      userSearchValue: "",
      Organization: types.maybeNull(Organisation),
      ProjectUsers: types.optional(types.array(ProjectUser), []),
      Elements: types.optional(types.array(types.frozen<any>()), []),
      environments: types.optional(types.array(ProjectEnvironment), []),
      invites: types.optional(types.array(ProjectInvite), []),
      addedUsers: types.optional(types.array(User), []),
      usersToAdd: types.optional(types.array(User), []),
      serverError: "",
      baseUrl: types.maybeNull(types.string),
    }),
    WithSelected
  )
  .views((self) => ({
    get created() {
      return moment(self.updatedAt).format("D MMM YYYY");
    },
    get yourRole(): string | undefined {
      const { users } = getRoot<IRoot>(self);
      const profile = users.profile;
      return profile.role;
    },
    get filteredUsersToAdd() {
      const userSearchValue = self.userSearchValue.toLowerCase();
      return filter(
        self.usersToAdd,
        (item) =>
          (item.firstLastName || "").toLowerCase().indexOf(userSearchValue) >
            -1 || item.email.toLowerCase().indexOf(userSearchValue) > -1
      );
    },
    get allUsersCount() {
      return self.addedUsers.length + self.usersToAdd.length;
    },
    get fieldErrors() {
      if (!self.name) {
        return { name: i18next.t("projects:errors:name:required") };
      }
      if (self.name.length > 50) {
        return { name: i18next.t("projects:errors:name:maxLength") };
      }
      if (!/^[a-z0-9-._]+$/i.test(self.name)) {
        return { name: i18next.t("projects:errors:name:pattern") };
      }
      if (self.serverError === "DUPLICATE_NAME") {
        return { name: i18next.t("projects:errors:name:duplicate") };
      }
      return {};
    },
    get environmentsErrors() {
      return self.environments.map((environment) =>
        environment.fieldErrors(self.environments)
      );
    },
    get inviteErrors() {
      return self.invites.map((invite) =>
        invite.fieldErrors(false, self.invites)
      );
    },
    isValid(step: "first" | "second") {
      switch (step) {
        case "first":
          return (
            isEmpty(this.fieldErrors) &&
            this.environmentsErrors.every((error) => isEmpty(error))
          );
        case "second":
          return this.inviteErrors.every((error) => isEmpty(error));
        default:
          return false;
      }
    },
  }))
  .actions((self) => ({
    inviteUsers: flow(function* (id) {
      const emails = map(
        [...self.invites, ...self.addedUsers],
        (user) => user.email
      ).filter(Boolean);
      try {
        yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${id}/invite`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ emails }),
          }
        );
        applySnapshot(self.invites, []);
        applySnapshot(self.addedUsers, []);
      } catch (error: any) {
        console.error("Error in inviteUsers method", error?.message);
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    setName(name: string) {
      if (self.serverError === "DUPLICATE_NAME") self.serverError = "";
      self.name = name;
    },
    setBaseUrl(baseUrl: string) {
      self.baseUrl = baseUrl;
    },
    initUsers() {
      const { users } = getRoot<IRoot>(self);
      applySnapshot(
        self.usersToAdd,
        users.activeMembers.map((user) => clone(user))
      );
      applySnapshot(
        self.addedUsers,
        users.activeOwners.map((user) => clone(user))
      );
    },
    initUsersToAdd: flow(function* () {
      const usersToAdd: IUsers = Users.create();
      try {
        yield usersToAdd.load();
        usersToAdd.setSortParam("by_name");
        const usersInProjectIds = self.ProjectUsers.map((user) => user.User.id);
        applySnapshot(
          self.usersToAdd,
          usersToAdd.activeMembers
            .filter((user) => !usersInProjectIds.includes(user.id))
            .map((user) => clone(user))
        );
        destroy(usersToAdd);
      } catch (e) {
        console.error(`Error while initUsersToAdd: ${e}`);
      }
    }),
    setOrganizationId() {
      const { users } = getRoot<IRoot>(self);
      self.OrganizationId = users.profile.OrganizationId;
    },
    onAddEnvironment() {
      self.environments.push(
        ProjectEnvironment.create({ basic: self.environments.length === 0 })
      );
    },
    onRemoveEnvironment(environment: IProjectEnvironment) {
      destroy(environment);
    },
    onAddInvite() {
      self.invites.push(ProjectInvite.create({}));
    },
    onRemoveInvite(invite: IProjectInvite) {
      destroy(invite);
    },
    onAddUser(user: IUser) {
      const userToAdd = find(self.usersToAdd, { id: user.id });
      if (!userToAdd) return;
      self.addedUsers.push(clone(userToAdd));
      destroy(userToAdd);
    },
    onRemoveUser(user: IUser) {
      const userToRemove = find(self.addedUsers, { id: user.id });
      if (!userToRemove) return;
      self.usersToAdd.push(clone(userToRemove));
      destroy(userToRemove);
    },
    onUserSearchChange(searchValue: string) {
      self.userSearchValue = searchValue;
    },
    setServerError(error: string) {
      self.serverError = error;
    },
    setAddedUsers(users: IUser[]) {
      applySnapshot(
        self.addedUsers,
        users.map((user) => clone(user))
      );
    },
    setInvites(invites: IProjectInviteSnapshotOut[]) {
      applySnapshot(
        self.invites,
        map(invites, (invite) => ProjectInvite.create(invite))
      );
    },
  }))
  .actions((self) => ({
    validateProjectNameUniq: flow(function* () {
      try {
        const params = new URLSearchParams({ name: self.name });
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${self.id}/validate?${params}`,
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        const result = yield response.json();

        self.setServerError(result ? "DUPLICATE_NAME" : "");
      } catch (error) {
        throw error;
      }
    }),
    validateInvites: flow(function* () {
      try {
        const emails = map(self.invites, (invite) => invite.email);
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/auth/validate`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ emails }),
          }
        );

        const { emails: existingEmails } = yield response.json();

        self.invites.forEach((invite) => {
          invite.setServerError(
            existingEmails.includes(invite.email) ? "EXISTING_EMAIL" : ""
          );
        });
      } catch (error) {
        throw error;
      }
    }),
    createProject: flow(function* () {
      try {
        self.environments.forEach((env) => {
          if (env.name.length === 0 && env.url.length === 0)
            env.removeEnvironment();
        });

        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(getSnapshot(self)),
          }
        );
        const item = yield response.json();

        if (item.status === "ERROR") throw new Error(item.message);

        if (self.invites.length || self.addedUsers.length)
          yield self.inviteUsers(item.id);

        getParentOfType(self, Projects).onProjectCreated();

        return item;
      } catch (error) {
        if (self.environments.length === 0) self.onAddEnvironment();
        throw error;
      }
    }),
    remove: flow(function* () {
      try {
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${self.id}`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
          }
        );

        if (!response.ok) throw new Error("Could not do remove request");
        getParentOfType(self, Projects).onProjectRemove(self as IProject);
      } catch (error) {
        throw error;
      }
    }),
  }));

export interface IProject extends Instance<typeof Project> {}
export interface IProjectSnapshotOut extends SnapshotOut<typeof Project> {}

export default Project;
