import { FC } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { ProjectTeam, SettingsHeader } from "../components";

const useStyles = makeStyles(() => ({
  settingsContent: {
    minHeight: 0,
  },
}));

const Settings: FC = () => {
  const { url, path } = useRouteMatch();
  const classes = useStyles();
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item container direction="column" wrap="nowrap">
        <SettingsHeader />
      </Grid>
      <Grid container direction="column" className={classes.settingsContent}>
        <Switch>
          <Route path={`${path}/configurations`}>Configurations</Route>
          <Route path={`${path}/team`} component={ProjectTeam} />
          <Route path={`${path}/environment`}>Environment</Route>
          <Route path={url}>
            <Redirect to={`${url}/team`} />
          </Route>
        </Switch>
      </Grid>
    </Grid>
  );
};

export default Settings;
