import { types, Instance, flow } from "mobx-state-tree";
import i18next from "i18next";
import _, { identity, isEmpty, pickBy } from "lodash";
import { handleFetch } from "./utils";
import WithSelected from "./WithSelected";
import EnvironmentVariable, {
  IEnvironmentVariable,
} from "./EnvironmentVariable";

const Variable = types
  .compose(
    types.model("Variable", {
      ProjectId: types.maybe(types.number),
      EnvironmentVariables: types.optional(
        types.array(EnvironmentVariable),
        [],
      ),
      createdAt: types.maybe(types.string),
      id: types.maybe(types.number),
      name: "",
      value: types.maybeNull(types.string),
      global: types.boolean,
      copied: false,
      serverError: "",
    }),
    WithSelected,
  )
  .views((self) => ({
    basicEnvironment(id: number): IEnvironmentVariable {
      return _.find(
        self.EnvironmentVariables,
        (item) => item.EnvironmentId === id,
      ) as IEnvironmentVariable;
    },
    get error() {
      const errors: { name?: string; value?: string } = {};
      if (!self.name) {
        errors.name = i18next.t("variables:errors:name:required");
      } else if (self.name.length > 30) {
        errors.name = i18next.t("variables:errors:name:maxLength");
      } else if (!/^[a-z0-9_]+$/i.test(self.name)) {
        errors.name = i18next.t("variables:errors:name:pattern");
      } else if (self.serverError === "ERROR_UNIQUE") {
        errors.name = i18next.t("variables:errors:name:duplicate");
      }
      if (self.value && self.value.length > 255) {
        errors.value = i18next.t("variables:errors:value:maxLength");
      }
      return pickBy(errors, identity);
    },
  }))
  .views((self) => ({
    get isValid() {
      return isEmpty(self.error);
    },
  }))
  .actions((self) => ({
    save: flow(function* (projectId: string) {
      try {
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${projectId}/variables`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              name: self.name,
              value: self.value,
              global: self.global,
            }),
          },
        );

        if (!response) return;

        const variable = yield response.json();

        return variable;
        //TODO: Show Sucsess msg
      } catch (error: any) {
        //TODO: Show Error msg
        self.serverError = error?.code;
        console.error("Error while variable create in Variable.save", error);
        throw error;
      }
    }),
    remove: flow(function* () {
      //TODO
    }),
  }))
  .actions((self) => ({
    showCopiedNotification() {
      self.copied = true;
    },
    hideCopiedNotification() {
      self.copied = false;
    },
    copyToClipboard() {
      if (!self.value) return;
      navigator &&
        navigator.clipboard &&
        navigator.clipboard.writeText(self.value);
    },
    setVariableName(name: string) {
      if (self.serverError === "ERROR_UNIQUE") self.serverError = "";
      self.name = name;
    },
    setVariableValue(value: string) {
      self.value = value;
    },
    setGlobal(isGlobal: boolean) {
      self.global = isGlobal;
    },
    setServerError(error: string) {
      self.serverError = error;
    },
  }));

export interface IVariable extends Instance<typeof Variable> {}
export default Variable;
