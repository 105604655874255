import { FC } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IProject } from "../../models";
import ProjectItemMenu from "./ProjectItemMenu";

const useStyles = makeStyles((theme: Theme) => ({
  projectLink: {
    textDecoration: "none",
    "&:last-child $projectContent": {
      border: "none",
    },
  },
  projectContainer: {
    height: "4.5625rem",
    padding: theme.spacing(0, 4),
    backgroundColor: theme.palette.white.main,
  },
  projectContent: {
    borderBottom: `1px solid ${theme.palette.catskillWhite.main}`,
  },
  column: {
    color: theme.palette.mineShaft.main,
    ...theme.typography.bodyTextStyle,
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: 4,
  },
  columnXs: {
    flex: 1,
  },
  columnSm: {
    flex: 2,
  },
  columnMd: {
    flex: 3,
  },
  columnLg: {
    flex: 4,
  },
  projectLogo: {
    height: "2.5rem",
    margin: theme.spacing(2, 0),
  },
}));

interface IProjectListItem {
  project: IProject;
}

const ProjectListItem: FC<IProjectListItem> = ({ project }) => {
  const classes = useStyles();
  const { t } = useTranslation(["projects"]);

  return (
    <Link to={`/projects/${project.id}/tests`} className={classes.projectLink}>
      <Grid
        item
        container
        direction="column"
        className={classes.projectContainer}
        wrap="nowrap"
      >
        <Grid
          container
          alignItems="center"
          wrap="nowrap"
          className={classes.projectContent}
        >
          <Grid
            item
            container
            className={clsx(classes.column, classes.columnMd)}
          >
            {/*ToDo: use real project logo*/}
            <img
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="logo"
              className={classes.projectLogo}
            />
          </Grid>
          <Grid item className={clsx(classes.column, classes.columnLg)}>
            {project.name}
          </Grid>
          <Grid item className={clsx(classes.column, classes.columnLg)}>
            {project.baseUrl}
          </Grid>
          <Grid item className={clsx(classes.column, classes.columnSm)}>
            {t(`roles.${project.yourRole}`)}
          </Grid>
          <Grid item className={clsx(classes.column, classes.columnSm)}>
            {project.TestCount}
          </Grid>
          <Grid item className={clsx(classes.column, classes.columnXs)}>
            {project.ProjectUsers.length}
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            className={clsx(classes.column, classes.columnXs)}
          >
            <ProjectItemMenu project={project} />
          </Grid>
        </Grid>
      </Grid>
    </Link>
  );
};

export default ProjectListItem;
