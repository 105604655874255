import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles, createStyles } from "@mui/styles";
import { Divider, Tabs, Tab, Theme } from "@mui/material";
import { useRootStoreContext } from "../../hooks";
import ActionsList from "./ActionsList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootBox: {
      margin: theme.spacing(2, 0, 0, 3),
      paddingRight: theme.spacing(3),
    },
    tabIndicator: {
      backgroundColor: theme.palette.baliHai.main,
    },
    tab: {
      minWidth: 0,
      padding: theme.spacing(0, 1),
      margin: theme.spacing(0, -1),
      textTransform: "none",
      fontWeight: "normal",
      marginLeft: theme.spacing(4),
      height: 48,
      opacity: 1,
      color: theme.palette.doveGray.main,
      "&.tabSelected": {
        color: theme.palette.mineShaft.main,
      },
    },
    divider: {
      backgroundColor: theme.palette.catskillWhite.main,
      margin: theme.spacing(0, 4),
    },
  })
);

const Actions: React.FC = observer(() => {
  const { t } = useTranslation([
    "side_bar_of_actions_elements_shared_steps",
    "common",
  ]);
  const classes = useStyles();
  const { actions } = useRootStoreContext();

  return (
    <>
      <Tabs
        value={actions.selectedTabName}
        onChange={(e, newValue) => actions.setSelectedTabName(newValue)}
        scrollButtons={false}
        TabIndicatorProps={{ className: classes.tabIndicator }}
      >
        <Tab
          label={t("element")}
          className={classes.tab}
          classes={{ selected: "tabSelected" }}
          value="element"
        ></Tab>
        <Tab
          label={t("browser")}
          classes={{ selected: "tabSelected" }}
          className={classes.tab}
          value="browser"
        />
      </Tabs>
      <Divider className={classes.divider} />
      <ActionsList actions={actions} />
    </>
  );
});

export default Actions;
