import { FC } from "react";
import clsx from "clsx";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { INotification } from "../../models/Notification";

const useStyles = makeStyles((theme: Theme) => ({
  notificationContainer: {
    padding: theme.spacing(2, 3, 0),
    "&.unread": {
      backgroundColor: theme.palette.catskillLightWhite.main,
    },
    "&:not(:last-child) $notificationDate": {
      borderBottom: `1px solid ${theme.palette.catskillWhite.main}`,
    },
  },
  notificationTitle: {
    marginBottom: theme.spacing(),
    color: theme.palette.mineShaft.main,
    ...theme.typography.subHeaderTextStyle,
  },
  notificationText: {
    marginBottom: "0.25rem",
    color: theme.palette.mineShaft.main,
    ...theme.typography.bodyTextStyle,
  },
  notificationDate: {
    paddingBottom: "1rem",
    color: theme.palette.doveGray.main,
    ...theme.typography.descriptionTextStyle,
  },
}));

interface INotificationItem {
  notification: INotification;
}

const NotificationItem: FC<INotificationItem> = ({ notification }) => {
  const classes = useStyles();
  const { title, message, createdAt, read } = notification;
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={clsx(classes.notificationContainer, { unread: !read })}
    >
      <Grid item className={classes.notificationTitle}>
        {title}
      </Grid>
      <Grid item className={classes.notificationText}>
        {message}
      </Grid>
      <Grid item className={classes.notificationDate}>
        {createdAt}
      </Grid>
    </Grid>
  );
};

export default NotificationItem;
