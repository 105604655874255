import { FC, SyntheticEvent } from "react";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { IVariable } from "../../models";
import { ContentCopy, DoneOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const styles = {
  iconContainer: {
    position: "absolute",
    left: "-2rem",
    visibility: "hidden",
  },
  copyIcon: {
    width: "1.5rem",
    height: "1.5rem",
    color: "rockBlue.main",
    "&:hover": {
      color: "outrageousOrange.main",
    },
  },
};

interface IVariableNameCell {
  variable: IVariable;
}

const VariableNameCell: FC<IVariableNameCell> = observer(({ variable }) => {
  const { t } = useTranslation(["variables"]);

  const onVariableNameCopy = (e: SyntheticEvent) => {
    e.stopPropagation();
    variable.showCopiedNotification();
    variable.copyToClipboard();
  };

  return (
    <Grid container alignItems="center" wrap="nowrap">
      <Grid
        item
        title={variable.copied ? t("copied") : t("copy")}
        className="visibleOnHoverContent"
        sx={styles.iconContainer}
        onClick={onVariableNameCopy}
      >
        {variable.copied ? (
          <DoneOutlined
            sx={styles.copyIcon}
            onMouseLeave={() => variable.hideCopiedNotification()}
          />
        ) : (
          <ContentCopy sx={styles.copyIcon} />
        )}
      </Grid>
      <Grid item>{variable.value}</Grid>
    </Grid>
  );
});

export default VariableNameCell;
