import { FC } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IProject } from "../../models";
import UserList from "./UserList";

const useStyles = makeStyles((theme: Theme) => ({
  memberTitle: {
    marginBottom: theme.spacing(1),
    color: theme.palette.mineShaft.main,
    ...theme.typography.subHeaderTextStyle,
  },
}));

interface IMembersAdded {
  project: IProject;
}

const MembersAdded: FC<IMembersAdded> = observer(({ project }) => {
  const classes = useStyles();
  const { t } = useTranslation(["project_create"]);
  if (!project.addedUsers.length) return null;
  return (
    <Grid container direction="column">
      <Grid item className={classes.memberTitle}>
        {t("members")}
      </Grid>
      <Grid item>
        <UserList
          users={project.addedUsers}
          userActionType="remove"
          onUserAction={project.onRemoveUser}
        />
      </Grid>
    </Grid>
  );
});

export default MembersAdded;
