import { FC } from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { Avatar, Grid, Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  listColName: {
    flex: "none",
    paddingRight: 4,
  },
  userAvatar: {
    width: "2.5rem",
    height: "2.5rem",
    marginRight: theme.spacing(),
  },
  userDescriptionContainer: {
    minWidth: 0,
  },
  userName: {
    color: theme.palette.mineShaft.main,
    ...theme.typography.bodyTextStyle,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  userEmail: {
    color: theme.palette.doveGray.main,
    ...theme.typography.descriptionTextStyle,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

interface ITableCellProfile {
  firstLastName?: string | null;
  email?: string | null;
}

const TableCellProfile: FC<ITableCellProfile> = observer(
  ({ firstLastName, email }) => {
    const classes = useStyles();

    return (
      <Grid item container wrap="nowrap" className={classes.listColName}>
        <Grid item>
          <Avatar className={classes.userAvatar}>
            {firstLastName?.charAt(0)}
          </Avatar>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          wrap="nowrap"
          className={classes.userDescriptionContainer}
        >
          <Grid item className={classes.userName}>
            {firstLastName}
          </Grid>
          <Grid item className={classes.userEmail}>
            {email}
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

export default TableCellProfile;
