import { types, Instance, flow, SnapshotOut } from "mobx-state-tree";
import i18next from "i18next";
import { identity, isEmpty, pickBy } from "lodash";
import EnvironmentVariable from "./EnvironmentVariable";
import WithSelected from "./WithSelected";
import { handleFetch } from "./utils";

const Environment = types
  .compose(
    types.model("Environment", {
      ProjectId: types.maybe(types.number),
      BaseEnvironmentId: types.maybeNull(types.number),
      createdAt: types.maybe(types.string),
      id: types.maybe(types.number),
      name: "",
      basic: types.boolean,
      EnvironmentVariables: types.optional(
        types.array(EnvironmentVariable),
        []
      ),
      copyValue: types.maybe(types.boolean),
      copyValuesFrom: "",
      serverError: "",
    }),
    WithSelected
  )
  .views((self) => ({
    get baseUrl() {
      return self.EnvironmentVariables[0]?.value || "";
    },
    get error() {
      const errors: { name?: string; copyValuesFrom?: string } = {};
      if (!self.name) {
        errors.name = i18next.t("environments:errors:name:required");
      } else if (self.name.length > 50) {
        errors.name = i18next.t("environments:errors:name:maxLength");
      } else if (/[\\/:*?"<>|]+/i.test(self.name)) {
        errors.name = i18next.t("environments:errors:name:pattern");
      } else if (self.serverError === "ERROR_UNIQUE") {
        errors.name = i18next.t("environments:errors:name:duplicate");
      }
      if (self.copyValue && !self.copyValuesFrom) {
        errors.copyValuesFrom = i18next.t(
          "environments:errors:copy_value_from:required"
        );
      }
      return pickBy(errors, identity);
    },
  }))
  .views((self) => ({
    get isValid() {
      return isEmpty(self.error);
    },
  }))
  .actions((self) => ({
    save: flow(function* (projectId: string) {
      try {
        const url = self.copyValue
          ? `${process.env.REACT_APP_API_URL}/projects/${projectId}/environments/${self.copyValuesFrom}/copy`
          : `${process.env.REACT_APP_API_URL}/projects/${projectId}/environments`;

        const response = yield handleFetch(self, url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: self.name,
          }),
        });

        if (!response) return;

        const environment = yield response.json();

        return environment;
        //TODO: Show Sucsess msg
      } catch (error: any) {
        //TODO: Show Error msg
        self.serverError = error?.code;
        console.error(
          "Error while environment create in Environment.save",
          error
        );
        throw error;
      }
    }),
    remove: flow(function* () {
      //TODO
    }),
    setEnvironmentName(name: string) {
      if (self.serverError === "ERROR_UNIQUE") self.serverError = "";
      self.name = name;
    },
    toggleCopyValue() {
      self.copyValue = !self.copyValue;
      self.copyValuesFrom = "";
    },
    setCopyValuesFrom(environment: string) {
      self.copyValuesFrom = environment;
    },
    setServerError(error: string) {
      self.serverError = error;
    },
  }));

export interface IEnvironment extends Instance<typeof Environment> {}
export interface IEnvironmentSnapshotOut
  extends SnapshotOut<typeof Environment> {}
export default Environment;
