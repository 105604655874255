import { types, Instance, SnapshotOut, applySnapshot } from "mobx-state-tree";
import _ from "lodash";
import { createBrowserHistory } from "history";
import { PeriodFilterType, IPeriodFilterType, updateUrlFilter } from "./utils";
import { IFilterAuthor } from "./FilterAuthor";
import { ILabel } from "./Label";

const history = createBrowserHistory();

const ListFilters = types
  .model("List Filters", {
    projectId: "",
    nameFilter: "",
    creatorsFilter: types.optional(types.array(types.string), []),
    updatersFilter: types.optional(types.array(types.string), []),
    periodFilter: types.maybeNull(PeriodFilterType),
    labelFilter: types.optional(types.array(types.string), []),
  })
  .views((self) => ({
    get isEmpty() {
      return _.reduce(
        self,
        (r, value, key) => (key === "projectId" ? r : r && _.isEmpty(value)),
        true
      );
    },
    get periodFilterTypes() {
      return [
        "today",
        "yesterday",
        "thisWeek",
        "lastWeek",
        "thisMonth",
        "lastMonth",
        "lastThreeMonths",
      ] as const;
    },
    get filterUrl() {
      return window.location.search;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      const filters = new URLSearchParams(window.location.search);
      self.nameFilter = filters.get("nameFilter") || "";
      applySnapshot(self.creatorsFilter, filters.getAll("createdBy"));
      applySnapshot(self.updatersFilter, filters.getAll("updatedBy"));
      applySnapshot(self.labelFilter, filters.getAll("label"));
      const periodFilterType = filters.get("periodFilter") as IPeriodFilterType;
      if (self.periodFilterTypes.includes(periodFilterType || "")) {
        self.periodFilter = periodFilterType;
      }
    },
  }))
  .actions((self) => ({
    setProjectId(projectId: string) {
      self.projectId = projectId;
    },
    saveNameFilter(nameFilter: string) {
      self.nameFilter = nameFilter;
      updateUrlFilter("nameFilter", nameFilter);
    },
    clearNameFilter() {
      self.nameFilter = "";
      updateUrlFilter("nameFilter", "");
    },
    saveCreatorsFilter(creators: IFilterAuthor[]) {
      const creatorEmails = _.map(creators, "email");
      applySnapshot(self.creatorsFilter, creatorEmails);
      updateUrlFilter("createdBy", creatorEmails);
    },
    clearCreatorsFilter(creator: string) {
      const creators = _.without(self.creatorsFilter, creator);
      applySnapshot(self.creatorsFilter, creators);
      updateUrlFilter("createdBy", creators);
    },
    saveUpdatersFilter(updaters: IFilterAuthor[]) {
      const updaterEmails = _.map(updaters, "email");
      applySnapshot(self.updatersFilter, updaterEmails);
      updateUrlFilter("updatedBy", updaterEmails);
    },
    clearUpdatersFilter(updater: string) {
      const updaters = _.without(self.updatersFilter, updater);
      applySnapshot(self.updatersFilter, updaters);
      updateUrlFilter("updatedBy", updaters);
    },
    savePeriodFilter(value: IPeriodFilterType) {
      self.periodFilter = value;
      updateUrlFilter("periodFilter", value);
    },
    clearPeriodFilter() {
      self.periodFilter = null;
      updateUrlFilter("periodFilter", "");
    },
    saveLabelFilter(labels: ILabel[]) {
      const labelNames = _.map(labels, "name");
      applySnapshot(self.labelFilter, labelNames);
      updateUrlFilter("label", labelNames);
    },
    clearLabelFilter(label: string) {
      const labels = _.without(self.labelFilter, label);
      applySnapshot(self.labelFilter, labels);
      updateUrlFilter("label", labels);
    },
    clearAllFilters() {
      applySnapshot(self, {});
      //ToDo: revert once fixed https://github.com/ReactTraining/history/issues/814
      history.push({ pathname: window.location.pathname, search: "" });
    },
  }))
  .actions((self) => ({
    getTestsClearAction(filterName: string, filter: any) {
      switch (filterName) {
        case "nameFilter":
          return self.clearNameFilter;
        case "creatorsFilter":
          return () => self.clearCreatorsFilter(filter!);
        case "updatersFilter":
          return () => self.clearUpdatersFilter(filter!);
        case "periodFilter":
          return self.clearPeriodFilter;
        case "labelFilter":
          return () => self.clearLabelFilter(filter!);
        default:
          return () => null;
      }
    },
  }));
export interface IListFilters extends Instance<typeof ListFilters> {}
export interface IListFiltersSnapshotOut
  extends SnapshotOut<typeof ListFilters> {}

export default ListFilters;
