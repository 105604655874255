import React from "react";
import { observer } from "mobx-react";
import Tabs from "./Tabs";
import { Grid, AppBar } from "@mui/material";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router-dom";
import { addResourcesBundle } from "../../i18n";
import ElementFilterRow from "../ElementsList/ElementFilterRow";
import VariablesFilterRow from "../Variables/VariablesFilterRow";
import en from "./en.json";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    marginBottom: "2rem",
    boxShadow: "none",
  },
}));

const DataHeader: React.FC = observer(() => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const isElements = pathname.includes("elements");
  const isVariables = pathname.includes("environments");

  return (
    <AppBar position="sticky" color="inherit" className={classes.mainContainer}>
      <Grid container wrap="nowrap">
        <Grid container item direction="column" wrap="nowrap">
          <Tabs />
          {isElements ? <ElementFilterRow /> : null}
          {isVariables ? <VariablesFilterRow /> : null}
        </Grid>
      </Grid>
    </AppBar>
  );
});

export default DataHeader;
