import React from "react";
import { map } from "lodash";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Menu, MenuItem, Divider } from "../../Menus";
import { IPeriodFilterType } from "../../../models/utils";
import { addResourcesBundle } from "../../../i18n";
import { filterStore } from "../../FilterBar";
import TaIcon from "../../TaIcon";
import en from "../en.json";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  iconClass: {
    marginLeft: 20,
    "&.MuiSvgIcon-root": {
      marginRight: 0,
    },
  },
  periodFilterTitle: {
    ...theme.typography.descriptionTextStyle,
    color: theme.palette.doveGray.main,
    margin: theme.spacing(2, 3, 0),
  },
}));

interface IPeriodFilter {
  title: string;
  filterStore: filterStore;
  anchorEl: null | HTMLElement;
  onMenuClose: () => void;
  onSave: (value: IPeriodFilterType) => void;
}

const PeriodFilter: React.FC<IPeriodFilter> = observer(
  ({ anchorEl, filterStore, title, onMenuClose, onSave }) => {
    const classes = useStyles();
    const { t } = useTranslation(["filters"]);

    const savePeriodFilter = (value: IPeriodFilterType) => {
      onSave(value);
      onMenuClose();
    };

    if (!anchorEl) return null;
    if (!("periodFilterTypes" in filterStore)) return null;

    return (
      <Menu anchorEl={anchorEl} onClose={onMenuClose}>
        <Box className={classes.periodFilterTitle}>{title}</Box>
        {map(filterStore.periodFilterTypes, (value) => (
          <MenuItem key={value} onClick={() => savePeriodFilter(value)}>
            {t(`period_filter.${value}`)}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem>
          {t("period_filter.select_period")}
          <TaIcon
            iconName="ArrowForwardIosOutlined"
            className={classes.iconClass}
          />
        </MenuItem>
      </Menu>
    );
  }
);

export default PeriodFilter;
