import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Theme, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDragLayer, XYCoord } from "react-dnd";
import TaIcon from "./TaIcon";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    height: 40,
    maxWidth: 264,
    width: "fit-content",
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.shark.main,
    "&:hover": {
      cursor: "grabbing",
    },
  },
  text: {
    marginLeft: theme.spacing(1),
    color: theme.palette.white.main,
    ...theme.typography.bodyTextStyle,
  },
  foldersIcon: {
    color: theme.palette.baliHai.main,
    lineHeight: 1,
  },
}));

const getStyles = (
  isDragging: boolean,
  offset: XYCoord | null,
  dragLayerRef: React.RefObject<HTMLDivElement>
) => {
  if (!isDragging || !offset) return { display: "none" };
  const elementHeight = (dragLayerRef.current?.offsetHeight || 0) / 2;
  const elementWidth = (dragLayerRef.current?.offsetWidth || 0) / 2;

  const transform = `translate(${offset.x - elementWidth}px, ${
    offset.y - elementHeight
  }px)`;
  return {
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    pointerEvents: "none",
    transform,
    WebkitTransform: transform,
  } as React.CSSProperties;
};

interface IDragLayer {
  itemType: string;
  iconName: string;
}

const ItemDragLayer: React.FC<IDragLayer> = ({ itemType, iconName }) => {
  const classes = useStyles();
  const dragLayerRef = useRef(null);
  const { t } = useTranslation(["suites"]);
  const { type, isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    type: monitor.getItemType(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging || type !== itemType) return null;

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      justifyContent="flex-start"
      ref={dragLayerRef}
      className={classes.itemWrapper}
      style={getStyles(isDragging, currentOffset, dragLayerRef)}
    >
      <Grid item className={classes.foldersIcon}>
        <TaIcon iconName={iconName} />
      </Grid>
      <Grid item zeroMinWidth>
        <Typography className={classes.text} noWrap>
          {t("move", {
            name: item.skipQuotes
              ? item.dragLayerText
              : `${item.dragLayerText}`,
            interpolation: {
              escapeValue: false,
            },
          })}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ItemDragLayer;
