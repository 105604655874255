import { FC } from "react";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { DataHeader } from "../components";
import { Elements, Variables } from "./";

const Data: FC = observer(() => {
  const { url, path } = useRouteMatch();

  return (
    <Grid container direction="column" wrap="nowrap">
      <DataHeader />
      <Switch>
        <Route path={`${path}/elements`} component={Elements} />
        <Route
          path={`${path}/environments`}
          component={Variables}
          exact={true}
        />
        <Route
          path={`${path}/environments/:environmentId/variables`}
          component={Variables}
        />
        <Route path={url}>
          <Redirect to={`${url}/elements`} />
        </Route>
      </Switch>
    </Grid>
  );
});

export default Data;
