import { FC } from "react";
import _ from "lodash";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { List, Theme } from "@mui/material";
import { useRootStoreContext } from "../../hooks";
import { ITest } from "../../models";
import { EmptyContainer } from "../index";
import TestItem from "./TestItem";

const useStyles = makeStyles((theme: Theme) => ({
  listContainer: {
    width: "100%",
    overflowY: "auto",
    paddingTop: theme.spacing(3),
  },
}));

interface ITestList {
  useSharedSteps?: boolean;
}

const TestList: FC<ITestList> = observer(({ useSharedSteps = true }) => {
  const classes = useStyles();
  const { tests } = useRootStoreContext();
  const { t } = useTranslation("tests");
  if (!tests.preparedItems?.length && !tests.newTest)
    return (
      <EmptyContainer
        title={t("emptyListTitle")}
        description={t("emptyListDescription")}
      />
    );

  return (
    <List className={classes.listContainer}>
      {tests.newTest ? (
        <TestItem
          key="new_test"
          test={tests.newTest}
          useSharedSteps={useSharedSteps}
        />
      ) : null}
      {tests.preparedItems?.length
        ? _.map(tests.preparedItems, (item: ITest) => (
            <TestItem
              key={item.id}
              test={item}
              useSharedSteps={useSharedSteps}
            />
          ))
        : null}
    </List>
  );
});

export default TestList;
