import React from "react";
import { observer } from "mobx-react";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Checkbox } from "../../Inputs";
import { MenuItem } from "../../Menus";
import { IFilterAuthor } from "../../../models";

const useStyles = makeStyles((theme: Theme) => ({
  labelItem: {
    padding: 0,
    "&:hover $controlContainer": {
      visibility: "visible",
    },
  },
}));

interface IAuthorItem {
  testAuthor: IFilterAuthor;
}

const AuthorItem: React.FC<IAuthorItem> = observer(({ testAuthor }) => {
  const classes = useStyles();
  return (
    <MenuItem className={classes.labelItem}>
      <Checkbox
        checked={testAuthor.selected}
        onChange={testAuthor.onSelect}
        label={testAuthor.email}
      />
    </MenuItem>
  );
});

export default AuthorItem;
