import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import TaIcon from "../TaIcon";

const useStyles = makeStyles((theme: Theme) => ({
  emptyMessage: {
    lineHeight: "100%",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  subheader: {
    alignItems: "center",
    justifyContent: "center",
  },
  subheaderText: {
    ...theme.typography.subHeaderTextStyle,
    color: theme.palette.doveGray.main,
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1),
    color: theme.palette.baliHai.main,
  },
  descriptionText: {
    ...theme.typography.bodyTextStyle,
    marginTop: theme.spacing(1),
    color: theme.palette.baliHai.main,
  },
}));

const EmptyListMessage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation(["test_steps"]);
  return (
    <Grid container direction="column" className={classes.emptyMessage}>
      <Grid item container className={classes.subheader}>
        <TaIcon iconName="InfoOutlined" className={classes.icon} />
        <Grid item className={classes.subheaderText}>
          {t("empty_list_message")}
        </Grid>
      </Grid>
      <Grid item className={classes.descriptionText}>
        {t("empty_list_description")}
      </Grid>
    </Grid>
  );
};

export default EmptyListMessage;
