import { FC } from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { map } from "lodash";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import { ITableColumn, ITableRowData } from "./index";
import TableCell from "./TableCell";

const useStyles = makeStyles((theme: Theme) => ({
  rowContainer: {
    height: "4.5625rem",
    minHeight: "4.5625rem",
    padding: theme.spacing(0, 4),
    backgroundColor: theme.palette.white.main,
    borderLeft: `4px solid ${theme.palette.white.main}`,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.catskillLightWhite.main,
      borderLeft: `4px solid ${theme.palette.catskillLightWhite.main}`,
      "& .visibleOnHoverContent": {
        visibility: "visible",
      },
    },
    "&:last-of-type $rowContent": {
      border: "none",
    },
    "&.selected": {
      backgroundColor: theme.palette.catskillLightWhite.main,
      borderLeft: `4px solid ${theme.palette.outrageousOrange.main}`,
    },
  },

  rowContent: {
    height: "100%",
    display: "grid",
    position: "relative",
    borderBottom: `1px solid ${theme.palette.catskillWhite.main}`,
  },
}));

export interface ITableRow {
  columns: ITableColumn[];
  row: ITableRowData;
  customClassName?: string;
}

const TableRow: FC<ITableRow> = observer(
  ({ columns, row, customClassName }) => {
    const classes = useStyles();
    const gridTemplateColumns = map(columns, ({ width }) => width).join(" ");

    return (
      <Grid
        item
        container
        direction="column"
        className={clsx(
          classes.rowContainer,
          { selected: row.selected },
          customClassName
        )}
        wrap="nowrap"
        onClick={row.toggleSelected}
      >
        <Grid
          container
          alignItems="center"
          wrap="nowrap"
          className={classes.rowContent}
          style={{ gridTemplateColumns }}
        >
          {map(columns, (column, i) => (
            <TableCell key={`table_cell_${i}`} column={column} row={row} />
          ))}
        </Grid>
      </Grid>
    );
  }
);

export default TableRow;
