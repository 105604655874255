import { FC, useEffect } from "react";
import { observer } from "mobx-react";
import { map } from "lodash";
import { useTranslation } from "react-i18next";
import { IUser } from "../../models";
import { useRootStoreContext } from "../../hooks";
import BulkMenus, { DeactivatedUsersBulkMenu } from "../BulkMenus";
import Table, { ITableColumn } from "../Table";

const DeactivatedUsers: FC = observer(() => {
  const { t } = useTranslation(["users"]);
  const { users } = useRootStoreContext();

  useEffect(() => {
    users.setSortParam("last_deactivated");
  }, [users]);

  const columns: ITableColumn[] = [
    {
      type: "profile",
      label: t("name"),
      width: "4fr",
    },
    {
      key: "role",
      label: t("role"),
      width: "2fr",
    },
    {
      type: "date",
      key: "updatedAt",
      label: t("deactivated"),
      width: "3fr",
    },
    {
      type: "menu",
      width: "24px",
    },
  ];

  const menuItems = (user: IUser) => [
    {
      label: t("activate"),
      iconName: "ArchiveOutlined",
      onClick: async () => {
        await users.toggleUserLock({ userIds: [user.id], lockState: false });
        await users.load();
      },
      isConfirm: true,
      confirmText: t("common:confirm"),
    },
  ];

  const userItems = map(users.deactivatedUsers, (item) => ({
    ...item,
    role: t(`roles.${item.role}`),
    toggleSelected: item.toggleSelected,
    menuItems: menuItems(item),
  }));

  return (
    <>
      <Table
        columns={columns}
        rows={userItems}
        emptyListText={t("no_users")}
        emptyListDescription={t("no_users_description_deactivated")}
      />
      {users.getSelectedSize(users.deactivatedUsers) > 1 ? (
        <BulkMenus>
          <DeactivatedUsersBulkMenu />
        </BulkMenus>
      ) : null}
    </>
  );
});

export default DeactivatedUsers;
