import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import _ from "lodash";
import { NumberTextField } from "../Inputs";
import { ITestStep } from "../../models";

const useStyles = makeStyles((theme: Theme) => ({
  inputBlock: {
    marginBottom: theme.spacing(4),
  },
}));

interface ISetWindowSizeAction {
  testStep: ITestStep;
}

const SetWindowSizeAction: React.FC<ISetWindowSizeAction> = observer(
  ({ testStep }) => {
    const classes = useStyles();
    const { t } = useTranslation(["actions"]);
    const bounced = useCallback(
      () => _.debounce(testStep.save, 1000),
      [testStep.save]
    );

    useEffect(() => {
      if (Object.keys(testStep.jsonbValueSize).length === 0) {
        testStep.addNewWindowSize();
      }
    }, [testStep, testStep.type]);

    const onChangeWidth = (e: React.ChangeEvent<HTMLInputElement>) => {
      testStep.setWidth(e.target.value);
      bounced();
    };

    const onChangeHeight = (e: React.ChangeEvent<HTMLInputElement>) => {
      testStep.setHeight(e.target.value);
      bounced();
    };

    return (
      <>
        <Grid item className={classes.inputBlock}>
          <NumberTextField
            labelText={t("width")}
            maxLength={4}
            value={testStep.jsonbValueSize.width}
            onChange={onChangeWidth}
            onBlur={testStep.save}
          />
        </Grid>
        <Grid item>
          <NumberTextField
            labelText={t("height")}
            maxLength={4}
            value={testStep.jsonbValueSize.height}
            onChange={onChangeHeight}
            onBlur={testStep.save}
          />
        </Grid>
      </>
    );
  }
);

export default SetWindowSizeAction;
