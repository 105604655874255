import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import clsx from "clsx";

import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { Badge, Box } from "@mui/material";

import {
  KeyboardArrowDown,
  NotificationsOutlined,
  Search,
  PersonOutline,
} from "@mui/icons-material";

import { addResourcesBundle } from "../../i18n";
import { useRootStoreContext } from "../../hooks";

import StyledButton from "../StyledButton";
import NotificationPopover from "../NotificationPopover";

import en from "./en.json";
import useStyles from "./styles";
import Tabs from "./Tabs";
import ProjectDrawer from "./ProjectDrawer";
import ProfileMenu from "./ProfileMenu";

addResourcesBundle({ en });

interface IHeaderProps {
  isEmpty?: boolean;
}

const Header: React.FC<IHeaderProps> = observer(({ isEmpty = false }) => {
  const { t } = useTranslation(["header"]);
  const { projects, unreadNotificationsList, users } = useRootStoreContext();
  const { projectId = "" } = useParams<{ projectId: string }>();
  const history = useHistory();
  const currentProject = _.find(
    projects.items,
    ({ id }) => id === parseInt(projectId, 10)
  );
  const [drawerOpen, setDrawerOpen] = useState(false);
  const projectName = currentProject?.name;
  const user = useMemo(() => users?.profile || {}, [users]);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [notificationAnchorEl, setNotificationAnchorEl] =
    React.useState<null | HTMLElement>(null);

  useEffect(() => {
    unreadNotificationsList.getNotificationCount({
      userId: user.id,
      unread: true,
    });
  }, [unreadNotificationsList, user]);

  const onProfileMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onProfileMenuClose = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onLogoClick = (e: React.MouseEvent) => {
    if (isEmpty) {
      history.push("/dashboard");
    } else {
      toggleDrawer(true)(e);
    }
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (isEmpty) return;
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setDrawerOpen(open);
    };

  const showNotificationList = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setNotificationAnchorEl(e.currentTarget);
  };

  const closeNotificationPopover = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setNotificationAnchorEl(null);
  };

  return (
    <AppBar
      position="sticky"
      color="inherit"
      className={clsx(classes.appBar, { [classes.empty]: isEmpty })}
    >
      <Grid container className={classes.toolbar} wrap="nowrap">
        <Grid
          container
          item
          alignItems="center"
          wrap="nowrap"
          zeroMinWidth
          className={clsx(classes.projectNameBlock, classes.itemContentWidth)}
          onClick={onLogoClick}
        >
          <img
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="logo"
            className={classes.logo}
          />
          {isEmpty ? (
            <Grid item className={classes.projectNameText}>
              {t("ta")}
            </Grid>
          ) : (
            <>
              <StyledButton
                endIcon={<KeyboardArrowDown />}
                sx={{ "&.MuiButton-root": { padding: "0.5rem" } }}
              >
                <Box className={classes.projectNameText} title={projectName}>
                  {projectName}
                </Box>
              </StyledButton>
            </>
          )}
        </Grid>
        <Grid container item>
          {isEmpty ? null : <Tabs />}
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="flex-end"
          className={classes.itemContentWidth}
        >
          <IconButton className={classes.button} size="large">
            <Search />
          </IconButton>
          <IconButton
            className={classes.button}
            onClick={showNotificationList}
            size="large"
          >
            <Badge
              variant="dot"
              overlap="circular"
              classes={{ dot: classes.badge }}
              invisible={unreadNotificationsList.itemCount === 0}
            >
              <NotificationsOutlined />
            </Badge>
          </IconButton>
          <Avatar className={classes.avatar}>
            <PersonOutline />
          </Avatar>
          <Button
            className={clsx(classes.button, classes.userNameBlock)}
            onClick={onProfileMenuClick}
            endIcon={<KeyboardArrowDown />}
          >
            <ProfileMenu anchorEl={anchorEl} onMenuClose={onProfileMenuClose} />
            <Hidden mdDown>
              <Typography className={classes.userNameText}>
                {user.firstLastName}
              </Typography>
            </Hidden>
          </Button>
        </Grid>
      </Grid>
      <ProjectDrawer
        drawerOpen={drawerOpen}
        toggleDrawer={toggleDrawer}
        currentProject={currentProject}
      />
      <NotificationPopover
        anchorEl={notificationAnchorEl}
        onPopoverClose={closeNotificationPopover}
      />
    </AppBar>
  );
});

export default Header;
