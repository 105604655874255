import { FC, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import {
  ArchiveOutlined,
  AddCircleOutline,
  DashboardOutlined,
} from "@mui/icons-material";
import { map } from "lodash";
import { IUser } from "../../models";
import { useRootStoreContext } from "../../hooks";
import { ReactComponent as AttributionIcon } from "../../icons/Attribution.svg";
import ProjectMenu from "../Users/ProjectMenu";
import { ConfirmationModal } from "../Modals";
import StyledButton from "./StyledButton";
import SelectMenuItem from "./SelectMenuItem";

const useStyles = makeStyles(() => ({
  attributionButton: {
    marginRight: "auto",
  },
}));

const ActiveUsersBulkMenu: FC = observer(() => {
  const { users } = useRootStoreContext();
  const selectedUsersIds = map<any, number>(
    users.getSelectedItems(users.activeUsers),
    (user) => (user.role !== "admin" ? user.id : null)
  ).filter(Boolean);

  const { t } = useTranslation(["users"]);
  const classes = useStyles();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onProjectMenuOpen = (anchor: null | HTMLElement) => {
    setAnchorEl(anchor);
  };

  const onProjectMenuClose = () => {
    setAnchorEl(null);
  };

  const onDeactivateConfirm = async () => {
    setShowConfirmationModal(false);
    const selectedIds = map<any, number>(
      users.getSelectedItems(users.activeUsers),
      ({ id }: IUser) => id
    );

    await users.toggleUserLock({
      userIds: selectedIds,
      lockState: true,
    });
    await users.load();
  };

  return (
    <>
      <StyledButton
        onClick={() => setShowConfirmationModal(true)}
        startIcon={<ArchiveOutlined />}
      >
        {t("deactivate")}
      </StyledButton>
      <StyledButton
        onClick={() => console.log("on team edit")}
        startIcon={<AddCircleOutline />}
      >
        {t("teams")}
      </StyledButton>
      <StyledButton
        onClick={(e) => onProjectMenuOpen(e.currentTarget)}
        startIcon={<DashboardOutlined />}
      >
        {t("projects")}
      </StyledButton>
      <StyledButton
        onClick={() => console.log("on role change")}
        startIcon={<AttributionIcon />}
        className={classes.attributionButton}
      >
        {t("change_role")}
      </StyledButton>
      <SelectMenuItem
        selectedSize={users.getSelectedSize(users.activeUsers)}
        onSelectAll={() => users.toAllsetSelect(users.activeUsers, true)}
        onSelectNone={() => users.toAllsetSelect(users.activeUsers, false)}
      />
      {anchorEl ? (
        <ProjectMenu
          anchorEl={anchorEl}
          userIds={selectedUsersIds}
          onClose={onProjectMenuClose}
        />
      ) : null}
      <ConfirmationModal
        isOpened={showConfirmationModal}
        text={t("common:confirm")}
        onConfirm={() => onDeactivateConfirm()}
        onCancel={() => setShowConfirmationModal(false)}
      />
    </>
  );
});

export default ActiveUsersBulkMenu;
