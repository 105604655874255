import i18next from "i18next";
import { parseCountFilterWithOption } from "../../models/utils";

export const getReportFilterLabels = (key: string, filter?: string) => {
  switch (key) {
    case "nameFilter":
      return i18next.t("applied_filter_bar:reports:name_contains_search", {
        search: filter,
      });
    case "lastResultFilter":
      return i18next.t("applied_filter_bar:reports.last_result", {
        search: i18next.t(`reports:results:${filter}`),
      });
    case "periodFilter":
      return i18next.t(`applied_filter_bar:period_filter.${filter}`);
    default:
      return "";
  }
};

export const getElementFilterLabels = (key: string, filter?: string) => {
  switch (key) {
    case "locatorFilter":
      return i18next.t("applied_filter_bar:elements:locator_contains_search", {
        search: filter,
      });
    case "labelFilter":
      return i18next.t("applied_filter_bar:elements:label_contains_search", {
        search: filter,
      });
    case "typeFilter":
      return i18next.t("applied_filter_bar:elements:type_contains_search", {
        search: i18next.t(`elements:types:${filter}`),
      });
    case "periodFilter":
      return i18next.t(`applied_filter_bar:period_filter:${filter}`);
    case "usedFilter":
      const [option, counter] = parseCountFilterWithOption(filter!);
      return i18next.t("applied_filter_bar:elements:used_count", {
        option,
        counter: `${counter}`,
      });
    default:
      return "";
  }
};

export const getUserFilterLabels = (key: string, filter?: string) => {
  switch (key) {
    case "nameFilter":
      return i18next.t("applied_filter_bar:users:name_contains_search", {
        search: filter,
      });
    case "emailFilter":
      return i18next.t("applied_filter_bar:users:email_contains_search", {
        search: filter,
      });
    case "roleFilter":
      return i18next.t("applied_filter_bar:users:role_contains_search", {
        search: i18next.t(`users:roles:${filter}`),
      });
    default:
      return "";
  }
};

export const getTestFilterLabels = (key: string, filter?: string) => {
  switch (key) {
    case "nameFilter":
      return i18next.t("applied_filter_bar:tests:name_contains_search", {
        search: filter,
      });
    case "creatorsFilter":
      return i18next.t("applied_filter_bar:tests:created_by", {
        creator: filter,
      });
    case "updatersFilter":
      return i18next.t("applied_filter_bar:tests:updated_by", {
        updater: filter,
      });
    case "periodFilter":
      return i18next.t(`applied_filter_bar:period_filter.${filter}`);
    case "labelFilter":
      return i18next.t("applied_filter_bar:tests:label", { label: filter });
    default:
      return "";
  }
};
