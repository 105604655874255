import _, { difference, each, map } from "lodash";
import moment from "moment";
import { types, flow, applySnapshot, destroy } from "mobx-state-tree";
import Project, { IProject } from "./Project";
import { handleFetch } from "./utils";
import WithSelectable from "./WithSelectable";

const Projects = types
  .compose(
    types.model({
      state: types.maybeNull(
        types.enumeration("ProjectsState", ["loading", "ready"])
      ),
      items: types.optional(types.array(Project), []),
      sortField: "last_added",
      newProject: types.maybeNull(Project),
      projectSearchValue: "",
      isSearchTextFieldOpen: false,
    }),
    WithSelectable
  )
  .views((self) => ({
    get sortedItems() {
      let sortField: keyof IProject;
      const iteratee = (u1: IProject, u2: IProject) => {
        switch (self.sortField) {
          case "last_added":
            sortField = "createdAt";
            break;
          case "last_updated":
            sortField = "updatedAt";
            break;
        }
        if (u1[sortField] === u2[sortField]) return 0;

        if (!u1[sortField]) return 1;
        if (!u2[sortField]) return -1;
        return moment(u1[sortField]).isBefore(u2[sortField]) ? 1 : -1;
      };

      return self.sortField ? self.items.slice().sort(iteratee) : self.items;
    },
  }))
  .views((self) => ({
    get filteredItems() {
      const projectSearchValue = self.projectSearchValue.toLowerCase();
      return _.filter(
        self.sortedItems,
        (item) => item.name.toLowerCase().indexOf(projectSearchValue) > -1
      );
    },
    getById(id: IProject["id"]) {
      return _.find(self.items, { id });
    },
  }))
  .actions((self) => ({
    load: flow(function* () {
      try {
        self.state = "loading";
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects`
        );
        // TODO: move to private route or aut store
        if (response.ok && response.status === 401) {
          console.error("Unauthorized Projects request");
          return;
        }
        if (!response) return;
        const projects = yield response.json();
        applySnapshot(self.items, projects);
      } catch (error) {
        console.error("Failed to fetch projects", error);
      } finally {
        self.state = "ready";
      }
    }),
  }))
  .actions((self) => ({
    onProjectSearch(searchValue: string) {
      self.projectSearchValue = searchValue;
    },
    onSearchOpenCloseClick() {
      self.projectSearchValue = "";
      self.isSearchTextFieldOpen = !self.isSearchTextFieldOpen;
    },
    onProjectAdd() {
      self.newProject = Project.create({});
      self.newProject.onAddEnvironment();
      self.newProject.onAddInvite();
      self.newProject.setOrganizationId();
    },
    onProjectCreated() {
      destroy(self.newProject);
    },
    onProjectRemove(item: IProject) {
      destroy(item);
    },
    setSelectedByUsers(userIds: number[]) {
      each(self.items, (project) => {
        const usersNotInProject = difference(
          userIds,
          map(project.ProjectUsers, (user) => user.User.id)
        );
        const isAllUsersInProject = usersNotInProject.length === 0;
        const isSomeUsersInProject =
          usersNotInProject.length && usersNotInProject.length < userIds.length;
        if (isAllUsersInProject) project.selected = true;
        if (isSomeUsersInProject) {
          project.selected = false;
          project.indeterminate = true;
        }
      });
    },
    setSortField(sortField: string) {
      self.sortField = sortField;
    },
  }));

export default Projects;
