import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { Box, ListItem, Theme } from "@mui/material";
import StyledButton from "../StyledButton";
import { useRootStoreContext } from "../../hooks";
import TaIcon from "../TaIcon";

const useStyles = makeStyles((theme: Theme) => ({
  iconButtonAddIcon: {
    marginLeft: theme.spacing(3),
    padding: theme.spacing(1),
  },
  iconButtonSearchOutlinedIcon: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  secondaryBox: {
    display: "flex",
    alignItems: "center",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
    height: 56,
  },
}));

const HeaderForListOfItemOfNestedTests: React.FC<{}> = observer((props) => {
  const { t } = useTranslation(["suites", "common"]);
  const { suites } = useRootStoreContext();
  const { projectId = "", suiteId = "" } = useParams<{
    projectId: string;
    suiteId: string;
  }>();
  const classes = useStyles();

  return (
    <ListItem className={classes.listItem}>
      <Box className={classes.secondaryBox}>
        <StyledButton
          startIcon={<TaIcon iconName="AddBoxOutlined" />}
          className={classes.iconButtonAddIcon}
          disabled={!suites.canAddSuite}
          onClick={() => suites.onAddNewClick(parseInt(projectId, 10), suiteId)}
        >
          {t("insert_group")}
        </StyledButton>
      </Box>

      <Box className={classes.secondaryBox}>
        <StyledButton
          endIcon={<TaIcon iconName="SearchOutlined" />}
          className={classes.iconButtonSearchOutlinedIcon}
          onClick={suites.onSearchOpenCloseClick}
        >
          {t("common:search")}
        </StyledButton>
      </Box>
    </ListItem>
  );
});

export default HeaderForListOfItemOfNestedTests;
