import { FC, useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { map } from "lodash";
import { useRootStoreContext } from "../../hooks";
import Table, { ITableColumn } from "../Table";
import VariableNameCell from "./VariableNameCell";
import { useTranslation } from "react-i18next";
import { IVariable } from "../../models";
import { ITableCellMenuItem } from "../Table/TableCellMenu";
import VariableAdd from "./VariableAdd";

const VariablesTable: FC = observer(() => {
  const { t } = useTranslation(["variables"]);
  const { variables } = useRootStoreContext();
  const { projectId = "", environmentId = "" } = useParams<{
    projectId: string;
    environmentId: string;
  }>();

  useEffect(() => {
    if (projectId && !environmentId) {
      variables.load({
        projectId: parseInt(projectId, 10),
      });
    }

    if (projectId && environmentId) {
      variables.loadEnvironmentVariables({
        projectId: parseInt(projectId, 10),
        environmentId: parseInt(environmentId, 10),
      });
    }
  }, [projectId, variables, environmentId]);

  const columns: ITableColumn[] = [
    {
      key: "name",
      label: t("name"),
      width: "2fr",
    },
    {
      key: "value",
      label: t("value"),
      width: "5fr",
      type: "custom",
      customComponent: VariableNameCell,
    },
    {
      type: "number",
      key: "used",
      label: t("using"),
      width: "1fr",
    },
    {
      type: "hover-menu",
      width: "0",
    },
  ];

  const hoverMenuItems = (variable: IVariable): ITableCellMenuItem[] => [
    {
      iconName: "BorderColorOutlined",
      onClick: () => console.log("edit variable"),
    },
    {
      isMenu: true,
    },
  ];

  const menuItems = (variable: IVariable): ITableCellMenuItem[] => [
    {
      label: t("move_to"),
      iconName: "SubdirectoryArrowRightOutlined",
      isConfirm: true,
      confirmText: t("common:confirm"),
      onClick: async () => {
        console.log("move variable");
      },
    },
    {
      label: t("common:duplicate"),
      iconName: "ControlPointDuplicateOutlined",
      onClick: async () => {
        console.log("duplicate variable");
      },
    },
    {
      label: t("common:delete"),
      iconName: "DeleteOutlineOutlined",
      isConfirm: true,
      confirmText: t("common:confirm"),
      onClick: async () => {
        console.log("remove variable");
      },
    },
  ];

  const rows = map(variables.items, (item: IVariable) => ({
    ...item,
    customComponentProps: { variable: item },
    hoverMenuItems: hoverMenuItems(item),
    menuItems: menuItems(item),
  }));

  return (
    <>
      <Table
        columns={columns}
        rows={rows}
        emptyListText={t("empty_list_text")}
        emptyListDescription={t("empty_list_description")}
      >
        {variables.newVariable ? <VariableAdd /> : null}
      </Table>
    </>
  );
});

export default VariablesTable;
