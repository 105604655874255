import React, { ReactNode } from "react";
import { observer } from "mobx-react";
import {
  FormControlLabel,
  FormControlLabelProps,
  Checkbox,
  CheckboxProps,
  Typography,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@mui/icons-material";

const useStyles = makeStyles((theme: Theme) => ({
  labelFormControl: {
    margin: 0,
    flex: "1 1 auto",
    minWidth: 0,
  },
  labelFormControlText: {
    ...theme.typography.bodyTextStyle,
    minWidth: 0,
  },
  checkbox: {
    padding: theme.spacing(1),
    "&:hover, &.Mui-checked": {
      backgroundColor: "transparent",
      "& .MuiSvgIcon-root": {
        color: theme.palette.outrageousOrange.main,
      },
    },
    "&.Mui-checked:hover": {
      backgroundColor: "transparent",
      "& .MuiSvgIcon-root": {
        color: theme.palette.pomegranate.main,
      },
    },
    "& .MuiSvgIcon-root": {
      marginRight: 0,
      color: theme.palette.baliHai.main,
    },
  },
}));

interface ICheckbox {
  label?: string | ReactNode;
  labelFormControlStyle?: string;
  value?: FormControlLabelProps["value"];
  labelPlacement?: FormControlLabelProps["labelPlacement"];
  checked: boolean;
  indeterminate?: boolean;
  className?: string;
  onChange: CheckboxProps["onChange"];
}

const SharedCheckbox: React.FC<ICheckbox> = observer(
  ({
    label,
    labelFormControlStyle,
    labelPlacement,
    checked,
    indeterminate,
    className,
    onChange,
  }) => {
    const classes = useStyles();
    return (
      <FormControlLabel
        classes={{
          root: labelFormControlStyle || classes.labelFormControl,
          label: classes.labelFormControlText,
        }}
        className={className}
        value="bottom"
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            disableFocusRipple
            disableRipple
            className={classes.checkbox}
            indeterminate={indeterminate}
            icon={<CheckBoxOutlineBlank />}
            checkedIcon={<CheckBoxOutlined />}
          />
        }
        label={
          typeof label === "string" ? (
            <Typography component="div" variant="inherit" title={label} noWrap>
              {label}
            </Typography>
          ) : (
            label
          )
        }
        labelPlacement={labelPlacement}
      />
    );
  }
);

export default SharedCheckbox;
