import { types, Instance, SnapshotOut, getParentOfType } from "mobx-state-tree";
import i18next from "i18next";
import { isEmpty } from "lodash";

import Project from "./Project";

export interface IProjectEnvironmentErrors {
  name?: string;
  url?: string;
}

const ProjectEnvironment = types
  .model({
    name: "",
    url: "",
    basic: false,
  })
  .actions((self) => ({
    setEnvironmentName(name: string) {
      self.name = name;
    },
    setEnvironmentUrl(url: string) {
      self.url = url;
    },
    removeEnvironment() {
      getParentOfType(self, Project).onRemoveEnvironment(
        self as IProjectEnvironment
      );
    },
    addEnvironment() {
      getParentOfType(self, Project).onAddEnvironment();
    },
  }))
  .views((self) => ({
    fieldErrors(scope: Array<IProjectEnvironmentErrors> = []) {
      const errors: IProjectEnvironmentErrors = {};

      const name = self.name.trim();
      const url = self.url.trim();

      if (!url) {
        return errors;
      } else if (url.length > 255) {
        errors.url = i18next.t("project_environments:errors:url:maxLength");
      }

      if (!name) {
        errors.name = i18next.t("project_environments:errors:name:required");
      } else if (name.length > 50) {
        errors.name = i18next.t("project_environments:errors:name:maxLength");
      } else if (!RegExp("^[a-zA-Z0-9\\-\\._]+$").test(name)) {
        errors.name = i18next.t("project_environments:errors:name:pattern");
      } else if (
        scope.filter((item) => item.name?.trim() === name).length > 1
      ) {
        errors.name = i18next.t("project_environments:errors:name:duplicate");
      }

      return errors;
    },
    get isValid() {
      return isEmpty(this.fieldErrors);
    },
  }));

export interface IProjectEnvironment
  extends Instance<typeof ProjectEnvironment> {}
export interface IProjectEnvironmentSnapshotOut
  extends SnapshotOut<typeof ProjectEnvironment> {}

export default ProjectEnvironment;
