import { types, Instance, SnapshotOut, applySnapshot } from "mobx-state-tree";
import { reduce, isEmpty, without } from "lodash";
import { createBrowserHistory } from "history";
import { updateUrlFilter, PeriodFilterType, IPeriodFilterType } from "./utils";

const history = createBrowserHistory();

const ReportFilters = types
  .model("Report Filters", {
    projectId: "",
    nameFilter: "",
    lastResultFilter: types.optional(types.array(types.string), []),
    periodFilter: types.maybeNull(PeriodFilterType),
  })
  .views((self) => ({
    get isEmpty() {
      return reduce(
        self,
        (r, value, key) => (key === "projectId" ? r : r && isEmpty(value)),
        true
      );
    },
    get periodFilterTypes() {
      return [
        "today",
        "yesterday",
        "thisWeek",
        "lastWeek",
        "thisMonth",
        "lastMonth",
        "lastThreeMonths",
      ] as const;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      const filters = new URLSearchParams(window.location.search);
      self.nameFilter = filters.get("nameFilter") || "";
      applySnapshot(self.lastResultFilter, filters.getAll("lastResultFilter"));
      const periodFilterType = filters.get("periodFilter") as IPeriodFilterType;
      if (self.periodFilterTypes.includes(periodFilterType || "")) {
        self.periodFilter = periodFilterType;
      }
    },
  }))
  .actions((self) => ({
    setProjectId(projectId: string) {
      self.projectId = projectId;
    },
    saveNameFilter(nameFilter: string) {
      self.nameFilter = nameFilter;
      updateUrlFilter("nameFilter", nameFilter);
    },
    clearNameFilter() {
      self.nameFilter = "";
      updateUrlFilter("nameFilter", "");
    },
    saveLastResultFilter(results: string[]) {
      applySnapshot(self.lastResultFilter, results);
      updateUrlFilter("lastResultFilter", results);
    },
    clearLastResultFilter(result: string) {
      const results = without(self.lastResultFilter, result);
      applySnapshot(self.lastResultFilter, results);
      updateUrlFilter("lastResultFilter", results);
    },
    savePeriodFilter(value: IPeriodFilterType) {
      self.periodFilter = value;
      updateUrlFilter("periodFilter", value);
    },
    clearPeriodFilter() {
      self.periodFilter = null;
      updateUrlFilter("periodFilter", "");
    },
    clearAllFilters() {
      applySnapshot(self, {});
      //ToDo: revert once fixed https://github.com/ReactTraining/history/issues/814
      history.push({ pathname: window.location.pathname, search: "" });
    },
  }))
  .actions((self) => ({
    getReportsClearAction(filterName: string, filter: any) {
      switch (filterName) {
        case "nameFilter":
          return self.clearNameFilter;
        case "lastResultFilter":
          return () => self.clearLastResultFilter(filter);
        case "periodFilter":
          return self.clearPeriodFilter;
        default:
          return () => null;
      }
    },
  }));

export interface IReportFilters extends Instance<typeof ReportFilters> {}
export interface IReportFiltersSnapshotOut
  extends SnapshotOut<typeof ReportFilters> {}

export default ReportFilters;
