import React from "react";
import { observer } from "mobx-react";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { addResourcesBundle } from "../../i18n";
import en from "./en.json";
import ControlBar from "./ControlBar";
import SharedStepsList from "./SharedStepsList";

const useStyles = makeStyles((theme: Theme) => ({
  sharedStepsContainer: {
    height: "100%",
  },
}));

addResourcesBundle({ en });

const SharedSteps: React.FC = observer(() => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={classes.sharedStepsContainer}
    >
      <ControlBar />
      <SharedStepsList />
    </Grid>
  );
});

export default SharedSteps;
