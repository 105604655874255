import React, { FC, useEffect } from "react";
import { observer } from "mobx-react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import { addResourcesBundle } from "../../i18n";
import { useRootStoreContext } from "../../hooks";
import UserHeader from "./UserHeader";
import ControlBar from "./ControlBar";
import ActiveUsers from "./ActiveUsers";
import PendingUsers from "./PendingUsers";
import DeactivatedUsers from "./DeactivatedUsers";

import en from "./en.json";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  userContent: {
    flex: 1,
    minHeight: 0,
    marginTop: theme.spacing(3),
  },
}));

const Users: FC = observer(() => {
  const { path } = useRouteMatch();
  const classes = useStyles();
  const { users } = useRootStoreContext();

  useEffect(() => {
    users.load();
  }, [users]);

  if (!users.profile.isAuthenticated) return <Redirect to="/sign-in" />;
  if (users.profile.role === "user") return <Redirect to="/" />;

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item container direction="column" wrap="nowrap">
        <UserHeader />
        <ControlBar />
      </Grid>
      <Grid container direction="column" className={classes.userContent}>
        <Switch>
          {/*ToDo: keep side menu item active independently of user tab link */}
          <Route path={`${path}/active`} component={ActiveUsers} />
          <Route path={`${path}/pending`} component={PendingUsers} />
          <Route path={`${path}/deactivated`} component={DeactivatedUsers} />
          <Route path={path}>
            <Redirect to={`${path}/active`} />
          </Route>
        </Switch>
      </Grid>
    </Grid>
  );
});

export default Users;
