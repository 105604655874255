import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { Grid, Divider, Theme } from "@mui/material";
import {
  DropTargetMonitor,
  useDrag,
  useDrop,
  useDragLayer,
  XYCoord,
} from "react-dnd";
import { useParams } from "react-router-dom";
import { getEmptyImage } from "react-dnd-html5-backend";
import { ITestStep, IElement } from "../../models";
import { useRootStoreContext } from "../../hooks";
import { IActionType } from "../../models/utils";
import StepActions from "../StepActions";
import NewSharedStepTitle from "./NewSharedStepTitle";
import StepTitle from "./StepTitle";

const useStyles = makeStyles((theme: Theme) => ({
  stepWrapper: {
    padding: 0,
    "&:not(:last-child)": {
      marginBottom: theme.spacing(1),
    },
    "&:hover": {
      backgroundColor: theme.palette.catskillLightWhite.main,
      "&:not(.editMode) .testStepControlContainer": {
        visibility: "visible",
      },
      "&:not(.editMode) .testStepTitle": {
        backgroundColor: theme.palette.catskillLightWhite.main,
      },
    },
    "&.isOver": {
      border: `2px solid ${theme.palette.outrageousOrange.main}`,
    },
    "&.isOverUp": {
      borderTop: `2px solid ${theme.palette.outrageousOrange.main}`,
      paddingTop: theme.spacing(1),
    },
    "&.isOverDown": {
      borderBottom: `2px solid ${theme.palette.outrageousOrange.main}`,
      paddingBottom: theme.spacing(1),
    },
  },
  stepBody: {
    padding: theme.spacing(0, 4),
    backgroundColor: theme.palette.white.main,
  },
  divider: {
    marginTop: -1,
  },
}));

interface IDragItem extends IElement {
  type: string;
  testStepId: number;
  SharedStepId?: string;
  value?: string;
  actionType: IActionType;
}

interface ITestStepProps {
  testStep: ITestStep;
  isElementOverList?: boolean;
}

interface IRouteParams {
  projectId?: string;
  suiteId?: string;
  testId?: string;
}

const TestStep: React.FC<ITestStepProps> = observer(
  ({ testStep, isElementOverList }) => {
    const classes = useStyles();
    const {
      projectId = "",
      suiteId = "",
      testId = "",
    } = useParams<IRouteParams>();

    const ref = useRef<HTMLDivElement>(null);
    const { actions, testSteps } = useRootStoreContext();
    const [isOverUp, setIsOverUp] = useState<boolean>(false);
    const [isOverDown, setIsOverDown] = useState<boolean>(false);
    const [canDropElement, setCanDropElement] = useState<boolean>(false);

    const draggingStepsSize = testSteps.draggingStepsSize(testStep.id!);
    const dragLayerText =
      draggingStepsSize === 1 ? testStep.title : `${draggingStepsSize} actions`;

    const [{ isDragging }, drag, preview] = useDrag({
      type: "Action",
      item: {
        testStepId: testStep.id,
        dragLayerText,
        skipQuotes: draggingStepsSize === 1,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [{ isElementOver, isActionOver, isSharedActionOver }, drop] = useDrop(
      {
        accept: ["Element", "Action", "Shared Action"],
        canDrop: (item: IDragItem, monitor) => {
          const itemType = monitor.getItemType();
          return (
            (itemType === "Action" && item.testStepId !== testStep.id) ||
            (itemType === "Element" && canDropElement) ||
            itemType === "Shared Action"
          );
        },
        drop: (item: IDragItem, monitor) => {
          if (monitor.getItemType() === "Element") {
            testStep.dropElement(item);
          } else if (item.testStepId) {
            testSteps.moveStep({
              id: item.testStepId,
              stepIdtoDropTo: testStep.id!,
              before: isOverUp,
              projectId: parseInt(projectId, 10),
              suiteId,
              testId,
            });
          } else {
            testSteps.createStep({
              type: item.actionType,
              stepIdtoDropTo: testStep.id!,
              before: isOverUp,
              projectId: parseInt(projectId, 10),
              SharedStepId: item.SharedStepId,
              suiteId,
              testId,
              value: item.value,
            });
          }
          return item;
        },
        collect: (monitor) => ({
          isElementOver:
            monitor.getItemType() === "Element" && !!monitor.isOver(),
          isActionOver:
            monitor.getItemType() === "Action" && !!monitor.isOver(),
          isSharedActionOver:
            monitor.getItemType() === "Shared Action" && !!monitor.isOver(),
        }),
        hover(item, monitor: DropTargetMonitor) {
          const { testStepId: draggedId } = item as IDragItem;
          const type = monitor.getItemType();
          if (
            !["Action", "Shared Action"].includes(type as string) ||
            !ref.current ||
            draggedId === testStep.id
          ) {
            setIsOverUp(false);
            setIsOverDown(false);
            return;
          }

          // Determine rectangle on screen
          const hoverBoundingRect = ref.current?.getBoundingClientRect();

          // Determine mouse position
          const clientOffset = monitor.getClientOffset();

          const isOverUp =
            (clientOffset as XYCoord).y <
            hoverBoundingRect.top +
              (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

          setIsOverUp(isOverUp);
          setIsOverDown(!isOverUp);
        },
      }
    );

    const { isOtherItemDragging, isElementDragging } = useDragLayer(
      (monitor) => ({
        isOtherItemDragging:
          monitor.getItemType() === "Action" && !!monitor.isDragging(),
        isElementDragging:
          monitor.getItemType() === "Element" && monitor.isDragging(),
      })
    );

    useEffect(() => {
      if (!isActionOver && !isSharedActionOver) {
        setIsOverUp(false);
        setIsOverDown(false);
      }
    }, [isActionOver, isSharedActionOver]);

    useEffect(() => {
      setCanDropElement(actions.isElementType(testStep.type));
    }, [actions, testStep.type]);

    useEffect(() => {
      preview(getEmptyImage());
    }, [preview]);

    drag(drop(ref));

    return (
      <Grid
        ref={ref}
        className={clsx(classes.stepWrapper, {
          isOver:
            (isElementOver || (isElementDragging && !isElementOverList)) &&
            canDropElement,
          isOverUp,
          isOverDown,
        })}
        style={{
          opacity:
            isDragging || (isOtherItemDragging && testStep.selected) ? 0.35 : 1,
        }}
      >
        {testStep.type === "sharedAction" && testStep.id === 0 ? (
          <NewSharedStepTitle testStep={testStep} />
        ) : (
          <StepTitle testStep={testStep} />
        )}
        {testStep.showDetails ? (
          <Grid container direction="column" className={classes.stepBody}>
            <Divider className={classes.divider} />
            <StepActions testStep={testStep} />
          </Grid>
        ) : null}
      </Grid>
    );
  }
);

export default TestStep;
