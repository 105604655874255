import { FC } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography, Theme, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Modal } from ".";

const useStyles = makeStyles((theme: Theme) => ({
  decription: {
    ...theme.typography.bodyTextStyle,
    marginBottom: theme.spacing(2),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  link: {
    color: theme.palette.outrageousOrange.main,
  },
}));

interface INoRecorder {
  isOpen: boolean;
  onClose: () => void;
}

const TA_RECORDER_URL =
  "https://chrome.google.com/webstore/detail/trueautomation-test-recor/jghmgmhgmmaljojeeplgeigepiiiagcg";

const NoRecorder: FC<INoRecorder> = observer(({ isOpen, onClose }) => {
  const { t } = useTranslation(["modals"]);
  const classes = useStyles();

  const handleInstallRecorder = () => {
    window.open(TA_RECORDER_URL, "_blank");
    onClose();
  };

  return (
    <Modal
      title={t("no_recorder.title")}
      open={isOpen}
      onClose={onClose}
      modalActions={[
        {
          text: t("no_recorder.action"),
          onClick: handleInstallRecorder,
        },
      ]}
    >
      <Grid item>
        <Typography className={classes.decription}>
          {t("no_recorder.description_1")}
          <Link className={classes.link} to="/">
            {t("true_automation")}
          </Link>
        </Typography>
        <Typography className={classes.decription}>
          {t("no_recorder.description_2")}
        </Typography>
      </Grid>
    </Modal>
  );
});

export default NoRecorder;
