import {
  applySnapshot,
  flow,
  types,
  Instance,
  getRoot,
  SnapshotOut,
} from "mobx-state-tree";
import _ from "lodash";
import { IListFilters } from "./ListFilters";
import { IRoot } from "./Root";
import FilterAuthor, { IFilterAuthor } from "./FilterAuthor";
import { getFilteredListItems, handleFetch } from "./utils";
import TestStep from "./TestStep";

const SharedTestSteps = types
  .model("SharedTestSteps", {
    searchTextFieldValue: "",
    sharedSteps: types.optional(types.array(types.late(() => TestStep)), []),
    sharedStepsState: types.optional(
      types.enumeration("SharedStepsState", ["loading", "ready"]),
      "ready"
    ),
    sortField: "createdAt",
    newSharedStep: types.maybeNull(types.late(() => TestStep)),
  })
  .views((self) => ({
    get sharedStepsWithoutActions() {
      return _.filter(
        self.sharedSteps,
        (step) => step.type === "sharedAction" && !step.StepId
      );
    },
  }))
  .views((self) => ({
    get searchItems() {
      const searchTextFieldValue = self.searchTextFieldValue.toLowerCase();

      return _.filter(
        self.sharedStepsWithoutActions,
        (step) => step.value.toLowerCase().indexOf(searchTextFieldValue) > -1
      );
    },
    get sharedStepCreators() {
      return _.chain(self.sharedSteps)
        .map((step) => step.creatorEmail)
        .compact()
        .uniq()
        .map((email): IFilterAuthor => FilterAuthor.create({ email: email! }))
        .value();
    },
    get sharedStepUpdaters() {
      return _.chain(self.sharedSteps)
        .map((step) => step.changerEmail)
        .compact()
        .uniq()
        .map((email): IFilterAuthor => FilterAuthor.create({ email: email! }))
        .value();
    },
  }))
  .views((self) => ({
    get sortedSharedSteps() {
      return _.orderBy(
        self.sharedStepsWithoutActions,
        [self.sortField],
        ["desc"]
      );
    },
  }))
  .views((self) => ({
    get filteredItems() {
      const filters: IListFilters = getRoot<IRoot>(self).sharedStepFilters;

      return getFilteredListItems(self.sortedSharedSteps, filters);
    },
  }))
  .actions((self) => ({
    loadSharedSteps: flow(function* (projectId: number) {
      try {
        self.sharedStepsState = "loading";

        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${projectId}/sharedSteps`
        );
        applySnapshot(self.sharedSteps, yield response.json());
        const { sharedStepAuthors } = getRoot<IRoot>(self);
        applySnapshot(sharedStepAuthors.creators, self.sharedStepCreators);
        applySnapshot(sharedStepAuthors.updaters, self.sharedStepUpdaters);
      } catch (error) {
        //TODO: Show Error msg
        console.error("Error while load shared steps", error);
        throw error;
      } finally {
        self.sharedStepsState = "ready";
      }
    }),
    setSearchTextFieldValue(value: string) {
      self.searchTextFieldValue = value;
    },
    setSortField: (sortField: string) => {
      self.sortField = sortField;
    },
    toggleSharedStepDetails(id: string, showDetails: boolean) {
      _.each(self.sharedSteps, (item) => {
        if (item.SharedStepId === id) {
          item.showDetails = showDetails;
        } else {
          item.showDetails = false;
        }
      });
    },
  }));

export interface ISharedTestSteps extends Instance<typeof SharedTestSteps> {}
export interface ISharedTestStepsSnapshotOut
  extends SnapshotOut<typeof SharedTestSteps> {}

export default SharedTestSteps;
