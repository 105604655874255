import { FC } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { chain } from "lodash";

import { ITest } from "../../models/Test";
import TestResult from "./TestResult";
import Table, { ITableColumn } from "../Table";
import { browserIcons } from "./config";
import { formatDuration } from "../../models/utils";

interface ITestHistoryItem {
  test: ITest;
}

const TestHistory: FC<ITestHistoryItem> = observer(({ test }) => {
  const { t } = useTranslation(["reports"]);

  const columns: ITableColumn[] = [
    {
      key: "environment",
      label: t("environment"),
      width: "3fr",
    },
    {
      key: "browser",
      label: t("browser"),
      width: "1fr",
    },
    {
      key: "base_url",
      label: t("base_url"),
      width: "3fr",
    },
    {
      key: "duration",
      label: t("duration"),
      width: "1fr",
    },
    {
      key: "started_by",
      label: t("started_by"),
      width: "2fr",
    },
    {
      type: "date",
      key: "createdAt",
      label: t("started"),
      width: "2fr",
    },
    {
      key: "result",
      label: t("result"),
      width: "1fr",
    },
  ];

  const reportItems = chain(test.Reports)
    .filter(Boolean)
    .map((item) => {
      return {
        ...item,
        started_by: item.User?.firstLastName || "N/A",
        environment: item.Environment?.name || "",
        base_url: item.baseUrl || "",
        duration: formatDuration(item.runTime || 0),
        browser: browserIcons(item.browser || ""),
        result: <TestResult result={item.result || ""} />,
      };
    })
    .value();

  return <Table columns={columns} rows={reportItems} />;
});

export default TestHistory;
