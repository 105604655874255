import { createElement } from "react";
import { Route, Redirect } from "react-router-dom";
import { useRootStoreContext } from "../../hooks";

const PublicRoute = ({ component, loggedIn, ...rest }: any) => {
  const {
    users: {
      profile: { isAuthenticated },
    },
  } = useRootStoreContext();
  const routeComponent = (props: any) =>
    !isAuthenticated ? (
      createElement(component, props)
    ) : (
      <Redirect to="/dashboard/projects" />
    );
  return <Route {...rest} render={routeComponent} />;
};

export default PublicRoute;
