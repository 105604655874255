import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { Menu } from "../Menus";
import { ILabel } from "../../models";
import { useLabelMenuContext, useRootStoreContext } from "../../hooks";
import { ValidatableTextArea } from "../Inputs";

const useStyles = makeStyles((theme: Theme) => ({
  menuPaper: {
    ...theme.typography.descriptionTextStyle,
    color: theme.palette.doveGray.main,
    padding: theme.spacing(1, 2),
    width: theme.spacing(36),
  },
  clsTooltip: {
    height: 80,
    width: "100%",
  },
}));

interface ILabelItemAdd {
  label: ILabel;
  anchor: null | HTMLElement;
  onClose: () => void;
  className?: string;
}

const LabelItemAdd: React.FC<ILabelItemAdd> = observer(
  ({ label, anchor, onClose, className }) => {
    const classes = useStyles();
    const { t } = useTranslation(["labels"]);
    const { labels } = useRootStoreContext();
    const { testIds } = useLabelMenuContext();

    const onMenuClose = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      e.stopPropagation();
      labels.onCancelAddLabel();
      onClose();
    };

    const onSaveClick = (e: React.SyntheticEvent) => {
      if (label.isValid) label.onSaveNewLabel(e);
    };

    const placement = testIds.length === 0 ? "right" : "left";

    return (
      <Menu
        anchorEl={anchor}
        onClose={onMenuClose}
        PaperProps={{ className: clsx(classes.menuPaper, className) }}
      >
        <ValidatableTextArea
          maxLength={50}
          placeholder={t("label_name")}
          labelText={t("label_name")}
          errorText={label.validationErrorText}
          itemName={label.name}
          isValid={label.isValid}
          useTooltip={true}
          onChange={label.onChangeName}
          onSaveClick={onSaveClick}
          onCancelClick={labels.onCancelAddLabel}
          placement={placement}
          clsTooltip={classes.clsTooltip}
        />
      </Menu>
    );
  }
);

export default LabelItemAdd;
