import { FC, SyntheticEvent } from "react";
import { observer } from "mobx-react";
import {
  Grid,
  Dialog,
  DialogProps,
  Theme,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import StyledIconButton from "../StyledIconButton";
import { ClearOutlined } from "@mui/icons-material";
import { map } from "lodash";
import StyledButton from "../StyledButton";

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    width: "30rem",
    borderRadius: 0,
    position: "relative",
    padding: theme.spacing(6),
  },
  modalTitleContainer: {
    position: "relative",
  },
  modalTitleContainerWithBtn: {
    minHeight: "1.5rem",
  },
  modalTitle: {
    ...theme.typography.topHeaderTextStyle,
    color: theme.palette.mineShaft.main,
    marginBottom: theme.spacing(1),
  },
  modalContent: {
    ...theme.typography.bodyTextStyle,
    color: theme.palette.mineShaft.main,
  },
  modalContentWithPadding: {
    paddingRight: theme.spacing(3),
  },
  modalActions: {
    marginTop: theme.spacing(2),
  },
  modalAction: {
    marginRight: theme.spacing(3),
  },
  closeButton: {
    padding: 0,
    right: theme.spacing(2),
    top: theme.spacing(2),
    position: "absolute",
  },
  loading: {
    color: theme.palette.white.main,
  },
}));

export interface modalAction {
  onClick?: () => void;
  text?: string;
  buttonType?: "contained" | "outlined";
  disabled?: boolean;
  loading?: boolean;
}

interface IModal extends DialogProps {
  title?: string;
  hasCloseBtn?: boolean;
  onClose?: () => void;
  modalActions?: modalAction[];
}

const Modal: FC<IModal> = observer((props) => {
  const {
    title,
    open,
    onClose,
    children,
    modalActions = [],
    hasCloseBtn = true,
  } = props;
  const classes = useStyles();
  const handleClose = (
    _e: SyntheticEvent,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (reason === "backdropClick" && !hasCloseBtn) return;
    if (onClose) onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.modalContainer }}
      disableEscapeKeyDown
    >
      {hasCloseBtn ? (
        <StyledIconButton className={classes.closeButton} onClick={onClose}>
          <ClearOutlined />
        </StyledIconButton>
      ) : null}
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        className={classes.modalTitleContainer}
      >
        {title ? (
          <Grid item className={classes.modalTitle}>
            {title}
          </Grid>
        ) : null}
      </Grid>
      <Grid container wrap="nowrap" className={classes.modalContent}>
        {children}
      </Grid>
      {modalActions.length ? (
        <Grid container wrap="nowrap" className={classes.modalActions}>
          {map(
            modalActions,
            (
              {
                text,
                onClick,
                buttonType = "contained",
                disabled = false,
                loading = false,
              },
              i
            ) => (
              <StyledButton
                className={classes.modalAction}
                key={`modal_action_btn_${i}`}
                color="primary"
                onClick={onClick}
                variant={buttonType}
                disabled={disabled || undefined}
                startIcon={
                  loading ? (
                    <CircularProgress
                      className={classes.loading}
                      size={16}
                      thickness={6}
                    />
                  ) : null
                }
              >
                {text}
              </StyledButton>
            )
          )}
        </Grid>
      ) : null}
    </Dialog>
  );
});

export default Modal;
