import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import _ from "lodash";
import { TextField, Checkbox } from "../Inputs";
import DropElement from "./DropElement";
import { ITestStep } from "../../models";
import ErrorStrategySelect from "./ErrorStrategySelect";

const useStyles = makeStyles((theme: Theme) => ({
  inputBlock: {
    marginBottom: theme.spacing(4),
  },
  helperTextRightClass: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  checkboxBlock: {
    margin: theme.spacing(2, 0, 4, 0),
  },
  checkbox: {
    marginLeft: theme.spacing(-1),
  },
}));

interface ISetValueAction {
  testStep: ITestStep;
}

const SetValueAction: React.FC<ISetValueAction> = observer(({ testStep }) => {
  const classes = useStyles();
  const { t } = useTranslation(["actions"]);
  const bounced = useCallback(
    () => _.debounce(testStep.save, 1000),
    [testStep.save]
  );

  const onClearFieldClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    testStep.toggleClearField();
    testStep.save();
  };

  const onSaveToValueClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    testStep.toggleSaveToValue();
    testStep.save();
  };

  const onChangeValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > 500) return;
    testStep.setValue(e.target.value);
    bounced();
  };

  return (
    <>
      <Grid item>
        <TextField
          labelText={t("enter_text")}
          helperTextRight={t("common:characters_left", {
            number: 500 - (testStep.value?.length || 0),
          })}
          helperTextRightClass={classes.helperTextRightClass}
          inputBaseProps={{
            value: testStep.value,
            fullWidth: true,
            rows: 5,
            maxRows: 5,
            multiline: true,
            onChange: onChangeValue,
            onBlur: testStep.save,
          }}
        />
      </Grid>
      <Grid
        item
        container
        justifyContent="space-between"
        className={classes.checkboxBlock}
      >
        <Grid item>
          <Checkbox
            checked={testStep.clearField}
            className={classes.checkbox}
            label={t("clear_field")}
            labelPlacement="end"
            onChange={onClearFieldClick}
          />
        </Grid>
        <Grid item>
          <Checkbox
            checked={testStep.saveToValue}
            label={t("save_to_values")}
            labelPlacement="end"
            onChange={onSaveToValueClick}
          />
        </Grid>
      </Grid>
      {testStep.saveToValue ? (
        <Grid item className={classes.inputBlock}>
          <TextField
            labelText={t("value_description")}
            inputBaseProps={{ fullWidth: true }}
          />
        </Grid>
      ) : null}
      <Grid item className={classes.inputBlock}>
        <DropElement label={t("target_element")} testStep={testStep} />
      </Grid>
      <ErrorStrategySelect testStep={testStep} />
    </>
  );
});

export default SetValueAction;
