import React from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button, { ButtonProps } from "@mui/material/Button";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    ...theme.typography.bodyTextStyle,
    textTransform: "none",
    color: theme.palette.mineShaft.main,
    borderRadius: 0,
    padding: theme.spacing(1, 2),
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.outrageousOrange.main,
      "& .startIcon": {
        color: theme.palette.outrageousOrange.main,
      },
    },
  },
  buttonStartIcon: {
    color: theme.palette.baliHai.main,
    marginLeft: theme.spacing(0),
    "& .MuiSvgIcon-root": {
      width: 24,
      height: 24,
    },
  },
}));

interface IMenuButton extends ButtonProps {
  label?: string;
}

const MenuButton: React.FC<IMenuButton> = observer(
  ({ onClick, label, startIcon, endIcon }) => {
    const classes = useStyles();
    return (
      <Button
        startIcon={startIcon}
        endIcon={endIcon}
        classes={{
          root: classes.button,
          startIcon: clsx(classes.buttonStartIcon, "startIcon"),
        }}
        onClick={onClick}
      >
        {label}
      </Button>
    );
  }
);

export default MenuButton;
