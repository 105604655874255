import { FC, ReactNode } from "react";
import { Menu, Fade } from "@mui/material";
import { PaperProps } from "@mui/material/Paper";
import { MenuProps } from "@mui/material/Menu";

export interface IMenu {
  anchorEl: null | HTMLElement;
  onClose: MenuProps["onClose"];
  PaperProps?: PaperProps;
  className?: string;
  transformOrigin?: MenuProps["transformOrigin"];
  anchorOrigin?: MenuProps["anchorOrigin"];
  classes?: MenuProps["classes"];
  children?: ReactNode;
}

const SharedMenu: FC<IMenu> = (props) => {
  const {
    anchorEl,
    onClose,
    PaperProps,
    className,
    classes,
    transformOrigin,
    anchorOrigin,
    children,
  } = props;

  if (!anchorEl) return null;

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      classes={classes}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        ...{ vertical: 8, horizontal: 32 },
        ...anchorOrigin,
      }}
      transformOrigin={{
        ...{
          vertical: "top",
          horizontal: "right",
        },
        ...transformOrigin,
      }}
      TransitionComponent={Fade}
      PaperProps={PaperProps}
      className={className}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </Menu>
  );
};

export default SharedMenu;
