import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { IconButton, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import {
  ControlPointDuplicateOutlined,
  MoreVertOutlined,
  FolderSpecialOutlined,
  DeleteOutlined,
} from "@mui/icons-material";
import { IEnvironment } from "../../models";
import { Menu, MenuItem, Divider } from "../Menus";
import { ConfirmationModal } from "../Modals";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionsIcons: {
      zIndex: 1,
      color: theme.palette.baliHai.main,
      padding: theme.spacing(1),
      marginRight: theme.spacing(1),
      "&:last-child": {
        marginRight: theme.spacing(0),
      },
      "&:hover": {
        color: theme.palette.outrageousOrange.main,
      },
    },
    menuPaper: {
      width: 135,
      maxHeight: 193,
    },
  })
);

interface IEnvironmentListItemMenu {
  environment: IEnvironment;
}

const EnvironmentListItemMenu: React.FC<IEnvironmentListItemMenu> = observer(
  ({ environment }) => {
    const { t } = useTranslation(["common"]);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const onShowDeleteConfirm = () => {
      setShowConfirmationModal(true);
    };

    const onRemoveClick = () => {
      setAnchorEl(null);
      console.log("on env remove");
    };

    const onShowMenu = (event: React.SyntheticEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    };

    return (
      <>
        <IconButton
          className={classes.actionsIcons}
          onClick={onShowMenu}
          size="large"
        >
          <MoreVertOutlined />
        </IconButton>
        <Menu
          PaperProps={{ className: classes.menuPaper }}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem>
            <ControlPointDuplicateOutlined />
            {t("duplicate")}
          </MenuItem>
          <MenuItem>
            <FolderSpecialOutlined />
            {t("Make base")}
          </MenuItem>
          <Divider />
          <MenuItem onClick={onShowDeleteConfirm}>
            <DeleteOutlined />
            {t("delete")}
          </MenuItem>
          <ConfirmationModal
            isOpened={showConfirmationModal}
            text={t("common:confirm")}
            onConfirm={onRemoveClick}
            onCancel={() => setShowConfirmationModal(false)}
          />
        </Menu>
      </>
    );
  }
);

export default EnvironmentListItemMenu;
