import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import { FC, useState } from "react";
import { TextField, ValidatableTextArea } from "../Inputs";
import { useTranslation } from "react-i18next";
import { useRootStoreContext } from "../../hooks";
import { RadioGroup, Radio } from "../Radio";
import StyledButton from "../StyledButton";
import { useHistory, useParams } from "react-router-dom";

const styles = {
  container: {
    bgColor: "catskillLightWhite.main",
    borderLeft: 4,
    borderColor: "outrageousOrange.main",
    padding: "1.5rem",
  },
};

const VariableAdd: FC = observer(() => {
  const { t } = useTranslation(["variables"]);
  const { variables } = useRootStoreContext();
  const { projectId, environmentId } = useParams<{
    projectId: string;
    environmentId: string;
  }>();
  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);

  const variable = variables.newVariable;

  const onVariableNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    variable?.setVariableName(e.target.value);
  };

  const trimVariableName = () => {
    variable?.setVariableName(variable.name.trim());
  };

  const onVariableValueChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    variable?.setVariableValue(e.target.value);
  };

  const onTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    variable?.setGlobal(event.target.value === "true");
  };

  const onCancel = () => {
    variables.onCancelVariableAdd();
    setSubmitted(false);
  };

  const onSubmit = async () => {
    setSubmitted(true);
    if (!variable?.isValid) return;
    const newVariable = await variables.addVariable(projectId);
    if (!newVariable) return;
    if (newVariable.global) {
      history.push(`/projects/${projectId}/data/environments`);
    } else {
      variables.loadEnvironmentVariables({
        projectId: parseInt(projectId, 10),
        environmentId: parseInt(environmentId, 10),
      });
    }
    setSubmitted(false);
  };

  return (
    <Grid container sx={styles.container}>
      <Grid item sx={{ mb: "1.5rem", width: "26.25rem" }}>
        <TextField
          inputBaseProps={{
            placeholder: t("name"),
            fullWidth: true,
            onChange: onVariableNameChange,
            onBlur: trimVariableName,
            value: variable?.name || "",
          }}
          errorText={(submitted && variable?.error["name"]) || ""}
        />
      </Grid>
      <Grid item sx={{ mb: "0.5rem", width: "100%" }}>
        <ValidatableTextArea
          hideControls
          minRows={3}
          maxRows={3}
          maxLength={255}
          itemName={variable?.value || ""}
          onChange={onVariableValueChange}
          placeholder={t("value")}
          errorText={(submitted && variable?.error["value"]) || ""}
        />
      </Grid>
      <Grid item sx={{ mb: "0.5rem" }}>
        <RadioGroup
          row
          labelText={t("value_type")}
          value={variable?.global}
          onChange={onTypeChange}
        >
          <Radio value="true" label={t("global")} />
          <Radio value="false" label={t("environment")} />
        </RadioGroup>
      </Grid>
      <Grid item container wrap="nowrap">
        <Grid item>
          <StyledButton variant="outlined" color="primary" onClick={onCancel}>
            {t("common:cancel")}
          </StyledButton>
        </Grid>
        <Grid item sx={{ ml: "2rem" }}>
          <StyledButton variant="contained" color="primary" onClick={onSubmit}>
            {t("create_value")}
          </StyledButton>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default VariableAdd;
