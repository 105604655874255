import { FC } from "react";
import { observer } from "mobx-react";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { addResourcesBundle } from "../../i18n";
import ControlBar from "./ControlBar";

import en from "./en.json";
import UserList from "./UserList";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  userContainer: {
    minHeight: 0,
  },
  userContent: {
    minHeight: 0,
    marginTop: theme.spacing(3),
  },
}));

const ProjectTeam: FC = observer(() => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={classes.userContainer}
    >
      <Grid item container>
        <ControlBar />
      </Grid>
      <Grid item container direction="column" className={classes.userContent}>
        <UserList />
      </Grid>
    </Grid>
  );
});

export default ProjectTeam;
