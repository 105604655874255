import { FC, useState } from "react";
import { map } from "lodash";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { DeleteOutlined, DeleteSweepOutlined } from "@mui/icons-material";
import { useRootStoreContext } from "../../hooks";
import { ITestStep } from "../../models";
import { ConfirmationModal } from "../Modals";
import StyledButton from "./StyledButton";
import SelectMenuItem from "./SelectMenuItem";

const useStyles = makeStyles(() => ({
  attributionButton: {
    marginLeft: "auto",
  },
}));

const SharedStepListBulkMenu: FC = observer(() => {
  const { testSteps } = useRootStoreContext();
  const classes = useStyles();
  const { t } = useTranslation(["shared_steps"]);
  const { projectId = "" } = useParams<{ projectId: string }>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [onConfirm, setOnConfirm] = useState<(() => void) | null>(null);

  const onUngroup = () => {
    setOnConfirm(() => onUngroupConfirm);
    setShowConfirmationModal(true);
  };

  const onUngroupConfirm = async () => {
    setShowConfirmationModal(false);
    const selectedSharedSteps = testSteps.getSelectedItems(
      testSteps.filteredItems
    );

    await testSteps.ungroupSharedStep(selectedSharedSteps as ITestStep[]);

    setOnConfirm(null);
  };

  const onDelete = () => {
    setOnConfirm(() => onDeleteConfirm);
    setShowConfirmationModal(true);
  };

  const onDeleteConfirm = async () => {
    setShowConfirmationModal(false);
    const selectedIds = map<any, string | null>(
      testSteps.getSelectedItems(testSteps.filteredItems),
      ({ SharedStepId }: ITestStep) => SharedStepId
    ).filter(Boolean);

    await testSteps.deleteSharedStepWithActions(
      selectedIds as string[],
      projectId
    );

    setOnConfirm(null);
  };

  const onConfirmCancel = () => {
    setOnConfirm(null);
    setShowConfirmationModal(false);
  };

  return (
    <>
      <StyledButton onClick={onUngroup} startIcon={<DeleteSweepOutlined />}>
        {t("delete_with_ungrouping")}
      </StyledButton>
      <StyledButton onClick={onDelete} startIcon={<DeleteOutlined />}>
        {t("delete_with_actions")}
      </StyledButton>
      <SelectMenuItem
        containerClassName={classes.attributionButton}
        selectedSize={testSteps.getSelectedSize(testSteps.filteredItems)}
        onSelectAll={() =>
          testSteps.toAllsetSelect(testSteps.filteredItems, true)
        }
        onSelectNone={() =>
          testSteps.toAllsetSelect(testSteps.filteredItems, false)
        }
      />
      <ConfirmationModal
        isOpened={showConfirmationModal}
        text={t("common:confirm")}
        onConfirm={() => onConfirm && onConfirm()}
        onCancel={onConfirmCancel}
      />
    </>
  );
});

export default SharedStepListBulkMenu;
