import React from "react";
import Tab, { TabProps } from "@mui/material/Tab";
import { Theme } from "@mui/material";
import { withStyles } from "@mui/styles";

export default withStyles((theme: Theme) => ({
  root: {
    minWidth: 0,
    padding: theme.spacing(0, 1),
    margin: theme.spacing(0, -1),
    textTransform: "none",
    fontWeight: "normal",
    marginLeft: theme.spacing(4),
    height: 48,
    opacity: 1,
    color: theme.palette.doveGray.main,
    "&.tabSelected": {
      color: theme.palette.mineShaft.main,
    },
    "&:first-of-type": {
      marginLeft: 0,
    },
  },
}))((props: TabProps) => <Tab disableRipple {...props} />);
