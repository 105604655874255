import { FC, ReactNode, useLayoutEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Theme, Snackbar, Grid, Fade } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import en from "./en.json";
import { addResourcesBundle } from "../../i18n";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flex: "1 1 auto",
    bottom: theme.spacing(2),
    padding: theme.spacing(0, 3),
    position: "relative",
    transform: "none",
    left: "initial",
    right: "initial",
    zIndex: theme.zIndex.modal - 1,
  },
  container: {
    position: "fixed",
    bottom: 16,
    height: 48,
    padding: theme.spacing(0, 2),
    backgroundColor: theme.palette.shark.main,
    boxShadow: `0px 8px 16px ${theme.utils.withHexOpacity(
      theme.palette.black,
      5
    )}`,
  },
}));

interface IBulkMenus {
  customContainerClassName?: string;
  customSnackbarClassName?: string;
  children?: ReactNode;
}

const BulkMenus: FC<IBulkMenus> = observer(
  ({ children, customContainerClassName, customSnackbarClassName }) => {
    const classes = useStyles();
    const snackRef = useRef<HTMLElement>(null);
    const [snackbarWidth, setSnackbarWidth] = useState(0);
    const [leftOffset, setLeftOffset] = useState(0);

    const handleSize = () => {
      if (!snackRef || !snackRef.current) return;
      const { width, left } = snackRef.current.getBoundingClientRect();
      setSnackbarWidth(width);
      setLeftOffset(left);
    };

    useLayoutEffect(() => {
      handleSize();
      window.addEventListener("resize", handleSize);
      return () => window.removeEventListener("resize", handleSize);
    }, [snackRef]);

    return (
      <Snackbar
        className={clsx(classes.root, customSnackbarClassName || null)}
        ref={snackRef}
        transitionDuration={0}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={true}
      >
        <div>
          <Grid
            container
            wrap="nowrap"
            alignItems="center"
            style={{ width: snackbarWidth - 48, left: leftOffset + 24 }}
            className={clsx(
              classes.container,
              customContainerClassName || null
            )}
          >
            {children}
          </Grid>
        </div>
      </Snackbar>
    );
  }
);

export default BulkMenus;
export { default as SharedStepsBulkMenus } from "./SharedStepsBulkMenus";
export { default as TestsBulkMenus } from "./TestsBulkMenus";
export { default as ElementsBulkMenus } from "./ElementsBulkMenus";
export { default as ActiveUsersBulkMenu } from "./ActiveUsersBulkMenu";
export { default as PendingUsersBulkMenu } from "./PendingUsersBulkMenu";
export { default as DeactivatedUsersBulkMenu } from "./DeactivatedUsersBulkMenu";
export { default as ProjectUsersBulkMenu } from "./ProjectUsersBulkMenu";
export { default as SharedStepListBulkMenu } from "./SharedStepListBulkMenu";
