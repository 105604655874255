import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { Grid, Theme, ListItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ITestStep } from "../../models";
import ValidatableTextArea from "../Inputs/ValidatableTextArea";

const useStyles = makeStyles((theme: Theme) => ({
  step: {
    justifyContent: "space-between",
    minHeight: 96,
    padding: theme.spacing(3, 4),
    backgroundColor: theme.palette.white.main,
    "&.testStepSelected": {
      borderLeft: `4px solid ${theme.palette.outrageousOrange.main}`,
      paddingLeft: "1.75rem",
    },
    "&.testStepSelected, &.testStepSelected:hover, &:hover": {
      backgroundColor: theme.palette.catskillLightWhite.main,
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

interface INewSharedStepTitleProps {
  testStep: ITestStep;
}

const NewSharedStepTitle: React.FC<INewSharedStepTitleProps> = observer(
  ({ testStep }) => {
    const classes = useStyles();
    const { t } = useTranslation(["test_steps"]);

    const onChangeName = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      testStep.setValue(event.target.value);
    };

    return (
      <ListItem
        classes={{ selected: "testStepSelected" }}
        component={Grid}
        container
        className={classes.step}
        selected={testStep.selected}
        wrap="nowrap"
        onClick={testStep.toggleSelected}
      >
        <ValidatableTextArea
          minRows={3}
          maxRows={3}
          maxLength={255}
          itemName={testStep.value}
          isValid={testStep.isValid}
          onChange={onChangeName}
          onSaveClick={testStep.onSaveSharedStepClick}
          onCancelClick={testStep.onCancelSharedStepClick}
          placeholder={t("new_shared_step_name")}
          errorText={testStep.validationErrorText}
        />
      </ListItem>
    );
  }
);

export default NewSharedStepTitle;
