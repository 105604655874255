import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import _ from "lodash";
import DropElement from "./DropElement";
import { ITestStep } from "../../models";
import ErrorStrategySelect from "./ErrorStrategySelect";
import { NumberTextField } from "../Inputs";

const useStyles = makeStyles((theme: Theme) => ({
  dropElementBlock: {
    marginBottom: theme.spacing(4),
  },
}));

interface IClickAction {
  testStep: ITestStep;
}

const ClickAction: React.FC<IClickAction> = observer(({ testStep }) => {
  const classes = useStyles();
  const { t } = useTranslation(["actions"]);

  const bounced = useCallback(
    () => _.debounce(testStep.save, 1000),
    [testStep.save],
  );

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    testStep.setScroll(parseInt(e.target.value));
    bounced();
  };

  return (
    <>
      <Grid item className={classes.dropElementBlock}>
        <DropElement label={t("target_element")} testStep={testStep} />
      </Grid>
      <NumberTextField
        labelText={t("scroll_to")}
        maxLength={8}
        value={testStep.scroll?.toString()}
        onChange={onChangeValue}
        onBlur={testStep.save}
      />
      <ErrorStrategySelect testStep={testStep} />
    </>
  );
});

export default ClickAction;
