import React, { useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { addResourcesBundle } from "../../i18n";
import { IReportFilters } from "../../models";
import { Menu, MenuItem } from "../Menus";

import en from "./en.json";
import { TextFilter, CheckboxFilter, PeriodFilter } from "../Filters";

addResourcesBundle({ en });

interface IFilterMenu {
  anchorEl: null | HTMLElement;
  onMenuClose: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  filterStore: IReportFilters;
}

const FilterMenu: React.FC<IFilterMenu> = observer(
  ({ anchorEl, onMenuClose, filterStore }) => {
    const { t } = useTranslation(["report_filters"]);
    const LIST_ITEMS = [
      { label: t("reports:results:passed"), value: "passed" },
      { label: t("reports:results:pending"), value: "pending" },
      { label: t("reports:results:broken"), value: "broken" },
      { label: t("reports:results:failed"), value: "failed" },
    ];

    const [nameFilterAnchor, setNameFilterAnchor] =
      useState<null | HTMLElement>(null);

    const [lastResultFilterAnchor, setLastResultFilterAnchor] =
      useState<null | HTMLElement>(null);

    const [periodFilterAnchor, setPeriodFilterAnchor] =
      useState<null | HTMLElement>(null);

    const onNameFilterClick = (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      setNameFilterAnchor(anchorEl);
      onMenuClose(e);
    };

    const onLastResultFilterClick = (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      setLastResultFilterAnchor(anchorEl);
      onMenuClose(e);
    };

    const onPeriodFilterClick = (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      setPeriodFilterAnchor(anchorEl);
      onMenuClose(e);
    };

    return (
      <>
        <Menu
          anchorEl={anchorEl}
          onClose={onMenuClose}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MenuItem onClick={onNameFilterClick}>{t("name")}</MenuItem>
          <MenuItem onClick={onLastResultFilterClick}>
            {t("last_result")}
          </MenuItem>
          <MenuItem onClick={onPeriodFilterClick}>{t("last_run")}</MenuItem>
        </Menu>
        <TextFilter
          multiline
          anchorEl={nameFilterAnchor}
          onMenuClose={() => setNameFilterAnchor(null)}
          title={t("filter_by_name")}
          value={filterStore.nameFilter}
          onSave={filterStore.saveNameFilter}
        />
        <CheckboxFilter
          title={t("filter_by_last_result")}
          checkboxItems={LIST_ITEMS}
          initiallySelectedItems={[...filterStore.lastResultFilter]}
          filterStore={filterStore}
          anchorEl={lastResultFilterAnchor}
          onMenuClose={() => setLastResultFilterAnchor(null)}
          onSave={filterStore.saveLastResultFilter}
        />
        <PeriodFilter
          anchorEl={periodFilterAnchor}
          onMenuClose={() => setPeriodFilterAnchor(null)}
          filterStore={filterStore}
          title={t("filter_by_time_period")}
          onSave={filterStore.savePeriodFilter}
        />
      </>
    );
  }
);

export default FilterMenu;
