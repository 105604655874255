import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { InputBaseProps, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import { Menu, MenuButton, MenuButtonBlock } from "../../Menus";
import { TextField } from "../../Inputs";

const useStyles = makeStyles((theme: Theme) => ({
  menuPaper: {
    ...theme.typography.descriptionTextStyle,
    color: theme.palette.doveGray.main,
    padding: theme.spacing(1, 3, 0),
    width: theme.spacing(36),
  },
  textField: {
    width: "100%",
  },
  inputBaseClass: {
    backgroundColor: theme.palette.white.main,
    flex: "1 1 auto",
    margin: theme.spacing(0.5, 0, 1),
  },
}));

interface ITextFilter {
  anchorEl: null | HTMLElement;
  onMenuClose: () => void;
  value: string;
  onSave: (value: string) => void;
  title: string;
  placeholder?: string;
  multiline?: boolean;
}

const TextFilter: React.FC<ITextFilter> = observer(
  ({
    anchorEl,
    onMenuClose,
    value,
    onSave,
    title,
    placeholder,
    multiline = false,
  }) => {
    const classes = useStyles();
    const { t } = useTranslation(["common"]);
    const [textFilter, setTextFilter] = useState<string>("");

    useEffect(() => {
      if (anchorEl) setTextFilter(value);
    }, [anchorEl, value]);

    const onFilterCancel = () => {
      onMenuClose();
    };

    const onFilterSave = () => {
      onSave(textFilter);
      onMenuClose();
    };

    const inputBaseProps: InputBaseProps = Object.assign(
      {
        fullWidth: true,
        value: textFilter,
        placeholder: placeholder || t("enter_keywords"),
        className: classes.inputBaseClass,
        onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setTextFilter(e.target.value),
        onClick: (e: React.SyntheticEvent) => e.stopPropagation(),
      },
      multiline ? { multiline: true, minRows: 4, maxRows: 4 } : {}
    );

    if (!anchorEl) return null;

    return (
      <Menu
        anchorEl={anchorEl}
        onClose={onMenuClose}
        PaperProps={{ className: classes.menuPaper }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <TextField
          boxClassName={classes.textField}
          labelText={title}
          inputBaseProps={inputBaseProps}
        />
        <MenuButtonBlock>
          <MenuButton
            startIcon={<CancelOutlined />}
            label={t("common:cancel")}
            onClick={onFilterCancel}
          />
          <MenuButton
            startIcon={<CheckCircleOutline />}
            label={t("common:apply")}
            onClick={onFilterSave}
          />
        </MenuButtonBlock>
      </Menu>
    );
  }
);

export default TextFilter;
