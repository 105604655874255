import React from "react";
import { observer } from "mobx-react";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { BorderColorOutlined } from "@mui/icons-material";
import { ILabel } from "../../models";
import { useLabelMenuContext, useRootStoreContext } from "../../hooks";
import { MenuItem } from "../Menus";
import { Checkbox } from "../Inputs";
import StyledIconButton from "../StyledIconButton";
import LabelItemEdit from "./LabelItemEdit";
import LabelItemMenu from "./LabelItemMenu";

interface ILabelItem {
  label: ILabel;
  hasControls: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  labelItem: {
    height: "auto",
    padding: 0,
    "&:hover $controlContainer": {
      visibility: "visible",
    },
  },
  controlContainer: {
    visibility: "hidden",
    position: "absolute",
    top: 0,
    right: 0,
    width: "auto",
  },
  controlBlock: {
    padding: theme.spacing(0),
    backgroundColor: theme.palette.catskillLightWhite.main,
  },
  gradientBlock: {
    padding: theme.spacing(5, 5, 0, 0),
    background: `linear-gradient(90deg, ${theme.palette.catskillLightWhite.main}00 0%, ${theme.palette.catskillLightWhite.main} 100%)`,
  },
}));

const LabelItem: React.FC<ILabelItem> = observer(({ label, hasControls }) => {
  const classes = useStyles();
  const { labels } = useRootStoreContext();
  const { projectId = "", suiteId: currentSuiteId = "" } = useParams<{
    projectId: string;
    suiteId: string;
  }>();
  const { testIds, suiteId } = useLabelMenuContext();
  const onSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    label.onSelect(e);
    if (!hasControls) return;
    if (suiteId && !testIds?.length) {
      labels.assignLabelsToSuite({
        projectId: parseInt(projectId, 10),
        suiteId,
        isSuiteSelected: suiteId === currentSuiteId,
      });
    } else {
      labels.assignLabelsToTests({
        projectId: parseInt(projectId, 10),
        suiteId,
        testIds,
      });
    }
  };

  return (
    <MenuItem className={classes.labelItem}>
      {labels.editLabel && labels.editLabel.id === label.id ? (
        <LabelItemEdit label={labels.editLabel} />
      ) : (
        <>
          <Checkbox
            checked={label.selected}
            onChange={onSelect}
            label={label.name}
            indeterminate={label.indeterminate}
          />
          {hasControls ? (
            <Grid
              container
              item
              className={classes.controlContainer}
              wrap="nowrap"
            >
              <Grid container item className={classes.gradientBlock} />
              <Grid
                container
                item
                className={classes.controlBlock}
                wrap="nowrap"
              >
                <StyledIconButton
                  disableFocusRipple={true}
                  disableRipple={true}
                  onClick={label.onEditExistingLabelClick}
                >
                  <BorderColorOutlined />
                </StyledIconButton>
                <LabelItemMenu label={label} />
              </Grid>
            </Grid>
          ) : null}
        </>
      )}
    </MenuItem>
  );
});

export default LabelItem;
