import { withStyles } from "@mui/styles";
import { Button, Theme } from "@mui/material";

export default withStyles((theme: Theme) => ({
  root: {
    position: "relative",
    ...theme.typography.bodyTextStyle,
    padding: theme.spacing(1, 3),
    textTransform: "none",
    whiteSpace: "nowrap",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.catskillLightWhite.main,
      "&$text, $startIcon, $endIcon": {
        color: theme.palette.outrageousOrange.main,
      },
    },
  },
  startIcon: {
    color: theme.palette.baliHai.main,
    "& .MuiSvgIcon-root": {
      fontSize: 24,
    },
  },
  endIcon: {
    color: theme.palette.baliHai.main,
    "& .MuiSvgIcon-root": {
      fontSize: 24,
    },
  },
  text: {
    color: theme.palette.mineShaft.main,
  },
  textSecondary: {
    color: theme.palette.outrageousOrange.main,
  },
  disabled: {
    opacity: 0.35,
  },
  outlinedPrimary: {
    padding: theme.spacing("0.375rem", 3),
    borderColor: theme.palette.outrageousOrange.main,
    textDecoration: "none",
    color: theme.palette.outrageousOrange.main,
    "& $startIcon, & $endIcon": {
      color: theme.palette.outrageousOrange.main,
    },
    "&:hover": {
      boxShadow: "none",
      color: theme.palette.pomegranate.main,
      borderColor: theme.palette.pomegranate.main,
      backgroundColor: theme.palette.white.main,
    },
    "&:disabled": {
      borderColor: theme.palette.outrageousOrange.main,
    },
  },
  containedPrimary: {
    padding: theme.spacing("0.4375rem", 3),
    backgroundColor: theme.palette.outrageousOrange.main,
    boxShadow: "none",
    "& $startIcon, & $endIcon": {
      color: theme.palette.white.main,
    },
    "&:hover": {
      boxShadow: "none",
      color: theme.palette.white.main,
      backgroundColor: theme.palette.pomegranate.main,
    },
    "&:disabled": {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.outrageousOrange.main,
    },
    "&:active, &:focus-visible": {
      boxShadow: "none",
    },
  },
}))(Button);
