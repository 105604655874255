import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import _ from "lodash";
import { ITestStep } from "../../models";
import { TextField } from "../Inputs";

interface IVisitAction {
  testStep: ITestStep;
}

const VisitAction: React.FC<IVisitAction> = observer(({ testStep }) => {
  const { t } = useTranslation(["actions", "common"]);
  const bounced = useCallback(
    () => _.debounce(testStep.save, 1000),
    [testStep.save]
  );

  const onChangeValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > 255) return;
    testStep.setValue(e.target.value.replace(/[\r\n]/g, ""));
    bounced();
  };

  return (
    <Grid item>
      <TextField
        labelText={t("url")}
        helperTextRight={t("common:characters_left", {
          number: 255 - (testStep.value?.length || 0),
        })}
        inputBaseProps={{
          minRows: 3,
          maxRows: 3,
          fullWidth: true,
          onChange: onChangeValue,
          onBlur: testStep.save,
          value: testStep.value || "",
          multiline: true,
        }}
      />
    </Grid>
  );
});

export default VisitAction;
