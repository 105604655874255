import { FC, ReactNode } from "react";
import clsx from "clsx";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  controlContainer: {
    visibility: "hidden",
    position: "absolute",
    top: 0,
    right: theme.spacing(3),
    width: "auto",
  },
  controlBlock: {
    padding: theme.spacing(2, 0),
    backgroundColor: theme.palette.catskillLightWhite.main,
  },
  controlButton: {
    padding: theme.spacing(1),
  },
  gradientBlock: {
    padding: theme.spacing(9, 5, 0, 0),
    background: `linear-gradient(90deg, ${theme.palette.catskillLightWhite.main}00 0%, ${theme.palette.catskillLightWhite.main} 100%)`,
  },
}));

const ItemControlButtonBlock: FC<{ children?: ReactNode }> = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      className={clsx(classes.controlContainer, "controlContainer")}
      wrap="nowrap"
    >
      <Grid container item className={classes.gradientBlock} />
      <Grid container item className={classes.controlBlock} wrap="nowrap">
        {children}
      </Grid>
    </Grid>
  );
};

export default ItemControlButtonBlock;
