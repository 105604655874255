import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Divider, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import { Menu, MenuButton, MenuButtonBlock } from "../../Menus";
import { Search } from "../../Inputs";
import { useRootStoreContext } from "../../../hooks";
import LabelList from "../../LabelMenu/LabelItemList";

const useStyles = makeStyles((theme: Theme) => ({
  menuPaper: {
    ...theme.typography.descriptionTextStyle,
    color: theme.palette.doveGray.main,
    padding: theme.spacing(1, 3, 0),
    width: theme.spacing(36),
  },
  searchBox: {
    margin: theme.spacing(0.5, 0, 2),
  },
}));

interface ILabelFilter {
  anchorEl: null | HTMLElement;
  onMenuClose: () => void;
}

const LabelFilter: React.FC<ILabelFilter> = observer(
  ({ anchorEl, onMenuClose }) => {
    const classes = useStyles();
    const { t } = useTranslation(["test_filters", "common"]);
    const { testFilters, labels } = useRootStoreContext();

    const onFilterCancel = () => {
      onMenuClose();
    };

    const onFilterSave = () => {
      testFilters.saveLabelFilter(labels.selectedLabels);
      onMenuClose();
    };

    useEffect(() => {
      if (anchorEl) {
        labels.clearLabels();
        labels.loadTestFilterLabels();
      }
    }, [labels, anchorEl]);

    if (!anchorEl) return null;

    return (
      <Menu
        anchorEl={anchorEl}
        onClose={onMenuClose}
        PaperProps={{ className: classes.menuPaper }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Search
          labelText={t("filter_tests_by_label")}
          value={labels.labelSearchValue}
          className={classes.searchBox}
          onChangeText={labels.onLabelsSearch}
        />
        <LabelList hasControls={false} anchorEl={anchorEl} />
        <Divider />
        <MenuButtonBlock>
          <MenuButton
            startIcon={<CancelOutlined />}
            label={t("common:cancel")}
            onClick={onFilterCancel}
          />
          <MenuButton
            startIcon={<CheckCircleOutline />}
            label={t("common:apply")}
            onClick={onFilterSave}
          />
        </MenuButtonBlock>
      </Menu>
    );
  }
);

export default LabelFilter;
