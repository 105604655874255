import React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useRootStoreContext } from "../../hooks";
import SelectMenuItem from "./SelectMenuItem";
import EditMenuItem from "./EditMenuItem";
import LabelBulkMenu from "./LabelBulkMenu";

interface ITestsBulkMenus {}

const TestsBulkMenus: React.FC<ITestsBulkMenus> = observer(() => {
  const { projectId = "", suiteId = "" } = useParams<{
    projectId: string;
    suiteId: string;
  }>();
  const { suites, tests } = useRootStoreContext();

  const onDuplicate = () => {
    tests.duplicateTests({ projectId: parseInt(projectId, 10), suiteId });
  };

  const onDelete = () => {
    tests.deleteTests({ projectId: parseInt(projectId, 10), suiteId });
  };

  const onGroupSelected = () => {
    suites.onGroupSelected(parseInt(projectId, 10), suiteId);
  };

  return (
    <>
      <EditMenuItem
        onDuplicate={onDuplicate}
        onDelete={onDelete}
        onGroupSelected={onGroupSelected}
      />
      <LabelBulkMenu />
      <SelectMenuItem
        selectedSize={tests.getSelectedSize(tests.preparedItems)}
        onSelectAll={() => tests.toAllsetSelect(tests.preparedItems, true)}
        onSelectNone={() => tests.toAllsetSelect(tests.preparedItems, false)}
      />
    </>
  );
});

export default TestsBulkMenus;
