import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Theme, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CancelOutlined, AddBoxOutlined } from "@mui/icons-material";
import clsx from "clsx";

import { addResourcesBundle } from "../../i18n";
import en from "./en.json";

import { useRootStoreContext } from "../../hooks";
import { ILabelSnapshotOut } from "../../models";
import { Menu, MenuButton, MenuButtonBlock } from "../Menus";
import { Search } from "../Inputs";
import LabelItemList from "./LabelItemList";
import LabelItemAdd from "./LabelItemAdd";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  menuPaper: {
    ...theme.typography.descriptionTextStyle,
    color: theme.palette.doveGray.main,
    padding: theme.spacing(2, 3, 1),
    width: theme.spacing(36),
  },
  searchBox: {
    margin: theme.spacing(0.5, 0, 2),
  },
}));

interface ILabelMenu {
  anchorEl: null | HTMLElement;
  onClose: () => void;
  currentLabels?: ILabelSnapshotOut[];
  className?: string;
}

const LabelMenu: React.FC<ILabelMenu> = observer(
  ({ anchorEl, currentLabels = [], onClose, className }) => {
    const classes = useStyles();
    const { t } = useTranslation(["labels", "common"]);
    const { labels } = useRootStoreContext();
    const { projectId = "" } = useParams<{ projectId: string }>();

    const onAddLabel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      labels.onAddNewClick(parseInt(projectId, 10));
    };

    const onMenuClose = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      e.stopPropagation();
      labels.onCancelEditExistingLabel();
      onClose();
    };

    useEffect(() => {
      if (anchorEl) labels.setCurrentLabels(currentLabels);
    }, [labels, currentLabels, anchorEl]);

    useEffect(() => {
      if (anchorEl) labels.load({ projectId });
    }, [labels, projectId, anchorEl]);

    if (!anchorEl) return null;

    return labels.newLabel ? (
      <LabelItemAdd
        label={labels.newLabel}
        anchor={anchorEl}
        onClose={onClose}
        className={className}
      />
    ) : (
      <Menu
        anchorEl={anchorEl}
        onClose={onMenuClose}
        PaperProps={{ className: clsx(classes.menuPaper, className) }}
      >
        <Search
          labelText={t("common:search")}
          value={labels.labelSearchValue}
          className={classes.searchBox}
          onChangeText={labels.onLabelsSearch}
        />
        <LabelItemList anchorEl={anchorEl} />
        <Divider />
        <MenuButtonBlock>
          <MenuButton
            startIcon={<CancelOutlined />}
            label={t("common:done")}
            onClick={onClose}
          />
          <MenuButton
            startIcon={<AddBoxOutlined />}
            label={t("add_label")}
            onClick={onAddLabel}
          />
        </MenuButtonBlock>
      </Menu>
    );
  }
);

export default LabelMenu;
