import { FC, SyntheticEvent, useState } from "react";
import { observer } from "mobx-react";
import { Popover, Box, Theme, Grid, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import StyledButton from "../StyledButton";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  popoverContainer: {
    width: 320,
    borderRadius: 0,
    position: "relative",
    padding: theme.spacing(2),
    color: theme.palette.white.main,
    backgroundColor: theme.palette.shark.main,
    boxShadow: "none",
    marginTop: 5,
    overflow: "visible",
  },
  arrowUp: {
    position: "absolute",
    top: -4,
    left: 0,
    right: 0,
    margin: "0 auto",
    width: 0,
    height: 0,
    borderLeft: "4px solid transparent",
    borderRight: "4px solid transparent",
    borderBottom: `4px solid ${theme.palette.shark.main}`,
  },
  right: {
    marginRight: 20,
  },
  left: {
    marginLeft: 20,
  },
  title: {
    ...theme.typography.subHeaderTextStyle,
  },
  description: {
    ...theme.typography.bodyTextStyle,
    marginTop: theme.spacing(1),
  },
  actions: {
    marginTop: theme.spacing(2),
  },
  skip: {
    alignSelf: "center",
  },
  skipLink: {
    ...theme.typography.bodyTextStyle,
    fontFamily: theme.typography.fontFamily,
    marginLeft: 4,
    textDecoration: "underline",
    color: theme.palette.paleRed.main,
  },
}));

interface IHint {
  open: boolean;
  onAction?: () => void;
  onSkip?: () => void;
  anchorEl: HTMLElement | null;
  arrowPosition?: "left" | "center" | "right";
  title?: string;
  description?: string;
  actionText?: string;
}

const Hint: FC<IHint> = observer(
  ({
    open,
    anchorEl,
    onAction,
    onSkip,
    arrowPosition = "center",
    title,
    description,
    actionText,
  }) => {
    const classes = useStyles();
    const { t } = useTranslation(["hints"]);

    const [cancelled, setCancelled] = useState(false);

    const handleClose = (
      _e: SyntheticEvent,
      reason: "backdropClick" | "escapeKeyDown"
    ) => {
      if (reason === "backdropClick") setCancelled(true);
    };

    if (!anchorEl) return null;

    return (
      <Popover
        open={open && !cancelled}
        anchorEl={anchorEl}
        classes={{ paper: classes.popoverContainer }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: arrowPosition,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: arrowPosition,
        }}
        BackdropProps={{ invisible: false }}
        style={{ zIndex: 1100 }}
      >
        <Box
          className={clsx(
            classes.arrowUp,
            arrowPosition !== "center" && classes[arrowPosition]
          )}
        />
        <Grid item className={classes.title}>
          {title}
        </Grid>
        <Grid item className={classes.description}>
          {description}
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          className={classes.actions}
        >
          {onAction ? (
            <StyledButton
              variant="contained"
              color="primary"
              onClick={onAction}
            >
              {actionText}
            </StyledButton>
          ) : null}
          {onSkip ? (
            <Grid item className={classes.skip}>
              {t("done")}
              <Link
                component="button"
                onClick={onSkip}
                className={classes.skipLink}
              >
                {t("skip")}
              </Link>
            </Grid>
          ) : null}
        </Grid>
      </Popover>
    );
  }
);

export default Hint;
