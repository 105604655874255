import { FC, useEffect, useState } from "react";
import _ from "lodash";
import { Redirect, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { List, Grid, ListItem, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { useRootStoreContext } from "../../hooks";
import SeparateTest from "./SeparateTest";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootBox: {
      flex: 1,
      minHeight: 0,
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      margin: 0,
      backgroundColor: theme.palette.white.main,
    },
    listItem: {
      padding: theme.spacing(3, 4, 0),
      display: "flex",
    },
    headerContent: {
      borderBottom: `1px solid ${theme.palette.catskillWhite.main}`,
      paddingBottom: theme.spacing(2),
      paddingRight: 36, // 36px is the width of the expand icon
    },
    headerLabel: {
      color: theme.palette.doveGray.main,
      ...theme.typography.tableLabelTextStyle,
      paddingRight: ".3rem",
    },
    columnName: {
      flex: 8,
    },
    columnRun: {
      flex: 2,
      minWidth: 165,
    },
    columnResult: {
      flex: 1,
      minWidth: 84,
    },
  })
);

const SeparateTests: FC = observer(() => {
  const classes = useStyles();
  const { projectId = "", testId = "" } = useParams<{
    projectId: string;
    testId: string;
  }>();
  const {
    tests,
    environments,
    variables,
    users: { profile },
  } = useRootStoreContext();
  const { t } = useTranslation(["reports"]);

  const [expandedId, setExpandedId] = useState<null | string>(null);

  useEffect(() => {
    tests.loadTestsWithReports({
      projectId: parseInt(projectId, 10),
    });
    profile.load();
    environments.load({ projectId: parseInt(projectId, 10) });
    variables.load({ projectId: parseInt(projectId, 10) });
    return () => {
      tests.clearItems();
    };
  }, [tests, projectId, profile, variables, environments]);

  useEffect(() => {
    if (testId) {
      setExpandedId(testId);
    }
  }, [testId]);

  if (!profile.isAuthenticated) return <Redirect to="/sign-in" />;

  // TODO: Replace with loading overlay
  if (tests.testsState === "loading") return <Grid>Loading</Grid>;

  const testsList = testId
    ? [tests.getById(testId)].filter(Boolean)
    : tests.sortedFilteredItemsWithReports;

  return (
    <List disablePadding className={classes.rootBox}>
      <ListItem component="li" classes={{ root: classes.listItem }}>
        <Grid
          container
          alignItems="center"
          wrap="nowrap"
          className={classes.headerContent}
        >
          <Grid item className={clsx(classes.headerLabel, classes.columnName)}>
            {t("name")}
          </Grid>
          <Grid item className={clsx(classes.headerLabel, classes.columnRun)}>
            {t("last_run")}
          </Grid>
          <Grid
            item
            className={clsx(classes.headerLabel, classes.columnResult)}
          >
            {t("last_result")}
          </Grid>
        </Grid>
      </ListItem>
      {_.map(testsList, (test) => (
        <SeparateTest
          key={test.id}
          test={test}
          expanded={expandedId === test.id}
          onExpand={setExpandedId}
        />
      ))}
    </List>
  );
});

export default SeparateTests;
