import React, { useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { addResourcesBundle } from "../../i18n";
import { IUserFilters } from "../../models";
import { Menu, MenuItem } from "../Menus";
import { TextFilter, CheckboxFilter } from "../Filters";
import en from "./en.json";

addResourcesBundle({ en });

interface IFilterMenu {
  anchorEl: null | HTMLElement;
  onMenuClose: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  filterStore: IUserFilters;
}

const FilterMenu: React.FC<IFilterMenu> = observer(
  ({ anchorEl, onMenuClose, filterStore }) => {
    const { t } = useTranslation(["user_filters"]);
    const location = useLocation();
    const noNameFilter =
      location.pathname.split("/").reverse()[0] === "pending";

    const USER_ROLES = [
      { label: t("users:roles:admin"), value: "admin" },
      { label: t("users:roles:user"), value: "user" },
      { label: t("users:roles:manager"), value: "manager" },
    ];

    const [nameFilterAnchor, setNameFilterAnchor] =
      useState<null | HTMLElement>(null);

    const [emailFilterAnchor, setEmailFilterAnchor] =
      useState<null | HTMLElement>(null);

    const [roleFilterAnchor, setRoleFilterAnchor] =
      useState<null | HTMLElement>(null);

    const onNameFilterClick = (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      setNameFilterAnchor(anchorEl);
      onMenuClose(e);
    };

    const onEmailFilterClick = (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      setEmailFilterAnchor(anchorEl);
      onMenuClose(e);
    };

    const onRoleFilterClick = (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      setRoleFilterAnchor(anchorEl);
      onMenuClose(e);
    };

    return (
      <>
        <Menu
          anchorEl={anchorEl}
          onClose={onMenuClose}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          {noNameFilter ? null : (
            <MenuItem onClick={onNameFilterClick}>{t("name")}</MenuItem>
          )}
          <MenuItem onClick={onEmailFilterClick}>{t("email")}</MenuItem>
          <MenuItem onClick={onRoleFilterClick}>{t("role")}</MenuItem>
        </Menu>
        <TextFilter
          title={t("name_contains")}
          anchorEl={nameFilterAnchor}
          onMenuClose={() => setNameFilterAnchor(null)}
          value={filterStore.nameFilter}
          onSave={filterStore.saveNameFilter}
        />
        <TextFilter
          title={t("email_contains")}
          anchorEl={emailFilterAnchor}
          onMenuClose={() => setEmailFilterAnchor(null)}
          value={filterStore.emailFilter}
          onSave={filterStore.saveEmailFilter}
        />
        <CheckboxFilter
          title={t("select_roles")}
          checkboxItems={USER_ROLES}
          initiallySelectedItems={[...filterStore.roleFilter]}
          filterStore={filterStore}
          anchorEl={roleFilterAnchor}
          onMenuClose={() => setRoleFilterAnchor(null)}
          onSave={filterStore.saveRoleFilter}
        />
      </>
    );
  }
);

export default FilterMenu;
