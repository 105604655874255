import React, { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Grid, Theme, Divider, Box } from "@mui/material";
import {
  FilterListOutlined,
  AddBoxOutlined,
  CodeOutlined,
  PlayArrowOutlined,
  KeyboardArrowDownOutlined,
} from "@mui/icons-material";
import clsx from "clsx";

import { addResourcesBundle } from "../../i18n";
import en from "./en.json";
import { useRootStoreContext } from "../../hooks";
import StyledButton from "../StyledButton";
import TestFilterMenu from "../ListFilterMenu";
import SortMenu from "../SortMenu";
import BulkMenus, { TestsBulkMenus } from "../BulkMenus";
import {
  UpdateClient,
  NoClient,
  NoConnection,
  NoRecorder,
  Hint,
} from "../Modals";
import TestList from "./TestList";

import {
  addMiddleware,
  createActionTrackingMiddleware2,
} from "mobx-state-tree";
import _ from "lodash";
import { ITest } from "../../models";
import FilterBar from "../FilterBar";
import { checkClientVersion } from "../../models/utils";

addResourcesBundle({ en });

const pageHint = "add_test";

const useStyles = makeStyles((theme: Theme) => ({
  controlBarContainer: {
    backgroundColor: theme.palette.white.main,
    padding: theme.spacing(1, 4),
  },
  controlBar: {
    margin: theme.spacing(0, -2),
    width: "auto",
  },
  listWrapper: {
    minHeight: 0,
    height: "100%",
  },
  dividerWrapper: {
    padding: theme.spacing(0, 4),
    backgroundColor: theme.palette.white.main,
  },
  divider: {
    backgroundColor: theme.palette.catskillWhite.main,
  },
  sortMenu: {
    margin: theme.spacing(0, 3),
  },
  listContainer: {
    width: "100%",
    paddingTop: theme.spacing(3),
  },
  hintArea: {
    zIndex: 1200,
    backgroundColor: theme.palette.white.main,
    margin: -16,
    padding: 8,
  },
}));

const Tests = observer(() => {
  const classes = useStyles();

  const { t } = useTranslation([
    "test_control_bar",
    "test_sort_menu",
    "notifications",
  ]);

  const { projectId = "", suiteId = "" } = useParams<{
    projectId: string;
    suiteId: string;
  }>();
  const {
    tests,
    testFilters,
    testAuthors,
    labels,
    projects,
    users,
    environments,
    notifications,
    users: {
      profile: { isHintShown, updateHints, skipAllHints },
    },
  } = useRootStoreContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [sortAnchorEl, setSortAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const [updateClientVersion, setUpdateClientVersion] = useState("ignore");
  const [noClient, setNoClient] = useState(false);
  const [noRecorder, setNoRecorder] = useState(false);
  const [noConnection, setNoConnection] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [recorderExtId, setRecorderExtId] = useState("");
  const history = useHistory();

  const [anchorElHint, setAnchorElHint] = useState<HTMLDivElement | null>(null);
  const hintRef = useRef<HTMLDivElement>(null);

  useEffect(() => setAnchorElHint(hintRef.current), []);

  const showHint = !isHintShown(pageHint);
  const markHintShown = () => updateHints(pageHint);

  const noClientConditions = () =>
    !recorderExtId ||
    typeof chrome === "undefined" ||
    !chrome ||
    !chrome.runtime;

  const loadTests = useCallback(
    () =>
      tests.load({
        projectId: parseInt(projectId, 10),
        suiteId,
        selected: _.map(
          tests.getSelectedItems(tests.items),
          (item) => (item as ITest).id,
        ),
      }),
    [tests, projectId, suiteId],
  );

  useEffect(() => {
    loadTests();
    environments.load({ projectId: parseInt(projectId, 10) });
    const extId = users.profile.recorderExtId || process.env.TA_RECORDER;
    if (extId) setRecorderExtId(extId);
    if (history.location.state === "selected") history.replace({}, null);
  }, [
    loadTests,
    environments,
    projectId,
    history,
    users.profile.recorderExtId,
  ]);

  useEffect(() => {
    const disposer = addMiddleware(
      labels,
      createActionTrackingMiddleware2({
        filter(call) {
          return ["onSaveExistingLabelClick", "removeLabel"].includes(
            call.name,
          );
        },
        onStart() {},
        onFinish(call, error) {
          if (!error)
            tests.load({ projectId: parseInt(projectId, 10), suiteId });
        },
      }),
    );

    return disposer;
  }, [labels, tests, projectId, suiteId]);

  useEffect(() => {
    checkAPICalls();
  });

  const checkAPICalls = () => {
    if (!!users.profile.lastApiCallAt && updateClientVersion !== "no_response")
      return;

    setNoClient(true);
    return;
  };

  const checkVersion = async (recorderExtId: string) =>
    checkClientVersion(recorderExtId, (res: any) =>
      setUpdateClientVersion(res),
    );

  const sendUpdateClientVersion = async (recorderExtId: string) => {
    chrome.runtime.sendMessage(
      recorderExtId,
      {
        action: "bumpClientVersion",
      },
      {},
      (response) => {
        if (response) {
          console.log("Bump version result:");
          console.log(response);
        } else {
          console.log(chrome.runtime.lastError);
        }
      },
    );
  };

  const handleUpdateClientVersion = () => {
    if (noClientConditions()) {
      setNoRecorder(true);
      return;
    }

    try {
      sendUpdateClientVersion(recorderExtId);
    } catch (e) {
      console.log(`Error sendind message to extenstion: ${e}`);
    }
  };

  const anyRecordRunError = noClient || noRecorder || noConnection;

  const onFilterMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onSortMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSortAnchorEl(e.currentTarget);
  };

  const onFilterMenuClose = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onSortMenuClose = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    setSortAnchorEl(null);
  };

  const onAddNewTestClick = () => {
    markHintShown();
    tests.onAddNewTestClick(parseInt(projectId, 10), suiteId);
  };

  const handleRunTests = async () => {
    markHintShown();
    if (noClientConditions()) {
      setNoRecorder(true);
      return;
    }

    const invalidClientVersion =
      (await checkVersion(recorderExtId)) !== "ignore";
    if (anyRecordRunError || invalidClientVersion)
      return setIsErrorModalOpen(true);

    const testIds = _.map(
      tests.getSelectedItems(tests.items),
      (item) => (item as ITest).id,
    );

    if (testIds.length && projectId) {
      const currentProject = projects.getById(parseInt(projectId, 10));
      const baseEnv = environments.basicEnvironment;

      chrome.runtime.sendMessage(
        recorderExtId,
        {
          action: "runTests",
          parameters: {
            testIds,
            projectName: currentProject?.name,
            envId: baseEnv!?.id,
          },
        },
        {},
        (response) => {
          if (response && response.isDisconnected) {
            setNoConnection(true);
            return;
          }
          loadTests();
          const { success, error } = _.reduce(
            response || [],
            (result, resp) => {
              if (resp.errorMessage) {
                ++result.error;
              } else {
                ++result.success;
              }
              return result;
            },
            { success: 0, error: 0 },
          );
          notifications.addNotification({
            title: t("notifications:title"),
            text: t("notifications:multipleTests", {
              successNumber: success,
              errorNumber: error,
            }),
            type: error ? "error" : "success",
          });
        },
      );
    } else {
      alert("Please select at least one test to run");
    }
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    setNoRecorder(false);
    setNoConnection(false);
  };

  return (
    <>
      <Grid container direction="column" wrap="nowrap">
        <Grid
          container
          item
          direction="column"
          className={classes.controlBarContainer}
        >
          <Grid
            container
            item
            alignItems="center"
            direction="row"
            wrap="nowrap"
            className={classes.controlBar}
          >
            <Grid container item justifyContent="flex-start">
              <StyledButton
                startIcon={<FilterListOutlined />}
                onClick={onFilterMenuClick}
              >
                {t("filters")}
              </StyledButton>
              <TestFilterMenu
                anchorEl={anchorEl}
                onMenuClose={onFilterMenuClose}
                filterStore={testFilters}
                authorStore={testAuthors}
              />
              <StyledButton
                endIcon={<KeyboardArrowDownOutlined />}
                onClick={onSortMenuClick}
              >
                {t("test_sort_menu:" + tests.sortField)}
              </StyledButton>
              <SortMenu
                anchorEl={sortAnchorEl}
                onMenuClose={onSortMenuClose}
                className={classes.sortMenu}
                sort={tests.setSortField}
              />
            </Grid>
            <Grid
              ref={hintRef}
              container
              item
              justifyContent="flex-end"
              wrap="nowrap"
              className={clsx(showHint && classes.hintArea)}
            >
              <StyledButton
                startIcon={<CodeOutlined />}
                onClick={markHintShown}
              >
                {t("change_view")}
              </StyledButton>
              <StyledButton
                startIcon={<AddBoxOutlined />}
                onClick={onAddNewTestClick}
              >
                {t("insert_test")}
              </StyledButton>
              <StyledButton
                startIcon={<PlayArrowOutlined />}
                endIcon={<KeyboardArrowDownOutlined />}
                onClick={handleRunTests}
              >
                {t("run_test")}
              </StyledButton>
            </Grid>
            <Hint
              open={showHint}
              anchorEl={anchorElHint}
              onAction={markHintShown}
              onSkip={skipAllHints}
              title={t("hint.title")}
              description={t("hint.description")}
              actionText={t("hint.action")}
            />
          </Grid>
        </Grid>
        {testFilters.isEmpty ? null : (
          <>
            <Box className={classes.dividerWrapper}>
              <Divider className={classes.divider} />
            </Box>
            <FilterBar filterStore={testFilters} entityType="tests" />
          </>
        )}

        <Grid container item wrap="nowrap" className={classes.listWrapper}>
          <TestList />
        </Grid>
        {tests.getSelectedSize(tests.preparedItems) > 1 ? (
          <BulkMenus>
            <TestsBulkMenus />
          </BulkMenus>
        ) : null}
      </Grid>
      <UpdateClient
        isOpen={
          !["ignore", "no_response"].includes(updateClientVersion) &&
          isErrorModalOpen
        }
        onClose={handleCloseErrorModal}
        onUpdate={handleUpdateClientVersion}
        latestVersion={updateClientVersion}
      />
      <NoClient
        isOpen={noClient && isErrorModalOpen}
        onClose={handleCloseErrorModal}
      />
      <NoRecorder isOpen={noRecorder} onClose={handleCloseErrorModal} />
      <NoConnection isOpen={noConnection} onClose={handleCloseErrorModal} />
    </>
  );
});

export default Tests;
