import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { Grid, Theme, InputAdornment, Typography } from "@mui/material";

import {
  Layout,
  LayoutOfContent,
  StyledButton,
  TextField,
  TaIcon,
  PageSpinner,
} from "../components";

import { useRootStoreContext } from "../hooks";
import { SignUpSuccess } from "../components/Auth";

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    backgroundColor: theme.palette.white.main,
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    width: "100%",
    maxWidth: "100%",
    overflow: "auto",
  },
  root: {
    width: 410,
    marginLeft: "auto",
    marginRight: "auto",
    flexDirection: "column",
  },
  header: {
    marginTop: 80,
    marginBottom: 80,
    fontSize: 18,
    fontWeight: 500,
  },
  logo: {
    width: 48,
    height: 48,
    marginRight: theme.spacing(2),
  },
  actionButtons: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(4),
    alignSelf: "flex-start",
  },
  title: {
    fontSize: 15,
    fontWeight: 700,
    marginBottom: theme.spacing(),
  },
  titleText: {
    color: theme.palette.mineShaft.main,
    fontSize: 12,
    fontWeight: 400,
    marginBottom: theme.spacing(1),
  },
  eyeIcon: {
    cursor: "pointer",
    color: theme.palette.rockBlue.main,
    "&:hover": {
      color: theme.palette.outrageousOrange.main,
    },
  },
  passwordContainer: {
    marginTop: theme.spacing(2),
  },
}));

interface IFormInputs {
  password: string;
}

const NewPassword: FC<{}> = () => {
  const { t } = useTranslation("auth");

  const {
    users: { profile },
    projects,
  } = useRootStoreContext();

  const history = useHistory();

  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [passwordChecked, setPasswordChecked] = useState(false);
  const [step, setStep] = useState("reset");
  const [email, setEmail] = useState("");

  const params = new URLSearchParams(window.location.search);
  const resetCode = params.get("resetCode") || null;

  const {
    control,
    watch,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm<IFormInputs>();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = () => history.push("/sign-in");

  const handleNewPassword = async () => {
    setPasswordChecked(true);
    if (!(await trigger("password"))) return;

    setLoading(true);
    await profile.resetPassword(
      { ...watch(), resetCode },
      () => {
        projects.load();
        history.push("/dashboard/projects");
      },
      ({ error }) => {
        error === "UNCONFIRMED" ? setStep("confirm") : console.error(error);
      }
    );
    setLoading(false);
  };

  const handleChangeControlledField = (
    field: any,
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    triggered: Boolean
  ) => {
    clearErrors(field.name);
    field.onChange(event);
    triggered && trigger(field.name);
  };

  const validatePassword = (value: string) => {
    if (!value) return t("password_required");
    if (value.length > 50) return t("password_too_long");
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(value))
      return t("password_weak_short");
    return undefined;
  };

  useEffect(() => {
    try {
      fetch(
        `${process.env.REACT_APP_API_URL}/auth/validate-password-code?resetCode=${resetCode}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setEmail(data.email);
            setLoading(false);
          } else {
            handleLogin();
          }
        });
    } catch (error) {
      console.error("Failed to validate password code", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleOnEnter = (e: KeyboardEvent) => {
      if (e.code !== "Enter") return;
      if (step === "reset") handleNewPassword();
    };

    document.addEventListener("keydown", handleOnEnter);
    return () => {
      document.removeEventListener("keydown", handleOnEnter);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  if (loading) return <PageSpinner />;

  return (
    <Layout>
      <LayoutOfContent className={classes.layout}>
        <Grid container className={classes.root} wrap="nowrap">
          <Grid container alignItems="center" className={classes.header}>
            <img
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="logo"
              className={classes.logo}
            />
            {t("true_automation")}
          </Grid>
          {step === "reset" ? (
            <>
              <Typography className={classes.title}>
                {t("create_password")}
              </Typography>
              <Typography className={classes.titleText}>
                {t("password_text")}
              </Typography>
              <Grid item className={classes.passwordContainer}>
                <Controller
                  name="password"
                  control={control}
                  defaultValue={""}
                  rules={{ validate: validatePassword }}
                  render={({ field }) => (
                    <TextField
                      labelText={t("new_password")}
                      type={showPassword ? "text" : "password"}
                      errorText={errors.password?.message}
                      inputBaseProps={{
                        ...field,
                        placeholder: t("enter_new_password"),
                        fullWidth: true,
                        onChange: (event) =>
                          handleChangeControlledField(
                            field,
                            event,
                            passwordChecked
                          ),
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <TaIcon
                            className={classes.eyeIcon}
                            iconName={
                              showPassword
                                ? "VisibilityOffOutlined"
                                : "VisibilityOutlined"
                            }
                            onClick={handleClickShowPassword}
                          />
                        </InputAdornment>
                      }
                    />
                  )}
                />
              </Grid>
              <Grid container className={classes.actionButtons}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleNewPassword}
                  disabled={loading}
                >
                  {t("set_new_password")}
                </StyledButton>
              </Grid>
            </>
          ) : (
            <SignUpSuccess email={email} onLogin={handleLogin} />
          )}
        </Grid>
      </LayoutOfContent>
    </Layout>
  );
};

export default NewPassword;
