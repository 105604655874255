import { FC, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { addResourcesBundle } from "../../i18n";
import { useRootStoreContext } from "../../hooks";
import en from "./en.json";
import StepFirst from "./StepFirst";
import StepSecond from "./StepSecond";

addResourcesBundle({ en });

const Projects: FC = observer(() => {
  const [step, setStep] = useState<1 | 2>(1);
  const { projects, users } = useRootStoreContext();
  const newProject = projects.newProject;

  useEffect(() => {
    if (!projects.newProject) projects.onProjectAdd();
  }, [projects]);

  useEffect(() => {
    users.load();
  }, [users]);

  useEffect(() => {
    if (!newProject) return;
    newProject.initUsers();
  }, [newProject, users.users, users.users.length]);

  if (!newProject) return null;

  return step === 1 ? (
    <StepFirst project={newProject} setStep={setStep} />
  ) : (
    <StepSecond project={newProject} setStep={setStep} />
  );
});

export default Projects;
