import {
  Theme,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from "@mui/material";

import { withStyles } from "@mui/styles";

const Accordion = withStyles((theme: Theme) => ({
  root: {
    boxShadow: "none",
    width: "100%",
    padding: 0,
    "&$expanded": {
      margin: "auto",
      backgroundColor: theme.palette.catskillLightWhite.main,
    },
    "&:hover": {
      backgroundColor: theme.palette.catskillLightWhite.main,
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    padding: "0 32px",
    borderBottom: `solid 1px ${theme.palette.catskillLightWhite.main}`,
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    flexDirection: "column",
  },
}))(MuiAccordionDetails);

export { Accordion, AccordionSummary, AccordionDetails };
