import { FC, useState } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as UploadIcon } from "../../icons/Upload.svg";
import { IProject } from "../../models";
import { TextField } from "../Inputs";
import StyledButton from "../StyledButton";

const useStyles = makeStyles((theme: Theme) => ({
  stepFirstContainer: {
    backgroundColor: theme.palette.white.main,
    padding: theme.spacing(3, 4),
    overflowY: "auto",
  },
  projectCreateTitle: {
    marginBottom: theme.spacing(1),
    color: theme.palette.mineShaft.main,
    ...theme.typography.subHeaderTextStyle,
  },
  projectCreateDescription: {
    marginBottom: theme.spacing(3),
    color: theme.palette.mineShaft.main,
    ...theme.typography.bodyTextStyle,
  },
  projectName: {
    width: "26.25rem",
    marginBottom: theme.spacing(4),
  },
  baseUrl: {
    width: "26.25rem",
    marginBottom: theme.spacing(4),
  },
  projectLogoContainer: {
    width: "26.25rem",
    marginBottom: theme.spacing(4),
  },
  projectLogoTitle: {
    marginBottom: theme.spacing(0.5),
    color: theme.palette.doveGray.main,
    ...theme.typography.descriptionTextStyle,
  },
  projectLogo: {
    flex: "none",
    width: "6.25rem",
    height: "6.25rem",
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.rockBlue.main,
  },
  projectLogoDescription: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(4),
    textDecoration: "none",
  },
  baseUrlContainer: {},
  footer: {
    width: "26.25rem",
  },
  footerButtons: {},
  footerStepText: {
    color: theme.palette.doveGray.main,
    whiteSpace: "nowrap",
    ...theme.typography.descriptionTextStyle,
  },
}));

interface IStepFirst {
  project: IProject;
  setStep: (stepNumber: 1 | 2) => void;
}

const StepFirst: FC<IStepFirst> = observer(({ project, setStep }) => {
  const classes = useStyles();

  const [submitted, setSubmitted] = useState(false);

  const { t } = useTranslation(["project_create"]);

  const onProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    project.setName(e.target.value);
  };

  const onProjectBaseUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    project.setBaseUrl(e.target.value);
  };

  const trimProjectName = () => {
    project.setName(project.name.trim());
  };

  const handleNextStep = async () => {
    setSubmitted(true);
    await project.validateProjectNameUniq();
    if (project.isValid("first")) setStep(2);
  };

  return (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      className={classes.stepFirstContainer}
    >
      <Grid item container wrap="nowrap" direction="column">
        <Grid item className={classes.projectCreateTitle}>
          {t("create_new_project")}
        </Grid>
        <Grid item className={classes.projectCreateDescription}>
          {t("create_project_later")}
        </Grid>
      </Grid>
      <Grid item className={classes.projectName}>
        <TextField
          labelText={t("project_name")}
          inputBaseProps={{
            fullWidth: true,
            onChange: onProjectNameChange,
            value: project.name || "",
          }}
          errorText={(submitted || "") && project.fieldErrors["name"]}
        />
      </Grid>
      <Grid
        item
        container
        direction="column"
        className={classes.projectLogoContainer}
      >
        <Grid item className={classes.projectLogoTitle}>
          {t("project_logo")}
        </Grid>
        <Grid item container wrap="nowrap">
          <Grid item className={classes.projectLogo}></Grid>
          <Grid item container direction="column">
            <Grid item className={classes.projectLogoDescription}>
              {t("project_logo_description")}
            </Grid>
            <Grid item container alignItems="center">
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  startIcon={<UploadIcon />}
                  className={classes.button}
                  onClick={() => console.log("upload")}
                >
                  {t("common:upload")}
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  color="secondary"
                  className={classes.button}
                  onClick={() => console.log("remove")}
                >
                  {t("common:remove")}
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container className={classes.baseUrlContainer}>
        <Grid item className={classes.baseUrl}>
          <TextField
            labelText={t("base_url")}
            inputBaseProps={{
              minRows: 3,
              multiline: true,
              fullWidth: true,
              onChange: onProjectBaseUrlChange,
              onBlur: trimProjectName,
              value: project.baseUrl || "",
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        className={classes.footer}
      >
        <Grid item container className={classes.footerButtons}>
          <Grid item>
            <Link to="projects" className={classes.button}>
              <StyledButton variant="outlined" color="primary">
                {t("common:cancel")}
              </StyledButton>
            </Link>
          </Grid>
          <Grid item>
            <StyledButton
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleNextStep}
            >
              {t("next_step")}
            </StyledButton>
          </Grid>
        </Grid>
        <Grid item className={classes.footerStepText}>
          {t("step_one")}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default StepFirst;
