import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MoreVert, DeleteOutlined } from "@mui/icons-material";
import { ILabel } from "../../models";
import { Menu, MenuItem } from "../Menus";
import { useRootStoreContext } from "../../hooks";
import { ConfirmationModal } from "../Modals";
import StyledIconButton from "../StyledIconButton";

interface ILabelItemMenu {
  label: ILabel;
}

const LabelItemMenu: React.FC<ILabelItemMenu> = ({ label }) => {
  const { t } = useTranslation(["labels", "common"]);
  const { labels } = useRootStoreContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const onMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onMenuClose = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <StyledIconButton onClick={onMenuOpen}>
        <MoreVert />
      </StyledIconButton>
      <Menu anchorEl={anchorEl} onClose={onMenuClose}>
        <MenuItem onClick={() => setShowConfirmationModal(true)}>
          <DeleteOutlined />
          {t("common:delete")}
        </MenuItem>
      </Menu>
      <ConfirmationModal
        isOpened={showConfirmationModal}
        text={t("delete_label", {
          count: parseInt(label.testsCount),
          groups_count: t("groups", {
            context: parseInt(label.groupsCount) === 0 ? "zero" : "",
            count: parseInt(label.groupsCount),
          }),
        })}
        onConfirm={() => labels.removeLabel(label)}
        onCancel={() => setShowConfirmationModal(false)}
      />
    </>
  );
};

export default LabelItemMenu;
