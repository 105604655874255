import { withStyles } from "@mui/styles";
import { Button, Theme } from "@mui/material";

export default withStyles((theme: Theme) => ({
  root: {
    ...theme.typography.bodyTextStyle,
    padding: theme.spacing(1, 2),
    textTransform: "none",
    whiteSpace: "nowrap",
    borderRadius: 0,
    color: theme.palette.white.main,
    "&:hover": {
      color: theme.palette.paleRed.main,
      "& $startIcon, & $endIcon": {
        color: theme.palette.paleRed.main,
      },
    },
  },
  startIcon: {
    color: theme.palette.baliHai.main,
    "& .MuiSvgIcon-root": {
      fontSize: 24,
    },
  },
  endIcon: {
    color: theme.palette.baliHai.main,
    "& .MuiSvgIcon-root": {
      fontSize: 24,
    },
  },
  disabled: {
    opacity: 0.5,
  },
}))(Button);
