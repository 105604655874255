import React, { useEffect } from "react";
import { cast } from "mobx-state-tree";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import clsx from "clsx";
import { Redirect, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { useDrop, useDragLayer } from "react-dnd";
import { makeStyles } from "@mui/styles";
import { Grid, List, Theme, Box } from "@mui/material";
import { addResourcesBundle } from "../../i18n";
import en from "./en.json";
import TestHeader from "./TestHeader";
import TestStep from "./TestStep";
import TestItem from "../Tests/TestItem";
import BulkMenus, { SharedStepsBulkMenus } from "../BulkMenus";
import { useRootStoreContext } from "../../hooks";
import { IActionType } from "../../models/utils";
import ItemDragLayer from "../ItemDragLayer";
import EmptyContainer from "../EmptyContainer";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  testSteps: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 0, 3, 0),
    minHeight: 0,
    overflowY: "auto",
    flexGrow: 1,
    "&.isDroppable": {
      border: `solid ${theme.palette.outrageousOrange.main}`,
      borderWidth: "2px 2px 0",
    },
  },
  dropArea: {
    flex: 1,
    display: "flex",
    "&.isOver": {
      borderTop: `2px solid ${theme.palette.outrageousOrange.main}`,
    },
  },
}));

interface IDragItem {
  type: string;
  testStepId: number;
  SharedStepId?: string;
  value?: string;
  actionType: IActionType;
}

interface ITestSteps {
  useSharedSteps?: boolean;
}

const TestSteps: React.FC<ITestSteps> = observer(
  ({ useSharedSteps = true }) => {
    const { testId = "" } = useParams<{ testId: string }>();
    const {
      actionsForSelect,
      tests,
      testSteps,
      suites,
      users: { profile },
    } = useRootStoreContext();
    const classes = useStyles();
    const { t } = useTranslation(["test_steps"]);
    const test = tests.getById(testId);
    const projectId = test!?.ProjectId;
    const suiteId = test!?.SuiteId;
    const report = test!?.lastReport;
    const steps = testSteps.getCurrentSteps(testId);

    const { isDragging } = useDragLayer((monitor) => ({
      isDragging:
        ["Action", "Shared Action"].includes(
          (monitor.getItemType() as string) || ""
        ) && monitor.isDragging(),
    }));

    const [{ isOver }, drop] = useDrop({
      accept: ["Element", "Action", "Shared Action"],
      canDrop: (_item, monitor) =>
        ["Action", "Shared Action"].includes(monitor.getItemType() as string),
      drop: (item: IDragItem) => {
        testSteps.createStep({
          type: item.actionType,
          stepIdtoDropTo:
            testSteps.getCurrentSteps(testId)[
              testSteps.getCurrentSteps(testId).length - 1
            ]?.id,
          before: !testSteps.getCurrentSteps(testId).length,
          projectId: projectId!,
          SharedStepId: item.SharedStepId,
          value: item.value,
          suiteId,
          testId,
        });
        return item;
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    });

    const [{ isOverList, isElementOverList }, dropList] = useDrop({
      accept: ["Element", "Action", "Shared Action"],
      collect: (monitor) => ({
        isOverList: !!monitor.isOver(),
        isElementOverList:
          monitor.getItemType() === "Element" && !!monitor.isOver(),
      }),
    });

    useEffect(() => {
      if (projectId && suiteId && testId) {
        testSteps.load({
          projectId,
          suiteId,
          testId,
        });
        actionsForSelect.load();
        suites.loadOneSuite(projectId, suiteId);
        profile.load();
      }
      return () => {
        testSteps.clearItems();
      };
    }, [
      testSteps,
      projectId,
      suiteId,
      testId,
      actionsForSelect,
      suites,
      profile,
    ]);

    if (!profile.isAuthenticated) return <Redirect to="/sign-in" />;

    if (test?.error) return <Redirect to="/no-match" />;

    const isEmptyList = testSteps.state === "ready" && !steps.length;

    const isLoading =
      test?.testState !== "ready" || testSteps.state !== "ready";

    const displayReportStatus = ["passed", "failed"].includes(test!?.result);

    useEffect(() => {
      if (!isLoading && steps.length && !!report) {
        displayReportStatus
          ? testSteps.setReportStatus(report)
          : testSteps.clearReportStatus();
      }
    }, [isLoading, testSteps, steps, report, displayReportStatus]);

    const renderEmptyContainer = () => {
      return useSharedSteps ? (
        <EmptyContainer
          title={t("empty_test_message")}
          description={t("empty_test_description")}
        />
      ) : (
        <EmptyContainer
          title={t("empty_shared_step_message")}
          description={t("empty_shared_step_description")}
        />
      );
    };

    return (
      <Grid container direction="column" wrap="nowrap">
        <TestHeader useSharedSteps={useSharedSteps} />
        {test ? (
          <TestItem test={test} useSharedSteps={useSharedSteps} isSingleItem />
        ) : null}
        <List
          ref={dropList}
          component={Grid}
          container
          direction="column"
          wrap="nowrap"
          className={clsx(classes.testSteps, {
            isDroppable: !isOverList && isDragging,
          })}
        >
          {steps.length
            ? _.map(steps, (testStep) => (
                <TestStep
                  key={testStep.id}
                  testStep={cast(testStep)}
                  isElementOverList={isElementOverList}
                />
              ))
            : null}
          <Box
            {...{ ref: drop }}
            className={clsx(classes.dropArea, { isOver })}
          >
            {isLoading ? "Loading..." : null}
            {isEmptyList ? renderEmptyContainer() : null}
          </Box>
          <ItemDragLayer itemType="Action" iconName="CategoryOutlined" />
        </List>
        {testSteps.selectedAndNotShared.length > 1 ? (
          <BulkMenus>
            <SharedStepsBulkMenus useSharedSteps={useSharedSteps} />
          </BulkMenus>
        ) : null}
      </Grid>
    );
  }
);

export default TestSteps;
