import React from "react";
import ListItem from "@mui/material/ListItem";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";

import { IProject } from "../../models";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(4),
    color: theme.palette.black.main,
  },
  selected: {
    paddingLeft: "1.75rem",
    borderLeft: `4px solid ${theme.palette.outrageousOrange.main}`,
  },
  projectNameAvatarWrapper: {
    minWidth: theme.spacing(6),
  },
  projectNameAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  projectNameText: {
    fontSize: 18,
    fontWeight: 500,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
}));

interface IProjectListItem {
  project: IProject;
  selected?: boolean;
  onProjectClick: (
    event: React.KeyboardEvent | React.MouseEvent,
    projectId: number
  ) => void;
}

const ProjectListItem: React.FC<IProjectListItem> = observer(
  ({ project, selected, onProjectClick }) => {
    const classes = useStyles();
    return (
      <ListItem
        button={true}
        selected={selected}
        onClick={(event) => onProjectClick(event, project.id)}
        classes={{ root: classes.root, selected: classes.selected }}
      >
        <ListItemAvatar className={classes.projectNameAvatarWrapper}>
          <Avatar variant="square" className={classes.projectNameAvatar}>
            {project.name.toUpperCase().slice(0, 1)}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{
            className: classes.projectNameText,
            noWrap: true,
          }}
          disableTypography={false}
          className={classes.projectNameText}
        >
          {project.name}
        </ListItemText>
      </ListItem>
    );
  }
);

export default ProjectListItem;
