import { FC, ReactNode } from "react";
import { map } from "lodash";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import EmptyContainer from "../EmptyContainer";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import TableRowLinkWrapper from "./TableRowLinkWrapper";
import { ITableCellMenuItem } from "./TableCellMenu";

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
  },
  emptyMessage: {
    flex: 1,
    marginTop: theme.spacing(2),
    color: theme.palette.mineShaft.main,
  },
}));

export interface ITableRowData {
  menuItems?: ITableCellMenuItem[];
  hoverMenuItems?: ITableCellMenuItem[];
  link?: string;
  firstLastName?: string | null;
  email?: string | null;
  imageAlt?: string;
  customComponentProps?: {
    [key: string]: any;
  };
  [key: string]: any;
}

export interface ITableColumn {
  key?: string;
  type?:
    | "menu"
    | "hover-menu"
    | "date"
    | "image"
    | "profile"
    | "number"
    | "custom";
  label?: string;
  width?: string;
  className?: string;
  customComponent?: FC<any>;
}

interface ITable {
  columns: ITableColumn[];
  rows: ITableRowData[];
  header?: boolean;
  emptyListText?: string;
  emptyListDescription?: string;
  customRowClass?: string;
  children?: ReactNode;
}

const Table: FC<ITable> = observer(
  ({
    columns,
    rows,
    header = true,
    emptyListText,
    emptyListDescription,
    customRowClass,
    children,
  }) => {
    const classes = useStyles();

    return (
      <Grid
        container
        wrap="nowrap"
        direction="column"
        justifyContent="flex-start"
        alignContent="center"
        className={classes.tableContainer}
      >
        {header ? <TableHeader columns={columns} /> : null}
        {children}
        {!rows.length && emptyListText ? (
          <Grid
            container
            justifyContent="center"
            className={classes.emptyMessage}
          >
            <EmptyContainer
              title={emptyListText}
              description={emptyListDescription}
            />
          </Grid>
        ) : null}
        {map(rows, (row: any, i) => (
          <TableRowLinkWrapper key={`table_row_${i}`} link={row.link}>
            <TableRow
              columns={columns}
              row={row}
              customClassName={customRowClass}
            />
          </TableRowLinkWrapper>
        ))}
      </Grid>
    );
  }
);

export default Table;
