import React, { useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ITestStep } from "../../models";
import { ConfirmationModal } from "../Modals";
import { Menu, MenuItem, Divider } from "../Menus";
import StyledIconButton from "../StyledIconButton";
import { useRootStoreContext } from "../../hooks";
import TaIcon from "../TaIcon";

interface IMenuForItem {
  testStep: ITestStep;
}

const useStyles = makeStyles((theme: Theme) => ({
  controlButton: {
    padding: theme.spacing(1),
  },
}));

const MenuForItem: React.FC<IMenuForItem> = observer(({ testStep }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation(["common", "test_steps"]);
  const { testSteps } = useRootStoreContext();
  const { path } = useRouteMatch();

  const onMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onMenuClose = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onShowDeleteConfirm = () => {
    setShowConfirmationModal(true);
  };

  const onTestActionRemove = () => {
    testSteps.removeTestStep(testStep);
    setShowConfirmationModal(false);
    setAnchorEl(null);
  };

  const onDuplicateClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    testSteps.duplicateAction(testStep);
    setAnchorEl(null);
  };

  const onSharedClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    testSteps.buildNewShared(testStep);
    setAnchorEl(null);
  };
  const isSharedStep = /shared-steps/.test(path);

  return (
    <>
      <StyledIconButton className={classes.controlButton} onClick={onMenuOpen}>
        <TaIcon iconName="MoreVertOutlined" />
      </StyledIconButton>
      <Menu anchorEl={anchorEl} onClose={onMenuClose}>
        <MenuItem onClick={onDuplicateClick}>
          <TaIcon iconName="ControlPointDuplicateOutlined" />
          {t("duplicate")}
        </MenuItem>
        {isSharedStep ? null : (
          <MenuItem onClick={onSharedClick}>
            <TaIcon iconName="FolderSharedOutlined" />
            {t("test_steps:create_shared_step")}
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={onShowDeleteConfirm}>
          <TaIcon iconName="DeleteOutlined" />
          {t("common:delete")}
        </MenuItem>
      </Menu>
      <ConfirmationModal
        isOpened={showConfirmationModal}
        text={t("common:confirm")}
        onConfirm={onTestActionRemove}
        onCancel={() => setShowConfirmationModal(false)}
      />
    </>
  );
});

export default MenuForItem;
