import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { Box, Theme, Divider, List, ListItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import en from "./en.json";
import { addResourcesBundle } from "../../i18n";
import SideBarTabs, { ISideBarTabs } from "./SideBarTabs";
import SharedSteps from "./SharedStepList";
import Actions from "./Actions";
import ElementList from "./ElementList";
import Header from "./Header";
import { Search } from "../Inputs";
import { useRootStoreContext } from "../../hooks";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  rootBox: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      minWidth: 320,
      width: 320,
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 360,
      width: 360,
    },
    padding: 0,
  },
  list: {
    flex: 1,
    minHeight: 0,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0),
    backgroundColor: theme.palette.white.main,
  },
  divider: {
    backgroundColor: theme.palette.catskillWhite.main,
    margin: theme.spacing(0, 4),
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(0, 4),
    flexShrink: 0,
    height: 56,
  },
}));

interface ISideBarOfActionElementsSharedSteps {
  useSharedSteps?: boolean;
}

const SideBarOfActionElementsSharedSteps: React.FC<ISideBarOfActionElementsSharedSteps> =
  observer(({ useSharedSteps = true }) => {
    const classes = useStyles();
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [searchInputFieldValue, setSearchInputFieldValue] = useState("");
    const [sideBarTabName, setSideBarTabName] =
      useState<ISideBarTabs["value"]>("actions");
    const { t } = useTranslation([
      "side_bar_of_actions_elements_shared_steps",
      "common",
    ]);
    const { testSteps, actions, elements } = useRootStoreContext();

    useEffect(() => {
      testSteps.setSearchTextFieldValue(searchInputFieldValue);
      actions.setSearchTextFieldValue(searchInputFieldValue);
      elements.setSearchTextFieldValue(searchInputFieldValue);
    }, [testSteps, actions, elements, searchInputFieldValue]);

    const onTabSelect = (tabName: ISideBarTabs["value"]) => {
      setIsSearchOpen(false);
      setSearchInputFieldValue("");
      setSideBarTabName(tabName);
    };

    return (
      <Box className={classes.rootBox}>
        <SideBarTabs
          value={sideBarTabName}
          onSelect={onTabSelect}
          useSharedSteps={useSharedSteps}
        />
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            {isSearchOpen ? (
              <Search
                onChangeText={setSearchInputFieldValue}
                value={searchInputFieldValue}
                onClose={() => {
                  setSearchInputFieldValue("");
                  setIsSearchOpen(false);
                }}
              />
            ) : (
              <Header onSearchClick={() => setIsSearchOpen(true)}>
                {sideBarTabName === "shared-steps" && useSharedSteps
                  ? t("shared_steps")
                  : null}
                {sideBarTabName === "actions" ? t("actions") : null}
                {sideBarTabName === "elements" ? t("elements") : null}
              </Header>
            )}
          </ListItem>

          {sideBarTabName !== "actions" ? (
            <Divider className={classes.divider} />
          ) : null}
          {sideBarTabName === "shared-steps" && useSharedSteps ? (
            <SharedSteps searchIsEmpty={!searchInputFieldValue} />
          ) : null}
          {sideBarTabName === "actions" ? <Actions /> : null}
          {sideBarTabName === "elements" ? (
            <ElementList searchIsEmpty={!searchInputFieldValue} />
          ) : null}
        </List>
      </Box>
    );
  });

export default SideBarOfActionElementsSharedSteps;
