import { FC, ReactElement, useState } from "react";
import { map } from "lodash";
import { observer } from "mobx-react";
import { SvgIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TaIcon from "../TaIcon";
import StyledIconButton from "../StyledIconButton";
import { ConfirmationModal } from "../Modals";
import { Menu, MenuItem } from "../Menus";

const useStyles = makeStyles(() => ({
  menuButton: {
    padding: 0,
  },
}));

export interface ITableCellMenuItem {
  label?: string;
  iconName?: string;
  icon?: ReactElement;
  onClick?: (...params: any[]) => void | Promise<void>;
  isConfirm?: boolean;
  confirmText?: string;
  disabled?: boolean;
  isMenu?: boolean;
}

interface ITableCellMenu {
  menuItems?: ITableCellMenuItem[];
}

interface IItemClickParams {
  onClick?: (...params: any) => void | Promise<void>;
  isConfirm?: boolean;
}

const TableCellMenu: FC<ITableCellMenu> = observer(({ menuItems }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const onMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const onMenuClose = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onItemClick = async ({ onClick, isConfirm }: IItemClickParams) => {
    if (isConfirm) {
      setShowConfirmationModal(true);
    } else {
      setShowConfirmationModal(false);
      if (onClick) await onClick(anchorEl);
      setAnchorEl(null);
    }
  };

  return (
    <>
      <StyledIconButton className={classes.menuButton} onClick={onMenuOpen}>
        <TaIcon iconName="MoreVertOutlined" />
      </StyledIconButton>
      {menuItems?.length ? (
        <Menu anchorEl={anchorEl} onClose={onMenuClose}>
          {map(
            menuItems,
            (
              {
                label,
                iconName,
                icon,
                onClick,
                disabled = false,
                isConfirm = false,
                confirmText = "",
              },
              i
            ) => (
              <MenuItem
                key={`menu_item_${i}`}
                disabled={disabled}
                onClick={() => onItemClick({ onClick, isConfirm })}
              >
                {iconName ? <TaIcon iconName={iconName} /> : null}
                {icon ? <SvgIcon viewBox="0 0 24 24">{icon}</SvgIcon> : null}
                {label}
                {isConfirm ? (
                  <ConfirmationModal
                    isOpened={showConfirmationModal}
                    text={confirmText}
                    onConfirm={() => onItemClick({ onClick })}
                    onCancel={() => setShowConfirmationModal(false)}
                  />
                ) : null}
              </MenuItem>
            )
          )}
        </Menu>
      ) : null}
    </>
  );
});

export default TableCellMenu;
