import React from "react";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import { ITestStep } from "../../models";
import ClickAction from "./ClickAction";
import CookiesHaveValuesAction from "./CookiesHaveValues";
import ElementIsAction from "./ElementIsAction";
import ElementHasAction from "./ElementHasAction";
import ElementHasValueAction from "./ElementHasValueAction";
import PauseAction from "./PauseAction";
import SetValueAction from "./SetValueAction";
import SetWindowSizeAction from "./SetWindowSizeAction";
import SelectByAction from "./SelectByAction";
import SwitchToWindowAction from "./SwitchToWindowAction";
import VisitAction from "./VisitAction";
import WaitForElementAction from "./WaitForElementAction";
import SharedAction from "./Shared";
import ScrollAction from "./ScrollAction";

const useStyles = makeStyles((theme: Theme) => ({
  stepDetails: {
    minHeight: 202,
  },
}));

interface IActionBody {
  testStep: ITestStep;
}

const ActionBody: React.FC<IActionBody> = observer(({ testStep }) => {
  const classes = useStyles();

  switch (testStep.type) {
    case "assertAttribute":
    case "assertProperty":
      return <ElementHasAction testStep={testStep} />;
    case "assertCookieValue":
    case "setCookies":
    case "deleteCookies":
      return <CookiesHaveValuesAction testStep={testStep} />;
    case "assertValue":
    case "assertText":
      return <ElementHasValueAction testStep={testStep} />;
    case "clearValue":
    case "click":
    case "doubleClick":
    case "hover":
      return <ClickAction testStep={testStep} />;
    case "assertClickable":
    case "assertDisplayed":
    case "assertEnabled":
    case "assertExisting":
    case "assertFocused":
    case "assertSelected":
      return <ElementIsAction testStep={testStep} />;
    case "pause":
      return <PauseAction testStep={testStep} />;
    case "scrollTo":
      return <ScrollAction testStep={testStep} />;
    case "setValue":
      return <SetValueAction testStep={testStep} />;
    case "select":
    case "selectByAttribute":
    case "selectByIndex":
      return <SelectByAction testStep={testStep} />;
    case "switchWindow":
      return <SwitchToWindowAction testStep={testStep} />;
    case "visit":
      return <VisitAction testStep={testStep} />;
    case "waitForClickable":
    case "waitForDisplayed":
    case "waitForEnabled":
    case "waitForExist":
      return <WaitForElementAction testStep={testStep} />;
    case "setWindowSize":
      return <SetWindowSizeAction testStep={testStep} />;
    case "sharedAction":
      return <SharedAction testStep={testStep} />;
    default:
      return (
        <Grid item container className={classes.stepDetails}>
          <pre>{JSON.stringify(getSnapshot(testStep), null, 2)}</pre>
        </Grid>
      );
  }
});

export default ActionBody;
