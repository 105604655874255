import { FC, useCallback, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid, Popover, Tabs, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DoneAllOutlined } from "@mui/icons-material";
import { addResourcesBundle } from "../../i18n";
import { useRootStoreContext } from "../../hooks";
import StyledButton from "../StyledButton";
import NotificationList from "./NotificationList";
import Tab from "./Tab";
import en from "./en.json";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  notificationsContainer: {
    width: "20rem",
    borderRadius: 0,
  },
  popoverHeader: {
    height: "3.5rem",
    padding: "0 1.5rem",
  },
  newNotifications: {
    ...theme.typography.subHeaderTextStyle,
  },
  markAsRead: {
    color: theme.palette.mineShaft.main,
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  notificationTabs: {},
  notificationTab: {},
  tabsPanel: {
    width: "100%",
    margin: "0 1.5rem",
    borderBottom: `1px solid ${theme.palette.catskillLightWhite.main}`,
  },
  tab: {
    padding: "1rem",
  },
  tabIndicator: {
    backgroundColor: theme.palette.baliHai.main,
    width: "80%",
  },
  notificationListContainer: {},
}));

interface INotificationPopover {
  anchorEl: null | HTMLElement;
  onPopoverClose: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

const NotificationPopover: FC<INotificationPopover> = observer(
  ({ anchorEl, onPopoverClose }) => {
    const classes = useStyles();
    const { t } = useTranslation(["notifications_popover"]);
    const { users, allNotificationsList, unreadNotificationsList } =
      useRootStoreContext();
    const userId = users.profile.id;
    const [currentTab, setCurrentTab] = useState<"all" | "unread">("all");

    const notifications =
      currentTab === "all" ? allNotificationsList : unreadNotificationsList;

    const onClose = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      allNotificationsList.clearList();
      unreadNotificationsList.clearList();
      onPopoverClose(e);
    };

    const markAllNotificationsRead = async () => {
      await allNotificationsList.markRead({ userId });
      unreadNotificationsList.clearList();
      updateUnreadCount();
    };

    const updateUnreadCount = useCallback(() => {
      unreadNotificationsList.getNotificationCount({
        userId,
        unread: true,
      });
    }, [userId, unreadNotificationsList]);

    if (!anchorEl) return null;
    return (
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorOrigin={{
          ...{ vertical: 8, horizontal: 32 },
        }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        classes={{ paper: classes.notificationsContainer }}
      >
        <Grid container direction="column">
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            className={classes.popoverHeader}
          >
            <Grid item className={classes.newNotifications}>
              {t("newNotifications_count", {
                count: unreadNotificationsList.itemCount,
              })}
            </Grid>
            <Grid item>
              <StyledButton
                onClick={markAllNotificationsRead}
                startIcon={<DoneAllOutlined />}
                className={classes.markAsRead}
              >
                {t("mark_read")}
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.notificationTabs}>
          <Tabs
            value={currentTab}
            className={classes.tabsPanel}
            onChange={(_, val) => setCurrentTab(val)}
            classes={{ indicator: classes.tabIndicator }}
          >
            <Tab
              className={classes.tab}
              label={t("all_notifications")}
              value="all"
            />
            <Tab
              className={classes.tab}
              label={t("unread_notifications")}
              value="unread"
            />
          </Tabs>
        </Grid>
        <Grid container className={classes.notificationListContainer}>
          <NotificationList
            notifications={notifications}
            type={currentTab}
            updateUnreadCount={updateUnreadCount}
          />
        </Grid>
      </Popover>
    );
  }
);

export default NotificationPopover;
