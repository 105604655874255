import { FC, ReactNode } from "react";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  buttonBlock: {
    margin: theme.spacing(0, -2),
    display: "flex",
    justifyContent: "space-between",
  },
}));

const MenuButtonBlock: FC<{ children: ReactNode }> = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.buttonBlock}>{children}</Box>;
};

export default MenuButtonBlock;
