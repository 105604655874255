import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Theme, Box } from "@mui/material";
import { useRootStoreContext } from "../../hooks";
import { IActionType } from "../../models/utils";
import { Search, SelectWithCustomBody } from "../Inputs";
import { Tabs, Tab } from "../Tabs";
import ActionsList from "../SideBarOfActionElementsSharedSteps/ActionsList";

const useStyles = makeStyles((theme: Theme) => ({
  searchBox: {
    margin: theme.spacing(3, 0, 2),
  },
  listContainer: {
    height: 224,
    overflowY: "auto",
    margin: theme.spacing(1, 0, 2),
  },
}));

interface IActionSelect {
  actionType: string;
  onChange?: (val: IActionType) => void;
  error?: boolean;
}

const ActionSelect: React.FC<IActionSelect> = observer(
  ({ actionType, onChange, error }) => {
    const classes = useStyles();
    const { t } = useTranslation([
      "actions",
      "common",
      "side_bar_of_actions_elements_shared_steps",
    ]);
    const { actionsForSelect } = useRootStoreContext();

    useEffect(() => {
      actionsForSelect.load();
    }, [actionsForSelect]);

    const onSelect = (type: IActionType) => {
      onChange && onChange(type);
    };

    const renderSelectBody = (onSelectClose: (e: React.MouseEvent) => void) => {
      return (
        <Box>
          <Tabs
            value={actionsForSelect.selectedTabName}
            onChange={(e, newValue) =>
              actionsForSelect.setSelectedTabName(newValue)
            }
          >
            <Tab label={t("element")} value="element" />
            <Tab label={t("browser")} value="browser" />
          </Tabs>
          <Box>
            <Search
              value={actionsForSelect.searchTextFieldValue}
              className={classes.searchBox}
              onChangeText={(val) =>
                actionsForSelect.setSearchTextFieldValue(val)
              }
            />
          </Box>
          <Box className={classes.listContainer}>
            <ActionsList
              actions={actionsForSelect}
              selectable
              onMenuItemSelect={(e: React.MouseEvent, type: IActionType) => {
                onSelect(type);
                onSelectClose(e);
              }}
            />
          </Box>
        </Box>
      );
    };

    const i18nActionType = t(
      `side_bar_of_actions_elements_shared_steps:actions_types.${actionType}`
    );

    return (
      <SelectWithCustomBody
        value={i18nActionType}
        error={error}
        labelText={t("action")}
        readOnly
        cleanup={() => {
          actionsForSelect.setSearchTextFieldValue("");
        }}
      >
        {renderSelectBody}
      </SelectWithCustomBody>
    );
  }
);

export default ActionSelect;
