import React, { useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { addResourcesBundle } from "../../i18n";
import { IElementFilters } from "../../models";
import { Menu, MenuItem } from "../Menus";
import {
  TextFilter,
  CheckboxFilter,
  CountFilter,
  PeriodFilter,
} from "../Filters";

import en from "./en.json";

addResourcesBundle({ en });

interface IFilterMenu {
  anchorEl: null | HTMLElement;
  onMenuClose: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  filterStore: IElementFilters;
}

const FilterMenu: React.FC<IFilterMenu> = observer(
  ({ anchorEl, onMenuClose, filterStore }) => {
    const { t } = useTranslation(["element_filters"]);
    const ELEMENT_TYPES = [
      { label: t("elements:types:button"), value: "button" },
      { label: t("elements:types:link"), value: "link" },
      { label: t("elements:types:list"), value: "list" },
      { label: t("elements:types:input"), value: "input" },
      { label: t("elements:types:text"), value: "text" },
      { label: t("elements:types:element"), value: "element" },
    ];

    const [locatorFilterAnchor, setLocatorFilterAnchor] =
      useState<null | HTMLElement>(null);

    const [labelFilterAnchor, setLabelFilterAnchor] =
      useState<null | HTMLElement>(null);

    const [typeFilterAnchor, setTypeFilterAnchor] =
      useState<null | HTMLElement>(null);

    const [periodFilterAnchor, setPeriodFilterAnchor] =
      useState<null | HTMLElement>(null);

    const [countFilterAnchor, setCountFilterAnchor] =
      useState<null | HTMLElement>(null);

    const onLocatorFilterClick = (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      setLocatorFilterAnchor(anchorEl);
      onMenuClose(e);
    };

    const onLabelFilterClick = (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      setLabelFilterAnchor(anchorEl);
      onMenuClose(e);
    };

    const onTypeFilterClick = (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      setTypeFilterAnchor(anchorEl);
      onMenuClose(e);
    };

    const onPeriodFilterClick = (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      setPeriodFilterAnchor(anchorEl);
      onMenuClose(e);
    };

    const onCountFilterClick = (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      setCountFilterAnchor(anchorEl);
      onMenuClose(e);
    };

    return (
      <>
        <Menu
          anchorEl={anchorEl}
          onClose={onMenuClose}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MenuItem>{t("added_method")}</MenuItem>
          <MenuItem onClick={onLocatorFilterClick}>{t("locator")}</MenuItem>
          <MenuItem onClick={onLabelFilterClick}>{t("label")}</MenuItem>
          <MenuItem onClick={onTypeFilterClick}>{t("element_type")}</MenuItem>
          <MenuItem onClick={onPeriodFilterClick}>{t("period")}</MenuItem>
          <MenuItem onClick={onCountFilterClick}>{t("count_of_used")}</MenuItem>
        </Menu>
        <TextFilter
          anchorEl={locatorFilterAnchor}
          onMenuClose={() => setLocatorFilterAnchor(null)}
          title={t("locator_contains")}
          value={filterStore.locatorFilter}
          onSave={filterStore.saveLocatorFilter}
        />
        <TextFilter
          anchorEl={labelFilterAnchor}
          onMenuClose={() => setLabelFilterAnchor(null)}
          title={t("label_contains")}
          value={filterStore.labelFilter}
          onSave={filterStore.saveElementLabelFilter}
        />
        <CheckboxFilter
          title={t("select_types")}
          checkboxItems={ELEMENT_TYPES}
          initiallySelectedItems={[...filterStore.typeFilter]}
          filterStore={filterStore}
          anchorEl={typeFilterAnchor}
          onMenuClose={() => setTypeFilterAnchor(null)}
          onSave={filterStore.saveTypeFilter}
        />
        <PeriodFilter
          anchorEl={periodFilterAnchor}
          onMenuClose={() => setPeriodFilterAnchor(null)}
          filterStore={filterStore}
          title={t("filter_by_time_period")}
          onSave={filterStore.savePeriodFilter}
        />
        <CountFilter
          title={t("filter_by_count")}
          initValue={filterStore.usedFilter}
          anchorEl={countFilterAnchor}
          onMenuClose={() => setCountFilterAnchor(null)}
          onSave={filterStore.saveUsedFilter}
        />
      </>
    );
  }
);

export default FilterMenu;
