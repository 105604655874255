import { CSSProperties } from "react";
import "@mui/material/styles/createTypography";

declare module "@mui/material/styles/createTypography" {
  interface ITypographyTextStyle {
    fontStyle: CSSProperties["fontStyle"];
    fontWeight: CSSProperties["fontWeight"];
    fontSize: CSSProperties["fontSize"];
    lineHeight: React.CSSProperties["lineHeight"];
  }
  interface Typography {
    topHeaderTextStyle: FontStyle;
    subHeaderTextStyle: FontStyle;
    bodyTextStyle: FontStyle;
    descriptionTextStyle: FontStyle;
    labelTextStyle: FontStyle;
    tableLabelTextStyle: FontStyle;
  }

  interface TypographyOptions {
    topHeaderTextStyle: FontStyleOptions;
    subHeaderTextStyle: FontStyleOptions;
    bodyTextStyle: FontStyleOptions;
    descriptionTextStyle: FontStyleOptions;
    labelTextStyle: FontStyleOptions;
    tableLabelTextStyle: FontStyleOptions;
  }
}

const typography = {
  fontFamily: [
    '"Fira Sans Condensed"',
    "Roboto",
    "Helvetica",
    "Arial",
    "sans-serif",
  ].join(","),
  topHeaderTextStyle: {
    fontStyle: "normal",
    fontWeight: 500 as CSSProperties["fontWeight"],
    fontSize: 18,
    lineHeight: "140%",
  },
  subHeaderTextStyle: {
    fontStyle: "normal",
    fontWeight: "bold" as CSSProperties["fontWeight"],
    fontSize: 15,
    lineHeight: "140%",
  },
  bodyTextStyle: {
    fontStyle: "normal",
    fontWeight: "normal" as CSSProperties["fontWeight"],
    fontSize: 14,
    lineHeight: "140%",
  },
  descriptionTextStyle: {
    fontStyle: "normal",
    fontWeight: "normal" as CSSProperties["fontWeight"],
    fontSize: 12,
    lineHeight: "140%",
  },
  labelTextStyle: {
    fontStyle: "normal",
    fontWeight: "normal" as CSSProperties["fontWeight"],
    fontSize: 12,
    lineHeight: "12px",
  },
  tableLabelTextStyle: {
    fontStyle: "normal",
    fontWeight: "normal" as CSSProperties["fontWeight"],
    fontSize: 11,
    lineHeight: "140%",
  },
};

export default typography;
