import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Box, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    screenshotBox: {
      display: "flex",
      flex: "1 1 auto",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
    },
    noScreenshotBox: {
      backgroundColor: theme.palette.baliHai.main,
    },
    icon: {
      height: 48,
      width: 48,
      color: theme.palette.white.main,
    },
    imageClassName: {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  })
);

interface IScreenshot {
  src?: string;
  alt?: string;
  boxClassName?: string;
  imageClassName?: string;
  iconClassName?: string;
}
const Screenshot: React.FC<IScreenshot> = ({
  src,
  alt,
  imageClassName,
  boxClassName,
  iconClassName,
}) => {
  const classes = useStyles();
  const [imgLoadError, setImgLoadError] = useState(false);

  useEffect(() => {
    setImgLoadError(false);
  }, [src]);

  if (src && alt && !imgLoadError) {
    return (
      <Box className={clsx(classes.screenshotBox, boxClassName)}>
        <img
          alt={alt}
          src={src}
          className={clsx(classes.imageClassName, imageClassName)}
          onError={() => setImgLoadError(true)}
        />
      </Box>
    );
  }

  return (
    <Box
      className={clsx(
        classes.screenshotBox,
        boxClassName,
        classes.noScreenshotBox,
        "noScreenshot"
      )}
    >
      <ImageOutlinedIcon className={clsx(classes.icon, iconClassName)} />
    </Box>
  );
};

export default Screenshot;
