import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { makeStyles, createStyles } from "@mui/styles";
import { ListItem, Box, Typography, Theme } from "@mui/material";
import FolderSharedOutlinedIcon from "@mui/icons-material/FolderSharedOutlined";
import { ITestStep } from "../../models";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootBox: {
      overflowY: "auto",
      margin: theme.spacing(2, 0, 0, 3),
      paddingRight: theme.spacing(3),
    },
    listItem: {
      position: "relative",
      padding: theme.spacing(0, 0, 0, 1),
      height: 40,
      display: "flex",
      justifyContent: "space-between",
      "&:hover": {
        backgroundColor: theme.palette.catskillLightWhite.main,
        "& $sharedFoldersIcon, $text": {
          color: theme.palette.outrageousOrange.main,
        },
        cursor: "move",
      },
    },
    text: {
      marginLeft: theme.spacing(1),
      ...theme.typography.bodyTextStyle,
      color: theme.palette.mineShaft.main,
    },
    box: {
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
    },
    sharedFoldersIcon: {
      color: theme.palette.baliHai.main,
    },
    number: {
      ...theme.typography.descriptionTextStyle,
      color: theme.palette.doveGray.main,
    },
  })
);

interface ISharedStepItem {
  sharedStep: ITestStep;
}

const SharedStep: React.FC<ISharedStepItem> = observer(({ sharedStep }) => {
  const classes = useStyles();

  const [{ isDragging }, drag, preview] = useDrag({
    type: "Shared Action",
    item: {
      dragLayerText: sharedStep.value,
      actionType: sharedStep.type,
      ...sharedStep,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
      currentOffset: monitor.getSourceClientOffset(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  //ToDo: consider a different message when there are no items at all
  return (
    <ListItem
      ref={drag}
      key={sharedStep.testId}
      component="li"
      disabled={isDragging}
      className={classes.listItem}
    >
      <Box className={classes.box}>
        <FolderSharedOutlinedIcon className={classes.sharedFoldersIcon} />
        <Typography className={classes.text} noWrap={true}>
          {sharedStep.value}
        </Typography>
      </Box>
    </ListItem>
  );
});

export default SharedStep;
