import React from "react";
import { observer } from "mobx-react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { SharedSteps } from "../components";
import { SharedStep } from "./index";

const SharedStepsPage: React.FC = observer(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/suites/:suiteId/shared-steps/:testId`}
        component={SharedStep}
      />
      <Route path={`${path}`} component={SharedSteps} />
    </Switch>
  );
});

export default SharedStepsPage;
