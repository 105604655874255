import { FC, forwardRef } from "react";
import { map } from "lodash";
import { observer } from "mobx-react";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Item from "./Item";
import { ICheckboxFilterItem } from "../../../models";

const useStyles = makeStyles((theme: Theme) => ({
  roleItemContainer: {
    maxHeight: 7 * 40,
    overflowY: "auto",
    margin: theme.spacing(0, -1),
  },
}));

interface IRoleItemList {
  anchorEl: null | HTMLElement;
  items: ICheckboxFilterItem[];
  title: string;
}

const ItemList: FC<IRoleItemList> = observer(
  forwardRef(({ anchorEl, items, title }, _ref) => {
    const classes = useStyles();
    if (!anchorEl) return null;

    return (
      <>
        <Box>{title}</Box>
        <Box className={classes.roleItemContainer}>
          {map(items, (item) => (
            <Item key={item.label} item={item} />
          ))}
        </Box>
      </>
    );
  })
);

export default ItemList;
