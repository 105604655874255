import {
  types,
  flow,
  Instance,
  SnapshotOut,
  applySnapshot,
} from "mobx-state-tree";
import { handleFetch } from "./utils";
import Notification, { INotificationSnapshotOut } from "./Notification";

const Notifications = types
  .model({
    isOpen: false,
    isLoading: false,
    autoHideDuration: types.maybe(types.number),
    items: types.optional(types.array(Notification), []),
    listPage: 0,
    listItems: types.optional(types.array(Notification), []),
    itemCount: 0,
  })
  .views((self) => ({
    get isMultipleNotifications() {
      return self.items.length > 1;
    },
    get notificationToDisplay() {
      return self.items[self.items.length - 1];
    },
    get listItemIds() {
      return self.listItems.map((item) => item.id);
    },
  }))
  .actions((self) => ({
    load: flow(function* ({ userId, page = 0, perPage = 10, unread = false }) {
      const params = new URLSearchParams({ page, perPage, unread });
      if (!unread) params.delete("unread");
      try {
        self.isLoading = true;
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/users/${userId}/notifications?${params}`
        );
        if (response.ok && response.status === 401) {
          console.error("Unauthorized Notifications request");
          return;
        }
        if (!response) return;
        const notifications = yield response.json();
        applySnapshot(self.listItems, [...self.listItems, ...notifications]);
      } catch (error) {
        console.error("Failed to fetch notifications", error);
      } finally {
        self.isLoading = false;
      }
    }),
    markRead: flow(function* ({ notificationIds = [], userId }) {
      try {
        self.isLoading = true;
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/users/${userId}/notifications/markRead`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: notificationIds.length
              ? JSON.stringify({ notificationIds })
              : "",
          }
        );
        if (!response.ok) {
          throw new Error(
            "Error while marking notifications as read in markRead method"
          );
        }
        const readNotifications = self.listItems.map((item) => {
          if (notificationIds.includes(item.id) || !notificationIds.length)
            item.read = true;
          return item;
        });

        applySnapshot(self.listItems, readNotifications);
      } catch (error) {
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    getNotificationCount: flow(function* ({ userId, unread }) {
      const params = new URLSearchParams({ unread });
      if (!unread) params.delete("unread");
      try {
        self.isLoading = true;
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/users/${userId}/notifications/count?${params}`
        );
        if (response.ok && response.status === 401) {
          console.error("Unauthorized Notifications Count request");
          return;
        }
        if (!response) return;
        const { count: notificationsCount } = yield response.json();
        self.itemCount = notificationsCount;
      } catch (error) {
        console.error("Failed to fetch notifications", error);
      } finally {
        self.isLoading = false;
      }
    }),
    addNotification(notification: Partial<INotificationSnapshotOut>) {
      self.items.push(notification);
    },

    removeNotification() {
      self.items.pop();
    },
    nextListPage() {
      self.listPage = self.listPage + 1;
    },
    clearAll() {
      applySnapshot(self.items, []);
    },
    clearList() {
      applySnapshot(self.listItems, []);
      self.listPage = 0;
    },
  }));

export interface INotifications extends Instance<typeof Notifications> {}
export interface INotificationsSnapshotOut
  extends SnapshotOut<typeof Notifications> {}

export default Notifications;
