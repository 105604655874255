import { types, Instance, SnapshotOut } from "mobx-state-tree";

const FilterAuthor = types
  .model("Filter Author Model", {
    email: types.string,
    selected: false,
  })
  .actions((self) => ({
    onSelect(e: React.ChangeEvent<HTMLInputElement>) {
      e.stopPropagation();
      self.selected = !self.selected;
    },
  }));

export interface IFilterAuthor extends Instance<typeof FilterAuthor> {}
export interface IFilterAuthorSnapshotOut
  extends SnapshotOut<typeof FilterAuthor> {}

export default FilterAuthor;
