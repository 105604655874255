import { ReactComponent as Chrome } from "../../icons/Chrome.svg";
import { ReactComponent as Edge } from "../../icons/Edge.svg";
import { ReactComponent as Firefox } from "../../icons/Firefox.svg";
import { ReactComponent as Safari } from "../../icons/Safari.svg";

export const browserIcons = (browser: string) =>
  ({
    chrome: <Chrome />,
    edge: <Edge />,
    firefox: <Firefox />,
    safari: <Safari />,
  }[browser]);

export const iconByResult = (result: string) =>
  ({
    passed: "CheckCircleOutline",
    failed: "HighlightOff",
    broken: "ErrorOutline",
    skiped: "HelpOutline",
    pending: "HelpOutline",
  }[result]);
