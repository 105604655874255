import { FC } from "react";
import { observer } from "mobx-react";
import Tabs from "./Tabs";
import { Grid, AppBar, Theme } from "@mui/material";
import ReportsTestFilterRow from "../SeparateTestsList/SeparateTestFilterRow";
import en from "./en.json";
import { addResourcesBundle } from "../../i18n";
import { makeStyles } from "@mui/styles";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    marginBottom: theme.spacing(3),
    boxShadow: "none",
  },
}));

const ReportsHeader: FC = observer(() => {
  const classes = useStyles();

  return (
    <AppBar position="sticky" color="inherit" className={classes.mainContainer}>
      <Grid container wrap="nowrap">
        <Grid container item direction="column" wrap="nowrap">
          <Tabs />
          <ReportsTestFilterRow />
        </Grid>
      </Grid>
    </AppBar>
  );
});

export default ReportsHeader;
