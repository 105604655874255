import { FC } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { Grid, Theme } from "@mui/material";

import { makeStyles, createStyles } from "@mui/styles";

import TaIcon from "../TaIcon";
import { iconByResult } from "./config";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    column: {
      height: "4rem",
      padding: "1rem 0.3rem 1rem 0",
      color: theme.palette.mineShaft.main,
      ...theme.typography.bodyTextStyle,
    },
    columnXs: {
      flex: 1,
      minWidth: 84,
    },
    icon: {
      alignSelf: "center",
      "&.passed": {
        color: theme.palette.successGreen.main,
      },
      "&.failed": {
        color: theme.palette.guardsmanRed.main,
      },
      "&.broken, &.pending": {
        color: theme.palette.goldDrop.main,
      },
      "&.skipped": {
        color: theme.palette.pacificBlue.main,
      },
    },
    result: {
      alignSelf: "center",
      marginLeft: theme.spacing(1),
    },
  })
);

interface ITestResultItem {
  result: string;
}

const TestResult: FC<ITestResultItem> = observer(({ result }) => {
  const classes = useStyles();
  const { t } = useTranslation(["reports"]);

  return (
    <Grid item container className={clsx(classes.column, classes.columnXs)}>
      <TaIcon
        iconName={iconByResult(result) || "HelpOutline"}
        className={clsx(classes.icon, result)}
      />
      <Grid item className={classes.result}>
        {result && t(`results.${result}`)}
      </Grid>
    </Grid>
  );
});

export default TestResult;
