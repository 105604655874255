import { FC, useEffect, useState } from "react";
import { map } from "lodash";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Divider, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import { CheckboxFilterList, ICheckboxFilterItem } from "../../../models";
import { Menu, MenuButton, MenuButtonBlock } from "../../Menus";
import ItemList from "./ItemList";
import { filterStore } from "../../FilterBar";

const checkboxFilterListModel = CheckboxFilterList.create();

const useStyles = makeStyles((theme: Theme) => ({
  menuPaper: {
    ...theme.typography.descriptionTextStyle,
    color: theme.palette.doveGray.main,
    padding: theme.spacing(1, 3, 0),
    width: theme.spacing(36),
  },
}));

interface ICheckboxItem {
  label: string;
  value: string;
}

interface ICheckboxFilter {
  title: string;
  anchorEl: null | HTMLElement;
  filterStore: filterStore;
  checkboxItems: ICheckboxItem[];
  initiallySelectedItems: string[];
  onMenuClose: () => void;
  onSave: (items: string[]) => void;
}

const CheckboxFilter: FC<ICheckboxFilter> = observer(
  ({
    title,
    anchorEl,
    checkboxItems,
    initiallySelectedItems,
    onMenuClose,
    onSave,
  }) => {
    const classes = useStyles();
    const { t } = useTranslation(["common"]);
    const [listItems, setListItems] = useState<ICheckboxFilterItem[]>([]);

    useEffect(() => {
      checkboxFilterListModel.initItems(checkboxItems, initiallySelectedItems);
      setListItems(checkboxFilterListModel.items);
    }, [initiallySelectedItems, checkboxItems]);

    const onFilterCancel = () => {
      onMenuClose();
    };

    const onFilterSave = () => {
      const selectedItems = map<any, string>(
        checkboxFilterListModel.getSelectedItems(checkboxFilterListModel.items),
        (item: ICheckboxFilterItem) => item.value
      );
      onSave(selectedItems);
      onMenuClose();
    };

    if (!anchorEl) return null;

    return (
      <Menu
        anchorEl={anchorEl}
        onClose={onMenuClose}
        PaperProps={{ className: classes.menuPaper }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <ItemList anchorEl={anchorEl} items={listItems} title={title} />
        <Divider />
        <MenuButtonBlock>
          <MenuButton
            startIcon={<CancelOutlined />}
            label={t("common:cancel")}
            onClick={onFilterCancel}
          />
          <MenuButton
            startIcon={<CheckCircleOutline />}
            label={t("common:apply")}
            onClick={onFilterSave}
          />
        </MenuButtonBlock>
      </Menu>
    );
  }
);

export default CheckboxFilter;
