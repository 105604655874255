import React, { useState } from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Divider, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem } from "../Menus";
import TaIcon from "../TaIcon";
import StyledButton from "./StyledButton";
import { ConfirmationModal } from "../Modals";

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    margin: theme.spacing(0.75, 0),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  menu: {
    padding: 0,
    borderRadius: 0,
  },
  menuItem: {
    padding: theme.spacing(0, 3),
    height: 38,
  },
  selectedButton: {
    padding: theme.spacing(1),
  },
}));

interface IEditMenuItem {
  onDuplicate: () => void;
  onDelete: () => void;
  onGroupSelected: () => void;
}

const EditMenuItem: React.FC<IEditMenuItem> = ({
  onDuplicate,
  onDelete,
  onGroupSelected,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(["bulk_menus", "common"]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const onMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onMenuClose = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onShowDeleteConfirm = () => {
    setShowConfirmationModal(true);
  };

  const onRemoveTests = () => {
    onDelete();
    setShowConfirmationModal(false);
    setAnchorEl(null);
  };

  const onGroupSelectedClick = () => {
    setAnchorEl(null);
    setTimeout(() => onGroupSelected(), 0);
  };

  return (
    <>
      <StyledButton
        onClick={onMenuClick}
        startIcon={<EditOutlinedIcon />}
        endIcon={<KeyboardArrowDownOutlinedIcon />}
        className={classes.selectedButton}
      >
        {t("common:edit")}
      </StyledButton>
      <Menu
        anchorEl={anchorEl}
        classes={{ paper: classes.menu }}
        onClose={onMenuClose}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
        anchorOrigin={{
          vertical: 40,
          horizontal: 0,
        }}
      >
        <MenuItem className={classes.menuItem} onClick={onGroupSelectedClick}>
          <TaIcon iconName="CreateNewFolderOutlined" /> {t("group_selected")}
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            onDuplicate();
            setAnchorEl(null);
          }}
        >
          <TaIcon iconName="ControlPointDuplicateOutlined" />{" "}
          {t("common:duplicate")}
        </MenuItem>
        <Divider className={classes.divider} />
        <MenuItem className={classes.menuItem} onClick={onShowDeleteConfirm}>
          <DeleteOutlineOutlinedIcon /> {t("common:delete")}
        </MenuItem>
        <ConfirmationModal
          isOpened={showConfirmationModal}
          text={t("common:confirm")}
          onConfirm={onRemoveTests}
          onCancel={() => setShowConfirmationModal(false)}
        />
      </Menu>
    </>
  );
};

export default EditMenuItem;
