import React, { forwardRef } from "react";
import clsx from "clsx";
import _ from "lodash";
import { makeStyles, createStyles } from "@mui/styles";
import {
  InputLabel,
  InputAdornment,
  InputBase,
  InputBaseProps,
  InputBaseComponentProps,
  Box,
  FormHelperText,
  Grid,
  Theme,
} from "@mui/material";

type IAdornmentFunction = (className: string) => React.ReactNode;
type ITextFieldAdornment = IAdornmentFunction | React.ReactNode;
export interface ITextFieldProps {
  autoFocus?: boolean;
  disabled?: boolean;
  error?: boolean;
  type?: string;
  labelText?: string;
  errorText?: string;
  helperTextRight?: string;
  isHelperTextRightError?: boolean;
  boxClassName?: string;
  noStartAdornmentWrap?: boolean;
  startAdornment?: ITextFieldAdornment;
  noEndAdornmentWrap?: boolean;
  endAdornment?: ITextFieldAdornment;
  inputBaseProps?: InputBaseProps;
  helperTextLeftClass?: string;
  helperTextRightClass?: string;
  inputProps?: InputBaseComponentProps;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    disabledRoot: {
      "& $inputBase": {
        color: theme.palette.dustyGray.main,
        backgroundColor: theme.palette.catskillWhite.main,
      },
      "& $icons": {
        color: theme.palette.geyser.main,
      },
    },
    errorRoot: {
      "& $inputBase": {
        border: `1px solid ${theme.palette.guardsmanRed.main}`,
        backgroundColor: theme.palette.linen.main,
        "&.textFieldFocused": {
          border: `1px solid ${theme.palette.guardsmanRed.main}`,
          "& $icons": {
            color: theme.palette.heather.main,
          },
        },
      },
    },
    inputLabel: {
      ...theme.typography.descriptionTextStyle,
      color: theme.palette.doveGray.main,
      height: 17,
      marginBottom: 4,
    },
    inputBase: {
      color: theme.palette.mineShaft.main,
      ...theme.typography.bodyTextStyle,
      height: 40,
      boxSizing: "border-box",
      border: `1px solid ${theme.palette.baliHai.main}`,
      backgroundColor: theme.palette.white.main,
      padding: "10px 16px",
      "&:not(.textFieldDisabled)": {
        backgroundColor: theme.palette.white.main,
      },
      "&:not(.textFieldDisabled):not(.textFieldFocused):not(.textFieldError)": {
        "&:hover": {
          border: `1px solid ${theme.palette.outrageousOrange.main}`,
        },
      },
      "&.textFieldFocused": {
        border: `1px solid ${theme.palette.outrageousOrange.main}`,
        color: theme.palette.mineShaft.main,
      },
      "& .textFieldInput": {
        padding: 0,
        height: 20,
      },
      "&.textFieldMultiline": {
        height: "auto",
        "& textarea": {
          // material ui sets height in inline styles and calculates it using scrollHeight of a single row
          // scrollHeight rounds value to an integer what results in wrong height for multiple rows
          // calculate textarea height based on single row height and number of rows rounding the final result
          height: ({ rows = 1 }: { rows: number | undefined }) =>
            `${Math.floor(
              rows * theme.typography.bodyTextStyle.fontSize * 1.4
            )}px !important`,
        },
        "&:not(.textFieldDisabled)": {
          "&.textFieldMultiline": {
            backgroundColor: theme.palette.white.main,
          },
          "&.textFieldError": {
            backgroundColor: theme.palette.ivoryTower.main,
          },
          "&.textFieldDisabled": {
            color: theme.palette.doveGray.main,
            backgroundColor: theme.palette.catskillLightWhite.main,
          },
        },
      },
    },
    formHelperErrorText: {
      ...theme.typography.descriptionTextStyle,
      color: theme.palette.guardsmanRed.main,
    },
    formHelperText: {
      ...theme.typography.descriptionTextStyle,
      color: theme.palette.doveGray.main,
    },
    icons: {
      color: theme.palette.heather.main,
      "&:hover": {
        backgroundColor: "transparent",
        color: theme.palette.outrageousOrange.main,
      },
    },
  })
);

// TODO make component
const getAdornment =
  (
    type: "start" | "end",
    adornment: ITextFieldAdornment,
    noAdornmentWrap = false
  ): IAdornmentFunction =>
  (Icons) => {
    if (!adornment) {
      return null;
    }

    const adornmentNode = _.isFunction(adornment)
      ? adornment(Icons)
      : adornment;

    return noAdornmentWrap ? (
      adornmentNode
    ) : (
      <InputAdornment position={type}>{adornmentNode}</InputAdornment>
    );
  };

const TextField = forwardRef<HTMLInputElement, ITextFieldProps>(
  (
    {
      disabled,
      error,
      errorText,
      helperTextRight,
      isHelperTextRightError,
      labelText,
      boxClassName,
      inputBaseProps,
      noStartAdornmentWrap,
      startAdornment,
      noEndAdornmentWrap,
      endAdornment,
      autoFocus,
      type,
      helperTextLeftClass,
      helperTextRightClass,
      inputProps,
    },
    ref
  ) => {
    const classes = useStyles({ rows: inputBaseProps?.minRows as number });

    return (
      <Box
        className={clsx(
          boxClassName,
          classes.root,
          disabled && classes.disabledRoot,
          (errorText || error) && classes.errorRoot
        )}
      >
        {labelText && (
          <InputLabel
            htmlFor={inputBaseProps?.id}
            className={classes.inputLabel}
          >
            {labelText}
          </InputLabel>
        )}
        <InputBase
          autoFocus={autoFocus}
          ref={ref}
          type={type}
          {..._.defaults(inputBaseProps, {
            disabled,
            error: error || !_.isEmpty(errorText),
            startAdornment: getAdornment(
              "start",
              startAdornment,
              noStartAdornmentWrap
            )(classes.icons),
            endAdornment: getAdornment(
              "end",
              endAdornment,
              noEndAdornmentWrap
            )(classes.icons),
          })}
          classes={{
            disabled: "textFieldDisabled",
            focused: "textFieldFocused",
            error: "textFieldError",
            input: "textFieldInput",
            multiline: "textFieldMultiline",
          }}
          className={clsx(classes.inputBase, inputBaseProps?.className)}
          inputProps={inputProps}
        />
        {(errorText || helperTextRight) && (
          <FormHelperText component="div">
            <Grid container justifyContent="space-between">
              <Grid
                item
                className={clsx(
                  classes.formHelperErrorText,
                  helperTextLeftClass
                )}
              >
                {errorText}
              </Grid>
              <Grid
                item
                className={clsx(
                  isHelperTextRightError
                    ? classes.formHelperErrorText
                    : classes.formHelperText,
                  helperTextRightClass
                )}
              >
                {helperTextRight}
              </Grid>
            </Grid>
          </FormHelperText>
        )}
      </Box>
    );
  }
);

export default TextField;
