import React from "react";
import { SvgIconProps } from "material-ui";
import * as materialIcons from "@mui/icons-material";

interface IStepIconProps extends SvgIconProps {
  iconName: string;
}

const TaIcon: React.FC<IStepIconProps> = ({ iconName, ...props }) => {
  const iconsList: { [key: string]: React.FC } = materialIcons;

  const IconComponent: React.FC<SvgIconProps> = iconsList[iconName];

  return <IconComponent {...props} />;
};

export default TaIcon;
