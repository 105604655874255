import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import { useTranslation } from "react-i18next";
import AddBoxOutlined from "@mui/icons-material/AddBoxOutlined";
import { useRootStoreContext } from "../../hooks";
import StyledButton from "../StyledButton";
import EnvironmentList from "./EnvironmentList";
import EnvironmentSearch from "./EnvironmentSearch";
import EnvironmentAddForm from "./EnvironmentAddForm";

const useStyles = makeStyles((theme: Theme) => ({
  sideMenuContainer: {
    height: "100%",
    minWidth: "23.5rem",
    padding: "1rem 1.5rem",
    backgroundColor: theme.palette.white.main,
  },
  sideMenuHeader: {
    margin: "0 0.5rem",
    maxWidth: "calc(100% - 1rem)",
    borderBottom: `1px solid ${theme.palette.catskillWhite.main}`,
  },
  button: {
    margin: "0.5rem 1.5rem 0.5rem -0.5rem",
    padding: "0.5rem",
  },
}));

const EnvironmentSideMenu = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation(["environments"]);
  const { environments } = useRootStoreContext();
  return (
    <Grid
      container
      flexDirection="column"
      wrap="nowrap"
      className={classes.sideMenuContainer}
    >
      <Grid
        item
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        className={classes.sideMenuHeader}
      >
        {environments.isSearchFieldOpen ? null : (
          <StyledButton
            startIcon={<AddBoxOutlined />}
            className={classes.button}
            onClick={environments.onEnvironmentAdd}
          >
            {t("add_environment")}
          </StyledButton>
        )}
        <EnvironmentSearch />
      </Grid>
      <Grid
        item
        container
        wrap="nowrap"
        flexDirection="column"
        sx={{ minHeight: 0 }}
      >
        <EnvironmentAddForm />
        <EnvironmentList />
      </Grid>
    </Grid>
  );
});

export default EnvironmentSideMenu;
