import React from "react";
import { Grid, Backdrop, useMediaQuery, useTheme, Theme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as SmallSizeIcon } from "../../icons/SmallSizeIcon.svg";

import en from "./en.json";
import { addResourcesBundle } from "../../i18n";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    backgroundColor: theme.utils.withHexOpacity(theme.palette.black, 80),
    zIndex: theme.zIndex.tooltip + 1,
  },
  svgIcon: {
    width: 91,
    height: 91,
    color: theme.palette.outrageousOrange.main,
  },
  gridHeader: {
    textAlign: "center",
    height: 34,
    fontWeight: 600,
    fontSize: 24,
    fontStyle: "normal",
    lineHeight: "140%",
    color: theme.palette.white.main,
    margin: "16px 0px 11px 0px",
  },
  gridDescription: {
    ...theme.typography.descriptionTextStyle,
    color: theme.palette.white.main,
    width: 338,
    textAlign: "center",
  },
}));

interface IBackdropForSmallScreenProps {}

const BackdropForSmallScreen: React.FC<IBackdropForSmallScreenProps> = () => {
  const { t } = useTranslation(["layout"]);
  const clasees = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Backdrop className={clasees.backdrop} open={isSmall}>
      <Grid container alignItems="center" direction="column">
        <Grid item>
          <SvgIcon className={clasees.svgIcon} viewBox="0 0 91 91">
            <SmallSizeIcon />
          </SvgIcon>
        </Grid>
        <Grid item className={clasees.gridHeader}>
          {t("too_small_header")}
        </Grid>
        <Grid className={clasees.gridDescription} item>
          {t("too_small_description")}
        </Grid>
      </Grid>
    </Backdrop>
  );
};

export default BackdropForSmallScreen;
