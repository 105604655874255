import React from "react";
import {
  useHistory,
  useLocation,
  matchPath,
  useParams,
} from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import HeaderTabs from "@mui/material/Tabs";
import HeaderTab from "./HeaderTab";
import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabsPanel: {
      width: "100%",
      height: "3.5rem",
      padding: "0.5rem 1rem 0",
      marginBottom: "1px",
      borderBottom: `1px solid ${theme.palette.catskillLightWhite.main}`,
    },
    tabIndicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > span": {
        backgroundColor: theme.palette.baliHai.main,
        width: "60%",
      },
    },
  })
);

const Tabs: React.FC<{}> = observer(() => {
  const { t } = useTranslation("header");
  const history = useHistory();
  const { pathname } = useLocation();
  const { projectId = "" } = useParams<{ projectId: string }>();
  const classes = useStyles();

  const matchData = matchPath(pathname, {
    path: "/projects/:projectId/data",
    exact: true,
  });

  const matchEnv = matchPath(pathname, {
    path: "/projects/:projectId/data/environments",
  });

  const value = matchData
    ? `${matchData.url}/elements`
    : matchEnv
    ? `/projects/${projectId}/data/environments`
    : pathname;

  return (
    <HeaderTabs
      value={value}
      className={classes.tabsPanel}
      onChange={(e, val) => {
        if (val !== value) history.push(val);
      }}
      TabIndicatorProps={{
        className: classes.tabIndicator,
        children: <span />,
      }}
    >
      <HeaderTab
        label={t("elements")}
        value={`/projects/${projectId}/data/elements`}
      />
      <HeaderTab
        label={t("variables")}
        value={`/projects/${projectId}/data/environments`}
      />
      <HeaderTab label={t("files")} />
    </HeaderTabs>
  );
});

export default Tabs;
