import _ from "lodash";
import {
  types,
  flow,
  clone,
  getSnapshot,
  applySnapshot,
  Instance,
  getRoot,
  destroy,
} from "mobx-state-tree";
import Test, { ITest, ITestSnapshotOut } from "./Test";
import i18next from "i18next";
import { IListFilters } from "./ListFilters";
import { IRoot } from "./Root";
import FilterAuthor, { IFilterAuthor } from "./FilterAuthor";
import WithSelectable from "./WithSelectable";
import {
  getFilteredListItems,
  getFilteredTestsWithReports,
  handleFetch,
} from "./utils";
import { IReportFilters } from "./ReportFilters";

const Tests = types
  .compose(
    types.model("Tests", {
      testsState: types.optional(
        types.enumeration("TestsState", ["ready", "loading"]),
        "ready"
      ),
      newTest: types.maybeNull(Test),
      editTest: types.maybeNull(Test),
      items: types.optional(types.array(Test), []),
      sortField: "createdAt",
    }),
    WithSelectable
  )
  .views((self) => ({
    get filteredItems() {
      const filters: IListFilters = getRoot<IRoot>(self).testFilters;

      return getFilteredListItems(self.items, filters);
    },
    get filteredItemsWithReports() {
      const filters: IReportFilters = getRoot<IRoot>(self).reportFilters;

      return getFilteredTestsWithReports(self.items, filters);
    },
    get testCreators() {
      return _.chain(self.items)
        .map((test) => test.creatorUserEmail)
        .uniq()
        .map((email): IFilterAuthor => FilterAuthor.create({ email: email! }))
        .value();
    },
    get testUpdaters() {
      return _.chain(self.items)
        .map((test) => test.changerUserEmail)
        .uniq()
        .map((email): IFilterAuthor => FilterAuthor.create({ email: email! }))
        .value();
    },
    getById(testId: ITest["id"]) {
      return _.find(self.items, { id: testId }) as ITest;
    },
    getSortedItems(items: ITest[]) {
      return _.orderBy(items, [self.sortField], ["desc"]) as ITest[];
    },
  }))
  .views((self) => ({
    get preparedItems() {
      return self.getSortedItems(self.filteredItems as ITest[]);
    },
    get sortedFilteredItemsWithReports() {
      return self.getSortedItems(self.filteredItemsWithReports);
    },
  }))
  .actions((self) => ({
    load: flow(function* ({
      projectId,
      suiteId,
      selected,
    }: {
      projectId: number;
      suiteId: string;
      selected?: string[];
    }) {
      try {
        self.testsState = "loading";
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${projectId}/suites/${suiteId}/tests`
        );

        if (!response) return;
        const items = yield response.json();
        applySnapshot(self.items, items);
        if (selected)
          self.items.forEach(
            (item) => (item.selected = selected.includes(item.id))
          );
        const { testAuthors } = getRoot<IRoot>(self);
        if (!testAuthors) return;
        applySnapshot(testAuthors.creators, self.testCreators);
        applySnapshot(testAuthors.updaters, self.testUpdaters);
      } catch (e) {
        // ToDo show error message
        console.error("Error while load tests", e);
      } finally {
        self.testsState = "ready";
      }
    }),
    loadTestsWithReports: flow(function* ({
      projectId,
    }: {
      projectId: number;
    }) {
      try {
        self.testsState = "loading";
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${projectId}/reports`
        );

        if (!response) return;
        const items = yield response.json();
        applySnapshot(self.items, items);
      } catch (e) {
        console.error("Error while load reports", e);
      } finally {
        self.testsState = "ready";
      }
    }),
    clearItems() {
      applySnapshot(self.items, []);
    },
    setSortField: (sortField: string) => {
      self.sortField = sortField;
    },
    loadOneTest: flow(function* ({
      projectId,
      suiteId,
      testId,
    }: {
      projectId: number;
      suiteId: string;
      testId: string;
    }) {
      try {
        if (_.find(self.items, { id: testId })) {
          return;
        }

        const oneTest = Test.create({
          id: testId,
          ProjectId: projectId,
          SuiteId: suiteId,
        });

        self.items.push(oneTest);

        yield oneTest.load();
      } catch (e) {
        // ToDo show error message
        console.error("Error while loadOneTest", e);
      } finally {
        self.testsState = "ready";
      }
    }),
    touchOneTest: ({ testId }: { testId: ITest["id"] }) => {
      const test = _.find(self.items, { id: testId }) as ITest;
      if (test) test.touch();
    },
    addNewTest: flow(function* () {
      if (!self.newTest) return;
      const test = clone(self.newTest);
      const newTestSnapshot = getSnapshot(test);
      try {
        yield test.save();
        destroy(self.newTest);
        self.items.push(test);
      } catch (e) {
        console.error(`Error while addNewTest: ${e}`);
        applySnapshot(self.newTest, newTestSnapshot);
        self.newTest.error = i18next.t("tests:duplicate_error");
        destroy(test);
      }
    }),
    editExistingTest: flow(function* () {
      if (!self.editTest) return;
      const existingTest = _.find(self.items, { id: self.editTest.id });

      if (!existingTest) return;
      const existingTestSnap = getSnapshot(existingTest);
      const editTest = getSnapshot(self.editTest);

      try {
        applySnapshot(
          existingTest,
          Object.assign({}, editTest, { name: _.trim(editTest?.name) })
        );

        yield existingTest.save();

        destroy(self.editTest);

        //TODO: Show Success msg
      } catch (e) {
        console.error("Error while editExistingTest", e);
        self.editTest.error = i18next.t("tests:duplicate_error");
        applySnapshot(existingTest, existingTestSnap);
      }
    }),

    removeTest: flow(function* (item: ITest) {
      try {
        yield item.remove();

        destroy(item);
      } catch (e) {
        console.error(`Error while removeTest: ${e}`);
      }
    }),

    duplicateTest: flow(function* (item: ITest): Generator<Promise<ITest>> {
      try {
        const testItem = yield item.duplicate();
        self.items.unshift(testItem as ITest);

        return testItem;
      } catch (e) {
        console.error(`Error while duplicateTest: ${e}`);
      }
    }),
    duplicateTests: flow(function* ({
      projectId,
      suiteId,
    }: {
      projectId: number;
      suiteId: string;
    }) {
      try {
        self.testsState = "loading";
        const testIds = _.map(
          self.getSelectedItems(self.items),
          (item) => (item as ITest).id
        );
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${projectId}/suites/${suiteId}/tests/duplicate`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              testIds,
            }),
          }
        );
        const items = yield response.json();
        if (!response.ok) throw new Error(items.message);
        applySnapshot(self.items, items);
        const { testAuthors } = getRoot<IRoot>(self);

        applySnapshot(testAuthors.creators, self.testCreators);
        applySnapshot(testAuthors.updaters, self.testUpdaters);
      } catch (e) {
        // ToDo show error message
        console.error("Error while duplicate", e);
      } finally {
        self.testsState = "ready";
      }
    }),
    deleteTests: flow(function* ({
      projectId,
      suiteId,
    }: {
      projectId: number;
      suiteId: string;
    }) {
      try {
        self.testsState = "loading";
        const testIds = _.map(
          self.getSelectedItems(self.items),
          (item) => (item as ITest).id
        );
        yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${projectId}/suites/${suiteId}/tests`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              testIds,
            }),
          }
        );

        _.map(self.getSelectedItems(self.items), (item) =>
          destroy(item as ITest)
        );
      } catch (e) {
        // ToDo show error message
        console.error("Error while duplicate", e);
      } finally {
        self.testsState = "ready";
      }
    }),
    moveTests: flow(function* (projectId, suiteId, testIds) {
      try {
        self.testsState = "loading";

        yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${projectId}/suites/${suiteId}/tests/bulkmove`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              testIds,
            }),
          }
        );
      } catch (e) {
        // ToDo show error message
        console.error("Error while moving tests in moveTests method", e);
      } finally {
        self.testsState = "ready";
      }
    }),
    toggleDraggingStateForSelectedTests(isDragging: boolean) {
      const selectedTests = self.getSelectedItems(self.items) as ITest[];
      _.each(selectedTests, (test) =>
        test.setTestState(isDragging ? "dragging" : "loading")
      );
    },
  }))
  .actions((self) => ({
    onAddNewTestClick(ProjectId: number, SuiteId: string) {
      self.newTest = Test.create({ ProjectId, SuiteId });
    },
    onCancelAddNewTest() {
      self.newTest && destroy(self.newTest);
    },
    onEditExistingTest(testSnapshot: ITestSnapshotOut) {
      self.editTest = Test.create(testSnapshot);
    },
    onCancelEditExistingTest() {
      self.editTest && destroy(self.editTest);
    },
  }));
export interface ITests extends Instance<typeof Tests> {}

export default Tests;
