import React, { useEffect } from "react";
import { SideBarOfGroupedTests, Tests } from "../components";
import { observer } from "mobx-react";
import {
  Redirect,
  useParams,
  useRouteMatch,
  Switch,
  Route,
} from "react-router-dom";
import { useRootStoreContext } from "../hooks";

const Suites: React.FC<{}> = observer(() => {
  const {
    suites,
    users: { profile },
  } = useRootStoreContext();
  const { path, isExact, url } = useRouteMatch();
  const { projectId = "" } = useParams<{ projectId: string }>();

  useEffect(() => {
    suites.load(parseInt(projectId, 10));
    profile.load();
  }, [suites, projectId, profile]);

  if (!profile.isAuthenticated) return <Redirect to="/sign-in" />;
  if (suites.state !== "ready") {
    // TODO: ask for loading from designer
    return <span>Loading</span>;
  }

  if (isExact && suites.defaultParentId(parseInt(projectId, 10))) {
    return (
      <Redirect
        to={`${url}/${suites.defaultParentId(parseInt(projectId, 10))}/tests`}
      />
    );
  }

  return (
    <Switch>
      <Route path={`${path}/:suiteId/tests`}>
        <SideBarOfGroupedTests />
        <Tests />
      </Route>
    </Switch>
  );
});

export default Suites;
