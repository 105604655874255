import { FC, useCallback, useEffect } from "react";
import { map } from "lodash";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useRootStoreContext } from "../../hooks";
import { INotifications, INotification } from "../../models";
import EmptyContainer from "../EmptyContainer";
import NotificationItem from "./NotificationItem";

const useStyles = makeStyles((theme: Theme) => ({
  emptyListContainer: {
    margin: "1rem 0 1.5rem",
  },
  listContainer: {
    height: 513,
    overflowY: "auto",
  },
}));

interface INotificationList {
  notifications: INotifications;
  type: "all" | "unread";
  updateUnreadCount: () => void;
}

const NotificationList: FC<INotificationList> = observer(
  ({ notifications, type, updateUnreadCount }) => {
    const classes = useStyles();
    const { t } = useTranslation(["notifications_popover"]);
    const { users } = useRootStoreContext();
    const userId = users.profile.id;
    const hasNextPage =
      notifications.listItems.length < notifications.itemCount;

    const loadNotifications = useCallback(
      async ({ notifications, userId, page, type }: any) => {
        await notifications.getNotificationCount({
          userId,
          unread: type === "unread",
        });
        await notifications.load({ userId, page, unread: type === "unread" });
        if (type === "all")
          await notifications.markRead({
            userId,
            notificationIds: notifications.listItemIds,
          });
        updateUnreadCount();
      },
      [updateUnreadCount]
    );

    const loadMore = async () => {
      notifications.nextListPage();

      loadNotifications({
        notifications,
        userId,
        page: notifications.listPage,
        type,
      });
    };

    useEffect(() => {
      if (!userId || notifications.listPage || notifications.listItems.length)
        return;

      loadNotifications({
        notifications,
        userId,
        type,
        page: 0,
      });
    }, [loadNotifications, notifications, userId, type]);

    const [sentryRef, { rootRef }] = useInfiniteScroll({
      loading: notifications.isLoading,
      hasNextPage,
      onLoadMore: loadMore,
      disabled: false,
      rootMargin: "0px 0px 50px 0px",
    });

    if (!notifications.listItems.length) {
      const title =
        type === "all"
          ? t("empty_all_list_title")
          : t("empty_unread_list_title");
      const description =
        type === "all"
          ? t("empty_all_list_description")
          : t("empty_unread_list_description");
      return (
        <Grid container className={classes.emptyListContainer}>
          <EmptyContainer title={title} description={description} />
        </Grid>
      );
    }

    return (
      <Grid
        container
        className={classes.listContainer}
        direction="column"
        wrap="nowrap"
        ref={rootRef}
      >
        {map(notifications.listItems, (notification: INotification, i) => {
          return (
            <NotificationItem
              key={`notification-${i}`}
              notification={notification}
            />
          );
        })}
        {(notifications.isLoading || hasNextPage) && (
          <Grid ref={sentryRef}></Grid>
        )}
      </Grid>
    );
  }
);

export default NotificationList;
