import React from "react";
import { observer } from "mobx-react";
import Tabs from "./Tabs";
import { Grid, AppBar } from "@mui/material";
import en from "./en.json";
import { addResourcesBundle } from "../../i18n";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    boxShadow: "none",
  },
}));

const ReportHeader: React.FC<{
  currentTab: string;
  onChangeTab: (tab: string) => void;
}> = observer(({ currentTab, onChangeTab }) => {
  const classes = useStyles();

  return (
    <AppBar position="sticky" color="inherit" className={classes.mainContainer}>
      <Grid container wrap="nowrap">
        <Grid container item>
          <Tabs currentTab={currentTab} onChangeTab={onChangeTab} />
        </Grid>
      </Grid>
    </AppBar>
  );
});

export default ReportHeader;
