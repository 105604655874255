import React from "react";
import { map } from "lodash";
import { Box, Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DashboardOutlined, ArrowBackOutlined } from "@mui/icons-material";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRootStoreContext } from "../../hooks";

import MenuItem from "./MenuItem";
import { menuItems } from "./ItemsForMenu";

const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  header: {
    width: "100%",
    height: theme.spacing(7),
    padding: theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.catskillWhite.main}`,
  },
  list: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  listSubheader: {
    ...theme.typography.subHeaderTextStyle,
    padding: theme.spacing(3, 3, 2),
  },
  footer: {
    width: "100%",
    height: theme.spacing(7),
    borderTop: `1px solid ${theme.palette.catskillWhite.main}`,
  },
}));

const DashboardSideMenu: React.FC = () => {
  const { t } = useTranslation(["dashboard_menu"]);
  const classes = useStyles();
  const { path } = useRouteMatch();

  const {
    users: { profile },
  } = useRootStoreContext();

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item container className={classes.header}>
        <MenuItem
          label={t("projects")}
          link={`${path}/projects`}
          icon={<DashboardOutlined />}
        />
      </Grid>
      <Grid item container className={classes.list}>
        {map(
          menuItems,
          ({ label, link, icon, isSubheader, blockedRole }) =>
            profile.role !== blockedRole &&
            (isSubheader ? (
              <Box key={label} className={classes.listSubheader}>
                {label}
              </Box>
            ) : (
              <MenuItem
                key={label}
                label={label}
                link={`${path}/${link}`}
                icon={icon}
              />
            ))
        )}
      </Grid>
      <Grid item container className={classes.footer}>
        <MenuItem
          label={t("logout")}
          onClick={() => profile.logout()}
          icon={<ArrowBackOutlined />}
        />
      </Grid>
    </Grid>
  );
};

export default DashboardSideMenu;
