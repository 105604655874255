import { FC } from "react";
import { map } from "lodash";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem } from "../Menus";

interface IUserSortMenu {
  anchorEl: null | HTMLElement;
  onMenuClose: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  className: string | undefined;
  sort: (sortBy: string) => void;
}

const SortMenu: FC<IUserSortMenu> = observer(
  ({ anchorEl, onMenuClose, className, sort }) => {
    const { t } = useTranslation(["projects"]);

    const onSortClick = (
      sortBy: string,
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      e.stopPropagation();
      sort(sortBy);
      onMenuClose(e);
    };

    const menuItems: { label: string; sortParam: string }[] = [
      {
        label: t("sort_menu.last_added"),
        sortParam: "last_added",
      },
      {
        label: t("sort_menu.last_updated"),
        sortParam: "last_updated",
      },
    ];

    return (
      <Menu
        anchorEl={anchorEl}
        onClose={onMenuClose}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        className={className}
      >
        {map(menuItems, ({ label, sortParam }, i) => (
          <MenuItem
            key={`sort_menu_item_${i}`}
            onClick={(e) => onSortClick(sortParam, e)}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    );
  }
);

export default SortMenu;
