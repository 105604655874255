import { observer } from "mobx-react";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SearchOutlined } from "@mui/icons-material";

import { useRootStoreContext } from "../../hooks";
import StyledButton from "../StyledButton";
import { Search } from "../Inputs";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: "0.5rem -0.5rem 0.5rem 0",
    padding: "0.5rem",
  },
}));

const EnvironmentSearch = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation(["common"]);
  const { environments } = useRootStoreContext();

  if (!environments.isSearchFieldOpen) {
    return (
      <StyledButton
        className={classes.button}
        endIcon={<SearchOutlined />}
        onClick={environments.onSearchOpenCloseClick}
      >
        {t("search")}
      </StyledButton>
    );
  }
  return (
    <Search
      onChangeText={environments.setSearchTextFieldValue}
      value={environments.searchTextFieldValue}
      onClose={environments.onSearchOpenCloseClick}
    />
  );
});

export default EnvironmentSearch;
