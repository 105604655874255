import { FC, useState } from "react";
import { observer } from "mobx-react";

import { Grid, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

import { ITest } from "../../models/Test";
import ReportHeader from "../ReportHeader";
import NoSearchResult from "../NoSearchResult";
import TestHistory from "./TestHistory";
import TestOverview from "./TestOverview";

interface ITestReportItem {
  test: ITest;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noContent: {
      width: "100%",
      height: "5rem",
      display: "flex",
      backgroundColor: theme.palette.white.main,
      padding: theme.spacing(0, 4),
    },
  })
);

const TestReport: FC<ITestReportItem> = observer(({ test }) => {
  const classes = useStyles();

  const [currentTab, setCurrentTab] = useState("overview");

  return (
    <Grid container>
      <ReportHeader currentTab={currentTab} onChangeTab={setCurrentTab} />
      {currentTab === "overview" && <TestOverview test={test} />}
      {currentTab === "log" && (
        <Grid container item className={classes.noContent}>
          <NoSearchResult />
        </Grid>
      )}
      {currentTab === "history" && <TestHistory test={test} />}
    </Grid>
  );
});

export default TestReport;
