import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem } from "../Menus";
import { Divider, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TaIcon from "../TaIcon";
import { useHistory } from "react-router-dom";
import { useRootStoreContext } from "../../hooks";

const useStyles = makeStyles((theme: Theme) => ({
  profileMenuItem: {
    width: 127,
  },
  highDivider: {
    margin: theme.spacing(2, 0),
  },
}));

interface IProfileMenu {
  anchorEl: null | HTMLElement;
  onMenuClose: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

const ProfileMenu: React.FC<IProfileMenu> = observer(
  ({ anchorEl, onMenuClose }) => {
    const { t } = useTranslation(["profile_menu"]);
    const classes = useStyles();
    const history = useHistory();
    const {
      users: { profile },
    } = useRootStoreContext();

    const openProfilePage = () => {
      history.push("/dashboard/profile/general");
    };

    return (
      <Menu
        anchorEl={anchorEl}
        onClose={onMenuClose}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem className={classes.profileMenuItem} onClick={openProfilePage}>
          <TaIcon iconName="InsertEmoticon" /> {t("profile")}
        </MenuItem>
        <MenuItem className={classes.profileMenuItem}>
          <TaIcon iconName="Settings" /> {t("settings")}
        </MenuItem>
        <MenuItem className={classes.profileMenuItem}>
          <TaIcon iconName="Keyboard" /> {t("hotkeys")}
        </MenuItem>
        <MenuItem className={classes.profileMenuItem}>
          <TaIcon iconName="Help" /> {t("help")}
        </MenuItem>
        <Divider className={classes.highDivider} />
        <MenuItem className={classes.profileMenuItem} onClick={profile.logout}>
          <TaIcon iconName="ExitToApp" /> {t("logout")}
        </MenuItem>
      </Menu>
    );
  }
);

export default ProfileMenu;
