import React from "react";
import { CircularProgress, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "fixed",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#fff",
    "& .MuiCircularProgress-colorPrimary": {
      color: theme.palette.outrageousOrange.main,
    },
  },
}));

const PageSpinner: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
};

export default PageSpinner;
