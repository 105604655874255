import { FC, useEffect } from "react";
import { chain } from "lodash";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IElement } from "../../models";
import { addResourcesBundle } from "../../i18n";
import Table, { ITableColumn } from "../Table";
import { useRootStoreContext } from "../../hooks";
import BulkMenus, { ElementsBulkMenus } from "../BulkMenus";
import { ITableCellMenuItem } from "../Table/TableCellMenu";
import en from "./en.json";
import ElementNameCell from "./ElementNameCell";

const useStyles = makeStyles((theme: Theme) => ({
  screenshot: {
    "& > div:not(.noScreenshot)": {
      justifyContent: "flex-start",
    },
    "& > div": {
      overflow: "visible",
    },
    "& img": {
      transition: "all 0.1s ease-in-out",
      "&:hover": {
        zIndex: theme.zIndex.modal - 2,
        transform: "scale(1.7)",
      },
    },
  },
  elementRow: {
    minHeight: "5.0625rem",
  },
}));

addResourcesBundle({ en });

const ElementsList: FC = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation(["elements"]);
  const { elements } = useRootStoreContext();
  const { projectId = "" } = useParams<{
    projectId: string;
  }>();

  useEffect(() => {
    elements.load({
      projectId: parseInt(projectId, 10),
    });
  }, [elements, projectId]);

  const columns: ITableColumn[] = [
    {
      type: "image",
      key: "screenshotUrl",
      label: t("element"),
      width: "2fr",
      className: classes.screenshot,
    },
    {
      key: "elementLabel",
      label: t("Label"),
      width: "2fr",
    },
    {
      key: "elementType",
      label: t("type"),
      width: "1fr",
    },
    {
      type: "custom",
      label: t("locator"),
      width: "5fr",
      customComponent: ElementNameCell,
    },
    {
      type: "date",
      key: "createdAt",
      label: t("added"),
      width: "2fr",
    },
    {
      type: "number",
      key: "usageCount",
      label: t("used"),
      width: "1.5rem",
    },
    {
      type: "hover-menu",
      width: "0",
    },
  ];

  const hoverMenuItems = (element: IElement): ITableCellMenuItem[] => [
    {
      iconName: "BorderColorOutlined",
      onClick: element.onEditExistingClick,
    },
    {
      isMenu: true,
    },
  ];

  const menuItems = (element: IElement): ITableCellMenuItem[] => [
    {
      label: t("common:delete"),
      iconName: "DeleteOutlineOutlined",
      isConfirm: true,
      confirmText: t("common:confirm"),
      onClick: async () => {
        await elements.removeElement(element);
        await elements.load({ projectId: parseInt(projectId, 10) });
      },
    },
  ];

  const elementItems = chain(elements.filteredItems)
    .filter(Boolean)
    .map((item) => {
      return {
        ...item,
        imageAlt: item.name,
        screenshotUrl: item.screenshotUrl,
        customComponentProps: {
          element: item,
        },
        toggleSelected: item.toggleSelected,
        hoverMenuItems: hoverMenuItems(item),
        menuItems: menuItems(item),
      };
    })
    .value();

  return (
    <>
      <Table
        columns={columns}
        rows={elementItems}
        emptyListText={t("empty_list_text")}
        emptyListDescription={t("empty_list_description")}
        customRowClass={classes.elementRow}
      ></Table>
      {elements.getSelectedSize(elements.filteredItems) > 1 ? (
        <BulkMenus>
          <ElementsBulkMenus />
        </BulkMenus>
      ) : null}
    </>
  );
});

export default ElementsList;
