import { FC, ReactNode, useState } from "react";
import clsx from "clsx";
import _ from "lodash";
import { observer } from "mobx-react";
import { Theme, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TextField } from "../Inputs";
import { Menu } from "../Menus";
import TaIcon from "../TaIcon";

const useStyles = makeStyles((theme: Theme) => ({
  textField: {},
  inputBaseClass: {},
  iconButtonDoneOutlined: {},
  doneOutlinedIcon: {},
  selectBodyContainer: {
    width: theme.spacing(43),
    maxHeight: 436,
    padding: theme.spacing(0, 3),
  },
  opened: {
    border: `1px solid ${theme.palette.outrageousOrange.main}`,
  },
}));

interface ISelectWithCustomBody {
  actionType?: string;
  placeholder?: string;
  value?: string;
  labelText?: string;
  readOnly?: boolean;
  opened?: boolean;
  onChange?: () => void;
  cleanup?: () => void;
  error?: boolean;
  children?:
    | ReactNode
    | ((onSelectClose: (e: React.MouseEvent) => void) => JSX.Element);
}

const SelectWithCustomBody: FC<ISelectWithCustomBody> = observer(
  ({
    value,
    placeholder,
    labelText,
    readOnly,
    onChange,
    children,
    opened,
    cleanup,
    error,
  }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const onSelectBodyOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setAnchorEl(e.currentTarget);
    };

    const onSelectBodyClose = (e: React.MouseEvent) => {
      e.stopPropagation();
      cleanup && cleanup();
      setAnchorEl(null);
    };

    return (
      <>
        <TextField
          boxClassName={classes.textField}
          labelText={labelText}
          error={error}
          inputBaseProps={{
            rows: 1,
            maxRows: 1,
            fullWidth: true,
            value,
            placeholder,
            readOnly,
            className: clsx(classes.inputBaseClass, {
              [classes.opened]: anchorEl,
            }),
            onChange,
            onClick: (e: React.SyntheticEvent) => e.stopPropagation(),
          }}
          endAdornment={(className) => (
            <IconButton
              edge="end"
              className={clsx(className, classes.iconButtonDoneOutlined)}
              onClick={onSelectBodyOpen}
              size="large"
            >
              <TaIcon iconName="MoreVertOutlined" />
            </IconButton>
          )}
        />
        {anchorEl ? (
          <Menu
            anchorEl={anchorEl}
            classes={{ paper: classes.selectBodyContainer }}
            onClose={onSelectBodyClose}
          >
            {_.isFunction(children) ? children(onSelectBodyClose) : children}
          </Menu>
        ) : null}
      </>
    );
  }
);

export default SelectWithCustomBody;
