import { types, Instance } from "mobx-state-tree";
import ActionWindowSize from "./ActionWindowSize";

const ActionJSONWindowSize = types
  .model("ActionJSONWindowSize", {
    jsonbValueSize: types.optional(ActionWindowSize, {}),
  })
  .actions((self) => ({
    addNewWindowSize() {
      self.jsonbValueSize = ActionWindowSize.create();
    },
    setWidth(width: string) {
      self.jsonbValueSize.setWidth(width);
    },
    setHeight(height: string) {
      self.jsonbValueSize.setHeight(height);
    },
  }));

export interface IActionJSONWindowSize
  extends Instance<typeof ActionJSONWindowSize> {}
export default ActionJSONWindowSize;
