import { types, Instance, getParent } from "mobx-state-tree";
import _ from "lodash";
import i18n from "i18next";
import Action from "./Action";
import { IBaseActions } from "./Actions";

const ActionsItems = types
  .model("ActionsItems", {
    groupKey: types.maybe(types.enumeration(["default", "waits", "asserts"])),
    items: types.optional(types.array(Action), []),
    isOpen: false,
  })
  .views((self) => ({
    get name() {
      return i18n.t(
        `side_bar_of_actions_elements_shared_steps:actions_group_types.${self.groupKey}`
      );
    },
    get filteredItems() {
      const searchTextFieldValue = getParent<IBaseActions>(
        self,
        2
      ).searchTextFieldValue;

      return _.filter(
        self.items,
        (item) => item.name.toLowerCase().indexOf(searchTextFieldValue) > -1
      );
    },
  }));
export default ActionsItems;
export interface IActionsItems extends Instance<typeof ActionsItems> {}
