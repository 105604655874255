import { FC } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useRootStoreContext } from "../../hooks";
import { Modal } from ".";

interface INoConnection {
  isOpen: boolean;
  onClose: () => void;
}

const NoConnection: FC<INoConnection> = observer(({ isOpen, onClose }) => {
  const { t } = useTranslation(["modals"]);

  const {
    settings: { downloadClientUrl },
  } = useRootStoreContext();

  const handleDownloadClient = () => {
    if (downloadClientUrl) {
      window.open(downloadClientUrl, "_blank");
    }
    onClose();
  };

  return (
    <Modal
      title={t("no_connection.title")}
      open={isOpen}
      onClose={onClose}
      modalActions={[
        {
          text: t("no_connection.action"),
          onClick: handleDownloadClient,
        },
      ]}
    >
      {t("no_connection.description")}
    </Modal>
  );
});

export default NoConnection;
