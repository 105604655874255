import { withStyles } from "@mui/styles";
import { IconButton, Theme } from "@mui/material";

export default withStyles((theme: Theme) => ({
  root: {
    ...theme.typography.bodyTextStyle,
    padding: theme.spacing(1),
    borderRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.catskillLightWhite.main,
      color: theme.palette.outrageousOrange.main,
    },
    color: theme.palette.baliHai.main,
  },
  disabled: {
    color: theme.palette.geyser.main,
  },
}))(IconButton);
