import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useRootStoreContext } from "../../hooks";
import StyledButton from "./StyledButton";
import LabelMenu from "../LabelMenu";
import { LabelMenuContext } from "../../contexts";
import _ from "lodash";
import { ITest } from "../../models";

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    marginRight: "auto",
  },
  labelMenu: {
    top: "auto !important",
    bottom: theme.spacing(6.25),
    marginLeft: 270,
    borderRadius: 0,
  },
}));

interface IEditMenuItem {}

const LabelBulkMenu: React.FC<IEditMenuItem> = () => {
  const classes = useStyles();
  const { t } = useTranslation(["bulk_menus"]);
  const { suiteId = "" } = useParams<{ suiteId: string }>();
  const { tests, labels } = useRootStoreContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const testIds = _.map(
    tests.getSelectedItems(tests.items),
    (item) => (item as ITest).id
  );

  useEffect(() => {
    if (anchorEl) labels.loadTestLabels(testIds);
  }, [anchorEl, labels, testIds]);

  const onLabelMenuOpen = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onLabelMenuClose = () => {
    labels.onMenuClose();
    setAnchorEl(null);
  };

  const getLabelMenuContextData = useMemo(() => {
    return {
      testIds,
      suiteId,
    };
  }, [testIds, suiteId]);

  return (
    <>
      <StyledButton
        endIcon={<KeyboardArrowDownOutlinedIcon />}
        startIcon={<LabelOutlinedIcon />}
        className={classes.label}
        onClick={onLabelMenuOpen}
      >
        {t("label")}
      </StyledButton>
      <LabelMenuContext.Provider value={getLabelMenuContextData}>
        <LabelMenu
          anchorEl={anchorEl}
          currentLabels={labels.currentItems}
          onClose={onLabelMenuClose}
          className={classes.labelMenu}
        />
      </LabelMenuContext.Provider>
    </>
  );
};

export default LabelBulkMenu;
