import React from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import moment from "moment";

import { ListItem, Grid, Theme } from "@mui/material";

import { makeStyles, createStyles } from "@mui/styles";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { ITest } from "../../models/Test";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "./StyledAccordion";
import TestReport from "./TestReport";
import TestResult from "./TestResult";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      padding: 0,
      display: "flex",
      "&:hover": {
        backgroundColor: theme.palette.catskillLightWhite.main,
      },
      "&.listItemDisabled": {
        pointerEvents: "none",
        opacity: 1,
      },
    },
    selected: {
      borderLeft: `4px solid ${theme.palette.outrageousOrange.main}`,
      paddingLeft: "1.75rem",
    },
    column: {
      paddingRight: "0.3rem",
      color: theme.palette.mineShaft.main,
      ...theme.typography.bodyTextStyle,
    },
    columnName: {
      flex: 8,
    },
    columnRun: {
      flex: 2,
      minWidth: 165,
      whiteSpace: "nowrap",
    },
    testDetails: {
      padding: theme.spacing(3, 0),
      backgroundColor: theme.palette.catskillWhite.main,
    },
  })
);

const decorateTimestamp = (timestamp: string) =>
  moment(timestamp).format("MMMM D, YYYY h:mm A");

interface ISeparateTestItem {
  test: ITest;
  expanded: boolean;
  onExpand: React.Dispatch<React.SetStateAction<null | string>>;
}

const SeparateTest: React.FC<ISeparateTestItem> = observer(
  ({ test, expanded, onExpand }) => {
    const classes = useStyles();

    const handleExpanded = () => onExpand(expanded ? null : test.id);

    return (
      <>
        <ListItem
          component="li"
          classes={{
            root: classes.listItem,
            disabled: "listItemDisabled",
            selected: classes.selected,
          }}
        >
          <Accordion square expanded={expanded} onChange={handleExpanded}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container alignItems="center" wrap="nowrap">
                <Grid item className={clsx(classes.column, classes.columnName)}>
                  {test.name}
                </Grid>
                <Grid item className={clsx(classes.column, classes.columnRun)}>
                  {decorateTimestamp(test.lastRun)}
                </Grid>
                <TestResult result={test.result} />
              </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.testDetails}>
              {expanded && <TestReport test={test} />}
            </AccordionDetails>
          </Accordion>
        </ListItem>
      </>
    );
  }
);

export default SeparateTest;
