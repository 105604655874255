import { FC, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Grid, Theme, MenuItem, SelectChangeEvent } from "@mui/material";
import { useRootStoreContext } from "../../hooks";
import { IProjectInvite, role } from "../../models";
import StyledSelect from "../StyledSelect";
import ValidatableTextArea from "../Inputs/ValidatableTextArea";

const useStyles = makeStyles((theme: Theme) => ({
  inviteContainer: {
    borderLeft: `4px solid ${theme.palette.outrageousOrange.main}`,
    backgroundColor: theme.palette.catskillLightWhite.main,
    padding: theme.spacing(2, 3),
    height: "5.5rem",
  },
  selectContainer: {
    marginRight: theme.spacing(3),
    backgroundColor: "blue",
    flex: 1,
  },
  // ToDo: move to StyledSelect component
  selectField: {
    width: "100%",
    "& .MuiSelect-select": {
      height: "2.5rem",
      boxSizing: "border-box",
      borderColor: theme.palette.rockBlue.main,
      '&:hover, &:focus, &[aria-expanded="true"]': {
        borderColor: theme.palette.outrageousOrange.main,
      },
    },
  },
  emailContainer: {
    backgroundColor: "yellow",
    flex: 2.5,
  },
  emailField: {
    minHeight: "auto",
    maxHeight: "2.5rem",
    "& .MuiInputBase-root": {
      maxHeight: "2.5rem",
    },
  },
}));

interface IInviteUser {
  invite: IProjectInvite;
}

const InviteUser: FC<IInviteUser> = observer(({ invite }) => {
  const classes = useStyles();
  const { t } = useTranslation(["users"]);
  const { users } = useRootStoreContext();
  const [loading, setLoading] = useState(false);

  const onInviteEmailChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value?.length > 250) return;
    invite.setInviteEmail(e.target.value);
  };

  const onInviteRoleChange = (e: SelectChangeEvent<unknown>) => {
    invite.setInviteRole(e.target.value as role);
  };

  const onSaveClick = async () => {
    try {
      setLoading(true);
      await invite.validateEmail();
      if (!invite.isValid) return;

      await invite.inviteUser();
      await users.load();
      users.removeUserToInvite();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onCancelClick = () => {
    users.removeUserToInvite();
  };

  return (
    <Grid container alignItems="center" className={classes.inviteContainer}>
      <Grid item className={classes.selectContainer}>
        <StyledSelect
          className={classes.selectField}
          onChange={onInviteRoleChange}
          value={invite.role || "user"}
        >
          <MenuItem value="user">{t("roles.user")}</MenuItem>
          <MenuItem value="admin" disabled>
            {t("roles.admin")}
          </MenuItem>
          <MenuItem value="manager" disabled>
            {t("roles.manager")}
          </MenuItem>
        </StyledSelect>
      </Grid>
      <Grid item className={classes.emailContainer}>
        <ValidatableTextArea
          autoFocus
          hideHelperText
          maxLength={255}
          customClassName={classes.emailField}
          itemName={invite.email}
          isValid={invite.isValid}
          errorText={invite.fieldErrors(true).email}
          onChange={onInviteEmailChange}
          onSaveClick={onSaveClick}
          onCancelClick={onCancelClick}
          placeholder={t("invite_new_user_by_email")}
          disabled={loading}
        />
      </Grid>
    </Grid>
  );
});

export default InviteUser;
