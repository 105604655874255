import Tabs from "@mui/material/Tabs";
import { Theme } from "@mui/material";
import { withStyles } from "@mui/styles";

export default withStyles((theme: Theme) => ({
  scroller: {
    display: "flex",
    alignItems: "center",
  },

  indicator: {
    backgroundColor: theme.palette.baliHai.main,
  },
}))(Tabs);
