import { FC, useState } from "react";
import clsx from "clsx";
import { Theme, Badge } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MuiAlert, { AlertProps } from "@mui/lab/Alert";
import {
  CheckCircleOutlined,
  ErrorOutlineOutlined,
  CancelOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useRootStoreContext } from "../../hooks";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: "22.5rem",
    maxWidth: "80%",
    borderRadius: 0,
    padding: "0.5625rem 1.5rem",
  },
  root2: {
    boxShadow: `0 3px 4px -2px rgba(0,0,0,0.2), 0 8px 1px -4px ${theme.palette.catskillLightWhite.main}, 0 8px 0px -3px ${theme.palette.doveGray.main}`,
  },
  root3: {
    boxShadow: `0 3px 4px -2px rgba(0,0,0,0.2), 0 8px 1px -4px ${theme.palette.catskillLightWhite.main}, 0 8px 0px -3px ${theme.palette.doveGray.main}, 0 11px 4px -6px rgba(0,0,0,0.2), 0 16px 1px -8px ${theme.palette.catskillWhite.main}, 0 16px 0px -7px ${theme.palette.doveGray.main}`,
  },
  action: {
    color: theme.palette.rockBlue.main,
    "& .MuiSvgIcon-root": {
      fontSize: "1.5rem",
    },
  },
  message: {
    color: theme.palette.mineShaft.main,
    whiteSpace: "pre-line",
    wordBreak: "break-all",
  },
  outlinedSuccess: {
    color: theme.palette.mineShaft.main,
    borderColor: theme.palette.japaneseLaurel.main,
    backgroundColor: theme.palette.aquaSpring.main,
    "& .MuiAlert-icon": {
      color: theme.palette.japaneseLaurel.main,
    },
  },
  outlinedWarning: {
    color: theme.palette.mineShaft.main,
    borderColor: theme.palette.goldDrop.main,
    backgroundColor: theme.palette.oldLace.main,
    "& .MuiAlert-icon": {
      color: theme.palette.goldDrop.main,
    },
  },
  outlinedError: {
    color: theme.palette.mineShaft.main,
    borderColor: theme.palette.guardsmanRed.main,
    backgroundColor: theme.palette.ivoryTower.main,
    "& .MuiAlert-icon": {
      color: theme.palette.guardsmanRed.main,
    },
  },
  badge: {
    cursor: "pointer",
    color: theme.palette.mineShaft.main,
    transform: "scale(1) translate(-1rem, -45%)",
  },
  badgeSuccess: {
    border: "1px solid",
    borderColor: theme.palette.japaneseLaurel.main,
    backgroundColor: theme.palette.aquaSpring.main,
    "&:hover": {
      filter: "brightness(0.95)",
    },
  },
  badgeWarning: {
    border: "1px solid",
    borderColor: theme.palette.goldDrop.main,
    backgroundColor: theme.palette.oldLace.main,
    "&:hover": {
      filter: "brightness(0.95)",
    },
  },
  badgeError: {
    border: "1px solid",
    borderColor: theme.palette.guardsmanRed.main,
    backgroundColor: theme.palette.ivoryTower.main,
    "&:hover": {
      filter: "brightness(0.95)",
    },
  },
}));

const Alert: FC<AlertProps> = ({ severity, ...props }) => {
  const { notifications } = useRootStoreContext();
  const { t } = useTranslation(["notifications"]);
  const classes = useStyles();
  const notificationsLength = notifications.items.length;
  const [isBadgeVisible, setBadgeVisible] = useState(false);

  const alertClasses = {
    root: clsx(
      classes.root,
      { [classes.root2]: notificationsLength === 2 },
      { [classes.root3]: notificationsLength > 2 }
    ),
    action: classes.action,
    message: classes.message,
    outlinedSuccess: classes.outlinedSuccess,
    outlinedWarning: classes.outlinedWarning,
    outlinedError: classes.outlinedError,
  };

  const iconMapping = {
    error: <CancelOutlined />,
    success: <CheckCircleOutlined />,
    warning: <ErrorOutlineOutlined />,
  };

  return (
    <Badge
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      invisible={!isBadgeVisible}
      classes={{
        badge: clsx(classes.badge, {
          [classes.badgeSuccess]: severity === "success",
          [classes.badgeWarning]: severity === "warning",
          [classes.badgeError]: severity === "error",
        }),
      }}
      badgeContent={t("clearAll")}
      onMouseEnter={() => setBadgeVisible(notificationsLength > 1)}
      onMouseLeave={() => setBadgeVisible(false)}
      onClick={() => notifications.clearAll()}
    >
      <MuiAlert
        square
        elevation={6}
        variant="outlined"
        iconMapping={iconMapping}
        classes={alertClasses}
        severity={severity}
        {...props}
      />
    </Badge>
  );
};

export default Alert;
