import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { ICheckboxFilterItem } from "../../../models";
import { MenuItem } from "../../Menus";
import { Checkbox } from "../../Inputs";

interface IItem {
  item: ICheckboxFilterItem;
}

const useStyles = makeStyles(() => ({
  roleItem: {
    height: "auto",
    padding: 0,
  },
}));

const Item: React.FC<IItem> = observer(({ item }) => {
  const classes = useStyles();
  const onSelect = (_e: React.ChangeEvent<HTMLInputElement>) => {
    item.toggleSelected();
  };

  return (
    <MenuItem className={classes.roleItem}>
      <Checkbox
        checked={item.selected}
        onChange={onSelect}
        label={item.label}
      />
    </MenuItem>
  );
});

export default Item;
