import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Theme,
  Typography,
  MenuItem,
  FormHelperText,
} from "@mui/material";

import { useRootStoreContext } from "../hooks";

import {
  Layout,
  LayoutOfContent,
  PageSpinner,
  StyledButton,
  StyledSelect,
  TextField,
  Radio,
  RadioGroup,
} from "../components";

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    backgroundColor: theme.palette.white.main,
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    width: "100%",
    maxWidth: "100%",
    overflow: "auto",
  },
  root: {
    width: 410,
    marginLeft: "auto",
    marginRight: "auto",
    flexDirection: "column",
  },
  logo: {
    width: 48,
    height: 48,
    marginRight: theme.spacing(2),
  },
  header: {
    marginTop: 80,
    marginBottom: 80,
    fontSize: 18,
    fontWeight: 500,
  },
  pageTitle: {
    fontSize: 15,
    fontWeight: 700,
    marginBottom: theme.spacing(0),
  },
  fieldContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    marginTop: theme.spacing(1),
  },
  selectField: {
    width: "100%",
  },
  errorText: {
    ...theme.typography.descriptionTextStyle,
    color: theme.palette.guardsmanRed.main,
  },
}));

const userRoles = [
  "qa_eng",
  "qa_tl",
  "qa_head",
  "dev_eng",
  "dev_tl",
  "dev_head",
  "other",
];
const qaSizes = ["1-5", "5-10", "10-20", ">20"];

interface IFormInputs {
  name: string;
  company: string;
  qaTeam: string;
  role: string;
}

interface IServerErrors {
  global?: string;
  company?: string;
  firstLastName?: string;
}

const Confirm: FC<{}> = () => {
  const classes = useStyles();
  const { t } = useTranslation("auth");

  const {
    users: { profile },
    projects,
  } = useRootStoreContext();

  const history = useHistory();

  const {
    control,
    watch,
    trigger,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>();

  const [serverErrors, setServerErrors] = useState<IServerErrors>({});
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);

  const params = new URLSearchParams(window.location.search);
  const confirmationCode = params.get("confirmationCode") || null;

  const handleChangeControlledField = (field: any, event: any) => {
    clearErrors(field.name);
    setServerErrors({});
    field.onChange(event);
    submitted && trigger(field.name);
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    if (!(await trigger())) return;

    await profile.confirm(
      { ...watch(), confirmationCode },
      () => {
        projects.load();
        history.push("/dashboard/projects");
      },
      (msg, path) =>
        path
          ? setServerErrors({ [path]: msg })
          : setServerErrors({ global: msg })
    );
  };

  const validateName = (value: string) => {
    const trimmedValue = value.trim();
    if (!trimmedValue) return t("name_required");
    if (trimmedValue.length > 50) return t("name_too_long");
    return undefined;
  };

  const validateCompany = (value: string) => {
    const trimmedValue = value.trim();
    if (!trimmedValue) return t("company_required");
    if (trimmedValue.length > 50) return t("company_too_long");
    return undefined;
  };

  const validateQaTeam = (value: string) =>
    value ? undefined : t("qa_team_required");

  useEffect(() => {
    try {
      fetch(
        `${process.env.REACT_APP_API_URL}/auth/validate-confirmation-code?confirmationCode=${confirmationCode}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setLoading(false);
          } else {
            history.push("/sign-in");
          }
        });
    } catch (error) {
      console.error("Failed to validate password code", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <PageSpinner />;

  return (
    <Layout>
      <LayoutOfContent className={classes.layout}>
        <Grid container className={classes.root} wrap="nowrap">
          <Grid container alignItems="center" className={classes.header}>
            <img
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="logo"
              className={classes.logo}
            />
            {t("true_automation")}
          </Grid>
          <Typography className={classes.pageTitle}>{t("finish")}</Typography>
          {serverErrors.global && (
            <FormHelperText className={classes.errorText}>
              {serverErrors.global}
            </FormHelperText>
          )}
          <Grid item className={classes.fieldContainer}>
            <Controller
              name="name"
              control={control}
              defaultValue={""}
              rules={{ validate: validateName }}
              render={({ field }) => (
                <TextField
                  labelText={t("name")}
                  errorText={serverErrors.firstLastName || errors.name?.message}
                  inputBaseProps={{
                    ...field,
                    placeholder: t("enter_name"),
                    fullWidth: true,
                    onChange: (target) =>
                      handleChangeControlledField(field, target),
                    onBlur: ({ target }) =>
                      setValue(field.name, target.value.trim()),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item className={classes.fieldContainer}>
            <Controller
              name="company"
              control={control}
              defaultValue={""}
              rules={{ validate: validateCompany }}
              render={({ field }) => (
                <TextField
                  labelText={t("company")}
                  errorText={serverErrors.company || errors.company?.message}
                  inputBaseProps={{
                    ...field,
                    placeholder: t("enter_company"),
                    fullWidth: true,
                    onChange: (target) =>
                      handleChangeControlledField(field, target),
                    onBlur: ({ target }) =>
                      setValue(field.name, target.value.trim()),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item className={classes.fieldContainer}>
            <Controller
              name="qaTeam"
              control={control}
              rules={{ validate: validateQaTeam }}
              render={({ field }) => (
                <RadioGroup
                  row
                  labelText={t("qa_size")}
                  errorText={errors.qaTeam?.message}
                  onChange={(target) =>
                    handleChangeControlledField(field, target)
                  }
                >
                  {qaSizes.map((range) => (
                    <Radio key={range} value={range} label={range} />
                  ))}
                </RadioGroup>
              )}
            />
          </Grid>
          <Grid item className={classes.fieldContainer}>
            <Controller
              name="role"
              control={control}
              defaultValue={""}
              rules={{ required: true }}
              render={({ field }) => (
                <StyledSelect
                  displayEmpty
                  renderValue={(value) =>
                    value ? t(`roles.${value}`) : t("select_role")
                  }
                  className={classes.selectField}
                  labelText={t("role")}
                  errorText={errors.role ? t("role_required") : undefined}
                  inputProps={{
                    ...field,
                    onChange: (target) =>
                      handleChangeControlledField(field, target),
                  }}
                >
                  {userRoles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {t(`roles.${role}`)}
                    </MenuItem>
                  ))}
                </StyledSelect>
              )}
            />
          </Grid>
          <Grid item className={classes.buttonContainer}>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              {t("submit_finish")}
            </StyledButton>
          </Grid>
        </Grid>
      </LayoutOfContent>
    </Layout>
  );
};

export default Confirm;
