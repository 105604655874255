import React, { FC, useState } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IProject } from "../../models";
import TaIcon from "../TaIcon";
import StyledIconButton from "../StyledIconButton";
import { Menu, MenuItem } from "../Menus";
import { ConfirmationModal } from "../Modals";

const useStyles = makeStyles((theme: Theme) => ({
  menuButton: {
    padding: 0,
  },
  menuLink: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    ...theme.typography.bodyTextStyle,
    color: theme.palette.mineShaft.main,
    "&>.MuiSvgIcon-root": {
      marginRight: theme.spacing(1),
      color: theme.palette.baliHai.main,
    },
  },
}));

interface IProjectItemMenu {
  project: IProject;
}

const ProjectItemMenu: FC<IProjectItemMenu> = observer(({ project }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation(["projects", "common"]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const classes = useStyles();

  const onMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const onMenuClose = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onShowDeleteConfirm = () => {
    setShowConfirmationModal(true);
  };

  const onDeleteClick = () => {
    project.remove();
  };

  return (
    <>
      <StyledIconButton className={classes.menuButton} onClick={onMenuOpen}>
        <TaIcon iconName="MoreVertOutlined" />
      </StyledIconButton>
      <Menu anchorEl={anchorEl} onClose={onMenuClose}>
        <MenuItem onClick={console.log}>
          <TaIcon iconName="LinkOutlined" />
          {t("invite_link")}
        </MenuItem>
        <MenuItem>
          <Link
            to={`/projects/${project.id}/settings`}
            className={classes.menuLink}
          >
            <TaIcon iconName="SettingsOutlined" />
            {t("project_settings")}
          </Link>
        </MenuItem>
        <MenuItem onClick={onShowDeleteConfirm}>
          <TaIcon iconName="DeleteOutlineOutlined" />
          {t("common:delete")}
        </MenuItem>
        <ConfirmationModal
          isOpened={showConfirmationModal}
          text={t("common:confirm")}
          onConfirm={onDeleteClick}
          onCancel={() => setShowConfirmationModal(false)}
        />
      </Menu>
    </>
  );
});

export default ProjectItemMenu;
