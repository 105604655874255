import React from "react";
import { observer } from "mobx-react";
import en from "./en.json";
import { addResourcesBundle } from "../../i18n";
import { useRootStoreContext } from "../../hooks";

import AppBar from "@mui/material/AppBar";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Tabs from "./Tabs";
import GeneralSettings from "./GeneralSettings";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  profileContainer: {
    overflowY: "auto",
    minHeight: 0,
  },
  tabsContainer: {
    borderBottom: `1px solid ${theme.palette.catskillWhite.main}`,
  },
  contentContainer: {
    boxShadow: "none",
  },
}));

const Profile: React.FC = observer(() => {
  const { users } = useRootStoreContext();
  const classes = useStyles();
  return (
    <AppBar
      position="sticky"
      color="inherit"
      className={classes.contentContainer}
    >
      <Grid container wrap="nowrap" className={classes.tabsContainer}>
        <Grid container item>
          <Tabs />
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" className={classes.profileContainer}>
        <GeneralSettings profile={users.profile} />
      </Grid>
    </AppBar>
  );
});

export default Profile;
