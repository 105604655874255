import React, { useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ITestStep } from "../../models";
import { ConfirmationModal } from "../Modals";
import { Menu, MenuItem, Divider } from "../Menus";
import StyledIconButton from "../StyledIconButton";
import { useRootStoreContext } from "../../hooks";
import TaIcon from "../TaIcon";
import { ReactComponent as UngroupIcon } from "../../icons/Ungroup.svg";
import { useHistory, useRouteMatch } from "react-router-dom";

interface IMenuForSharedStep {
  testStep: ITestStep;
  sharedMenu?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  controlButton: {
    padding: theme.spacing(1),
  },
  icon: {
    color: theme.palette.baliHai.main,
    marginRight: theme.spacing(1),
  },
}));

const MenuForSharedStep: React.FC<IMenuForSharedStep> = observer(
  ({ testStep, sharedMenu }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const classes = useStyles();

    const history = useHistory();
    const { path } = useRouteMatch();
    const { t } = useTranslation(["common", "test_steps"]);
    const { testSteps } = useRootStoreContext();

    const onMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setAnchorEl(e.currentTarget);
    };

    const onMenuClose = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      e.stopPropagation();
      setAnchorEl(null);
    };

    const onShowDeleteConfirm = () => {
      setShowConfirmationModal(true);
    };

    const onTestActionRemove = () => {
      testSteps.removeTestStep(testStep);
      setShowConfirmationModal(false);
      setAnchorEl(null);
    };

    const onDuplicateClick = async (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      e.stopPropagation();
      const isSharedStep = /shared-steps/.test(path);
      isSharedStep
        ? await testSteps.duplicateSharedStep([testStep])
        : await testSteps.duplicateAction(testStep);
      setAnchorEl(null);
    };

    const onUngroupClick = async (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      e.stopPropagation();
      await testSteps.ungroupSharedStep([testStep], testStep.StepId);
      setAnchorEl(null);
    };

    const backParam =
      testStep.SharedStepId === testStep.testId
        ? ""
        : `?backTestId=${testStep.testId}`;

    return (
      <>
        <StyledIconButton
          className={classes.controlButton}
          onClick={onMenuOpen}
        >
          <TaIcon iconName="MoreVertOutlined" />
        </StyledIconButton>
        <Menu anchorEl={anchorEl} onClose={onMenuClose}>
          <MenuItem
            onClick={() => {
              history.push(
                `/projects/${testStep.projectId}/shared-steps/suites/${testStep.suiteId}/shared-steps/${testStep.SharedStepId}/steps${backParam}`
              );
            }}
          >
            <TaIcon iconName="EditOutlined" />
            {t("common:edit")}
          </MenuItem>
          <MenuItem onClick={testStep.onEditSharedStep}>
            <TaIcon iconName="BorderColorOutlined" />
            {t("common:rename")}
          </MenuItem>
          <MenuItem onClick={onDuplicateClick}>
            <TaIcon iconName="ControlPointDuplicateOutlined" />
            {t("duplicate")}
          </MenuItem>
          <Divider />
          <MenuItem onClick={onUngroupClick}>
            <UngroupIcon className={classes.icon} />
            {sharedMenu ? t("common:shared_ungroup") : t("common:ungroup")}
          </MenuItem>
          <MenuItem onClick={onShowDeleteConfirm}>
            <TaIcon iconName="DeleteOutlined" />
            {t("common:shared_delete")}
          </MenuItem>
        </Menu>
        <ConfirmationModal
          isOpened={showConfirmationModal}
          text={t("common:confirm")}
          onConfirm={onTestActionRemove}
          onCancel={() => setShowConfirmationModal(false)}
        />
      </>
    );
  }
);

export default MenuForSharedStep;
