import { FC, useEffect } from "react";
import { observer } from "mobx-react";
import { map } from "lodash";
import { cast } from "mobx-state-tree";

import { Grid, List, Theme, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useRootStoreContext } from "../../hooks";
import { ITest } from "../../models/Test";
import TestStep from "../TestSteps/TestStep";
import EmptyListMessage from "../TestSteps/EmptyListMessage";

const useStyles = makeStyles((theme: Theme) => ({
  testSteps: {
    padding: 0,
    minHeight: 0,
    overflowY: "auto",
    flexGrow: 1,
  },
}));

interface ITestOverviewItem {
  test: ITest;
}

const TestOverview: FC<ITestOverviewItem> = observer(({ test }) => {
  const testId = test.id;
  const { testSteps } = useRootStoreContext();

  const classes = useStyles();
  const projectId = test!?.ProjectId;
  const suiteId = test!?.SuiteId;
  const report = test!?.Reports[0];

  useEffect(() => {
    if (projectId && suiteId && testId) {
      testSteps.load({
        projectId,
        suiteId,
        testId,
      });
    }
    return () => {
      testSteps.clearItems();
    };
  }, [testSteps, projectId, suiteId, testId]);

  const steps = testSteps.getCurrentSteps(testId);

  const isEmptyList = testSteps.state === "ready" && !steps.length;

  const isLoading = test?.testState !== "ready" || testSteps.state !== "ready";

  const displayReportStatus = ["passed", "failed"].includes(test.result);

  useEffect(() => {
    if (!isLoading && steps.length && !!report && displayReportStatus) {
      testSteps.setReportStatus(report);
    }
  }, [isLoading, testSteps, steps, report, displayReportStatus]);

  return (
    <List
      component={Grid}
      container
      direction="column"
      wrap="nowrap"
      className={classes.testSteps}
    >
      {steps.length
        ? map(steps, (testStep) => (
            <TestStep key={testStep.id} testStep={cast(testStep)} />
          ))
        : null}
      <Box>
        {isLoading ? "Loading..." : null}
        {isEmptyList ? <EmptyListMessage /> : null}
      </Box>
    </List>
  );
});

export default TestOverview;
