import { FC, useState } from "react";
import { observer } from "mobx-react";
import { Grid, MenuItem, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRootStoreContext } from "../../hooks";
import { Checkbox, TextField } from "../Inputs";
import StyledSelect from "../StyledSelect";
import { map } from "lodash";
import StyledButton from "../StyledButton";
import { useHistory, useParams } from "react-router-dom";

const styles = {
  container: {
    padding: "1.5rem",
    backgroundColor: "catskillLightWhite.main",
  },
};

const EnvironmentAddForm: FC = observer(() => {
  const { environments } = useRootStoreContext();
  const { t } = useTranslation(["environments"]);
  const { projectId } = useParams<{ projectId: string }>();
  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
  const environment = environments.newEnvironment;

  const onEnvironmentNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    environment?.setEnvironmentName(e.target.value);
  };

  const trimEnvironmentName = () => {
    environment?.setEnvironmentName(environment.name.trim());
  };

  const onEnvironmentSelectChange = (e: SelectChangeEvent<unknown>) => {
    environment?.setCopyValuesFrom(`${e.target.value}` as string);
  };

  const onCancel = () => {
    environments.onCancelEnvironmentAdd();
    setSubmitted(false);
  };

  const onSubmit = async () => {
    setSubmitted(true);
    if (!environment?.isValid) return;
    const newEnvironment = await environments.addEnvironment(projectId);
    if (!newEnvironment) return;
    history.push(
      `/projects/${projectId}/data/environments/${newEnvironment.id}/variables`
    );
    setSubmitted(false);
  };

  if (!environment) return null;

  return (
    <Grid container flexDirection="column" sx={styles.container}>
      <Grid item sx={{ mb: "0.5rem" }}>
        <TextField
          inputBaseProps={{
            placeholder: t("name"),
            fullWidth: true,
            onChange: onEnvironmentNameChange,
            onBlur: trimEnvironmentName,
            value: environment.name || "",
          }}
          errorText={(submitted && environment.error["name"]) || ""}
        />
      </Grid>
      <Grid item sx={{ ml: "-0.75rem", color: "mineShaft.main" }}>
        <Checkbox
          checked={!!environment.copyValue}
          onChange={environment.toggleCopyValue}
          label={t("copy_values")}
        />
      </Grid>
      {environment.copyValue ? (
        <Grid item sx={{ mb: "1rem" }}>
          <StyledSelect
            displayEmpty
            onChange={onEnvironmentSelectChange}
            value={environment.copyValuesFrom}
            labelText={t("environment")}
            errorText={(submitted && environment.error["copyValuesFrom"]) || ""}
          >
            <MenuItem disabled value="">
              {t("select_environment")}
            </MenuItem>
            {map(environments.items, (environment) => {
              return (
                <MenuItem key={environment.id} value={environment.id}>
                  {environment.name}
                </MenuItem>
              );
            })}
          </StyledSelect>
        </Grid>
      ) : null}
      <Grid item container wrap="nowrap">
        <Grid item>
          <StyledButton variant="outlined" color="primary" onClick={onCancel}>
            {t("common:cancel")}
          </StyledButton>
        </Grid>
        <Grid item sx={{ ml: "2rem" }}>
          <StyledButton variant="contained" color="primary" onClick={onSubmit}>
            {t("create_environment")}
          </StyledButton>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default EnvironmentAddForm;
