import React, { useState } from "react";
import { observer } from "mobx-react";
import {
  List,
  ListItem,
  Box,
  Typography,
  IconButton,
  Theme,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import { makeStyles, createStyles } from "@mui/styles";
import { IActionsItems, IAction } from "../../models";
import ActionItem from "./ActionItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      position: "relative",
      padding: theme.spacing(0, 0, 0, 1),
      height: 40,
      display: "flex",
      justifyContent: "space-between",
      "&:hover": {
        backgroundColor: theme.palette.catskillLightWhite.main,
        "& $expandCollapseIcon, $foldersIcon, $text": {
          color: theme.palette.outrageousOrange.main,
        },
      },
      "&.selected": {
        backgroundColor: theme.palette.catskillLightWhite.main,
      },
    },
    text: {
      marginLeft: theme.spacing(1),
      ...theme.typography.bodyTextStyle,
      color: theme.palette.mineShaft.main,
    },
    box: {
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
    },
    expandCollapseIcon: { padding: 0, color: theme.palette.baliHai.main },
    foldersIcon: {
      color: theme.palette.baliHai.main,
    },
    nested: {
      marginLeft: theme.spacing(3),
    },
  })
);

type IActionsItemsProps = {
  actionsItems: IActionsItems;
  selectable?: boolean;
  onClick: (e: React.MouseEvent, item: IAction) => void;
};

const ActionsItems: React.FC<IActionsItemsProps> = observer(
  ({ actionsItems, selectable, onClick }) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    if (actionsItems.groupKey === "default")
      return (
        <>
          {actionsItems.filteredItems.map((item: IAction) => (
            <ActionItem
              key={item.type}
              item={item}
              onClick={onClick}
              selectable={selectable}
            />
          ))}
        </>
      );

    return (
      <>
        <ListItem component="li" className={classes.listItem} button>
          <Box className={classes.box}>
            <IconButton
              className={classes.expandCollapseIcon}
              onClick={() => setIsOpen(!isOpen)}
              size="large"
            >
              {isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </IconButton>
            {isOpen ? (
              <FolderOpenOutlinedIcon className={classes.foldersIcon} />
            ) : (
              <FolderOutlinedIcon className={classes.foldersIcon} />
            )}
            <Typography className={classes.text} noWrap={true}>
              {actionsItems.name}
            </Typography>
          </Box>
        </ListItem>
        <List disablePadding className={classes.nested}>
          {isOpen
            ? actionsItems.filteredItems.map((item: IAction) => (
                <ActionItem
                  key={item.type}
                  item={item}
                  selectable={selectable}
                  onClick={onClick}
                />
              ))
            : null}
        </List>
      </>
    );
  }
);

export default ActionsItems;
