import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Modal } from ".";
import { useRootStoreContext } from "../../hooks";

interface IUpdateClient {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: () => void;
  latestVersion: string;
}

const UpdateClient: FC<IUpdateClient> = observer(
  ({ isOpen, onClose, onUpdate, latestVersion }) => {
    const { t } = useTranslation(["modals", "notifications"]);
    const initialState = latestVersion === "updating" ? "inProcess" : "initial";
    const [modalState, setModalState] = useState(initialState);
    const { notifications } = useRootStoreContext();

    useEffect(() => {
      window.TA_ON_VERION_UPDATE_FINISH = updateClientVersionFinished;
    });

    const downloadClientUrl = () => {
      const downloadsServerUrl = process.env.REACT_APP_CLIENT_DOWNLOAD_URL;
      return `${downloadsServerUrl}/${latestVersion}/mac-os/trueautomation.pkg`;
    };

    const updateClientVersionFinished = (isSuccessfull: boolean) => {
      setModalState(isSuccessfull ? "success" : "failed");
      notifications.addNotification({
        title: isSuccessfull
          ? t("notifications:clietnUpdate:successTitle", {
              version: latestVersion,
            })
          : t("notifications:clietnUpdate:errorTitle"),
        text: isSuccessfull
          ? t("notifications:clietnUpdate:successMessage", {
              version: latestVersion,
            })
          : t("notifications:clietnUpdate:errorMessage", {
              downloadUrl: downloadClientUrl(),
            }),
        type: isSuccessfull ? "success" : "error",
      });
    };

    const handleUpdate = () => {
      setModalState("inProcess");
      onUpdate();
    };

    const handleClose = () => {
      setModalState("initial");
      onClose();
    };

    const handleDownload = () => {
      if (latestVersion === "updating") return;

      window.open(downloadClientUrl(), "_blank");
    };

    const successModalActions = [
      {
        text: t("common:close"),
        onClick: handleClose,
      },
    ];
    const failedModalActions = [
      {
        text: t("common:retry"),
        onClick: handleUpdate,
        buttonType: "outlined",
      },
      {
        text: t("common:download"),
        onClick: handleDownload,
      },
    ];
    const initialModalActions = [
      {
        text: t("common:update"),
        onClick: handleUpdate,
      },
    ];
    const inProgressModalActions = [
      {
        text: t("common:updating"),
        disabled: true,
        loading: true,
      },
    ];

    const modalActions =
      (modalState === "success" && successModalActions) ||
      (modalState === "failed" && failedModalActions) ||
      (modalState === "inProcess" && inProgressModalActions) ||
      initialModalActions;

    const updatingModalText =
      (modalState === "success" && t("update_client.success")) ||
      (modalState === "failed" && t("update_client.failed")) ||
      (modalState === "inProcess" && t("update_client.in_progress")) ||
      t("update_client.description");
    const updatingModalTitle =
      modalState === "success"
        ? t("update_client.success_title")
        : modalState === "failed"
        ? t("update_client.failed_title")
        : t("update_client.title");

    return (
      <Modal
        title={updatingModalTitle}
        open={isOpen}
        onClose={onClose}
        modalActions={modalActions}
      >
        {updatingModalText}
      </Modal>
    );
  }
);

export default UpdateClient;
