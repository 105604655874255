import { FC, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { ArchiveOutlined } from "@mui/icons-material";
import { map } from "lodash";
import { IUser } from "../../models";
import { useRootStoreContext } from "../../hooks";
import { ConfirmationModal } from "../Modals";
import SelectMenuItem from "./SelectMenuItem";
import StyledButton from "./StyledButton";

const useStyles = makeStyles(() => ({
  attributionButton: {
    marginRight: "auto",
  },
}));

const DeactivatedUsersBulkMenu: FC = observer(() => {
  const { users } = useRootStoreContext();
  const { t } = useTranslation(["users"]);
  const classes = useStyles();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const onActivateConfirm = async () => {
    setShowConfirmationModal(false);
    const selectedIds = map<any, number>(
      users.getSelectedItems(users.deactivatedUsers),
      ({ id }: IUser) => id
    );

    await users.toggleUserLock({
      userIds: selectedIds,
      lockState: false,
    });
    await users.load();
  };

  return (
    <>
      <StyledButton
        onClick={() => setShowConfirmationModal(true)}
        startIcon={<ArchiveOutlined />}
        className={classes.attributionButton}
      >
        {t("activate")}
      </StyledButton>
      <SelectMenuItem
        selectedSize={users.getSelectedSize(users.deactivatedUsers)}
        onSelectAll={() => users.toAllsetSelect(users.deactivatedUsers, true)}
        onSelectNone={() => users.toAllsetSelect(users.deactivatedUsers, false)}
      />
      <ConfirmationModal
        isOpened={showConfirmationModal}
        text={t("common:confirm")}
        onConfirm={() => onActivateConfirm()}
        onCancel={() => setShowConfirmationModal(false)}
      />
    </>
  );
});

export default DeactivatedUsersBulkMenu;
