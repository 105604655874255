import { types, Instance } from "mobx-state-tree";

const ActionWindowSize = types
  .model("ActionWindowSize", {
    width: "",
    height: "",
  })
  .actions((self) => ({
    setWidth(width: string) {
      self.width = width;
    },
    setHeight(height: string) {
      self.height = height;
    },
  }));

export interface IActionWindowSize extends Instance<typeof ActionWindowSize> {}
export default ActionWindowSize;
