import { useContext } from "react";
import { LabelMenuContext } from "../contexts";

const useLabelMenuContext = () => {
  const store = useContext(LabelMenuContext);
  if (store === null) {
    throw new Error("Tests cannot be null, please add a context provider");
  }
  return store;
};

export default useLabelMenuContext;
