import React from "react";
import { isArray, map } from "lodash";
import { observer } from "mobx-react";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CloseOutlined } from "@mui/icons-material";
import StyledButton from "../StyledButton";
import { filterEntityType, filterStore } from ".";
import {
  getElementFilterLabels,
  getReportFilterLabels,
  getTestFilterLabels,
  getUserFilterLabels,
} from "./utils";

const useStyles = makeStyles((theme: Theme) => ({
  filterItem: {
    ...theme.typography.descriptionTextStyle,
    "& .MuiSvgIcon-root": {
      fontSize: 16,
    },
  },
  filterItemLabel: {
    color: theme.palette.doveGray.main,
  },
}));
interface IFilterItem {
  filterStore: filterStore;
  entityType: filterEntityType;
  value?: string | string[];
  filterKey: string;
}

const FilterItem: React.FC<IFilterItem> = observer(
  ({ filterStore, value, filterKey, entityType }) => {
    const classes = useStyles();

    const getFilterLabel = (key: string, filter?: string) => {
      switch (entityType) {
        case "reports":
          return getReportFilterLabels(key, filter);
        case "elements":
          return getElementFilterLabels(key, filter);
        case "users":
          return getUserFilterLabels(key, filter);
        case "tests":
          return getTestFilterLabels(key, filter);
        default:
          return "";
      }
    };

    const getFilterClearAction = (key: string, filter?: string) => {
      if (entityType === "elements" && "getElementsClearAction" in filterStore)
        return filterStore.getElementsClearAction(key, filter);

      if (entityType === "reports" && "getReportsClearAction" in filterStore)
        return filterStore.getReportsClearAction(key, filter);

      if (entityType === "users" && "getUsersClearAction" in filterStore)
        return filterStore.getUsersClearAction(key, filter);

      if (entityType === "tests" && "getTestsClearAction" in filterStore)
        return filterStore.getTestsClearAction(key, filter);

      return () => null;
    };

    const filters = isArray(value) ? value : [value];

    const items = map(filters, (filter) => {
      if (!filter) return null;

      const filterLabel = getFilterLabel(filterKey, filter);
      const filterClearAction = getFilterClearAction(filterKey, filter);

      return (
        <StyledButton
          key={`${filterKey}-${filter}`}
          className={classes.filterItem}
          classes={{ text: classes.filterItemLabel }}
          endIcon={<CloseOutlined />}
          onClick={filterClearAction}
        >
          {filterLabel}
        </StyledButton>
      );
    });
    return <>{items}</>;
  }
);

export default FilterItem;
