import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { makeStyles, createStyles } from "@mui/styles";
import { ListItem, Box, Typography, Theme } from "@mui/material";
import { IElement } from "../../models";
import Screenshot from "../Screenshot";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      padding: 0,
      display: "flex",
      "&:hover": {
        backgroundColor: theme.palette.catskillLightWhite.main,
        "& $text": {
          color: theme.palette.outrageousOrange.main,
        },
        cursor: "move",
      },
      "&.listItemDisabled": {
        pointerEvents: "none",
        opacity: 1,
        "& $screenshot": {
          opacity: 0.65,
        },
      },
    },

    text: {
      ...theme.typography.descriptionTextStyle,
      color: theme.palette.doveGray.main,
      wordBreak: "break-all",
    },
    box: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      padding: theme.spacing(1),
    },
    screenshot: {
      display: "flex",
      height: 95,
      marginBottom: theme.spacing(1),
    },
  })
);

interface IElementItem {
  element: IElement;
}

const Element: React.FC<IElementItem> = observer(({ element }) => {
  const classes = useStyles();

  const [{ isDragging }, drag, preview] = useDrag({
    type: "Element",
    item: {
      dragLayerText: element.name,
      ...element,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
      currentOffset: monitor.getSourceClientOffset(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  //ToDo: consider a different message when there are no items at all
  return (
    <ListItem
      ref={drag}
      component="li"
      classes={{ root: classes.listItem, disabled: "listItemDisabled" }}
      disabled={isDragging}
      button
    >
      <Box className={classes.box}>
        <Box className={classes.screenshot}>
          <Screenshot alt={element.name} src={element.screenshotUrl} />
        </Box>
        <Typography className={classes.text}>{element.name}</Typography>
      </Box>
    </ListItem>
  );
});

export default Element;
