import { FC, useState } from "react";
import { observer } from "mobx-react";
import { map } from "lodash";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { DashboardOutlined, AddCircleOutline } from "@mui/icons-material";
import { useRootStoreContext } from "../../hooks";
import { ReactComponent as AttributionIcon } from "../../icons/Attribution.svg";
import ProjectMenu from "../Users/ProjectMenu";
import SelectMenuItem from "./SelectMenuItem";
import StyledButton from "./StyledButton";

const useStyles = makeStyles(() => ({
  attributionButton: {
    marginRight: "auto",
  },
}));

const PendingUsersBulkMenu: FC = observer(() => {
  const { users } = useRootStoreContext();
  const selectedUsersIds = map<any, number>(
    users.getSelectedItems(users.pendingUsers),
    (user) => (user.role !== "admin" ? user.id : null)
  ).filter(Boolean);
  const { t } = useTranslation(["users"]);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onProjectMenuOpen = (anchor: null | HTMLElement) => {
    setAnchorEl(anchor);
  };

  const onProjectMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledButton
        onClick={() => console.log("on team edit")}
        startIcon={<AddCircleOutline />}
      >
        {t("teams")}
      </StyledButton>
      <StyledButton
        onClick={(e) => onProjectMenuOpen(e.currentTarget)}
        startIcon={<DashboardOutlined />}
      >
        {t("projects")}
      </StyledButton>
      <StyledButton
        onClick={() => console.log("on role change")}
        startIcon={<AttributionIcon />}
        className={classes.attributionButton}
      >
        {t("change_role")}
      </StyledButton>
      <SelectMenuItem
        selectedSize={users.getSelectedSize(users.pendingUsers)}
        onSelectAll={() => users.toAllsetSelect(users.pendingUsers, true)}
        onSelectNone={() => users.toAllsetSelect(users.pendingUsers, false)}
      />
      {anchorEl ? (
        <ProjectMenu
          anchorEl={anchorEl}
          userIds={selectedUsersIds}
          onClose={onProjectMenuClose}
        />
      ) : null}
    </>
  );
});

export default PendingUsersBulkMenu;
