import {
  Dispatch,
  FC,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { isArray, last, map } from "lodash";
import clsx from "clsx";
import { isEmail } from "../../models/utils";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Chip, Theme, Grid, Avatar, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  CancelOutlined,
  ClearOutlined,
  DoneOutlined,
} from "@mui/icons-material";
import { IProject } from "../../models";
import { TextField } from "../Inputs";
import TaIcon from "../TaIcon";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderRadius: 0,
    maxWidth: "21rem",
    marginTop: -1,
    border: `1px solid ${theme.palette.outrageousOrange.main}`,
  },
  listbox: {
    padding: 0,
  },
  optionContainer: {
    flex: "none",
    margin: "0.125rem 0",
    padding: "0.5rem 1rem",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.catskillLightWhite.main,
    },
  },
  userAvatar: {
    width: "2.5rem",
    height: "2.5rem",
    marginRight: theme.spacing(),
  },
  userName: {
    color: theme.palette.mineShaft.main,
    ...theme.typography.bodyTextStyle,
  },
  userEmail: {
    color: theme.palette.doveGray.main,
    ...theme.typography.descriptionTextStyle,
  },
  userEmailLg: {
    ...theme.typography.bodyTextStyle,
  },
  newOptionContainer: {
    padding: "1.125rem 1rem",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.catskillLightWhite.main,
    },
  },
  newOptionIcon: {
    color: theme.palette.rockBlue.main,
    marginRight: theme.spacing(),
  },
  newOptionText: {
    ...theme.typography.bodyTextStyle,
    color: theme.palette.mineShaft.main,
  },
  chipContainer: {
    background: "none",
    height: "1.5rem",
    margin: 0,
    color: theme.palette.mineShaft.main,
    ...theme.typography.bodyTextStyle,
  },
  chipDeleteIcon: {
    color: theme.palette.rockBlue.main,
    "&:hover": {
      color: theme.palette.outrageousOrange.main,
    },
  },
  chipError: {
    color: theme.palette.guardsmanRed.main,
    "& $chipDeleteIcon": {
      color: theme.palette.guardsmanRed.main,
    },
  },
  textField: {
    padding: theme.spacing(2, 3),
    width: "100%",
    minHeight: "4.5rem",
    height: "fit-content",
    position: "relative",
    backgroundColor: theme.palette.catskillLightWhite.main,
    borderLeft: `4px solid ${theme.palette.outrageousOrange.main}`,
  },
  inputBaseClass: {
    backgroundColor: theme.palette.white.main,
    minHeight: "2.5rem",
    height: "100%",
    padding: theme.spacing(1, 5),
  },
  buttonOutlined: {
    color: theme.palette.heather.main,
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.outrageousOrange.main,
    },
  },
  clearIconButtonContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: "2.5rem",
  },
  iconButtonClearOutlined: {
    left: "12px",
    padding: theme.spacing(1),
  },
  clearOutlinedIcon: {
    width: 24,
    height: 24,
  },
  doneIconButtonContainer: {
    position: "absolute",
    right: 0,
    top: 0,
    height: "100%",
    width: "2.5rem",
  },
  iconButtonDoneOutlined: {
    right: "12px",
    padding: theme.spacing(1),
  },
  doneOutlinedIcon: {
    width: 24,
    height: 24,
  },
}));

interface IAddUser {
  project: IProject;
  onSave: (items: any[], setItems: Dispatch<SetStateAction<any[]>>) => void;
  onClose: () => void;
}

const AddUser: FC<IAddUser> = observer(({ project, onSave, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation(["project_team"]);
  const [usersToInvite, setUsersToInvite] = useState<any[]>([]);
  const autoCompleteFilter = createFilterOptions<any>();

  useEffect(() => {
    project.initUsersToAdd();
  }, [project]);

  const getErrorText = () => {
    return !!usersToInvite.length &&
      usersToInvite.some((user) => user.type === "new" && !user.isValidEmail)
      ? t("invalid_email")
      : usersToInvite.some((user) => user.isDeactivated)
      ? t("deactivated_email_error")
      : "";
  };

  const onSaveClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (getErrorText()) return;
    onSave(usersToInvite, setUsersToInvite);
  };

  const onCancelClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    setUsersToInvite([]);
    onClose();
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      autoHighlight
      filterSelectedOptions
      classes={{
        paper: classes.paper,
        listbox: classes.listbox,
      }}
      value={usersToInvite}
      options={project.usersToAdd}
      onChange={(_event, newValue) => {
        if (typeof last(newValue) === "string")
          newValue.splice(-1, 1, {
            type: "new",
            role: "user",
            isValidEmail: isEmail(last(newValue)),
            email: last(newValue),
          });
        setUsersToInvite(newValue as any[]);
      }}
      filterOptions={(options, params) => {
        const filtered = autoCompleteFilter(options, params);

        if (params.inputValue !== "") {
          filtered.push({
            type: "new",
            role: "user",
            isValidEmail: isEmail(params.inputValue),
            email: params.inputValue,
          });
        }

        return filtered;
      }}
      getOptionLabel={(option) => {
        return option.firstLastName || option.email;
      }}
      renderOption={(props, option) => {
        const { firstLastName, email, type } = option;

        if (type === "new") {
          return (
            <li {...props} className={classes.newOptionContainer}>
              <Grid item container alignItems="center" wrap="nowrap">
                <TaIcon
                  iconName="AddBoxOutlined"
                  className={classes.newOptionIcon}
                />
                <Grid item className={classes.newOptionText}>
                  {`Add new member "${option.email}" via email`}
                </Grid>
              </Grid>
            </li>
          );
        }

        return (
          <li {...props} className={classes.optionContainer}>
            <Grid item container wrap="nowrap">
              <Grid item>
                <Avatar className={classes.userAvatar}>
                  {firstLastName?.charAt(0)}
                </Avatar>
              </Grid>
              <Grid item container direction="column" justifyContent="center">
                {firstLastName ? (
                  <Grid item className={classes.userName}>
                    {firstLastName}
                  </Grid>
                ) : null}
                <Grid
                  item
                  className={clsx(classes.userEmail, {
                    [classes.userEmailLg]: !firstLastName,
                  })}
                >
                  {email}
                </Grid>
              </Grid>
            </Grid>
          </li>
        );
      }}
      renderTags={(value, getTagProps) => {
        return map(value, (option, index) => {
          const label = option.firstLastName || option.email;
          return (
            <Chip
              classes={{
                root: clsx(classes.chipContainer, {
                  [classes.chipError]:
                    (option.type === "new" && !option.isValidEmail) ||
                    option.isDeactivated,
                }),
                deleteIcon: classes.chipDeleteIcon,
              }}
              label={label}
              deleteIcon={<CancelOutlined />}
              {...getTagProps({ index })}
            />
          );
        });
      }}
      renderInput={(params) => {
        const startEdorment = (
          <Grid
            container
            alignItems="center"
            key="autocomplete_start_edorment"
            className={classes.clearIconButtonContainer}
          >
            <IconButton
              edge="start"
              className={clsx(
                classes.iconButtonClearOutlined,
                classes.buttonOutlined
              )}
              onClick={onCancelClick}
              size="large"
            >
              <ClearOutlined className={classes.clearOutlinedIcon} />
            </IconButton>
          </Grid>
        );
        if (isArray(params.InputProps.startAdornment)) {
          params.InputProps.startAdornment.unshift(startEdorment);
        } else {
          params.InputProps.startAdornment = startEdorment;
        }

        params.InputProps.endAdornment = (
          <Grid
            container
            alignItems="center"
            className={classes.doneIconButtonContainer}
          >
            <IconButton
              edge="end"
              className={clsx(
                classes.iconButtonDoneOutlined,
                classes.buttonOutlined
              )}
              onClick={onSaveClick}
              disabled={usersToInvite.length === 0}
              size="large"
            >
              <DoneOutlined className={classes.doneOutlinedIcon} />
            </IconButton>
          </Grid>
        );

        return (
          <TextField
            {...params}
            {...params.InputProps}
            noStartAdornmentWrap
            errorText={getErrorText()}
            boxClassName={classes.textField}
            inputBaseProps={{
              fullWidth: true,
              className: clsx(
                classes.inputBaseClass,
                params.InputProps.className
              ),
            }}
          />
        );
      }}
    />
  );
});

export default AddUser;
