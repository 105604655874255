import { FC, ReactNode } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  rowLink: {
    textDecoration: "none",
  },
}));

interface ITableRowLinkWrapper {
  link?: string;
  children?: ReactNode;
}

const TableRowLinkWrapper: FC<ITableRowLinkWrapper> = observer(
  ({ link, children }) => {
    const classes = useStyles();
    return link ? (
      <Link to={link} className={classes.rowLink}>
        {children}
      </Link>
    ) : (
      <>{children}</>
    );
  }
);

export default TableRowLinkWrapper;
