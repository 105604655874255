import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as SearchNothingIcon } from "../icons/SearchNothing.svg";
import TaIcon from "../components/TaIcon";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    alignItems: "center",
    height: 24,
    marginRight: theme.spacing(1),
  },
  text: {
    ...theme.typography.bodyTextStyle,
    color: theme.palette.doveGray.main,
  },
  emptyIcon: {
    color: theme.palette.rockBlue.main,
  },
}));

interface INoSearchResult {
  isEmpty?: boolean;
  description?: string;
}

const NoSearchResult: FC<INoSearchResult> = ({ isEmpty, description }) => {
  const classes = useStyles();
  const { t } = useTranslation(["common"]);
  return (
    <Grid container alignItems="center" wrap="nowrap">
      <Grid item className={classes.icon}>
        {isEmpty ? (
          <TaIcon iconName="InfoOutlined" className={classes.emptyIcon} />
        ) : (
          <SearchNothingIcon />
        )}
      </Grid>
      <Grid item className={classes.text}>
        {isEmpty ? description : t("no_search_results")}
      </Grid>
    </Grid>
  );
};

export default NoSearchResult;
