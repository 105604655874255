import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import clsx from "clsx";
import { addResourcesBundle } from "../../i18n";
import { ITestStep } from "../../models";
import { IActionType } from "../../models/utils";
import en from "./en.json";
import ActionSelect from "./ActionSelect";
import ActionBody from "./ActionBody";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  actionContainer: {
    width: 416,
    minWidth: 416,
    margin: theme.spacing(3, 0),
  },
  actionSelect: {
    marginBottom: theme.spacing(4),
  },
  error: {
    ...theme.typography.descriptionTextStyle,
    color: theme.palette.error.main,
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(3),
  },
}));

interface IStepActions {
  testStep: ITestStep;
}

// ToDo: add testStep context
const StepActions: React.FC<IStepActions> = observer(({ testStep }) => {
  const classes = useStyles();

  const onActionSelect = (type: IActionType) => {
    if (type === testStep.type) return;
    testStep.setType(type);
    testStep.save();
  };

  return (
    <Grid container wrap="nowrap">
      <Grid
        item
        container
        direction="column"
        className={clsx({
          [classes.actionContainer]: testStep.type !== "sharedAction",
        })}
      >
        {testStep.type !== "sharedAction" ? (
          <Grid item className={classes.actionSelect}>
            <ActionSelect
              actionType={testStep.type}
              onChange={onActionSelect}
              error={!!testStep.reportErrorMessage}
            />
          </Grid>
        ) : null}
        <ActionBody testStep={testStep} />
      </Grid>
      {testStep.reportErrorMessage && testStep.type !== "sharedAction" ? (
        <Grid item className={classes.error}>
          {testStep.reportErrorMessage}
        </Grid>
      ) : null}
    </Grid>
  );
});

export default StepActions;
