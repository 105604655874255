import React, { useState, useEffect } from "react";
import { Grid, Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CancelOutlined } from "@mui/icons-material";
import clsx from "clsx";

interface INotificationBar {
  message?: string;
  type?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: theme.spacing(5),
    color: theme.palette.white.main,
    backgroundColor: theme.palette.baliHai.main,
  },
  containerWarning: {
    backgroundColor: theme.palette.outrageousOrange.main,
  },
  notificationText: {
    ...theme.typography.subHeaderTextStyle,
    padding: theme.spacing(0, 8),
    cursor: "default",
  },
  closeIcon: {
    position: "absolute",
    right: 37,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
}));

const NotificationBar: React.FC<INotificationBar> = (props) => {
  const classes = useStyles();
  const { message, type } = props;
  const [isShown, setIsShown] = useState(!!message);

  useEffect(() => {
    if (message) setIsShown(true);
  }, [message]);

  if (!isShown) return null;

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={clsx(
        classes.container,
        type === "warning" && classes.containerWarning
      )}
    >
      <Grid item className={classes.notificationText}>
        {message}
      </Grid>
      <Box className={classes.closeIcon}>
        <CancelOutlined onClick={() => setIsShown(false)} />
      </Box>
    </Grid>
  );
};

export default NotificationBar;
