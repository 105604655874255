import { FC } from "react";
import clsx from "clsx";
import { map } from "lodash";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import TaIcon from "../TaIcon";
import StyledIconButton from "../StyledIconButton";
import TableCellMenu, { ITableCellMenuItem } from "./TableCellMenu";

const useStyles = makeStyles((theme: Theme) => ({
  menuContainer: {
    top: 0,
    right: 0,
    width: "auto",
    height: "100%",
    position: "absolute",
    visibility: "hidden",
    backgroundColor: theme.palette.catskillLightWhite.main,
  },
  menuButton: {
    padding: 0,
    "&:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
}));

interface ITableRowHoverMenu {
  menuItems?: ITableCellMenuItem[];
  hoverMenuItems?: ITableCellMenuItem[];
}

const TableRowHoverMenu: FC<ITableRowHoverMenu> = observer(
  ({ hoverMenuItems, menuItems }) => {
    const classes = useStyles();
    return (
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        className={clsx("visibleOnHoverContent", classes.menuContainer)}
      >
        {map(hoverMenuItems, (hoverMenuItem, i) => {
          const { onClick, iconName, isMenu } = hoverMenuItem;
          if (isMenu)
            return (
              <TableCellMenu
                key={`hover_menu_item_${i}`}
                menuItems={menuItems}
              />
            );
          return iconName ? (
            <StyledIconButton
              key={`hover_menu_item_${i}`}
              className={classes.menuButton}
              onClick={onClick}
            >
              <TaIcon iconName={iconName} />
            </StyledIconButton>
          ) : null;
        })}
      </Grid>
    );
  }
);

export default TableRowHoverMenu;
