import React, { useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import {
  MoreVertOutlined,
  LabelOutlined,
  AccessTimeOutlined,
  TimelineOutlined,
  DeleteOutlined,
  ControlPointDuplicateOutlined,
} from "@mui/icons-material";
import { ITest } from "../../models";
import { Menu, MenuItem, Divider } from "../Menus";
import { useRootStoreContext } from "../../hooks";
import StyledIconButton from "../StyledIconButton";
import { ConfirmationModal } from "../Modals";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as UngroupIcon } from "../../icons/Ungroup.svg";

interface IMenuForItem {
  test: ITest;
  isSingleItem?: boolean;
  onLabelMenuOpen: (e: null | HTMLElement) => void | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.baliHai.main,
    marginRight: theme.spacing(1),
  },
}));

const MenuForItem: React.FC<IMenuForItem> = observer(
  ({ onLabelMenuOpen, test, isSingleItem }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { path } = useRouteMatch();
    const classes = useStyles();

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const backTestId = searchParams.get("backTestId") || "";

    const isSharedStep = /shared-steps/.test(path);

    const onShowDeleteConfirm = () => {
      setShowConfirmationModal(true);
    };

    const { t } = useTranslation(["common"]);
    const { tests, testFilters } = useRootStoreContext();
    const history = useHistory();

    const onMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setAnchorEl(e.currentTarget);
    };

    const onMenuClose = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      e.stopPropagation();
      setAnchorEl(null);
    };

    const onAddLabelClick = (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      e.stopPropagation();
      onLabelMenuOpen(anchorEl);
      setAnchorEl(null);
    };

    const onTestRemove = () => {
      tests.removeTest(test);

      if (isSingleItem) {
        let redirectPath = `/projects/${test.ProjectId}/tests/suites/${test.SuiteId}/tests${testFilters.filterUrl}`;
        if (isSharedStep)
          redirectPath = `/projects/${test.ProjectId}/shared-steps`;
        if (isSharedStep && backTestId)
          redirectPath = `/projects/${test.ProjectId}/tests/suites/${test.SuiteId}/tests/${backTestId}/steps`;

        history.push(redirectPath);
      }

      setShowConfirmationModal(false);
      setAnchorEl(null);
    };

    const onDuplicateClick = async (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      e.stopPropagation();
      const duplicatedTest = await tests.duplicateTest(test);

      if (isSingleItem) {
        history.push(
          `/projects/${test.ProjectId}/tests/suites/${test.SuiteId}/tests/${duplicatedTest.id}/steps`
        );
      }

      setAnchorEl(null);
    };

    const onUngroupClick = async (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      e.stopPropagation();
      await test.onUngroupSharedStep();
      setAnchorEl(null);
      let redirectPath = `/projects/${test.ProjectId}/shared-steps`;
      if (isSharedStep && backTestId)
        redirectPath = `/projects/${test.ProjectId}/tests/suites/${test.SuiteId}/tests/${backTestId}/steps`;

      history.push(redirectPath);
    };

    const onReportsClick = () => {
      history.push(`/projects/${test.ProjectId}/reports/tests/${test.id}`);
    };

    return (
      <>
        <StyledIconButton onClick={onMenuOpen}>
          <MoreVertOutlined />
        </StyledIconButton>
        <Menu anchorEl={anchorEl} onClose={onMenuClose}>
          <MenuItem onClick={onDuplicateClick}>
            <ControlPointDuplicateOutlined />
            {t("duplicate")}
          </MenuItem>
          {isSharedStep ? null : (
            <MenuItem onClick={onAddLabelClick}>
              <LabelOutlined />
              {t("menu_label")}
            </MenuItem>
          )}
          {isSharedStep ? null : (
            <MenuItem>
              <AccessTimeOutlined />
              {t("schedule")}
            </MenuItem>
          )}
          {isSharedStep ? null : (
            <MenuItem onClick={onReportsClick}>
              <TimelineOutlined />
              {t("reports")}
            </MenuItem>
          )}
          <Divider />
          {isSharedStep ? (
            <MenuItem onClick={onUngroupClick}>
              <UngroupIcon className={classes.icon} />
              {t("common:shared_ungroup")}
            </MenuItem>
          ) : null}
          <MenuItem onClick={onShowDeleteConfirm}>
            <DeleteOutlined />
            {isSharedStep ? t("common:shared_delete") : t("common:delete")}
          </MenuItem>
          <ConfirmationModal
            isOpened={showConfirmationModal}
            text={t("common:confirm")}
            onConfirm={onTestRemove}
            onCancel={() => setShowConfirmationModal(false)}
          />
        </Menu>
      </>
    );
  }
);

export default MenuForItem;
