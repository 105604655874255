import {
  FC,
  useEffect,
  createRef,
  useState,
  useRef,
  useCallback,
  ReactNode,
} from "react";
import {
  addMiddleware,
  createActionTrackingMiddleware2,
  destroy,
} from "mobx-state-tree";
import _ from "lodash";
import clsx from "clsx";
import { observer } from "mobx-react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles, createStyles } from "@mui/styles";
import {
  Box,
  ListItem,
  IconButton,
  Typography,
  List,
  Theme,
} from "@mui/material";
import { ISuite, Tests } from "../../models";
import { StyledTooltip } from "../../components";
import { LabelMenuContext } from "../../contexts";
import { useRootStoreContext } from "../../hooks";
import TaIcon from "../TaIcon";
import LabelMenu from "../LabelMenu";
import GroupFormTextArea from "./GroupFormTextArea";
import MenuForItemOfNestedTests from "./MenuForItemOfNestedTests";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      position: "relative",
      padding: theme.spacing(0, 1),
      margin: theme.spacing(0, 0, 1, 0),
      height: 40,
      display: "flex",
      justifyContent: "space-between",
      "&.Mui-focusVisible": {
        backgroundColor: "transparent",
      },
      "&:hover": {
        backgroundColor: "transparent",
        "& $itemWrapper": {
          backgroundColor: theme.palette.catskillLightWhite.main,
        },
        "& $expandCollapseIcon, $foldersIcon, $text": {
          color: theme.palette.outrageousOrange.main,
        },
        "& $actionsBox": {
          visibility: "visible",
        },
        cursor: "pointer",
      },
      "&.listItemDisabled": {
        opacity: 1,
        "& $text, & $number": {
          color: theme.palette.dustyGray.main,
        },
        "& $expandCollapseIcon, & $foldersIcon": {
          color: theme.palette.geyser.main,
        },
      },
    },
    defaultSuiteListItem: {
      "&:hover": {
        cursor: "pointer",
      },
      "& $text": {
        ...theme.typography.subHeaderTextStyle,
      },
    },
    activeListItem: {
      "& $itemWrapper": {
        backgroundColor: theme.palette.catskillLightWhite.main,
      },
    },
    text: {
      marginLeft: theme.spacing(1),
      ...theme.typography.bodyTextStyle,
      color: theme.palette.mineShaft.main,
    },
    box: {
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
    },
    numberBox: {
      display: "flex",
      alignItems: "center",
      paddingRight: theme.spacing(1),
    },
    expandCollapseIcon: { padding: 0, color: theme.palette.baliHai.main },
    withEmptyColumn: { marginLeft: theme.spacing(3) },
    foldersIcon: {
      color: theme.palette.baliHai.main,
    },
    number: {
      ...theme.typography.descriptionTextStyle,
      color: theme.palette.doveGray.main,
    },
    actionsBox: {
      right: 0,
      position: "absolute",
      display: "flex",
      visibility: "hidden",
    },
    actionsButtonBox: {
      backgroundColor: theme.palette.catskillLightWhite.main,
      alignItems: "center",
      display: "flex",
      justifyContent: "flex-end",
    },
    actionsGradientBox: {
      width: 40,
      height: 40,
      background: `linear-gradient(90deg, ${theme.palette.catskillLightWhite.main}00 0%, ${theme.palette.catskillLightWhite.main} 100%)`,
    },
    nestedItem: {
      width: (props: { level: number }) => {
        const level = props.level === 0 ? 0 : props.level - 1;
        return `calc(100% - calc(${theme.spacing(3 * level)} + 4px))`;
      },
      marginLeft: (props: { level: number }) => {
        const level = props.level === 0 ? 0 : props.level - 1;
        return theme.spacing(3 * level);
      },
    },
    itemWrapper: {
      position: "relative",
      display: "flex",
      width: "100%",
      height: 40,
      justifyContent: "space-between",
      boxSizing: "initial",
      border: "2px solid transparent",
      "&.draggingOver": {
        backgroundColor: theme.palette.catskillLightWhite.main,
        borderColor: theme.palette.outrageousOrange.main,
      },
    },
    actionsIcons: {
      color: theme.palette.baliHai.main,
      padding: theme.spacing(1),
      marginRight: theme.spacing(1),
      "&:last-child": {
        marginRight: theme.spacing(0),
      },
      "&:hover": {
        color: theme.palette.outrageousOrange.main,
      },
    },
  })
);

interface IDragItem {
  type: string;
  suiteId: string;
  suiteName: string;
  childDeepness: number;
  testIds?: string[];
}

interface IItemOfNestedTests {
  suite: ISuite;
  nestedLevel: number;
  children?: ReactNode;
}

const ItemOfNestedTests: FC<IItemOfNestedTests> = observer(
  ({ children, suite, nestedLevel }) => {
    const classes = useStyles({ level: nestedLevel });
    const { suites, labels, tests } = useRootStoreContext();
    const { projectId = "", suiteId = "" } = useParams<{
      projectId: string;
      suiteId: string;
    }>();
    const history = useHistory();
    const timeoutRef = useRef<number | undefined>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const nestedRef = createRef<HTMLUListElement>();
    const textContainerRef = createRef<HTMLElement>();

    const handleIsOpenClick = (event: React.SyntheticEvent) => {
      event.stopPropagation();
      suite.toggleOpened();
    };

    const withCreationForm = suites.newSuite?.parentId === suite.id;

    const handleIsActiveClick = () => {
      history.push(`/projects/${projectId}/tests/suites/${suite.id}/tests`);
    };

    const onLabelMenuOpen = (anchor: null | HTMLElement) => {
      labels.loadSuiteLabels({ projectId, suiteId: suite.id });
      setAnchorEl(anchor);
    };

    const onLabelMenuClose = () => {
      labels.onMenuClose();
      setAnchorEl(null);
    };

    const canAcceptSuite = useCallback(
      (item: IDragItem) => {
        return (
          nestedLevel + item.childDeepness < 4 &&
          !suite.isChildOf(item.suiteId) &&
          suite.id !== item.suiteId
        );
      },
      [suite, nestedLevel]
    );

    const onMoveTests = async (dragItem: IDragItem) => {
      await tests.moveTests(projectId, suite.id, dragItem.testIds);

      await tests.load({
        projectId: parseInt(projectId, 10),
        suiteId,
      });
      const currentSuite = _.find(suites.items, { id: suiteId });
      currentSuite?.setTestCount(tests.items.length);

      const tmpTests = Tests.create();
      await tmpTests.load({
        projectId: parseInt(projectId, 10),
        suiteId: suite.id,
      });
      suite.setTestCount(tmpTests.items.length);
      destroy(tmpTests);
    };

    const [{ isDragging }, drag, preview] = useDrag({
      type: "Suite",
      item: {
        suiteId: suite.id,
        suiteName: suite.name,
        dragLayerText: suite.name,
        childDeepness: 0,
        begin: () => {
          return {
            type: "Suite",
            suiteId: suite.id,
            suiteName: suite.name,
            dragLayerText: suite.name,
            childDeepness: suite.childDeepness(),
          };
        },
      },
      canDrag: () => !suite.defaultSuite,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
        currentOffset: monitor.getSourceClientOffset(),
      }),
    });

    const [{ isOver, canDrop }, drop] = useDrop({
      accept: ["Suite", "Test"],
      canDrop: (item: IDragItem) =>
        item.type === "Test" || canAcceptSuite(item),
      drop: (item, monitor) => {
        if (!monitor.didDrop()) {
          if (item.type === "Test") {
            onMoveTests(item);
          } else {
            suites.onChangePosition(item.suiteId, suite.id);
          }
          return item;
        }
        return undefined;
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver({ shallow: true }),
        canDrop: !!monitor.canDrop(),
      }),
    });

    useEffect(() => {
      const scrollWidth = textContainerRef.current?.scrollWidth || 0;
      const offsetWidth = textContainerRef.current?.offsetWidth || 0;
      setShowTooltip(scrollWidth > offsetWidth);
      if (suiteId === suite.id) {
        suites.setCanAddSuite(nestedLevel < 4);
      }
    }, [suite.id, suites, suiteId, nestedLevel, textContainerRef]);

    useEffect(() => {
      if (isOver && !suite.isOpened) {
        if (timeoutRef.current) return;
        timeoutRef.current = _.delay(suite.toggleOpened, 500);
      } else if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
      }
    }, [isOver, suite]);

    useEffect(() => {
      preview(getEmptyImage());
    }, [preview]);

    useEffect(() => {
      if (suite.id !== suiteId) return;
      return addMiddleware(
        tests,
        createActionTrackingMiddleware2({
          filter(call) {
            return [
              "addNewTest",
              "duplicateTest",
              "duplicateTests",
              "removeTest",
              "deleteTests",
            ].includes(call.name);
          },
          onStart() {},
          onFinish(call, error) {
            if (!error) suite.setTestCount(tests.items.length);
          },
        })
      );
    }, [suite, suiteId, tests]);

    const suiteElementRender = (suite: ISuite) => (
      <ListItem
        ref={drop}
        data-suite-id={suite.id}
        data-parent-id={suite.parentId}
        component="li"
        disabled={suite.suiteState === "loading" || isDragging}
        className={clsx(
          classes.listItem,
          suite.defaultSuite && classes.defaultSuiteListItem,
          suite.id === suiteId && classes.activeListItem
        )}
        button
        disableRipple
        onClick={handleIsActiveClick}
        classes={{
          root: "listItemRoot",
          disabled: "listItemDisabled",
        }}
      >
        <Box
          {...{ ref: drag }}
          className={clsx(classes.itemWrapper, classes.nestedItem, {
            draggingOver: isOver && canDrop,
          })}
        >
          <Box
            className={clsx(
              classes.box,
              _.isEmpty(suite.suites) && classes.withEmptyColumn
            )}
          >
            {!_.isEmpty(suite.suites) || withCreationForm ? (
              <IconButton
                className={classes.expandCollapseIcon}
                onClick={handleIsOpenClick}
                size="large"
              >
                {suite.isOpened ? (
                  <TaIcon iconName="ArrowDropDown" />
                ) : (
                  <TaIcon iconName="ArrowRight" />
                )}
              </IconButton>
            ) : null}

            {suite.isOpened
              ? !suite.defaultSuite && (
                  <TaIcon
                    iconName="FolderOpenOutlined"
                    className={classes.foldersIcon}
                  />
                )
              : !suite.defaultSuite && (
                  <TaIcon
                    iconName="FolderOutlined"
                    className={classes.foldersIcon}
                  />
                )}
            <Typography
              className={classes.text}
              noWrap={true}
              ref={textContainerRef}
            >
              {suite.name}
            </Typography>
          </Box>
          <Box className={classes.numberBox}>
            {suite.id && (
              <Typography className={classes.number}>
                {suite.TestCount}
              </Typography>
            )}
          </Box>
          <Box className={classes.actionsBox}>
            <Box className={classes.actionsGradientBox}></Box>
            <Box className={classes.actionsButtonBox}>
              {!suite.defaultSuite ? (
                <IconButton
                  className={classes.actionsIcons}
                  onClick={suite.onEditClick}
                  size="large"
                >
                  <TaIcon iconName="BorderColorOutlined" />
                </IconButton>
              ) : null}
              <MenuForItemOfNestedTests
                suite={suite}
                onLabelMenuOpen={onLabelMenuOpen}
              />
            </Box>
          </Box>
          <LabelMenuContext.Provider value={{ testIds: [], suiteId: suite.id }}>
            <LabelMenu
              anchorEl={anchorEl}
              onClose={onLabelMenuClose}
              currentLabels={labels.currentItems}
            />
          </LabelMenuContext.Provider>
        </Box>
      </ListItem>
    );

    const renderSuiteForm = (suite: ISuite) => (
      <GroupFormTextArea suite={suite} />
    );

    const elementRenderWrapper = (suite: ISuite) => {
      return (
        <StyledTooltip
          disableHoverListener={!showTooltip}
          title={suite.name}
          type="default"
          placement={"right-start"}
        >
          {suiteElementRender(suite)}
        </StyledTooltip>
      );
    };

    return (
      <>
        {suites.editSuite?.id === suite.id
          ? renderSuiteForm(suites.editSuite)
          : elementRenderWrapper(suite)}

        <List disablePadding ref={nestedRef} data-id={suite.id}>
          {suite.isOpened ? children : null}
        </List>
      </>
    );
  }
);

export default ItemOfNestedTests;
