import React from "react";
import _ from "lodash";
import { observer } from "mobx-react";
import { List, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { IActions, IAction, IActionsItems } from "../../models";
import NoSearchResult from "../NoSearchResult";
import ActionsItems from "./ActionsItems";
import { IActionType } from "../../models/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootBox: {
      overflowY: "auto",
      margin: theme.spacing(2, 0, 0, 3),
      paddingRight: theme.spacing(3),
    },
  })
);

interface IActionList {
  selectable?: boolean;
  actions: IActions;
  onMenuItemSelect?: (e: React.MouseEvent, type: IActionType) => void;
}

const ActionsList: React.FC<IActionList> = observer(
  ({ selectable, actions, onMenuItemSelect }) => {
    const classes = useStyles();

    const onSelect = (e: React.MouseEvent, item: IAction) => {
      if (!selectable) return;
      e.stopPropagation();
      onMenuItemSelect && onMenuItemSelect(e, item.type);
    };

    //ToDo: consider a different message when there are no items at all
    return (
      <List disablePadding className={classes.rootBox}>
        {!actions.filteredItemsLength ? (
          <NoSearchResult />
        ) : (
          _.map(actions.filteredItems, (actionsItems: IActionsItems) =>
            actionsItems.filteredItems.length ? (
              <ActionsItems
                key={actionsItems.groupKey}
                actionsItems={actionsItems}
                onClick={onSelect}
                selectable={selectable}
              />
            ) : null
          )
        )}
      </List>
    );
  }
);

export default ActionsList;
