import React from "react";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { Box, Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  menuItemContainer: {
    position: "relative",
  },
  menuItemLink: {
    flex: 1,
    height: 40,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: theme.spacing(2, 3),
    textDecoration: "none",
    color: theme.palette.mineShaft.main,
    ...theme.typography.bodyTextStyle,
    "&:hover": {
      color: theme.palette.outrageousOrange.main,
      backgroundColor: theme.palette.catskillLightWhite.main,
    },
    "&:hover $menuItemIcon": {
      color: theme.palette.outrageousOrange.main,
    },
  },
  menuItemIcon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
    color: theme.palette.doveGray.main,
    "&.active": {
      color: theme.palette.outrageousOrange.main,
    },
  },
  activeLine: {
    width: 2,
    height: theme.spacing(3),
    position: "absolute",
    margin: "auto",
    left: 0,
    backgroundColor: theme.palette.outrageousOrange.main,
  },
}));

interface IMenuItem {
  label: string;
  link?: string;
  icon?: React.ReactNode;
  onClick?: () => void;
}

const MenuItem: React.FC<IMenuItem> = ({ label, link, icon, onClick }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const active = pathname.includes(link);

  return (
    <Grid container alignItems="center" className={classes.menuItemContainer}>
      {active ? <Box className={classes.activeLine}></Box> : null}
      {onClick ? (
        <Grid item className={classes.menuItemLink} onClick={onClick}>
          {icon ? (
            <Grid item className={clsx(classes.menuItemIcon, { active })}>
              {icon}
            </Grid>
          ) : null}
          {label}
        </Grid>
      ) : (
        <Link className={classes.menuItemLink} to={link}>
          {icon ? (
            <Grid item className={clsx(classes.menuItemIcon, { active })}>
              {icon}
            </Grid>
          ) : null}
          {label}
        </Link>
      )}
    </Grid>
  );
};

export default MenuItem;
