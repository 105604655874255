import React, { useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { addResourcesBundle } from "../../i18n";
import { IListFilters, IFilterAuthors } from "../../models";
import { Menu, MenuItem } from "../Menus";
import en from "./en.json";
import AuthorFilter from "./AuthorFilter";
import LabelFilter from "./LabelFilter";
import { TextFilter, PeriodFilter } from "../Filters";

addResourcesBundle({ en });

interface IFilterMenu {
  anchorEl: null | HTMLElement;
  onMenuClose: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  filterStore: IListFilters;
  authorStore: IFilterAuthors;
  isSharedStep?: boolean;
}

const FilterMenu: React.FC<IFilterMenu> = observer(
  ({
    anchorEl,
    onMenuClose,
    filterStore,
    authorStore,
    isSharedStep = false,
  }) => {
    const { t } = useTranslation(["test_filters"]);

    const [nameFilterAnchor, setNameFilterAnchor] =
      useState<null | HTMLElement>(null);

    const [creatorFilterAnchor, setCreatorFilterAnchor] =
      useState<null | HTMLElement>(null);

    const [updaterFilterAnchor, setUpdaterFilterAnchor] =
      useState<null | HTMLElement>(null);

    const [periodFilterAnchor, setPeriodFilterAnchor] =
      useState<null | HTMLElement>(null);

    const [labelFilterAnchor, setLabelFilterAnchor] =
      useState<null | HTMLElement>(null);

    const onNameFilterClick = (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      setNameFilterAnchor(anchorEl);
      onMenuClose(e);
    };

    const onCreatorFilterClick = (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      setCreatorFilterAnchor(anchorEl);
      onMenuClose(e);
    };

    const onUpdaterFilterClick = (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      setUpdaterFilterAnchor(anchorEl);
      onMenuClose(e);
    };

    const onPeriodFilterClick = (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      setPeriodFilterAnchor(anchorEl);
      onMenuClose(e);
    };

    const onLabelFilterClick = (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      setLabelFilterAnchor(anchorEl);
      onMenuClose(e);
    };

    return (
      <>
        <Menu
          anchorEl={anchorEl}
          onClose={onMenuClose}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MenuItem onClick={onNameFilterClick}>{t("name")}</MenuItem>
          <MenuItem onClick={onCreatorFilterClick}>{t("author")}</MenuItem>
          <MenuItem onClick={onUpdaterFilterClick}>{t("last_editor")}</MenuItem>
          <MenuItem onClick={onPeriodFilterClick}>{t("period")}</MenuItem>
          {isSharedStep ? null : (
            <MenuItem onClick={onLabelFilterClick} key="labelItem">
              {t("label")}
            </MenuItem>
          )}
        </Menu>
        <TextFilter
          anchorEl={nameFilterAnchor}
          onMenuClose={() => setNameFilterAnchor(null)}
          title={
            isSharedStep ? t("filter_steps_by_name") : t("filter_tests_by_name")
          }
          value={filterStore.nameFilter}
          onSave={filterStore.saveNameFilter}
        />
        <AuthorFilter
          title={
            isSharedStep
              ? t("filter_steps_by_author")
              : t("filter_tests_by_author")
          }
          anchorEl={creatorFilterAnchor}
          onMenuClose={() => setCreatorFilterAnchor(null)}
          filterStore={filterStore}
          authorStore={authorStore}
        />
        <AuthorFilter
          title={
            isSharedStep
              ? t("filter_steps_by_editor")
              : t("filter_tests_by_editor")
          }
          anchorEl={updaterFilterAnchor}
          onMenuClose={() => setUpdaterFilterAnchor(null)}
          filterStore={filterStore}
          authorStore={authorStore}
          isUpdaters={true}
        />
        <PeriodFilter
          title={
            isSharedStep
              ? t("filter_steps_by_time_period")
              : t("filter_tests_by_time_period")
          }
          anchorEl={periodFilterAnchor}
          onMenuClose={() => setPeriodFilterAnchor(null)}
          filterStore={filterStore}
          onSave={filterStore.savePeriodFilter}
        />
        <LabelFilter
          key="labelFilter"
          anchorEl={labelFilterAnchor}
          onMenuClose={() => setLabelFilterAnchor(null)}
        />
      </>
    );
  }
);

export default FilterMenu;
