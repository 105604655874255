import { FC, useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { Link, useRouteMatch, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  KeyboardArrowDownOutlined,
  AddCircleOutline,
} from "@mui/icons-material";
import { addResourcesBundle } from "../../i18n";
import { useRootStoreContext } from "../../hooks";
import { Hint } from "../Modals";
import StyledButton from "../StyledButton";
import en from "./en.json";
import ProjectList from "./ProjectList";
import SortMenu from "./SortMenu";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  projectListBar: {
    height: "3.5rem",
    flexShrink: 0,
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.white.main,
    "& a": {
      textDecoration: "none",
    },
  },
  projectListWrapper: {
    height: "100%",
    minHeight: 0,
  },
  hintArea: {
    zIndex: 1200,
    backgroundColor: theme.palette.white.main,
  },
  sortMenu: {
    margin: theme.spacing(0, 3),
  },
}));

const pageHint = "add_project";

const Projects: FC = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation(["projects"]);
  const {
    projects,
    users: {
      profile: { role, updateHints, isHintShown, skipAllHints },
    },
  } = useRootStoreContext();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null);
  const hintRef = useRef<HTMLButtonElement>(null);

  useEffect(() => setAnchorEl(hintRef.current), []);

  const showHint = !isHintShown(pageHint);
  const markHintShown = () => updateHints(pageHint);

  const { isExact } = useRouteMatch();

  const onSortMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSortAnchorEl(e.currentTarget);
  };

  const onSortMenuClose = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    setSortAnchorEl(null);
  };

  if (!isExact) return <Redirect to="/no-match" />;

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.projectListBar}
      >
        <StyledButton
          endIcon={<KeyboardArrowDownOutlined />}
          onClick={onSortMenuClick}
        >
          {t(`sort_menu.${projects.sortField}`)}
        </StyledButton>
        <SortMenu
          anchorEl={sortAnchorEl}
          onMenuClose={onSortMenuClose}
          className={classes.sortMenu}
          sort={(sortBy) => projects.setSortField(sortBy)}
        />
        {role !== "user" && (
          <>
            <Link to="project-create">
              <StyledButton
                ref={hintRef}
                className={clsx(showHint && classes.hintArea)}
                startIcon={<AddCircleOutline />}
                onClick={markHintShown}
              >
                {t("add_project")}
              </StyledButton>
            </Link>
            <Hint
              open={showHint}
              anchorEl={anchorEl}
              onAction={markHintShown}
              onSkip={skipAllHints}
              arrowPosition="right"
              title={t("hint.title")}
              description={t("hint.description")}
              actionText={t("hint.action")}
            />
          </>
        )}
      </Grid>
      <Grid item container wrap="nowrap" className={classes.projectListWrapper}>
        <ProjectList />
      </Grid>
    </Grid>
  );
});

export default Projects;
