import _ from "lodash";
import {
  types,
  flow,
  destroy,
  clone,
  applySnapshot,
  getSnapshot,
  getRoot,
} from "mobx-state-tree";
import i18next from "i18next";
import Label, { ILabel, ILabelSnapshotOut } from "./Label";
import { IRoot } from "./Root";
import { ITest } from "./Test";
import { handleFetch } from "./utils";

const getUniqLabelsFromTests = (tests: ITest[]) => {
  const itemsNumber = tests.length;
  return _.chain(tests)
    .reduce<ILabel[]>((memo, item) => [...memo, ...item.Labels], []) // get list of labels
    .groupBy((item: ILabel) => item.id) // group all labels by id
    .map((val) => ({
      ...val[0],
      indeterminate: val.length !== itemsNumber, //if each item occurs less than number of tests it is indeterminate
    }))
    .value();
};

const Labels = types
  .model("Labels", {
    items: types.optional(types.array(types.late(() => Label)), []),
    currentItems: types.optional(types.array(types.late(() => Label)), []),
    editItems: types.optional(types.array(types.late(() => Label)), []),
    editLabel: types.maybeNull(types.late(() => Label)),
    newLabel: types.maybeNull(types.late(() => Label)),
    labelSearchValue: "",
    labelsState: types.optional(
      types.enumeration("LabelsState", ["ready", "loading"]),
      "ready"
    ),
  })
  .views((self) => ({
    get mergedItems() {
      const currentLabelsIds = self.currentItems.map(({ id }: ILabel) => id);
      return self.items.map((item: ILabel) => ({
        ...item,
        selected: currentLabelsIds.includes(item.id),
        indeterminate: !!_.find(self.currentItems, { id: item.id })
          ?.indeterminate,
      }));
    },
  }))
  .views((self) => ({
    get filteredItems() {
      const labelSearchValue = self.labelSearchValue.toLowerCase();
      return _.filter(
        self.editItems,
        (item) => item.name.toLowerCase().indexOf(labelSearchValue) > -1
      );
    },

    get selectedLabels() {
      return _.filter(self.editItems, { selected: true });
    },

    get selectedLabelsCount() {
      return self.editItems.reduce((r, item) => (item.selected ? ++r : r), 0);
    },

    get assignDiff() {
      return _.differenceWith(self.editItems, self.mergedItems, _.isEqual);
    },
  }))
  .views((self) => ({
    get itemIdsToAssign() {
      return _.chain(self.assignDiff)
        .filter({ selected: true })
        .map((item) => item.id)
        .value();
    },

    get itemIdsToUnassign() {
      return _.chain(self.assignDiff)
        .filter({ selected: false })
        .map((item) => item.id)
        .value();
    },
  }))
  .actions((self) => ({
    load: flow(function* ({ projectId }) {
      self.labelsState = "loading";
      try {
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${projectId}/labels`
        );

        applySnapshot(self.items, yield response.json());
        applySnapshot(self.editItems, self.mergedItems);
      } catch (e) {
        console.error(e);
      } finally {
        self.labelsState = "ready";
      }
    }),

    loadSuiteLabels: flow(function* ({ projectId, suiteId }) {
      self.labelsState = "loading";
      try {
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${projectId}/suites/${suiteId}/tests`
        );
        const items = yield response.json();
        const labels = getUniqLabelsFromTests(items);

        applySnapshot(self.currentItems, labels);
        applySnapshot(self.editItems, self.mergedItems);
      } catch (e) {
        // ToDo show error message
        console.error(e);
      } finally {
        self.labelsState = "ready";
      }
    }),
    loadTestFilterLabels() {
      const { tests, testFilters } = getRoot<IRoot>(self);
      const testLabels = getUniqLabelsFromTests(tests.items);
      const currentItems = testFilters.labelFilter;
      const labels = _.map(testLabels, (label) => ({
        ...label,
        selected: currentItems.includes(label.name),
        indeterminate: false,
      }));
      applySnapshot(self.editItems, labels);
    },
    addNewLabel: flow(function* () {
      if (!self.newLabel) return;

      const label = clone(self.newLabel);

      try {
        yield label.save();

        self.items.push(label);
        applySnapshot(self.editItems, self.mergedItems);

        destroy(self.newLabel);

        //TODO: Show Success msg
      } catch (error) {
        //TODO: Show Error msg
        self.newLabel.error = label.error;
        console.error("Error while addNewLabel", error);
      }
    }),
    editExistingLabel: flow(function* () {
      if (!self.editLabel) return;
      const existingLabel = _.find(self.editItems, { id: self.editLabel.id });

      if (!existingLabel) return;
      const existingLabelSnap = getSnapshot(existingLabel);

      try {
        applySnapshot(existingLabel, getSnapshot(self.editLabel));

        yield existingLabel.save();

        destroy(self.editLabel);

        //TODO: Show Success msg
      } catch (error) {
        //TODO: Show Error msg
        self.editLabel.error = i18next.t("labels:errors:duplicate");
        console.error("Error while editExistingLabel", error);
        applySnapshot(existingLabel, existingLabelSnap);
      }
    }),
    removeLabel: flow(function* (label: ILabel) {
      try {
        const filteredItems = self.items.filter((lb) => label.id !== lb.id);
        applySnapshot(self.items, filteredItems);

        yield label.remove();
        destroy(label);
      } catch (e) {
        console.error(e);
      }
    }),
    assignLabelsToSuite: flow(function* ({
      projectId,
      suiteId,
      isSuiteSelected,
    }) {
      self.labelsState = "loading";
      try {
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${projectId}/suites/${suiteId}/assign_labels`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              assign_label_ids: self.itemIdsToAssign,
              unassign_label_ids: self.itemIdsToUnassign,
            }),
          }
        );

        if (isSuiteSelected) {
          //ToDo fix this dirty hack
          const root: IRoot = getRoot<IRoot>(self);
          (async () => await root.tests.load({ projectId, suiteId }))();
        }
        const items = yield response.json();
        const labels = getUniqLabelsFromTests(items);
        applySnapshot(self.currentItems, labels);
        applySnapshot(self.editItems, self.mergedItems);
      } catch (e) {
        console.error(e);
      } finally {
        self.labelsState = "ready";
      }
    }),
    assignLabelsToTests: flow(function* ({ projectId, suiteId, testIds }) {
      self.labelsState = "loading";

      try {
        yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${projectId}/assign_steps_labels`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              step_ids: testIds,
              assign_label_ids: self.itemIdsToAssign,
              unassign_label_ids: self.itemIdsToUnassign,
            }),
          }
        );

        //ToDo fix this dirty hack
        const selected = testIds.length > 1 ? testIds : null;
        const root: IRoot = getRoot<IRoot>(self);
        (async () => await root.tests.load({ projectId, suiteId, selected }))();
      } catch (e) {
        console.error(e);
      } finally {
        self.labelsState = "ready";
      }
    }),
    setCurrentLabels(currentLabels: ILabelSnapshotOut[]) {
      applySnapshot(self.currentItems, currentLabels);
    },
    clearLabels() {
      applySnapshot(self, { items: [], editItems: [], currentItems: [] });
    },
  }))
  .actions((self) => ({
    onEditExistingLabel(labelSnapshot: ILabelSnapshotOut) {
      self.editLabel = Label.create(labelSnapshot);
    },
    onCancelEditExistingLabel() {
      self.editLabel && destroy(self.editLabel);
    },
    onAddNewClick(ProjectId: number) {
      self.newLabel = Label.create({ ProjectId });
    },
    onCancelAddLabel() {
      self.newLabel && destroy(self.newLabel);
    },
    onLabelsSearch(searchValue: string) {
      self.labelSearchValue = searchValue;
    },
    onMenuClose() {
      self.labelSearchValue = "";
      self.editLabel && destroy(self.editLabel);
    },
    loadTestLabels(testIds: string[]) {
      const { tests } = getRoot<IRoot>(self);
      const currentTests = _.filter(tests.items, (item) =>
        testIds.includes(item.id)
      );
      const testLabels = getUniqLabelsFromTests(currentTests);
      self.setCurrentLabels(testLabels);
    },
  }));

export default Labels;
