import { createRoot } from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "mobx-react-lite/batchingForReactDom";
import theme from "./theme";
import App from "./App";
import "./i18n";
import "./index.css";
import { root as rootStore } from "./stores";
import { RootStoreContext } from "./contexts";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RootStoreContext.Provider value={rootStore}>
        <DndProvider backend={HTML5Backend}>
          <App />
        </DndProvider>
      </RootStoreContext.Provider>
    </ThemeProvider>
  </StyledEngineProvider>
);
