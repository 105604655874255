import { FC, useEffect } from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Theme, Divider, Box } from "@mui/material";
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";

import { Checkbox, Search } from "../Inputs";

import { Menu, MenuButton, MenuButtonBlock, MenuItem } from "../Menus";
import { useRootStoreContext } from "../../hooks";
import { map } from "lodash";
import { applySnapshot, getSnapshot } from "mobx-state-tree";

const useStyles = makeStyles((theme: Theme) => ({
  menuPaper: {
    ...theme.typography.descriptionTextStyle,
    color: theme.palette.doveGray.main,
    padding: theme.spacing(2, 3, 1),
    width: theme.spacing(36),
  },
  searchBox: {
    margin: theme.spacing(0.5, 0, 2),
  },
  projectItemContainer: {
    maxHeight: 7 * 40,
    overflowY: "auto",
    margin: theme.spacing(0, -1),
  },
  projectItem: {
    height: "auto",
    padding: 0,
  },
}));

interface IProjectMenu {
  anchorEl: null | HTMLElement;
  userIds: number[];
  onClose: () => void;
  className?: string;
}

const ProjectMenu: FC<IProjectMenu> = observer(
  ({ anchorEl, userIds, onClose, className }) => {
    const classes = useStyles();
    const { t } = useTranslation(["users.project_menu", "common"]);
    const { projects, users } = useRootStoreContext();

    useEffect(() => {
      const snapshot = getSnapshot(projects);
      const loadAndSelectProjects = async () => {
        await projects.load();
        projects.setSelectedByUsers(userIds);
      };
      loadAndSelectProjects();
      return () => {
        applySnapshot(projects, snapshot);
      };
    }, [projects, userIds]);

    const onConfirm = async () => {
      try {
        await users.saveUserProjects(userIds);
        await users.load();
        onClose();
      } catch (e) {
        console.error("Error while changing user projects", e);
      }
    };

    return (
      <Menu
        anchorEl={anchorEl}
        onClose={onClose}
        PaperProps={{ className: clsx(classes.menuPaper, className) }}
      >
        <Search
          labelText={t("common:search")}
          value={projects.projectSearchValue}
          className={classes.searchBox}
          onChangeText={projects.onProjectSearch}
        />
        <Box>
          {t("common:selected_with_count", {
            count: projects.getSelectedSize(projects.items),
          })}
        </Box>
        <Box className={classes.projectItemContainer}>
          {map(projects.filteredItems, (project) => (
            <MenuItem
              key={`project_key_${project.id}`}
              className={classes.projectItem}
            >
              <Checkbox
                checked={project.selected}
                onChange={project.toggleSelected}
                label={project.name}
                indeterminate={project.indeterminate}
              />
            </MenuItem>
          ))}
        </Box>
        <Divider />
        <MenuButtonBlock>
          <MenuButton
            startIcon={<CancelOutlined />}
            label={t("Cancel")}
            onClick={onClose}
          />
          <MenuButton
            startIcon={<CheckCircleOutline />}
            label={t("Confirm")}
            onClick={onConfirm}
          />
        </MenuButtonBlock>
      </Menu>
    );
  }
);

export default ProjectMenu;
