import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Box, Grid, SelectChangeEvent, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import {
  counterOption,
  CountFilter as CountFilterModel,
} from "../../../models";
import { Menu, MenuButton, MenuButtonBlock, MenuItem } from "../../Menus";
import { NumberTextField } from "../../Inputs";
import StyledSelect from "../../StyledSelect";

const useStyles = makeStyles((theme: Theme) => ({
  menuPaper: {
    ...theme.typography.descriptionTextStyle,
    color: theme.palette.doveGray.main,
    padding: theme.spacing(1, 3, 0),
    width: theme.spacing(36),
  },
  textField: {
    flex: 1,
    height: "2.5rem",
  },
  inputBaseClass: {
    backgroundColor: theme.palette.white.main,
    flex: "1 1 auto",
    margin: 0,
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  selectField: {
    flex: 1,
    height: "2.5rem",
    marginRight: theme.spacing(2),
  },
}));

const countFilter = CountFilterModel.create();

interface ICountFilter {
  title: string;
  initValue: string;
  anchorEl: null | HTMLElement;
  onMenuClose: () => void;
  onSave: (value: string) => void;
}

const CountFilter: React.FC<ICountFilter> = observer(
  ({ title, initValue, anchorEl, onMenuClose, onSave }) => {
    const classes = useStyles();
    const { t } = useTranslation(["common"]);

    useEffect(() => {
      if (!anchorEl || !initValue) return;
      countFilter.init(initValue);
    }, [anchorEl, initValue]);

    const onCounterOptionChange = (e: SelectChangeEvent<unknown>) => {
      countFilter.setOption(e.target.value as counterOption);
    };

    const onCounterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      countFilter.setCounter(e.target.value);
    };

    const onFilterCancel = () => {
      onMenuClose();
    };

    const onFilterSave = () => {
      const filterValue = `${countFilter.option === "more" ? ">" : "<"}${
        countFilter.counter
      }`;
      onSave(filterValue);
      onMenuClose();
    };

    if (!anchorEl) return null;

    return (
      <Menu
        anchorEl={anchorEl}
        onClose={onMenuClose}
        PaperProps={{ className: classes.menuPaper }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Box className={classes.title}>{title}</Box>
        <Grid container wrap="nowrap" justifyContent="space-between">
          <StyledSelect
            className={classes.selectField}
            onChange={onCounterOptionChange}
            value={countFilter.option || "more"}
          >
            <MenuItem value="more">{t("more")}</MenuItem>
            <MenuItem value="less">{t("less")}</MenuItem>
          </StyledSelect>
          <NumberTextField
            boxClassName={classes.textField}
            value={countFilter.counter || ""}
            onChange={onCounterChange}
            inputBaseProps={{
              placeholder: t("value"),
            }}
          />
        </Grid>
        <MenuButtonBlock>
          <MenuButton
            startIcon={<CancelOutlined />}
            label={t("cancel")}
            onClick={onFilterCancel}
          />
          <MenuButton
            startIcon={<CheckCircleOutline />}
            label={t("apply")}
            onClick={onFilterSave}
          />
        </MenuButtonBlock>
      </Menu>
    );
  }
);

export default CountFilter;
