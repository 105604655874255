import React from "react";
import _ from "lodash";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { List, Theme } from "@mui/material";
import ItemOfNestedTests from "./ItemOfNestedTests";
import { ISuite } from "../../models";
import { useRootStoreContext } from "../../hooks";
import GroupFormTextArea from "./GroupFormTextArea";
import ItemDragLayer from "../ItemDragLayer";

const useStyles = makeStyles((theme: Theme) => ({
  rootBox: {
    margin: theme.spacing(0, 0, 0, 3),
    paddingRight: theme.spacing(3),
    paddingTop: "0.125rem",
  },
}));

interface IListOfItemOfNestedTestsProps {
  items: ISuite[];
}

const renderTree = (
  items: ISuite[],
  nestedLevel: number,
  newSuite?: ISuite | null
) =>
  _.chain(items)
    .filter({ isMatchSearch: true })
    .map((item) => (
      <ItemOfNestedTests key={item.id} suite={item} nestedLevel={nestedLevel}>
        {newSuite?.parentId === item.id ? (
          <GroupFormTextArea suite={newSuite} />
        ) : null}

        {item.suites
          ? renderTree(item.suites, nestedLevel + 1, newSuite)
          : null}
      </ItemOfNestedTests>
    ))
    .value();

const ListOfItemOfNestedTests: React.FC<IListOfItemOfNestedTestsProps> =
  observer(({ items }) => {
    const classes = useStyles();
    const { suites } = useRootStoreContext();

    return (
      <List disablePadding className={classes.rootBox}>
        {renderTree(items, 0, suites.newSuite)}
        <ItemDragLayer iconName="FolderOutlined" itemType="Suite" />
        <ItemDragLayer iconName="SubtitlesOutlined" itemType="Test" />
      </List>
    );
  });

export default ListOfItemOfNestedTests;
