import React from "react";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Tabs as HeaderTabs, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import HeaderTab from "./HeaderTab";

const useStyles = makeStyles((theme: Theme) => ({
  tabIndicator: {
    backgroundColor: theme.palette.baliHai.main,
  },
}));

const Tabs: React.FC<{}> = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation(["profileHeader"]);
  const history = useHistory();
  const { pathname } = useLocation();

  const match = matchPath(pathname, { path: "/dashboard/profile/general" });
  const value = match ? match.url : pathname;
  return (
    <HeaderTabs
      value={value}
      onChange={(e, val) => {
        if (val !== value) history.push(val);
      }}
      TabIndicatorProps={{ className: classes.tabIndicator }}
    >
      <HeaderTab label={t("general")} value={`/dashboard/profile/general`} />
      <HeaderTab label={t("security")} />
      <HeaderTab label={t("emailSettings")} />
      <HeaderTab label={t("integrations")} />
    </HeaderTabs>
  );
});

export default Tabs;
