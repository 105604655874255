import React from "react";
import _ from "lodash";
import { observer } from "mobx-react";
import {
  Switch,
  Route,
  useParams,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import { useRootStoreContext } from "../hooks";
import { Layout, LayoutOfContent, Header } from "../components";
import { Tests, Settings, SharedSteps, Data, Reports } from "./";

const Projects: React.FC<{}> = observer(() => {
  const { path } = useRouteMatch();
  const { projectId = "" } = useParams<{ projectId: "" }>();
  const { projects } = useRootStoreContext();

  if (projects.state !== "ready") {
    // TODO: ask for loading from designer
    return <Layout>Loading</Layout>;
  }

  if (_.isEmpty(projects.items)) {
    // TODO: add ability to add project when design ready
    return <Redirect to="/no-match" />;
  }

  if (!_.some(projects.items, { id: parseInt(projectId, 10) })) {
    // TODO: show message that project not exsit
    return <Redirect to="/no-match" />;
  }

  return (
    <Layout>
      <Header />
      <LayoutOfContent>
        <Switch>
          <Route path={`${path}/tests`} component={Tests} />
          <Route path={`${path}/shared-steps`} component={SharedSteps} />
          <Route path={`${path}/reports`} component={Reports} />
          <Route path={`${path}/data`} component={Data} />
          <Route path={`${path}/settings`} component={Settings} />
          {/* TODO: Add redirect of not of route used */}
        </Switch>
      </LayoutOfContent>
    </Layout>
  );
});

export default Projects;
