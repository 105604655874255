import React from "react";
import { observer } from "mobx-react";

interface IConfirmationModal {
  text: string;
  isOpened: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
}

const ConfirmationModal: React.FC<IConfirmationModal> = observer(
  ({ text, isOpened, onConfirm, onCancel }) => {
    // ToDo implement real modal once design is ready
    if (!isOpened) return null;
    const modalResult: boolean = window.confirm(text);
    if (modalResult) {
      onConfirm();
    } else {
      onCancel && onCancel();
    }
    return null;
  }
);

export default ConfirmationModal;
