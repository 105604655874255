import { FC } from "react";
import clsx from "clsx";
import { map } from "lodash";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import { ITableColumn } from "./index";

const useStyles = makeStyles((theme: Theme) => ({
  tableHeader: {
    top: 0,
    zIndex: 1,
    flexShrink: 0,
    height: "3.5rem",
    position: "sticky",
    padding: theme.spacing(0, 4),
    backgroundColor: theme.palette.white.main,
  },
  tableHeaderContent: {
    width: "100%",
    display: "grid",
    borderBottom: `1px solid ${theme.palette.catskillWhite.main}`,
  },
  tableHeaderLabel: {
    color: theme.palette.doveGray.main,
    ...theme.typography.tableLabelTextStyle,
  },
}));

interface ITableHeader {
  columns: ITableColumn[];
}

const TableHeader: FC<ITableHeader> = ({ columns }) => {
  const classes = useStyles();
  const gridTemplateColumns = map(columns, ({ width }) => width).join(" ");

  return (
    <Grid
      item
      container
      justifyContent="flex-start"
      className={classes.tableHeader}
    >
      <Grid
        container
        alignItems="center"
        wrap="nowrap"
        className={classes.tableHeaderContent}
        style={{ gridTemplateColumns }}
      >
        {map(columns, ({ label, className }, i) => (
          <Grid
            key={`table_header_column_${i}`}
            item
            className={clsx(classes.tableHeaderLabel, className)}
          >
            {label || null}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default TableHeader;
