import React from "react";
import { observer } from "mobx-react";
import { useLocation, matchPath } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Grid, Theme, Typography, ListItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from "@mui/icons-material";
import clsx from "clsx";
import StepIcon from "./StepIcon";
import MenuForItem from "./MenuForItem";
import MenuForSharedStep from "./MenuForSharedStep";
import StyledIconButton from "../StyledIconButton";
import Screenshot from "../Screenshot";
import ValidatableTextArea from "../Inputs/ValidatableTextArea";
import { ITestStep } from "../../models";
import { useRootStoreContext } from "../../hooks";

const useStyles = makeStyles((theme: Theme) => ({
  step: {
    justifyContent: "space-between",
    minHeight: 96,
    padding: theme.spacing(3, 4),
    backgroundColor: theme.palette.white.main,
    "&.testStepSelected": {
      borderLeft: `4px solid ${theme.palette.outrageousOrange.main}`,
      paddingLeft: "1.75rem",
    },
    "&.testStepSelected, &.testStepSelected:hover, &:hover": {
      backgroundColor: theme.palette.catskillLightWhite.main,
    },
    "&:hover": {
      cursor: "pointer",
    },
    "&.passed": {
      backgroundColor: theme.palette.aquaSpring.main,
    },
    "&.failed": {
      backgroundColor: theme.palette.ivoryTower.main,
    },
    "&.none": {
      backgroundColor: theme.palette.white.main,
    },
  },
  testStepControlContainer: {
    visibility: "hidden",
    position: "absolute",
    top: 0,
    right: theme.spacing(3),
    width: "auto",
  },
  controlBlock: {
    padding: theme.spacing(2, 0),
    backgroundColor: theme.palette.catskillLightWhite.main,
  },
  controlButton: {
    padding: theme.spacing(1),
  },
  gradientBlock: {
    padding: theme.spacing(9, 5, 0, 0),
    background: `linear-gradient(90deg, ${theme.palette.catskillLightWhite.main}00 0%, ${theme.palette.catskillLightWhite.main} 100%)`,
  },
  stepDescWrap: {
    width: "100%",
  },
  titleWithIcon: {
    marginBottom: theme.spacing(1),
  },
  title: {
    ...theme.typography.subHeaderTextStyle,
    color: theme.palette.mineShaft.main,
    wordBreak: "break-all",
  },
  description: {
    ...theme.typography.descriptionTextStyle,
    color: theme.palette.doveGray.main,
    marginLeft: theme.spacing(4),
  },
  screenshot: {
    display: "flex",
    height: 48,
    width: 130,
  },
}));

interface IStepTitleProps {
  testStep: ITestStep;
}

const StepTitle: React.FC<IStepTitleProps> = observer(({ testStep }) => {
  const classes = useStyles();
  const { actionsForSelect, testSteps } = useRootStoreContext();
  const { t } = useTranslation(["shared_steps"]);

  const { pathname } = useLocation();
  const isReportView = !!matchPath(pathname, {
    path: "/projects/:projectId/reports/tests",
  });

  const statusClass = testStep.executionStatus;
  const editSharedStep = testSteps.editSharedStep;

  const onEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    testStep.toggleDetails();
  };

  const onChangeName = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    testStep.editSharedStepName(e.target.value);
  };

  const onSaveSharedAction = () => {
    editSharedStep?.onSaveSharedStep();
  };

  return (
    <ListItem
      classes={{ selected: "testStepSelected" }}
      component={Grid}
      container
      className={clsx(classes.step, statusClass, "testStepTitle")}
      selected={testStep.selected}
      wrap="nowrap"
      onClick={testStep.toggleSelected}
    >
      {editSharedStep?.SharedStepId === testStep.SharedStepId &&
      editSharedStep?.id === testStep.id ? (
        <ValidatableTextArea
          autoFocus={!editSharedStep.SharedStepId}
          minRows={3}
          maxRows={3}
          maxLength={255}
          itemName={editSharedStep.value}
          isValid={editSharedStep.isValid}
          onChange={onChangeName}
          onSaveClick={onSaveSharedAction}
          onCancelClick={testSteps.onCancelEditExistingSharedStep}
          placeholder={t("shared_step_name")}
          errorText={editSharedStep.validationErrorText}
        />
      ) : (
        <Grid container wrap="nowrap">
          <Grid
            item
            container
            direction="column"
            wrap="nowrap"
            className={classes.stepDescWrap}
          >
            <Grid
              item
              container
              wrap="nowrap"
              className={classes.titleWithIcon}
            >
              <Grid container alignItems="center" wrap="nowrap">
                <StepIcon testStep={testStep} />
                <Typography className={classes.title}>
                  {testStep.title}
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.description}>
              {testStep.type !== "sharedAction" ? (
                testStep.description
              ) : (
                <Grid
                  dangerouslySetInnerHTML={{
                    __html: testStep.testSharedStepDescription,
                  }}
                />
              )}
            </Grid>
          </Grid>
          {actionsForSelect.isElementType(testStep.type) ? (
            <Grid item className={classes.screenshot}>
              <Screenshot alt="test" src={testStep.imgSrc} />
            </Grid>
          ) : null}
          <Grid
            container
            item
            className={clsx(
              classes.testStepControlContainer,
              "testStepControlContainer"
            )}
            wrap="nowrap"
          >
            <Grid container item className={classes.gradientBlock} />
            <Grid container item className={classes.controlBlock} wrap="nowrap">
              <StyledIconButton
                className={classes.controlButton}
                onClick={onEditClick}
              >
                {testStep.showDetails ? (
                  <KeyboardArrowUpOutlined />
                ) : (
                  <KeyboardArrowDownOutlined />
                )}
              </StyledIconButton>
              {!isReportView &&
                (testStep.type !== "sharedAction" ? (
                  <MenuForItem testStep={testStep} />
                ) : (
                  <MenuForSharedStep testStep={testStep} />
                ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </ListItem>
  );
});

export default StepTitle;
