import { FC } from "react";
import { observer } from "mobx-react";
import clsx from "clsx";
import { Grid } from "@mui/material";
import { useParams, Link } from "react-router-dom";
import { IEnvironment } from "../../models";
import { useRootStoreContext } from "../../hooks";
import TaIcon from "../TaIcon";
import EnvironmentListItemMenu from "./EnvironmentListItemMenu";

interface IEnvironmentListItem {
  environment: IEnvironment;
}

const styles = {
  container: {
    p: "0.5rem",
    cursor: "pointer",
    position: "relative",
    "&:hover": {
      bgcolor: "catskillLightWhite.main",
      color: "outrageousOrange.main",
      ".icon": {
        color: "outrageousOrange.main",
      },
      ".actionsContainer": {
        visibility: "visible",
      },
    },
    "&.selected": {
      bgcolor: "catskillLightWhite.main",
    },
  },
  icon: {
    color: "baliHai.main",
    mr: "0.5rem",
    display: "flex",
    alignItems: "center",
  },
  actionsContainer: {
    width: "auto",
    right: 0,
    position: "absolute",
    visibility: "hidden",
  },
  actionsGradientBox: {
    width: 40,
    height: 40,
    background: `linear-gradient(90deg, catskillLightWhite.main00 0%, catskillLightWhite.main 100%)`,
  },
  actionsButtonBox: {
    backgroundColor: "catskillLightWhite.main",
    width: "auto",
  },
};

const EnvironmentListItem: FC<IEnvironmentListItem> = observer(
  ({ environment }) => {
    const { projectId = "", environmentId = "" } = useParams<{
      projectId: string;
      environmentId: string;
    }>();

    const { environments } = useRootStoreContext();

    const link = environment.id
      ? `/projects/${projectId}/data/environments/${environment.id}/variables`
      : `/projects/${projectId}/data/environments`;

    const getEnvironmentIcon = () => {
      if (!environment.id) {
        return "FolderOpenOutlined";
      }
      if (environment.basic) {
        return "FolderSpecialOutlined";
      }
      return "FolderSharedOutlined";
    };

    return (
      <Link style={{ textDecoration: "none", color: "inherit" }} to={link}>
        <Grid
          container
          alignItems="center"
          wrap="nowrap"
          justifyContent="space-between"
          sx={styles.container}
          className={clsx({
            selected:
              ((!environment.id && !environmentId) ||
                environment.id === parseInt(environmentId, 10)) &&
              !environments.newEnvironment,
          })}
        >
          <Grid item container alignItems="center" wrap="nowrap">
            <Grid item sx={styles.icon} className="icon">
              <TaIcon iconName={getEnvironmentIcon()} />
            </Grid>
            <Grid item>{environment.name}</Grid>
          </Grid>
          <Grid item>{325}</Grid>
          <Grid
            container
            wrap="nowrap"
            sx={styles.actionsContainer}
            className="actionsContainer"
          >
            <Grid item sx={styles.actionsGradientBox}></Grid>
            <Grid
              item
              container
              wrap="nowrap"
              alignItems="center"
              justifyContent="flex-end"
              sx={styles.actionsButtonBox}
            >
              {environment.id ? (
                <EnvironmentListItemMenu environment={environment} />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Link>
    );
  }
);

export default EnvironmentListItem;
