import React from "react";
import { observer } from "mobx-react";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";

// ToDo: improve these styles
const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    ...theme.typography.bodyTextStyle,
    color: theme.palette.white.main,
    padding: theme.spacing(1, 3),
    borderRadius: 0,
    background: (props: { type: string }) =>
      props.type === "error"
        ? theme.palette.guardsmanRed.main
        : theme.palette.shark.main,
    top: 1,
  },
  tooltipPlacementLeft: {
    left: 5,
  },
  tooltipPlacementRight: {
    right: 5,
  },
  arrow: {
    color: (props: { type: string }) =>
      props.type === "error"
        ? theme.palette.guardsmanRed.main
        : theme.palette.shark.main,
    top: "8px !important",
  },
}));

const StyledTooltip: React.FC<TooltipProps & { type: string }> = observer(
  (props) => {
    const classes = useStyles({ type: props.type });

    return (
      <Tooltip
        disableFocusListener
        disableHoverListener
        disableTouchListener
        arrow
        {...props}
        classes={classes}
      />
    );
  }
);

export default StyledTooltip;
