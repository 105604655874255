import { FC, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  AddBoxOutlined,
  KeyboardArrowDownOutlined,
  FilterListOutlined,
} from "@mui/icons-material";
import { useRootStoreContext } from "../../hooks";
import StyledButton from "../StyledButton";
import SortMenu from "./SortMenu";

const useStyles = makeStyles((theme: Theme) => ({
  controlBarContainer: {
    height: theme.spacing(7),
    padding: theme.spacing(0, 2),
    backgroundColor: theme.palette.white.main,
  },
  sortMenu: {
    margin: theme.spacing(0, 3),
  },
}));

const ControlBar: FC = observer(() => {
  const { t } = useTranslation(["project_team"]);
  const classes = useStyles();
  const [, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null);
  const { users } = useRootStoreContext();

  useEffect(() => {
    users.setSortParam("last_added");
    return () => users.removeUserToInvite();
  }, [users]);

  const onFilterMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onSortMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSortAnchorEl(e.currentTarget);
  };

  const onSortMenuClose = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    setSortAnchorEl(null);
  };

  const onCreateClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    users.setUserToInvite();
  };

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      justifyContent="space-between"
      className={classes.controlBarContainer}
    >
      <Grid item container alignItems="center" justifyContent="flex-start">
        <StyledButton
          startIcon={<FilterListOutlined />}
          onClick={onFilterMenuClick}
        >
          {t("filters")}
        </StyledButton>
        <StyledButton
          endIcon={<KeyboardArrowDownOutlined />}
          onClick={onSortMenuClick}
        >
          {t(`sort_menu.${users.sortParam}`)}
        </StyledButton>
        <SortMenu
          anchorEl={sortAnchorEl}
          onMenuClose={onSortMenuClose}
          className={classes.sortMenu}
          sort={(sortBy) => users.setSortParam(sortBy)}
        />
      </Grid>
      <Grid item container alignItems="center" justifyContent="flex-end">
        <StyledButton onClick={onCreateClick} startIcon={<AddBoxOutlined />}>
          {t("add_user")}
        </StyledButton>
      </Grid>
    </Grid>
  );
});

export default ControlBar;
