import React from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Grid, Divider, Theme } from "@mui/material";
import { ReactComponent as KeyboardReturn } from "../../icons/KeyboardReturn.svg";
import { ITestStep } from "../../models";
import MenuForSharedStep from "../TestSteps/MenuForSharedStep";
import StyledIconButton from "../StyledIconButton";
import TaIcon from "../TaIcon";
import ItemControlBlock from "../ItemControlBlock";
import StepActions from "../StepActions";
import ValidatableTextArea from "../Inputs/ValidatableTextArea";
import { useRootStoreContext } from "../../hooks";

const useStyles = makeStyles((theme: Theme) => ({
  stepWrapper: {
    padding: 0,
    "&:not(:last-child)": {
      marginBottom: theme.spacing(1),
    },
    "&:hover": {
      backgroundColor: theme.palette.catskillLightWhite.main,
      "&:not(.editMode) .testStepControlContainer": {
        visibility: "visible",
      },
    },
    "&.isOver": {
      border: `2px solid ${theme.palette.outrageousOrange.main}`,
    },
    "&.isOverUp": {
      borderTop: `2px solid ${theme.palette.outrageousOrange.main}`,
      paddingTop: theme.spacing(1),
    },
    "&.isOverDown": {
      borderBottom: `2px solid ${theme.palette.outrageousOrange.main}`,
      paddingBottom: theme.spacing(1),
    },
  },
  sharedStepsItem: {
    flex: 1,
    position: "relative",
    padding: theme.spacing(3, 4),
    backgroundColor: theme.palette.white.main,
    borderLeft: `4px solid ${theme.palette.white.main}`,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.catskillLightWhite.main,
      borderLeft: `4px solid ${theme.palette.catskillLightWhite.main}`,
      "& .controlContainer": {
        visibility: "visible",
      },
    },
    "&.selected": {
      backgroundColor: theme.palette.catskillLightWhite.main,
      borderLeft: `4px solid ${theme.palette.outrageousOrange.main}`,
    },
  },
  icon: {
    flex: 0,
    marginRight: theme.spacing(1),
    color: theme.palette.baliHai.main,
  },
  nameContainer: {
    justifyContent: "space-between",
    color: theme.palette.mineShaft.main,
    marginBottom: theme.spacing(1),
  },
  name: {
    ...theme.typography.subHeaderTextStyle,
    wordBreak: "break-all",
    paddingRight: theme.spacing(7),
  },
  testCount: {
    flex: 0,
    ...theme.typography.bodyTextStyle,
  },
  description: {
    marginBottom: theme.spacing(1),
    color: theme.palette.doveGray.main,
    ...theme.typography.descriptionTextStyle,
  },
  editDataContainer: {
    color: theme.palette.doveGray.main,
    ...theme.typography.descriptionTextStyle,
  },
  createdBy: {},
  updatedBy: {},
  controlButton: {
    padding: theme.spacing(1),
  },
  stepBody: {
    padding: theme.spacing(0, 4),
    backgroundColor: theme.palette.white.main,
    width: "auto",
  },
  divider: {
    marginTop: -1,
  },
}));

interface ISharedStepsItem {
  sharedStep: ITestStep;
}

const SharedStepsItem: React.FC<ISharedStepsItem> = observer(
  ({ sharedStep }) => {
    const classes = useStyles();
    const { t } = useTranslation(["shared_steps"]);
    const { testSteps } = useRootStoreContext();

    const onTogglesSharedStepActions = (e: React.SyntheticEvent) => {
      e.stopPropagation();
      sharedStep.toggleSharedStepActions();
    };

    const onChangeName = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      sharedStep.editSharedStepName(e.target.value);
    };

    return (
      <Grid className={classes.stepWrapper} onClick={sharedStep.toggleSelected}>
        {testSteps.editSharedStep?.SharedStepId === sharedStep.SharedStepId ? (
          <ValidatableTextArea
            autoFocus={!testSteps.editSharedStep?.SharedStepId}
            minRows={3}
            maxRows={3}
            maxLength={255}
            itemName={testSteps.editSharedStep?.value}
            isValid={testSteps.editSharedStep?.isValid}
            onChange={onChangeName}
            onSaveClick={testSteps.editSharedStep?.onSaveSharedStep}
            onCancelClick={testSteps.onCancelEditExistingSharedStep}
            placeholder={t("shared_step_name")}
            errorText={testSteps.editSharedStep?.validationErrorText}
          />
        ) : (
          <Grid
            container
            wrap="nowrap"
            className={clsx(classes.sharedStepsItem, {
              selected: sharedStep.selected,
            })}
          >
            <Grid item container className={classes.icon}>
              <TaIcon iconName="FolderSharedOutlined" />
            </Grid>
            <Grid item container direction="column">
              <Grid
                item
                container
                wrap="nowrap"
                justifyContent="space-between"
                className={classes.nameContainer}
              >
                <Grid
                  item
                  container
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  className={classes.name}
                >
                  {sharedStep.value}
                </Grid>
                <Grid
                  item
                  container
                  alignItems="flex-start"
                  justifyContent="flex-end"
                  className={classes.testCount}
                >
                  {sharedStep.usageCount}
                </Grid>
              </Grid>
              <Grid
                item
                className={classes.description}
                dangerouslySetInnerHTML={{
                  __html: sharedStep.testSharedStepDescription,
                }}
              />
              <Grid
                item
                container
                wrap="nowrap"
                alignItems="center"
                justifyContent="space-between"
                className={classes.editDataContainer}
              >
                <Grid item>{sharedStep.createdWithEmail}</Grid>
                <Grid item>{sharedStep.updatedWithEmail}</Grid>
              </Grid>
            </Grid>
            <ItemControlBlock>
              <StyledIconButton
                className={classes.controlButton}
                onClick={onTogglesSharedStepActions}
              >
                <KeyboardReturn />
              </StyledIconButton>
              <MenuForSharedStep testStep={sharedStep} sharedMenu={true} />
            </ItemControlBlock>
          </Grid>
        )}
        {sharedStep.showDetails ? (
          <Grid container direction="column" className={classes.stepBody}>
            <Divider className={classes.divider} />
            <StepActions testStep={sharedStep} />
          </Grid>
        ) : null}
      </Grid>
    );
  }
);

export default SharedStepsItem;
