import _ from "lodash";
import { types, applySnapshot } from "mobx-state-tree";
import Role from "./Role";

const ALL_ROLES = ["admin", "user", "manager"];

const Roles = types
  .model("Roles", {
    items: types.optional(types.array(types.late(() => Role)), []),
  })
  .views((self) => ({
    get selectedRoles() {
      return _.filter(self.items, { selected: true });
    },
  }))
  .actions((self) => ({
    initItems(selected: string[]) {
      applySnapshot(
        self.items,
        _.map(ALL_ROLES, (name) => ({
          name,
          selected: selected.includes(name),
        }))
      );
    },
  }));

export default Roles;
