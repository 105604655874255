import _ from "lodash";
import {
  types,
  flow,
  applySnapshot,
  destroy,
  detach,
  getSnapshot,
  cast,
  clone,
  Instance,
  SnapshotOut,
  getRoot,
} from "mobx-state-tree";
import i18next from "i18next";
import TestStep, { ITestStep, ITestStepModelSnapshotOut } from "./TestStep";
import WithSelectable from "./WithSelectable";
import TestStepOptions from "./TestStepOptions";
import SharedTestSteps from "./SharedTestSteps";
import { handleFetch, IActionType } from "./utils";
import { IReport } from "./Report";
import { IRoot } from "./Root";
import { ITest } from "./Test";

interface ICreateStepProps {
  type?: IActionType;
  stepIdtoDropTo?: number;
  before?: boolean;
  projectId: number;
  suiteId: string;
  testId: string;
  SharedStepId?: string;
  value?: string;
}
interface IMoveStepProps extends ICreateStepProps {
  id: number;
}

const TestSteps = types
  .compose(
    types.model("TestSteps", {
      items: types.optional(types.array(types.late(() => TestStep)), []),
      editSharedStep: types.maybeNull(types.late(() => TestStep)),
      state: types.optional(
        types.enumeration("TestStepsState", ["loading", "ready"]),
        "ready"
      ),
    }),
    SharedTestSteps,
    WithSelectable
  )
  .views((self) => ({
    draggingStepsSize(dragItemId: number) {
      const selectedItems = self.getSelectedItems(self.items);
      const selectedItemsSize = self.getSelectedSize(self.items);
      return _.find(selectedItems, { id: dragItemId })
        ? selectedItemsSize
        : selectedItemsSize + 1;
    },
    isDisabledSharedStep(testId: ITestStep["StepId"]) {
      const orderedItems = self.items
        .filter((ts) => ts.testId === testId)
        .map((ts) => ts.order)
        .sort((order) => order);

      const selectedOrder = self
        .getSelectedItems(self.items)
        .map((ts) => (ts as ITestStep).order)
        .sort((order) => order);

      const selectedIndexes = selectedOrder.map((order) =>
        orderedItems.indexOf(order)
      );

      const sharedStepSelected = self
        .getSelectedItems(self.items)
        .find((ts) => (ts as ITestStep).type === "sharedAction");

      const isDisabled = selectedIndexes
        .map((order, index, { [index - 1]: previous }) => order - previous)
        .slice(1)
        .some((diff) => diff !== 1);

      return isDisabled || !!sharedStepSelected;
    },
    get selectedAndNotShared() {
      return self
        .getSelectedItems(self.items)
        .filter((item) => !(item as ITestStep).shared);
    },
    getCurrentSteps(testId: ITestStep["StepId"]) {
      return self.items
        .filter((item) => !item.shared && item.StepId === testId)
        .sort((s1, s2) => s1.order - s2.order);
    },
  }))
  .actions((self) => ({
    load: flow(function* ({
      projectId,
      suiteId,
      testId,
    }: {
      projectId: number;
      suiteId: string;
      testId: string;
    }) {
      try {
        self.state = "loading";
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${projectId}/suites/${suiteId}/tests/${testId}/steps`
        );
        if (!response) return;
        const items = yield response.json();
        applySnapshot(self.items, items);
      } catch (error) {
        //TODO: Show Error msg
        console.error("Error while load test steps", error);
      } finally {
        self.state = "ready";
      }
    }),
    touchParentTest(testId?: ITest["id"] | null) {
      if (!testId) return;

      const { tests } = getRoot<IRoot>(self);
      tests.touchOneTest({ testId });
    },
  }))
  .actions((self) => ({
    removeTestStep: flow(function* (item: ITestStep) {
      try {
        yield item.remove();
        self.touchParentTest(item.testId);
        destroy(item);
      } catch (e) {
        console.error(`Error while removeTestStep: ${e}`);
      }
    }),
    remove: flow(function* ({
      projectId,
      suiteId,
      testId,
    }: {
      projectId: number;
      suiteId: string;
      testId: string;
    }) {
      try {
        self.state = "loading";
        const stepIds = _.map(
          self.getSelectedItems(self.items),
          (item) => (item as ITestStep).id
        );
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${projectId}/suites/${suiteId}/tests/${testId}/steps`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              stepIds,
            }),
          }
        );
        if (!response.ok) throw new Error("Could not do remove tests request");
        _.map(self.getSelectedItems(self.items), (item) =>
          destroy(item as ITestStep)
        );
        self.touchParentTest(testId);
      } catch (e) {
        console.error(`Error while remove actions: ${e}`);
      } finally {
        self.state = "ready";
      }
    }),
    duplicateAction: flow(function* (
      item: ITestStep
    ): Generator<Promise<ITestStep>> {
      try {
        const items = yield item.duplicate();
        self.touchParentTest(item.testId);
        applySnapshot(self.items, items);
      } catch (e) {
        console.error(`Error while duplicateTest: ${e}`);
      }
    }),
    saveReorderedSteps: flow(function* ({
      projectId,
      suiteId,
      testId,
      stepActions,
    }: {
      projectId: number;
      suiteId: string;
      testId: string;
      stepActions: ITestStepModelSnapshotOut[];
    }) {
      try {
        self.state = "loading";
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${projectId}/suites/${suiteId}/tests/${testId}/steps/move`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ stepActions }),
          }
        );

        if (!response.ok) throw new Error("Could not do test step reorder");
        self.touchParentTest(testId);
      } catch (e) {
        console.error(`Error while saveReorderedSteps: ${e}`);
      } finally {
        self.state = "ready";
      }
    }),
    saveSharedSteps: flow(function* ({ step }: { step: ITestStep }) {
      try {
        self.state = "loading";
        const stepIds = _.map(
          _.filter(self.items, (item) => item.shared),
          (item) => (item as ITestStep).id
        );
        step.value = _.trim(step.value, ". \n");
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${step.projectId}/sharedSteps`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              stepIds,
              testId: step.testId,
              step,
            }),
          }
        );
        if (!response.ok) throw new Error("Could not do share steps request");
        const sharedSteps = yield response.json();
        self.items.forEach((item) => {
          if (item.shared) destroy(item);
        });
        self.touchParentTest(step.testId);
        applySnapshot(self.items, sharedSteps.steps);
        applySnapshot(self.sharedSteps, sharedSteps.sharedStepsWithActions);
      } catch (e) {
        step.error = i18next.t("test_steps:errors:duplicate");
        console.error(`Error while remove actions: ${e}`);
      } finally {
        self.state = "ready";
      }
    }),
    addNewEmptySharedStep: flow(function* ({ step }: { step: ITestStep }) {
      if (!self.newSharedStep) return;
      const sharedStep = clone(self.newSharedStep);
      const newSharedStepSnapshot = getSnapshot(sharedStep);
      try {
        yield sharedStep.saveEmptySharedStep();
        destroy(self.newSharedStep);
        self.sharedSteps.push(sharedStep);
      } catch (e) {
        console.error(`Error while addNewEmptySharedStep: ${e}`);
        destroy(sharedStep);
        applySnapshot(self.newSharedStep, newSharedStepSnapshot as ITestStep);
        self.newSharedStep.error = i18next.t("test_steps:errors:duplicate");
      }
    }),
    duplicateSharedStep: flow(function* (steps: ITestStep[]) {
      self.state = "loading";
      try {
        const testIds = steps.map((step) => step.SharedStepId);
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${steps[0].projectId}/sharedSteps/duplicate`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ testIds }),
          }
        );

        if (!response.ok) throw new Error("Could not duplicate the test");

        const sharedSteps = yield response.json();
        sharedSteps.forEach((step: ITestStep) => {
          self.sharedSteps.push({ ...step, Variables: [] });
        });
      } catch (error) {
        throw error;
      } finally {
        self.state = "ready";
      }
    }),
    ungroupSharedStep: flow(function* (
      steps: ITestStep[],
      testId?: string | null
    ) {
      self.state = "loading";
      try {
        const sharedStepIds = steps.map((step) => step.SharedStepId);
        const ids = steps.map((step) => step.id);
        const stepId = testId ? { testId } : {};
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${steps[0].projectId}/sharedSteps/ungroup`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ sharedStepIds, ...stepId, ids }),
          }
        );

        if (!response.ok) throw new Error("Could not ungroup the shared step");
        steps.forEach((step) => destroy(step));
        const ungroupedSteps = yield response.json();
        if (ungroupedSteps !== "ok") applySnapshot(self.items, ungroupedSteps);
        self.touchParentTest(testId);
      } catch (error) {
        throw error;
      } finally {
        self.state = "ready";
      }
    }),

    deleteSharedStepWithActions: flow(function* (
      sharedStepIds: string[],
      projectId: string
    ) {
      self.state = "loading";
      try {
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${projectId}/sharedSteps`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ ids: sharedStepIds }),
          }
        );

        if (!response.ok)
          throw new Error(
            "Could not delete shared step with actions in deleteSharedStepWithActions "
          );

        self.filteredItems.forEach((step) => {
          if (sharedStepIds.includes((step as ITestStep).SharedStepId || ""))
            destroy(step);
        });
      } catch (error) {
        throw error;
      } finally {
        self.state = "ready";
      }
    }),
  }))

  .actions((self) => ({
    toggleItemDetails(id: number, showDetails: boolean) {
      _.each(self.items, (item) => {
        if (item.id === id) {
          item.showDetails = showDetails;
        } else {
          item.showDetails = false;
        }
      });
    },
    findStep(id?: number) {
      const testStep: ITestStep = _.filter(self.items, { id })[0];
      return {
        testStep,
        index: self.getCurrentSteps(testStep?.testId).indexOf(testStep),
      };
    },
    buildNewShared(firstStep: ITestStep) {
      firstStep.shared = true;
      const newSharedStep = Object.assign(
        {},
        { ...firstStep },
        {
          id: 0,
          value: "",
          type: "sharedAction",
          shared: false,
          order: firstStep.order,
          elementName: "",
          options: TestStepOptions.create({}),
          jsonbValue: [],
          jsonbValueSize: {},
          showDetails: true,
          selected: true,
          Variables: [],
          Element: null,
        }
      );
      self.items.splice(firstStep.order, 0, newSharedStep as ITestStep);
    },
    onCancelAddNewSharedStep(sharedStep: ITestStep) {
      self.items
        .filter((item) => item.shared)
        .forEach((item) => (item.shared = false));
      sharedStep && destroy(sharedStep);
    },
  }))
  .actions((self) => ({
    onNewEmptySharedCreate(projectId: number) {
      self.newSharedStep = TestStep.create(
        cast({
          id: 0,
          order: 0,
          type: "sharedAction",
          projectId,
        })
      );
    },
    onNewSharedCreate() {
      const selectedSteps = self.getSelectedItems(self.items) as ITestStep[];
      const firstStep = selectedSteps.sort((s1, s2) => s1.order - s2.order)[0];
      selectedSteps.forEach((step) => (step.shared = true));
      self.buildNewShared(firstStep);
    },
  }))
  .actions((self) => ({
    createStep: flow(function* ({
      type,
      stepIdtoDropTo,
      before,
      SharedStepId,
      projectId,
      suiteId,
      testId,
      value,
    }: ICreateStepProps) {
      const { index } = self.findStep(stepIdtoDropTo);
      const order = before ? index : index + 1;
      const testStep = TestStep.create({
        type,
        order,
        projectId,
        suiteId,
        StepId: testId,
        testId,
        SharedStepId,
        valueAttribute: value,
      } as ITestStep);
      try {
        yield testStep.save();
        yield self.load({ projectId, suiteId, testId });
        self.touchParentTest(testId);
      } catch (e) {
        console.error(e);
      }
    }),
    moveStep: flow(function* ({
      id,
      stepIdtoDropTo,
      before,
      projectId,
      suiteId,
      testId,
    }: IMoveStepProps) {
      const selectedSteps = self.getSelectedItems(self.items) as ITestStep[];
      const { testStep } = self.findStep(id);

      if (selectedSteps.indexOf(testStep) < 0) {
        selectedSteps.push(testStep);
        selectedSteps.sort((s1, s2) => s1.order - s2.order);
      }

      const steps: ITestStepModelSnapshotOut[] = _.map(
        selectedSteps,
        (step) => {
          detach(step);
          return getSnapshot(step) as ITestStepModelSnapshotOut;
        }
      );

      const { index } = self.findStep(stepIdtoDropTo);
      const atIndex = before ? index : index + 1;

      const updatedSteps = _.map(steps, (step, i) =>
        Object.assign({}, step, { order: atIndex + i })
      );

      self.items.splice(
        atIndex,
        0,
        ...(updatedSteps as ITestStepModelSnapshotOut[])
      );

      yield self.saveReorderedSteps({
        projectId,
        suiteId,
        testId,
        stepActions: updatedSteps,
      });
      yield self.load({ projectId, suiteId, testId });
    }),
    clearItems() {
      applySnapshot(self.items, []);
    },
    setReportStatus(report: IReport) {
      const steps = self.getCurrentSteps(report.StepId);

      let stepExecutionStatus = "passed";
      const failedActionId =
        report.result === "failed" ? report.StepActionId || steps[0]?.id : null;

      steps.forEach((step) => {
        if (failedActionId === step.id) {
          stepExecutionStatus = "failed";
          step.setReportErrorMessage(report.errorMessage);
        }
        step.setExecutionStatus(stepExecutionStatus);
        if (stepExecutionStatus === "failed") stepExecutionStatus = "none";
      });
    },
    clearReportStatus() {
      self.items.forEach((step) => {
        step.setExecutionStatus("none");
        step.setReportErrorMessage("");
      });
    },
    onEditSharedStep(sharedStep: ITestStepModelSnapshotOut) {
      self.editSharedStep = TestStep.create(sharedStep as ITestStep);
    },
    onCancelEditExistingSharedStep() {
      self.editSharedStep && destroy(self.editSharedStep);
    },
    onSaveEditSharedStep: flow(function* () {
      if (!self.editSharedStep) return;
      const existingSharedStep = _.find(self.sharedSteps, {
        SharedStepId: self.editSharedStep.SharedStepId,
      });

      if (!existingSharedStep) return;
      const existingSharedStepSnap = getSnapshot(existingSharedStep);
      const editSharedStep = getSnapshot(self.editSharedStep);

      const existingSharedActions = _.filter(self.items, {
        SharedStepId: self.editSharedStep.SharedStepId,
      });

      const existingSharedActionSnaps = existingSharedActions.map((action) =>
        getSnapshot(action)
      );

      try {
        applySnapshot(
          existingSharedStep,
          Object.assign({}, existingSharedStep, {
            value: _.trim(editSharedStep?.value),
          })
        );

        existingSharedActions.forEach((action) =>
          applySnapshot(
            action,
            Object.assign({}, action, {
              value: _.trim(editSharedStep?.value),
            })
          )
        );

        yield existingSharedStep.saveSharedStep();

        destroy(self.editSharedStep);
      } catch (e) {
        console.error("Error while editExistingSharedStep", e);
        applySnapshot(existingSharedStep, existingSharedStepSnap);
        existingSharedActions.forEach((action, i) =>
          applySnapshot(action, existingSharedActionSnaps[i])
        );
      }
    }),
  }));

export interface ITestSteps extends Instance<typeof TestSteps> {}
export interface ITestStepsSnapshotOut extends SnapshotOut<typeof TestSteps> {}

export default TestSteps;
