import {
  applySnapshot,
  flow,
  types,
  SnapshotOut,
  Instance,
} from "mobx-state-tree";

const Settings = types
  .model("Settings model", {
    clientVersion: "",
    downloadClientUrl: "",
  })
  .actions((self) => ({
    load: flow(function* () {
      try {
        const response = yield fetch(
          `${window.location.origin.replace(
            "localhost:3006", // DEVNOTE: for local development environment
            "localhost:3000"
          )}/client/ta/version`
        );

        const version = yield response.text();
        applySnapshot(self, {
          clientVersion: version,
          downloadClientUrl: `${process.env.REACT_APP_CLIENT_DOWNLOAD_URL}/${version}/mac-os/trueautomation.pkg`,
        });
      } catch (error) {
        console.error("Failed to load settings", error);
      }
    }),
  }));

export interface ISettings extends Instance<typeof Settings> {}
export interface ISettingsSnapshotOut extends SnapshotOut<typeof Settings> {}

export default Settings;
