import React, { useEffect } from "react";
import { SideBarOfActionElementsSharedSteps } from "../components";
import { observer } from "mobx-react";
import {
  useRouteMatch,
  Switch,
  Route,
  useParams,
  Redirect,
} from "react-router-dom";
import { useRootStoreContext } from "../hooks";
import TestSteps from "../components/TestSteps";

const Test: React.FC = observer(() => {
  const { path, isExact, url } = useRouteMatch();
  const {
    projectId = "",
    testId = "",
    suiteId = "",
  } = useParams<{
    projectId: string;
    testId: string;
    suiteId: string;
  }>();
  const {
    testSteps,
    actions,
    elements,
    tests,
    environments,
    variables,
    users: { profile },
  } = useRootStoreContext();

  useEffect(() => {
    tests.loadOneTest({ projectId: parseInt(projectId, 10), suiteId, testId });
    testSteps.loadSharedSteps(parseInt(projectId, 10));
    actions.load();
    elements.load({ projectId: parseInt(projectId, 10) });
    environments.load({ projectId: parseInt(projectId, 10) });
    variables.load({ projectId: parseInt(projectId, 10) });
    profile.load();
  }, [
    testSteps,
    elements,
    actions,
    tests,
    environments,
    variables,
    projectId,
    suiteId,
    testId,
    profile,
  ]);

  if (!profile.isAuthenticated) return <Redirect to="/sign-in" />;

  if (isExact) {
    return <Redirect to={`${url}/steps`} />;
  }

  return (
    <Switch>
      <Route path={`${path}/steps`}>
        <TestSteps />
        <SideBarOfActionElementsSharedSteps />
      </Route>
      <Route path="*">
        <Redirect to="/no-match" />
      </Route>
    </Switch>
  );
});

export default Test;
