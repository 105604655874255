import { FC } from "react";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Theme } from "@mui/material";
import Screenshot from "../Screenshot";
import { ITableColumn, ITableRowData } from "./index";
import TableCellMenu from "./TableCellMenu";
import TableCellProfile from "./TableCellProfile";
import TableRowHoverMenu from "./TableRowHoverMenu";

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    height: "100%",
    position: "relative",
    color: theme.palette.mineShaft.main,
    ...theme.typography.bodyTextStyle,
    minWidth: 0,
  },
  clipBox: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    paddingRight: 4,
  },
  imageBox: {
    maxHeight: "4.5rem",
  },
  image: {
    maxHeight: "4.5rem",
  },
}));

interface ITableCell {
  column: ITableColumn;
  row: ITableRowData;
}

const TableCell: FC<ITableCell> = ({ column, row }) => {
  const classes = useStyles();
  const { key, type, className } = column;

  const renderCellContent = (
    colType: ITableColumn["type"],
    valueKey: string = ""
  ) => {
    switch (colType) {
      case "menu":
        return <TableCellMenu menuItems={row.menuItems} />;
      case "hover-menu":
        return (
          <TableRowHoverMenu
            menuItems={row.menuItems}
            hoverMenuItems={row.hoverMenuItems}
          />
        );
      case "date":
        return row[valueKey]
          ? moment(row[valueKey]).format("MMMM D, YYYY h:mm A")
          : null;
      case "custom": {
        if (!column.customComponent) return null;
        const Component: FC<any> = column.customComponent;
        return <Component {...row.customComponentProps} />;
      }
      case "profile":
        return (
          <TableCellProfile
            firstLastName={row.firstLastName}
            email={row.email}
          />
        );
      case "number":
        return <Box textAlign="right">{row[valueKey]}</Box>;
      case "image":
        return (
          <Screenshot
            alt={row.imageAlt}
            src={row[valueKey]}
            imageClassName={classes.image}
            boxClassName={classes.imageBox}
          />
        );
      default:
        return <Box className={classes.clipBox}>{row[valueKey]}</Box>;
    }
  };

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      item
      className={clsx(classes.column, className)}
    >
      {renderCellContent(type, key)}
    </Grid>
  );
};

export default TableCell;
