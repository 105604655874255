import { createTheme } from "@mui/material/styles";
import palette from "./palette";
import typography from "./typography";
import utils from "./utils";

import "typeface-roboto";
import "typeface-fira-sans-condensed";

const theme = createTheme({
  typography,
  palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 960,
      md: 1200,
      lg: 1920,
      xl: 2560,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
          lineHeight: 1.43,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          transition: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          transition: "none",
        },
      },
    },
  },
  utils,
});

export default theme;
