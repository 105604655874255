import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import { IconButton, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Menu, MenuItem, Divider } from "../Menus";
import { useRootStoreContext } from "../../hooks";
import { ISuite } from "../../models";
import { ConfirmationModal } from "../Modals";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionsIcons: {
      color: theme.palette.baliHai.main,

      padding: theme.spacing(1),
      marginRight: theme.spacing(1),
      "&:last-child": {
        marginRight: theme.spacing(0),
      },
      "&:hover": {
        color: theme.palette.outrageousOrange.main,
      },
    },
    menuPaper: {
      width: 135,
      maxHeight: 193,
    },
  })
);

interface IMenuForItemOfNestedTestsProps {
  suite: ISuite;
  onLabelMenuOpen: (e: null | HTMLElement) => void | undefined;
}

const MenuForItemOfNestedTests: React.FC<IMenuForItemOfNestedTestsProps> =
  observer(({ suite, onLabelMenuOpen }) => {
    const { t } = useTranslation(["common"]);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { suites } = useRootStoreContext();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const history = useHistory();
    const { suiteId = "" } = useParams<{ suiteId: string }>();

    const onShowDeleteConfirm = () => {
      setShowConfirmationModal(true);
    };

    const onRemoveClick = () => {
      setAnchorEl(null);
      if (suite.id === suiteId) {
        const { parentSuite } = suite;
        history.push(
          `/projects/${parentSuite?.ProjectId}/tests/suites/${parentSuite?.id}/tests`
        );
      }
      suites.removeSuite(suite);
    };

    const onShowMenu = (event: React.SyntheticEvent<HTMLElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const onAddLabelClick = (
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      e.stopPropagation();
      onLabelMenuOpen(anchorEl);
      setAnchorEl(null);
    };

    return (
      <>
        <IconButton
          className={classes.actionsIcons}
          onClick={onShowMenu}
          size="large"
        >
          <MoreVertOutlinedIcon />
        </IconButton>
        <Menu
          PaperProps={{ className: classes.menuPaper }}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={onAddLabelClick}>
            <LabelOutlinedIcon />
            {t("menu_label")}
          </MenuItem>
          <MenuItem>
            <AccessTimeOutlinedIcon />
            {t("schedule")}
          </MenuItem>
          <MenuItem>
            <TimelineOutlinedIcon />
            {t("reports")}
          </MenuItem>
          <Divider />
          <MenuItem
            disabled={!!suite.defaultSuite}
            onClick={onShowDeleteConfirm}
          >
            <DeleteOutlinedIcon />
            {t("delete")}
          </MenuItem>
          <ConfirmationModal
            isOpened={showConfirmationModal}
            text={t("common:confirm")}
            onConfirm={onRemoveClick}
            onCancel={() => setShowConfirmationModal(false)}
          />
        </Menu>
      </>
    );
  });

export default MenuForItemOfNestedTests;
