import {
  types,
  flow,
  getSnapshot,
  applySnapshot,
  Instance,
  destroy,
  clone,
} from "mobx-state-tree";
import _ from "lodash";
import i18next from "i18next";
import Suite, { ISuite, ISuiteSnapshotOut } from "./Suite";
import { ITest } from "./Test";
import { handleFetch } from "./utils";

const setOpenedPathToSuite = (items: ISuite[], parentSuiteId: string) => {
  const parentSuite = _.find(items, { id: parentSuiteId });
  if (!parentSuite) return;
  parentSuite.isOpened = true;
  if (parentSuite.parentId) {
    setOpenedPathToSuite(items, parentSuite.parentId);
  }
};

const Suites = types
  .model("Suites", {
    isSearchTextFieldOpen: false,
    searchTextFieldValue: "",
    editSuite: types.maybeNull(Suite),
    newSuite: types.maybeNull(Suite),
    canAddSuite: types.optional(types.boolean, false),
    items: types.optional(types.array(Suite), []),
    state: types.optional(
      types.enumeration("SuitesState", ["loading", "ready"]),
      "loading",
    ),
  })
  .views((self) => ({
    get filteredItems() {
      return _.filter(self.items, { defaultSuite: true });
    },
    defaultParentId(ProjectId: ISuite["ProjectId"]) {
      return _.find(self.items, { defaultSuite: true, ProjectId })?.id;
    },
    getById(suiteId: ISuite["id"]) {
      return _.find(self.items, { id: suiteId });
    },
  }))
  .actions((self) => ({
    load: flow(function* (projectId: number) {
      try {
        self.state = "loading";
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${projectId}/suites`,
        );

        if (!response) return;
        const items = yield response.json();

        applySnapshot(self.items, items);
        //TODO: Show Success msg
      } catch (error) {
        //TODO: Show Error msg
        console.error("Error while load suites", error);
        throw error;
      } finally {
        self.state = "ready";
      }
    }),
    loadOneSuite: flow(function* (projectId: number, id: string) {
      try {
        if (_.find(self.items, { id })) {
          return;
        }

        self.state = "loading";
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${projectId}/suites/${id}`,
        );

        const item = yield response.json();
        self.items.push(item);
        //TODO: Show Success msg
      } catch (error) {
        //TODO: Show Error msg
        console.error("Error while load onse suite", error);
        throw error;
      } finally {
        self.state = "ready";
      }
    }),
    addNewSuite: flow(function* () {
      if (!self.newSuite) return;
      const suite = clone(self.newSuite);

      try {
        yield suite.save();

        self.items.push(suite);
        destroy(self.newSuite);
        //TODO: Show Success msg
      } catch (error) {
        //TODO: Show Error msg
        console.error("Error while addNewSuite", error);
        self.newSuite.error = i18next.t("suites:errors:duplicate");
        destroy(suite);
      }
    }),
    addNewSuiteWithTests: flow(function* (tests: ITest[], suiteId: string) {
      if (!self.newSuite) return;
      self.newSuite.name = _.trim(self.newSuite.name, ". ");
      const suite = Object.assign({}, clone(self.newSuite), { tests });
      try {
        self.state = "loading";
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${suite.ProjectId}/suites`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(suite),
          },
        );
        if (!response.ok) throw new Error("Could not do Suite save request");

        const item = yield response.json();
        item.withTests = true;
        item.TestCount = tests.length;
        destroy(self.newSuite);

        const updSuite = self.items.find((s) => s.id === suiteId);
        if (updSuite) updSuite.TestCount -= tests.length;

        self.items.push(item);
        return item;
        //TODO: Show Success msg
      } catch (error) {
        //TODO: Show Error msg
        console.error("Error while addNewSuite", error);
        self.newSuite.error = i18next.t("suites:errors:duplicate");
      } finally {
        self.state = "ready";
      }
    }),
    editExistingSuite: flow(function* () {
      if (!self.editSuite) return;
      const existingSuite = _.find(self.items, { id: self.editSuite.id });

      if (!existingSuite) return;
      const existingSuiteSnap = getSnapshot(existingSuite);

      try {
        applySnapshot(existingSuite, getSnapshot(self.editSuite));

        yield existingSuite.save();

        destroy(self.editSuite);

        //TODO: Show Success msg
      } catch (e) {
        //TODO: Show Error msg
        self.editSuite.error = i18next.t("suites:errors:duplicate");
        console.error("Error while editExistingSuite", e);
        applySnapshot(existingSuite, existingSuiteSnap);
      }
    }),
    removeSuite: flow(function* (item: ISuite) {
      try {
        yield item.remove();

        destroy(item);
        //TODO: Show Success msg
      } catch (error) {
        //TODO: Show Error msg
        console.error("Error while removeSuite", error);
      }
    }),
    changePosition: flow(function* (
      suite: ISuite,
      newParentId: ISuite["parentId"],
    ) {
      const cloneSuite = clone(suite);

      try {
        suite.parentId = newParentId;

        yield suite.save();

        //TODO: Show Success msg
      } catch (error) {
        //TODO: Show Error msg
        console.error("Error while changePosition", error);
        destroy(suite);
        self.items.push(cloneSuite);
      }
    }),
  }))
  .actions((self) => ({
    onEditExistingClick(suiteSnapshot: ISuiteSnapshotOut) {
      self.editSuite = Suite.create(suiteSnapshot);
    },
    setCanAddSuite(canAddSuiteOption: boolean) {
      self.canAddSuite = canAddSuiteOption;
    },
    onRemoveExistingClick() {
      self.editSuite && destroy(self.editSuite);
    },
    onAddNewClick(
      ProjectId: number,
      SuiteId: ISuite["id"],
      groupSelected?: boolean,
    ) {
      const suite = _.find(self.items, { id: SuiteId });
      if (!suite) return;
      suite.isOpened = true;
      self.newSuite = Suite.create({
        ProjectId,
        parentId: SuiteId || self.defaultParentId(ProjectId),
        withTests: groupSelected,
      });
    },
    onRemoveNewClick() {
      self.newSuite && destroy(self.newSuite);
    },
    onSearchOpenCloseClick() {
      self.searchTextFieldValue = "";
      self.isSearchTextFieldOpen = !self.isSearchTextFieldOpen;
    },
    onSearchTextFieldValueChange(event: React.ChangeEvent<HTMLInputElement>) {
      self.searchTextFieldValue = event.target.value;
    },
    onChangePosition(id: string, newParentId: string) {
      const suiteToUpdate = _.find(self.items, { id });

      if (!suiteToUpdate || !newParentId) {
        return;
      }

      self.changePosition(suiteToUpdate, newParentId);
    },
    openPathToCurrentSuite(currentSuiteId: string) {
      const suite = _.find(self.items, { id: currentSuiteId });
      if (suite?.parentId) setOpenedPathToSuite(self.items, suite.parentId);
    },
  }))
  .actions((self) => ({
    onGroupSelected(ProjectId: number, id: string) {
      const parentSuite = _.find(self.items, { id });
      if (parentSuite)
        self.onAddNewClick(
          ProjectId,
          parentSuite.parentId || parentSuite.id,
          true,
        );
    },
  }));

export interface ISuites extends Instance<typeof Suites> {}
export default Suites;
