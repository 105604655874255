import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useRootStoreContext } from "../../hooks";
import { ILabel } from "../../models";
import LabelItem from "./LabelItem";

const useStyles = makeStyles((theme: Theme) => ({
  labelItemContainer: {
    maxHeight: 7 * 40,
    overflowY: "auto",
    margin: theme.spacing(0, -1),
  },
}));

interface ILabelItemList {
  hasControls?: boolean;
  anchorEl: null | HTMLElement;
}

const LabelItemList: React.FC<ILabelItemList> = observer(
  ({ hasControls = true, anchorEl }) => {
    const { labels } = useRootStoreContext();
    const classes = useStyles();
    const { t } = useTranslation(["common"]);
    if (!anchorEl) return null;
    return (
      <>
        <Box>
          {t("selected_with_count", { count: labels.selectedLabelsCount })}
        </Box>
        <Box className={classes.labelItemContainer}>
          {_.map(labels.filteredItems, (label: ILabel) => (
            <LabelItem key={label.id} label={label} hasControls={hasControls} />
          ))}
        </Box>
      </>
    );
  }
);

export default LabelItemList;
