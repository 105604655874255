import { types, Instance, SnapshotOut } from "mobx-state-tree";

const Notification = types.model({
  type: types.optional(
    types.enumeration("NotificationType", [
      "error",
      "warning",
      "info",
      "success",
    ]),
    "info"
  ),
  id: types.maybe(types.number),
  text: "", //ToDO: replace with 'message'
  message: "",
  title: "",
  read: types.optional(types.boolean, false),
  createdAt: types.optional(types.string, ""),
});

export interface INotification extends Instance<typeof Notification> {}
export interface INotificationSnapshotOut
  extends SnapshotOut<typeof Notification> {}

export default Notification;
