import { FC, SyntheticEvent, useRef } from "react";
import { observer } from "mobx-react";
import { AlertColor, Fade, Snackbar } from "@mui/material";
import { addResourcesBundle } from "../../i18n";
import { useRootStoreContext } from "../../hooks";
import en from "./en.json";
import Alert from "./Alert";
import MuiAlertTitle from "@mui/lab/AlertTitle";

addResourcesBundle({ en });

const Notifications: FC = observer(() => {
  const { notifications } = useRootStoreContext();
  const { notificationToDisplay } = notifications;
  const snackRef = useRef<HTMLElement>(null);

  const handleClose = (event: Event | SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    event?.stopPropagation();
    notifications.removeNotification();
  };

  if (!notifications.notificationToDisplay) return null;

  return (
    <Snackbar
      open={!!notifications.notificationToDisplay}
      ref={snackRef}
      transitionDuration={0}
      TransitionComponent={Fade}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <div>
        <Alert
          onClose={handleClose}
          severity={notificationToDisplay.type as AlertColor}
        >
          <MuiAlertTitle>{notificationToDisplay.title}</MuiAlertTitle>
          {notificationToDisplay.text}
        </Alert>
      </div>
    </Snackbar>
  );
});

export default Notifications;
