import React from "react";
import _ from "lodash";
import { observer } from "mobx-react";
import { List, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useRootStoreContext } from "../../hooks";
import NoSearchResult from "../NoSearchResult";
import Element from "./Element";
import ItemDragLayer from "../ItemDragLayer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootBox: {
      overflowY: "auto",
      margin: theme.spacing(2, 3, 0, 3),
    },
  })
);

interface IElementList {
  searchIsEmpty: boolean;
}

const ElementList: React.FC<IElementList> = observer(({ searchIsEmpty }) => {
  const classes = useStyles();
  const { t } = useTranslation(["common"]);
  const { elements } = useRootStoreContext();

  return (
    <List disablePadding className={classes.rootBox}>
      {!elements.searchedItems.length ? (
        <NoSearchResult
          isEmpty={searchIsEmpty}
          description={t("nothing_here")}
        />
      ) : (
        _.map(elements.searchedItems, (element) => (
          <Element key={element.id} element={element} />
        ))
      )}
      <ItemDragLayer itemType="Element" iconName="WrapTextOutlined" />
    </List>
  );
});

export default ElementList;
