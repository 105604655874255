import { FC } from "react";
import { observer } from "mobx-react";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SeparateTests from "./SeparateTests";

const useStyles = makeStyles((theme: Theme) => ({
  rootBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: 0,
    overflowY: "scroll",
  },
}));

const SeparateTestsList: FC = observer(() => {
  const classes = useStyles();

  return (
    <Box className={classes.rootBox}>
      <SeparateTests />
    </Box>
  );
});

export default SeparateTestsList;
