import React from "react";
import { useTranslation } from "react-i18next";
import TextField, { ITextFieldProps } from "./TextField";

interface INumberTextField extends ITextFieldProps {
  value?: string;
  maxLength?: number;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur?: () => Promise<void>;
}

const NumberTextField: React.FC<INumberTextField> = ({
  value,
  maxLength,
  onChange,
  onBlur,
  inputBaseProps,
  ...props
}) => {
  const { t } = useTranslation(["common"]);

  const onValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!new RegExp(`^\\d{0,${maxLength || ""}}$`, "g").test(e.target.value))
      return;
    onChange && onChange(e);
  };

  return (
    <TextField
      {...props}
      helperTextRight={
        maxLength
          ? t("characters_left", { number: maxLength - (value?.length || 0) })
          : ""
      }
      inputBaseProps={{
        ...inputBaseProps,
        ...{ value, onChange: onValueChange, onBlur, fullWidth: true },
      }}
    />
  );
};

export default NumberTextField;
