import { map } from "lodash";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import { useRootStoreContext } from "../../hooks";
import { IEnvironment } from "../../models";
import EnvironmentListItem from "./EnvironmentListItem";

const EnvironmentList = observer(() => {
  const { environments } = useRootStoreContext();
  const { projectId = "" } = useParams<{ projectId: string }>();

  useEffect(() => {
    environments.load({ projectId: parseInt(projectId, 10) });
  }, [environments, projectId]);

  return (
    <Grid
      container
      flexDirection="column"
      sx={{ mt: "1rem", overflowY: "auto" }}
      wrap="nowrap"
    >
      {map(
        environments.filteredItemsWithGlobal,
        (environment: IEnvironment) => (
          <EnvironmentListItem
            key={`env-${environment.id}`}
            environment={environment}
          />
        )
      )}
    </Grid>
  );
});

export default EnvironmentList;
