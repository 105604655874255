import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme, Divider, Tabs, Tab } from "@mui/material";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import FiberSmartRecordOutlinedIcon from "@mui/icons-material/FiberSmartRecordOutlined";
import WrapTextOutlinedIcon from "@mui/icons-material/WrapTextOutlined";
import en from "./en.json";
import { addResourcesBundle } from "../../i18n";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    minHeight: 56,
    backgroundColor: theme.palette.white.main,
  },
  tabIndicator: {
    display: "none",
  },
  tab: {
    flex: "1 0 auto",
    minWidth: 0,
    padding: 0,
    height: 56,
    opacity: 1,
    "&:hover": {
      "& $icon": {
        color: theme.palette.outrageousOrange.main,
      },
    },
    "&.tabSelected": {
      backgroundColor: theme.palette.catskillLightWhite.main,
    },
    borderLeft: `1px solid ${theme.palette.catskillWhite.main}`,
    "&:first-of-type": {
      border: "none",
    },
  },
  divider: {
    backgroundColor: theme.palette.catskillWhite.main,
  },
  icon: {
    color: theme.palette.baliHai.main,
  },
}));

type ISideBarTabsValue = "shared-steps" | "actions" | "elements";
export interface ISideBarTabs {
  onSelect: (value: ISideBarTabsValue) => void;
  value: ISideBarTabsValue;
  useSharedSteps: boolean;
}

const SideBarTabs: React.FC<ISideBarTabs> = ({
  onSelect,
  value,
  useSharedSteps,
}) => {
  const classes = useStyles();

  return (
    <>
      <Tabs
        className={classes.tabs}
        value={value}
        onChange={(e, newValue) => onSelect(newValue)}
        scrollButtons={false}
        TabIndicatorProps={{ className: classes.tabIndicator }}
      >
        <Tab
          classes={{ selected: "tabSelected" }}
          icon={<FiberSmartRecordOutlinedIcon className={classes.icon} />}
          className={classes.tab}
          value="actions"
        />
        <Tab
          classes={{ selected: "tabSelected" }}
          icon={<WrapTextOutlinedIcon className={classes.icon} />}
          className={classes.tab}
          value="elements"
        />
        {useSharedSteps ? (
          <Tab
            icon={<ShareOutlinedIcon className={classes.icon} />}
            className={classes.tab}
            classes={{ selected: "tabSelected" }}
            value="shared-steps"
          />
        ) : null}
      </Tabs>
      <Divider className={classes.divider} />
    </>
  );
};

export default SideBarTabs;
