import React, { FC, useEffect } from "react";
import clsx from "clsx";
import { map } from "lodash";
import { observer } from "mobx-react";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useRootStoreContext } from "../../hooks";
import { IProject } from "../../models";
import ProjectListItem from "./ProjectListItem";
import { Redirect } from "react-router-dom";
import { EmptyContainer } from "../index";

const useStyles = makeStyles((theme: Theme) => ({
  projectListContainer: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
  },
  projectListHeader: {
    top: 0,
    zIndex: 1,
    flexShrink: 0,
    height: "3.5rem",
    position: "sticky",
    padding: theme.spacing(0, 4),
    backgroundColor: theme.palette.white.main,
  },
  headerContent: {
    borderBottom: `1px solid ${theme.palette.catskillWhite.main}`,
  },
  headerLabel: {
    ...theme.typography.tableLabelTextStyle,
    color: theme.palette.doveGray.main,
    paddingRight: 4,
  },
  columnXs: {
    flex: 1,
  },
  columnSm: {
    flex: 2,
  },
  columnMd: {
    flex: 3,
  },
  columnLg: {
    flex: 4,
  },
}));

const ProjectList: FC = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation(["projects"]);
  const {
    projects,
    users: { profile },
  } = useRootStoreContext();

  useEffect(() => {
    profile.load();
  }, [profile]);

  if (!profile.isAuthenticated) return <Redirect to="/sign-in" />;
  if (!projects.items?.length)
    return (
      <EmptyContainer
        title={t("empty_list_title")}
        description={t("empty_list_description")}
      />
    );

  return (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      justifyContent="flex-start"
      alignContent="center"
      className={classes.projectListContainer}
    >
      <Grid
        item
        container
        justifyContent="flex-start"
        className={classes.projectListHeader}
      >
        <Grid
          container
          alignItems="center"
          wrap="nowrap"
          className={classes.headerContent}
        >
          <Grid item className={clsx(classes.headerLabel, classes.columnMd)}>
            {t("logo")}
          </Grid>
          <Grid item className={clsx(classes.headerLabel, classes.columnLg)}>
            {t("name")}
          </Grid>
          <Grid item className={clsx(classes.headerLabel, classes.columnLg)}>
            {t("base_env")}
          </Grid>
          <Grid item className={clsx(classes.headerLabel, classes.columnSm)}>
            {t("role")}
          </Grid>
          <Grid item className={clsx(classes.headerLabel, classes.columnSm)}>
            {t("tests")}
          </Grid>
          <Grid item className={clsx(classes.headerLabel, classes.columnXs)}>
            {t("users")}
          </Grid>
          <Grid
            item
            className={clsx(classes.headerLabel, classes.columnXs)}
          ></Grid>
        </Grid>
      </Grid>
      {map(projects.sortedItems, (project: IProject) => (
        <ProjectListItem key={project.id} project={project} />
      ))}
    </Grid>
  );
});

export default ProjectList;
