import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { NotificationBar, Notifications } from "./components";
import {
  NoMatch,
  Projects,
  Dashboard,
  SignIn,
  SignUp,
  Confirm,
  Invite,
  NewPassword,
} from "./pages";
import { useRootStoreContext } from "./hooks";
import { PrivateRoute, PublicRoute } from "./components/Auth";

const useStyles = makeStyles(() => ({
  mainContainer: {
    height: "100%",
  },
  contentContainer: {
    position: "relative",
    minHeight: 0,
    flexGrow: 1,
  },
}));

const App: React.FC<{}> = observer(() => {
  // TODO: move to separate component
  // ToDo: remove this once the backend is ready
  const { noficationMessage, messageType } = {
    noficationMessage: "",
    messageType: "",
  };
  const [loading, setLoading] = useState(true);

  const classes = useStyles();
  const rootStore = useRootStoreContext();

  useEffect(() => {
    rootStore.initialize(() => setLoading(false));
  }, [rootStore]);

  return (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      className={classes.mainContainer}
    >
      <NotificationBar message={noficationMessage} type={messageType} />
      {!loading && ( // TODO: Add loader component
        <Grid item className={classes.contentContainer}>
          <Router>
            <Switch>
              <PublicRoute path="/sign-in" component={SignIn} />
              <PublicRoute path="/sign-up" component={SignUp} />
              <PublicRoute path="/confirm" component={Confirm} />
              <PublicRoute path="/invite" component={Invite} />
              <PublicRoute path="/reset" component={NewPassword} />
              <PrivateRoute path="/dashboard" component={Dashboard} />
              <PrivateRoute
                path="/projects/:projectId([0-9]+)"
                component={Projects}
              />
              <PrivateRoute exact path="/">
                <Redirect to="/dashboard/projects" />
              </PrivateRoute>
              <PrivateRoute path="*" component={NoMatch} />
            </Switch>
          </Router>
        </Grid>
      )}
      <Notifications />
    </Grid>
  );
});

export default App;
