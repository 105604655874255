import {
  SupervisorAccountOutlined,
  NotificationsNoneOutlined,
  SettingsApplicationsOutlined,
  TouchAppOutlined,
  AppsOutlined,
  InsertEmoticonOutlined,
} from "@mui/icons-material";

import i18n from "i18next";
import { addResourcesBundle } from "../../i18n";
import en from "./en.json";

addResourcesBundle({ en });

export const menuItems = [
  {
    label: i18n.t("dashboard_menu:personal_settings"),
    isSubheader: true,
  },
  {
    label: i18n.t("dashboard_menu:profile"),
    link: "profile/general",
    icon: <SupervisorAccountOutlined />,
  },
  {
    label: i18n.t("dashboard_menu:notifications"),
    link: "notifications",
    icon: <NotificationsNoneOutlined />,
  },
  {
    label: i18n.t("dashboard_menu:integrations"),
    link: "integrations",
    icon: <SettingsApplicationsOutlined />,
  },
  {
    label: i18n.t("dashboard_menu:administration"),
    isSubheader: true,
    blockedRole: "user",
  },
  {
    label: i18n.t("dashboard_menu:billing"),
    link: "billing",
    icon: <TouchAppOutlined />,
    blockedRole: "user",
  },
  {
    label: i18n.t("dashboard_menu:applications"),
    link: "applications",
    icon: <AppsOutlined />,
    blockedRole: "user",
  },
  {
    label: i18n.t("dashboard_menu:users"),
    link: "users",
    icon: <InsertEmoticonOutlined />,
    blockedRole: "user",
  },
];
