import "@mui/material/styles/createPalette";

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    outrageousOrange: PaletteColor;
    shark: PaletteColor;
    catskillLightWhite: PaletteColor;
    black: PaletteColor;
    white: PaletteColor;
    baliHai: PaletteColor;
    heather: PaletteColor;
    geyser: PaletteColor;
    catskillWhite: PaletteColor;
    pomegranate: PaletteColor;
    rockBlue: PaletteColor;
    mineShaft: PaletteColor;
    doveGray: PaletteColor;
    dustyGray: PaletteColor;
    guardsmanRed: PaletteColor;
    linen: PaletteColor;
    ivoryTower: PaletteColor;
    alto: PaletteColor;
    goldDrop: PaletteColor;
    oldLace: PaletteColor;
    japaneseLaurel: PaletteColor;
    aquaSpring: PaletteColor;
    successGreen: PaletteColor;
    pacificBlue: PaletteColor;
    paleRed: PaletteColor;
  }

  interface PaletteOptions {
    outrageousOrange: PaletteColorOptions;
    shark: PaletteColorOptions;
    catskillLightWhite: PaletteColorOptions;
    black: PaletteColorOptions;
    white: PaletteColorOptions;
    baliHai: PaletteColorOptions;
    heather: PaletteColorOptions;
    geyser: PaletteColorOptions;
    catskillWhite: PaletteColorOptions;
    pomegranate: PaletteColorOptions;
    rockBlue: PaletteColorOptions;
    mineShaft: PaletteColorOptions;
    doveGray: PaletteColorOptions;
    dustyGray: PaletteColorOptions;
    guardsmanRed: PaletteColorOptions;
    linen: PaletteColorOptions;
    ivoryTower: PaletteColorOptions;
    successGreen: PaletteColorOptions;
    pacificBlue: PaletteColorOptions;
    paleRed: PaletteColorOptions;
  }
}

const palette = {
  outrageousOrange: {
    main: "#FF5A33",
  },
  shark: {
    main: "#2E3033",
  },
  catskillLightWhite: {
    main: "#F5F7FA",
  },
  black: {
    main: "#000000",
  },
  white: {
    main: "#FFFFFF",
  },
  baliHai: {
    main: "#8F9BB2",
  },
  heather: {
    main: "#C1C8D6",
  },
  geyser: {
    main: "#DBDFE6",
  },
  catskillWhite: {
    main: "#F0F2F7",
  },
  pomegranate: {
    main: "#E53F17",
  },
  mineShaft: {
    main: "#333333",
  },
  doveGray: {
    main: "#666666",
  },
  dustyGray: {
    main: "#999999",
  },
  guardsmanRed: {
    main: "#CC0000",
  },
  linen: {
    main: "#FCF2F2",
  },
  rockBlue: {
    main: "#8F9BB3",
  },
  ivoryTower: {
    main: "#FDF7F7",
  },
  alto: {
    main: "#DADADA",
  },
  goldDrop: {
    main: "#E58600",
  },
  oldLace: {
    main: "#FEFBF7",
  },
  japaneseLaurel: {
    main: "#199900",
  },
  aquaSpring: {
    main: "#F7FDF9",
  },
  successGreen: {
    main: "#00B23B",
  },
  pacificBlue: {
    main: "#0088CC",
  },
  paleRed: {
    main: "#FFC5B8",
  },
};

export default palette;
