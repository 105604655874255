import { FC, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { map } from "lodash";
import { useTranslation } from "react-i18next";
import { IUser } from "../../models";
import { useRootStoreContext } from "../../hooks";
import { ReactComponent as AttributionIcon } from "../../icons/Attribution.svg";
import BulkMenus, { PendingUsersBulkMenu } from "../BulkMenus";
import Table, { ITableColumn } from "../Table";
import InviteUser from "./InviteUser";
import ProjectMenu from "./ProjectMenu";

const PendingUsers: FC = observer(() => {
  const { t } = useTranslation(["users"]);
  const { users } = useRootStoreContext();

  useEffect(() => {
    users.setSortParam("last_added");
    return () => users.removeUserToInvite();
  }, [users]);

  const columns: ITableColumn[] = [
    {
      type: "profile",
      label: t("name"),
      width: "4fr",
    },
    {
      key: "role",
      label: t("role"),
      width: "2fr",
    },
    {
      key: "teams",
      label: t("teams"),
      width: "2fr",
    },
    {
      type: "date",
      key: "createdAt",
      label: t("invitation_sent"),
      width: "3fr",
    },
    {
      type: "menu",
      width: "24px",
    },
  ];

  const handleRevokeInvitation = async (user: IUser) => {
    await user.revokeInvite();
    await users.load();
  };

  const menuItems = (user: IUser) => [
    {
      label: t("teams"),
      iconName: "SupervisedUserCircleOutlined",
      onClick: () => console.log(user.id, "teams"),
    },
    {
      label: t("projects"),
      iconName: "DashboardOutlined",
      onClick: (anchor: null | HTMLElement) => {
        onProjectMenuOpen(anchor, user.id);
      },
      disabled: user.role === "admin",
    },
    {
      label: t("resend_invitation"),
      iconName: "ArchiveOutlined",
      onClick: user.resendInvite,
    },
    {
      label: t("change_role"),
      icon: <AttributionIcon />,
      onClick: () => console.log(user.id, "change_role"),
    },
    {
      label: t("revoke_invitation"),
      iconName: "ArchiveOutlined",
      onClick: () => handleRevokeInvitation(user),
    },
  ];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

  const onProjectMenuOpen = (anchor: null | HTMLElement, userId: number) => {
    setSelectedUserId(userId);
    setAnchorEl(anchor);
  };

  const onProjectMenuClose = () => {
    setAnchorEl(null);
    setSelectedUserId(null);
  };

  const userItems = map(users.pendingUsers, (item) => ({
    ...item,
    role: t(`roles.${item.role}`),
    toggleSelected: item.toggleSelected,
    menuItems: menuItems(item),
  }));

  const userIds = selectedUserId ? [selectedUserId] : [];

  return (
    <>
      <Table
        columns={columns}
        rows={userItems}
        emptyListText={t("no_users")}
        emptyListDescription={t("no_users_description_pending")}
      >
        {users.userToInvite ? <InviteUser invite={users.userToInvite} /> : null}
      </Table>
      {anchorEl ? (
        <ProjectMenu
          anchorEl={anchorEl}
          userIds={userIds}
          onClose={onProjectMenuClose}
        />
      ) : null}
      {users.getSelectedSize(users.pendingUsers) > 1 ? (
        <BulkMenus>
          <PendingUsersBulkMenu />
        </BulkMenus>
      ) : null}
    </>
  );
});

export default PendingUsers;
