import { types, Instance } from "mobx-state-tree";

const ActionCookie = types
  .model("ActionCookie", {
    name: "",
    value: "",
  })
  .actions((self) => ({
    setName(name: string) {
      self.name = name;
    },
    setValue(value: string) {
      self.value = value;
    },
  }));

export interface IActionCookie extends Instance<typeof ActionCookie> {}
export default ActionCookie;
