import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { Theme, Divider, Grid } from "@mui/material";
import _ from "lodash";
import { ITestStep } from "../../../models";
import SharedActionBlock from "./SharedActionBlock";

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    backgroundColor: theme.palette.catskillWhite.main,
    margin: 0,
  },
  action: {
    margin: 0,
  },
}));

interface ISharedAction {
  testStep: ITestStep;
}

const SharedAction: React.FC<ISharedAction> = observer(({ testStep }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.action}>
      {_.map(testStep.testSharedStepActions, (item, index) => (
        <Grid className={classes.action} key={item.id}>
          <SharedActionBlock testStep={item} />
          {testStep.testSharedStepActions.length - 1 > index ? (
            <Divider className={classes.divider} />
          ) : null}
        </Grid>
      ))}
    </Grid>
  );
});

export default SharedAction;
