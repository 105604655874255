import { types, Instance, SnapshotOut, applySnapshot } from "mobx-state-tree";
import _ from "lodash";
import FilterAuthor, { IFilterAuthor } from "./FilterAuthor";

const FilterAuthors = types
  .model("Filter Authors Model", {
    creators: types.array(FilterAuthor),
    updaters: types.array(FilterAuthor),
    itemSearchValue: "",
  })
  .views((self) => ({
    filterItems(items: IFilterAuthor[]) {
      return _.filter(
        items,
        (author: IFilterAuthor) =>
          author.email
            .toLowerCase()
            .indexOf(self.itemSearchValue.toLowerCase()) > -1
      );
    },
    selectedItems(items: IFilterAuthor[]) {
      return _.chain(items).filter({ selected: true }).map(_.cloneDeep).value();
    },
    updatedSelectedItems(items: IFilterAuthor[], selected: string[]) {
      return _.map(items, (item) => ({
        ...item,
        selected: selected.includes(item.email),
      }));
    },
  }))
  .views((self) => ({
    get filteredCreators() {
      return self.filterItems(self.creators);
    },
    get filteredUpdaters() {
      return self.filterItems(self.updaters);
    },
    get selectedCreators() {
      return self.selectedItems(self.creators);
    },
    get selectedUpdaters() {
      return self.selectedItems(self.updaters);
    },
  }))
  .actions((self) => ({
    updateSelectedCreators(selected: string[]) {
      applySnapshot(
        self.creators,
        self.updatedSelectedItems(self.creators, selected)
      );
    },
    updateSelectedUpdaters(selected: string[]) {
      applySnapshot(
        self.updaters,
        self.updatedSelectedItems(self.updaters, selected)
      );
    },
    onItemSearch(searchValue: string) {
      self.itemSearchValue = searchValue;
    },
  }));

export interface IFilterAuthors extends Instance<typeof FilterAuthors> {}
export interface IFilterAuthorsSnapshotOut
  extends SnapshotOut<typeof FilterAuthors> {}

export default FilterAuthors;
