import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IFilterAuthors, IFilterAuthor } from "../../../models";
import AuthorItem from "./AuthorItem";

const useStyles = makeStyles((theme: Theme) => ({
  authorListContainer: {
    maxHeight: 7 * 40,
    overflowY: "auto",
    margin: theme.spacing(0, -1),
  },
}));

interface IAuthorList {
  authorStore: IFilterAuthors;
  isUpdaters: boolean;
}

const AuthorList: React.FC<IAuthorList> = observer(
  ({ authorStore, isUpdaters }) => {
    const classes = useStyles();
    const { t } = useTranslation(["common"]);
    const selectedItemsCount = isUpdaters
      ? authorStore.selectedUpdaters.length
      : authorStore.selectedCreators.length;

    return (
      <>
        <Box>{t("selected_with_count", { count: selectedItemsCount })}</Box>
        <Box className={classes.authorListContainer}>
          {_.map(
            isUpdaters
              ? authorStore.filteredUpdaters
              : authorStore.filteredCreators,
            (filterAuthor: IFilterAuthor) => (
              <AuthorItem key={filterAuthor.email} testAuthor={filterAuthor} />
            )
          )}
        </Box>
      </>
    );
  }
);

export default AuthorList;
