import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { IUser } from "../../models";
import StyledIconButton from "../StyledIconButton";

const useStyles = makeStyles((theme: Theme) => ({
  listItemContainer: {
    height: "4.625rem",
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.catskillWhite.main}`,
    },
  },
  listColName: {
    flex: "none",
    width: "17rem",
  },
  listColRole: {
    flex: "none",
    width: "7.75rem",
    color: theme.palette.mineShaft.main,
    ...theme.typography.bodyTextStyle,
  },
  listColAdd: {
    flex: "none",
    width: "1.5rem",
  },
  userAvatar: {
    width: "2.5rem",
    height: "2.5rem",
    marginRight: theme.spacing(),
  },
  userName: {
    color: theme.palette.mineShaft.main,
    ...theme.typography.bodyTextStyle,
  },
  userEmail: {
    color: theme.palette.doveGray.main,
    ...theme.typography.descriptionTextStyle,
  },
  actionButton: {
    width: "1.5rem",
    height: "1.5rem",
  },
}));

interface IUserListItem {
  user: IUser;
  userActionType: "add" | "remove";
  onUserAction: (user: IUser) => void;
}

const UserListItem: FC<IUserListItem> = ({
  user,
  userActionType,
  onUserAction,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(["project_create"]);

  return (
    <Grid container alignItems="center" className={classes.listItemContainer}>
      <Grid item container wrap="nowrap" className={classes.listColName}>
        <Grid item>
          <Avatar className={classes.userAvatar}>
            {user.firstLastName?.charAt(0)}
          </Avatar>
        </Grid>
        <Grid item container direction="column">
          <Grid item className={classes.userName}>
            {user.firstLastName}
          </Grid>
          <Grid item className={classes.userEmail}>
            {user.email}
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.listColRole}>
        {t(`roles.${user.role}`)}
      </Grid>
      <Grid item className={classes.listColAdd}>
        {user.role !== "admin" && (
          <StyledIconButton
            onClick={() => onUserAction(user)}
            className={classes.actionButton}
          >
            {userActionType === "add" ? <AddCircleOutline /> : null}
            {userActionType === "remove" ? <RemoveCircleOutline /> : null}
          </StyledIconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default UserListItem;
