import i18next from "i18next";
import moment from "moment";
import { initReactI18next } from "react-i18next";
import _ from "lodash";
import en from "./en.json";

type ITranslationResourse = { [key: string]: string | object };
type IResourses = {
  en: ITranslationResourse;
};
export function addResourcesBundle(resourses: IResourses) {
  _.each(resourses, (resource: ITranslationResourse, language: string) => {
    _.each(resource, (items, namespase) => {
      i18next.addResourceBundle(language, namespase, items);
    });
  });
}

i18next.use(initReactI18next).init({
  resources: {
    en,
  },
  defaultNS: "common",
  lng: "en",
  fallbackLng: "en",
});

i18next.on("languageChanged", (lng: "en") => {
  moment.locale(lng);
});

export default i18next;
