import { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import {
  Layout,
  LayoutOfContent,
  Header,
  TaIcon,
  StyledButton,
} from "../components";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    ...theme.typography.subHeaderTextStyle,
    color: theme.palette.doveGray.main,
  },
  titleIcon: {
    width: "1.5rem",
    height: "1.5rem",
    marginRight: theme.spacing(1),
    color: theme.palette.alto.main,
  },
  description: {
    textAlign: "center",
    whiteSpace: "pre-line",
    margin: theme.spacing(1, 0, 2),
    color: theme.palette.doveGray.main,
    ...theme.typography.bodyTextStyle,
  },
  button: {
    textDecoration: "none",
  },
}));

const NoMatch: FC<{}> = () => {
  const { t } = useTranslation(["404"]);
  const classes = useStyles();
  return (
    <Layout>
      <Header isEmpty={true} />
      <LayoutOfContent>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item container justifyContent="center" alignItems="center">
            <TaIcon iconName="InfoOutlined" className={classes.titleIcon} />
            <Grid item className={classes.title}>
              {t("title")}
            </Grid>
          </Grid>
          <Grid item className={classes.description}>
            {t("description")}
          </Grid>
          <Grid item container justifyContent="center">
            <Link to="/dashboard/projects" className={classes.button}>
              <StyledButton variant="contained" color="primary">
                {t("button")}
              </StyledButton>
            </Link>
          </Grid>
        </Grid>
      </LayoutOfContent>
    </Layout>
  );
};

export default NoMatch;
