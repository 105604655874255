import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import {
  Layout,
  LayoutOfContent,
  Header,
  ProjectCreate,
  Projects,
  Profile,
  Users,
  DashboardSideMenu,
} from "../components";

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    padding: theme.spacing(0),
    width: "100%",
    maxWidth: 1360,
  },
  dashboard: {
    padding: theme.spacing(3),
  },
  sidebarBlock: {
    flex: "0 1 360px",
    marginRight: theme.spacing(3),
    backgroundColor: theme.palette.white.main,
  },
  contentBlock: {
    flex: "2 1 100%",
  },
}));

const Dashboard: React.FC = () => {
  const { path } = useRouteMatch();
  const classes = useStyles();

  return (
    <Layout>
      <Header isEmpty={true} />
      <LayoutOfContent className={classes.layout}>
        <Grid container wrap="nowrap" className={classes.dashboard}>
          <Grid container className={classes.sidebarBlock}>
            <DashboardSideMenu />
          </Grid>
          <Grid container wrap="nowrap" className={classes.contentBlock}>
            <Switch>
              <Route path={`${path}/projects`} component={Projects} />
              <Route
                path={`${path}/project-create`}
                component={ProjectCreate}
              />
              <Route path={`${path}/profile`} component={Profile} />
              <Route path={`${path}/users`} component={Users} />
              <Route path={path}>
                <Redirect to={`${path}/projects`} />
              </Route>
              {/* Add routes and components here */}
            </Switch>
          </Grid>
        </Grid>
      </LayoutOfContent>
    </Layout>
  );
};

export default Dashboard;
