import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  FilterListOutlined,
  FontDownloadOutlined,
  KeyboardArrowDownOutlined,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useRootStoreContext } from "../../hooks";
import StyledButton from "../StyledButton";

const useStyles = makeStyles((theme: Theme) => ({
  controlBarContainer: {
    height: theme.spacing(7),
    padding: theme.spacing(0, 2),
    backgroundColor: theme.palette.white.main,
  },
  sortMenu: {
    padding: theme.spacing(1, 4),
  },
  dividerWrapper: {
    padding: theme.spacing(0, 4),
    backgroundColor: theme.palette.white.main,
  },
  divider: {
    backgroundColor: theme.palette.catskillWhite.main,
  },
}));

const VariablesFilterRow: React.FC = observer(() => {
  const classes = useStyles();
  const { projectId } = useParams<{ projectId: string }>();
  const { variables } = useRootStoreContext();
  const { t: headerT } = useTranslation(["header"]);
  const { t: variablesT } = useTranslation(["variables"]);

  const onCreateClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    variables.onVariableAdd(parseInt(projectId, 10));
  };

  return (
    <Grid
      container
      item
      wrap="nowrap"
      justifyContent="space-between"
      alignItems="center"
      className={classes.controlBarContainer}
    >
      <Grid item container alignItems="center" justifyContent="flex-start">
        <StyledButton startIcon={<FilterListOutlined />}>
          {headerT("filters")}
        </StyledButton>
        <StyledButton endIcon={<KeyboardArrowDownOutlined />}>
          {variablesT("Last added")}
        </StyledButton>
      </Grid>
      <Grid item container alignItems="center" justifyContent="flex-end">
        <StyledButton
          onClick={onCreateClick}
          startIcon={<FontDownloadOutlined />}
        >
          {variablesT("new_variable")}
        </StyledButton>
      </Grid>
    </Grid>
  );
});

export default VariablesFilterRow;
