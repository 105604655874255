import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Box, List, Theme, Divider, ListItem } from "@mui/material";
import en from "./en.json";
import { useRootStoreContext } from "../../hooks";
import { addResourcesBundle } from "../../i18n";
import ListOfItemOfNestedTests from "./ListOfItemOfNestedTests";
import HeaderForListOfItemOfNestedTests from "./HeaderForListOfItemOfNestedTests";
import { Search } from "../Inputs";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    height: 56,
    padding: theme.spacing(0, 3),
  },
  rootBox: {
    backgroundColor: theme.palette.white.main,
    marginRight: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      minWidth: 320,
      width: 320,
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 360,
      width: 360,
    },
    display: "flex",
    flexDirection: "column",
  },
  overflowContainer: {
    overflowY: "auto",
  },
  list: {
    backgroundColor: theme.palette.white.main,
    padding: 0,
    marginBottom: theme.spacing(2),
  },
  divider: {
    backgroundColor: theme.palette.catskillWhite.main,
    margin: theme.spacing(0, 3),
  },
}));

const SideBarOfGroupedTests: React.FC<{}> = observer((props) => {
  const { suites } = useRootStoreContext();
  const { suiteId = "" } = useParams<{ suiteId: string }>();
  const classes = useStyles();

  useEffect(() => {
    suites.openPathToCurrentSuite(suiteId);
    return () => {
      if (suites.isSearchTextFieldOpen) suites.onSearchOpenCloseClick();
      suites.onRemoveExistingClick();
      suites.onRemoveNewClick();
    };
  }, [suites, suiteId]);

  return (
    <Box className={classes.rootBox}>
      <List className={classes.list}>
        {suites.isSearchTextFieldOpen ? (
          <ListItem className={classes.listItem}>
            <Search
              onChange={suites.onSearchTextFieldValueChange}
              value={suites.searchTextFieldValue}
              onClose={suites.onSearchOpenCloseClick}
            />
          </ListItem>
        ) : (
          <HeaderForListOfItemOfNestedTests />
        )}
        <Divider className={classes.divider} />
      </List>
      <Box className={classes.overflowContainer}>
        <List className={classes.list}>
          <ListOfItemOfNestedTests items={suites.filteredItems} />
        </List>
      </Box>
    </Box>
  );
});

export default SideBarOfGroupedTests;
