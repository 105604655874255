import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { addResourcesBundle } from "../../i18n";
import { Menu, MenuItem } from "../Menus";
import en from "./en.json";

addResourcesBundle({ en });

interface ISortMenu {
  anchorEl: null | HTMLElement;
  onMenuClose: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  className: string | undefined;
  sort: (sortBy: string) => void;
}

const SortMenu: React.FC<ISortMenu> = observer(
  ({ anchorEl, onMenuClose, className, sort }) => {
    const { t } = useTranslation(["test_sort_menu"]);

    const onSortClick = (
      sortBy: string,
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      e.stopPropagation();
      sort(sortBy);
      onMenuClose(e);
    };

    return (
      <Menu
        anchorEl={anchorEl}
        onClose={onMenuClose}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        className={className}
      >
        <MenuItem onClick={(e) => onSortClick("createdAt", e)}>
          {t("createdAt")}
        </MenuItem>
        <MenuItem onClick={(e) => onSortClick("updatedAt", e)}>
          {t("updatedAt")}
        </MenuItem>
      </Menu>
    );
  }
);

export default SortMenu;
