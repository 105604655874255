import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import AddBoxOutlined from "@mui/icons-material/AddBoxOutlined";
import SearchOutlined from "@mui/icons-material/SearchOutlined";

import { useRootStoreContext } from "../../hooks";
import { IProject } from "../../models";

import { Search } from "../Inputs";
import StyledButton from "../StyledButton";
import ProjectListItem from "./ProjectListItem";

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaperAnchorLeft: {
    width: 360,
  },
  backdropClass: {
    backgroundColor: theme.utils.withHexOpacity(theme.palette.baliHai, 80),
  },
  searchBlock: {
    padding: "20px 8px 24px",
  },
  searchTextField: {
    margin: theme.spacing(0, 4),
    marginTop: "1.25rem",
  },
  subHeaderTextStyle: {
    ...theme.typography.subHeaderTextStyle,
    margin: theme.spacing(3, 4),
  },
  projectList: {
    overflowY: "auto",
    height: "100%",
  },
  addProjectWrapper: {
    ...theme.typography.bodyTextStyle,
    padding: theme.spacing(2, 1),
    backgroundColor: theme.palette.white.main,
  },
}));

interface IProjectDrawer {
  currentProject?: IProject;
  drawerOpen: boolean;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const ProjectDrawer: React.FC<IProjectDrawer> = observer(
  ({ currentProject, drawerOpen, toggleDrawer }) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation(["header", "common"]);
    const { projects } = useRootStoreContext();

    const onProjectClick = (
      event: React.KeyboardEvent | React.MouseEvent,
      projectId: number
    ) => {
      history.push(`/projects/${projectId}/tests`);
      toggleDrawer(false)(event);
    };

    const onProjectAdd = () => {
      history.push("/dashboard/project-create");
    };

    return (
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        classes={{ paperAnchorLeft: classes.drawerPaperAnchorLeft }}
        ModalProps={{
          BackdropProps: {
            classes: { root: classes.backdropClass },
          },
        }}
      >
        {projects.isSearchTextFieldOpen ? (
          <Search
            labelText={t("common:search")}
            onChangeText={projects.onProjectSearch}
            value={projects.projectSearchValue}
            onClose={projects.onSearchOpenCloseClick}
            className={classes.searchTextField}
          />
        ) : (
          <Grid container alignItems="center" className={classes.searchBlock}>
            <StyledButton
              startIcon={<SearchOutlined />}
              onClick={projects.onSearchOpenCloseClick}
            >
              {t("find_project")}
            </StyledButton>
          </Grid>
        )}
        <h3 className={classes.subHeaderTextStyle}>{t("workspaces")}</h3>
        <List className={classes.projectList}>
          {projects.filteredItems.map((project) => {
            return (
              <ProjectListItem
                key={project.id}
                project={project}
                selected={project.id === currentProject?.id}
                onProjectClick={onProjectClick}
              />
            );
          })}
        </List>
        <Grid container className={classes.addProjectWrapper}>
          <StyledButton startIcon={<AddBoxOutlined />} onClick={onProjectAdd}>
            {t("add_new_project")}
          </StyledButton>
        </Grid>
      </Drawer>
    );
  }
);

export default ProjectDrawer;
