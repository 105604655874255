import React, { useState } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FolderSharedOutlinedIcon from "@mui/icons-material/FolderSharedOutlined";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useRootStoreContext } from "../../hooks";
import StyledButton from "./StyledButton";
import SelectMenuItem from "./SelectMenuItem";
import { ConfirmationModal } from "../Modals";

const useStyles = makeStyles(() => ({
  sharedStepButton: {
    "&:disabled": {
      opacity: 0.35,
    },
  },
  selectMenuItem: {
    marginLeft: "auto",
  },
}));

interface ISharedStepsBulkMenus {
  useSharedSteps?: boolean;
}

const SharedStepsBulkMenus: React.FC<ISharedStepsBulkMenus> = observer(
  ({ useSharedSteps = true }) => {
    const classes = useStyles();
    const { t } = useTranslation(["bulk_menus", "common"]);
    const {
      projectId = "",
      suiteId = "",
      testId = "",
    } = useParams<{
      projectId: string;
      suiteId: string;
      testId: string;
    }>();
    const { testSteps } = useRootStoreContext();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const onShowDeleteConfirm = () => {
      setShowConfirmationModal(true);
    };

    const onDeleteClick = () => {
      testSteps.remove({
        projectId: parseInt(projectId, 10),
        suiteId,
        testId,
      });
    };

    const onSharedStep = () => {
      testSteps.onNewSharedCreate();
    };

    const topLevelItems = testSteps.items.filter((ts) => ts.StepId === testId);

    return (
      <>
        <StyledButton
          startIcon={<DeleteOutlineOutlinedIcon />}
          onClick={onShowDeleteConfirm}
        >
          {t("common:delete")}
        </StyledButton>
        {useSharedSteps ? (
          <StyledButton
            startIcon={<FolderSharedOutlinedIcon />}
            onClick={onSharedStep}
            className={classes.sharedStepButton}
            disabled={testSteps.isDisabledSharedStep(testId)}
          >
            {t("bulk_menus:shared_step")}
          </StyledButton>
        ) : null}
        <SelectMenuItem
          selectedSize={testSteps.getSelectedSize(topLevelItems)}
          onSelectAll={() => testSteps.toAllsetSelect(topLevelItems, true)}
          onSelectNone={() => testSteps.toAllsetSelect(topLevelItems, false)}
          containerClassName={classes.selectMenuItem}
        />
        <ConfirmationModal
          isOpened={showConfirmationModal}
          text={t("common:confirm")}
          onConfirm={onDeleteClick}
          onCancel={() => setShowConfirmationModal(false)}
        />
      </>
    );
  }
);

export default SharedStepsBulkMenus;
