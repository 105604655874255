import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import _ from "lodash";
import { NumberTextField } from "../Inputs";
import { ITestStep } from "../../models";

interface IPauseAction {
  testStep: ITestStep;
}

const PauseAction: React.FC<IPauseAction> = observer(({ testStep }) => {
  const { t } = useTranslation(["actions"]);
  const bounced = useCallback(
    () => _.debounce(testStep.save, 1000),
    [testStep.save]
  );

  useEffect(() => {
    if (!testStep.value) testStep.setValue("500");
  }, [testStep]);

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    testStep.setValue(e.target.value);
    bounced();
  };

  return (
    <>
      <Grid item>
        <NumberTextField
          labelText={t("pause_time")}
          maxLength={8}
          value={testStep.value || ""}
          onChange={onChangeValue}
          onBlur={testStep.save}
        />
      </Grid>
    </>
  );
});

export default PauseAction;
