import { types, Instance, cast, destroy } from "mobx-state-tree";
import ActionCookie, { IActionCookie } from "./ActionCookie";

const ActionCookies = types
  .model("ActionCookies", {
    jsonbValue: types.optional(types.array(ActionCookie), []),
  })
  .actions((self) => ({
    addNewCookie() {
      if (self.jsonbValue) {
        self.jsonbValue.push(ActionCookie.create({}));
      } else {
        self.jsonbValue = cast([ActionCookie.create({})]);
      }
    },
    removeCookie(cookie: IActionCookie) {
      destroy(cookie);
    },
  }));

export interface IActionCookies extends Instance<typeof ActionCookies> {}
export default ActionCookies;
