import { Grid } from "@mui/material";
import { EnvironmentSideMenu, VariablesTable } from "../components";

const Variables = () => {
  return (
    <Grid
      container
      columns={2}
      columnSpacing={"1.5rem"}
      wrap="nowrap"
      sx={{ marginBottom: "1.5rem", minHeight: 0 }}
    >
      <Grid item sx={{ flex: "0 1 22.5rem" }}>
        <EnvironmentSideMenu />
      </Grid>
      <Grid item sx={{ flex: "2 1 100%" }}>
        <VariablesTable />
      </Grid>
    </Grid>
  );
};

export default Variables;
