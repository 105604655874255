import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Grid, Box, Divider, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  AddBoxOutlined,
  KeyboardArrowDownOutlined,
  FilterListOutlined,
} from "@mui/icons-material";
import { useRootStoreContext } from "../../hooks";
import StyledButton from "../StyledButton";
import TestFilterMenu from "../ListFilterMenu";
import SortMenu from "../SortMenu";
import FilterBar from "../FilterBar";

const useStyles = makeStyles((theme: Theme) => ({
  controlBarContainer: {
    height: theme.spacing(7),
    padding: theme.spacing(0, 2),
    backgroundColor: theme.palette.white.main,
  },
  sortMenu: {
    margin: theme.spacing(0, 3),
  },
  dividerWrapper: {
    padding: theme.spacing(0, 4),
    backgroundColor: theme.palette.white.main,
  },
  divider: {
    backgroundColor: theme.palette.catskillWhite.main,
  },
}));

const ControlBar: React.FC = observer(() => {
  const classes = useStyles();
  const { projectId = "" } = useParams<{ projectId: string }>();
  const { testSteps, sharedStepFilters, sharedStepAuthors } =
    useRootStoreContext();
  const { t } = useTranslation(["shared_steps"]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [sortAnchorEl, setSortAnchorEl] = React.useState<null | HTMLElement>(
    null
  );

  const onFilterMenuClose = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onFilterMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onSortMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSortAnchorEl(e.currentTarget);
  };

  const onSortMenuClose = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    setSortAnchorEl(null);
  };

  const onCreateClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    testSteps.onNewEmptySharedCreate(parseInt(projectId, 10));
  };

  return (
    <>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        className={classes.controlBarContainer}
      >
        <Grid item container alignItems="center" justifyContent="flex-start">
          <StyledButton
            startIcon={<FilterListOutlined />}
            onClick={onFilterMenuClick}
          >
            {t("filters")}
          </StyledButton>
          <TestFilterMenu
            anchorEl={anchorEl}
            onMenuClose={onFilterMenuClose}
            filterStore={sharedStepFilters}
            authorStore={sharedStepAuthors}
            isSharedStep={true}
          />
          <StyledButton
            endIcon={<KeyboardArrowDownOutlined />}
            onClick={onSortMenuClick}
          >
            {t("test_sort_menu:" + testSteps.sortField)}
          </StyledButton>
          <SortMenu
            anchorEl={sortAnchorEl}
            onMenuClose={onSortMenuClose}
            className={classes.sortMenu}
            sort={testSteps.setSortField}
          />
        </Grid>
        <Grid item container alignItems="center" justifyContent="flex-end">
          <StyledButton onClick={onCreateClick} startIcon={<AddBoxOutlined />}>
            {t("create_shared_step")}
          </StyledButton>
        </Grid>
      </Grid>
      {sharedStepFilters.isEmpty ? null : (
        <>
          <Box className={classes.dividerWrapper}>
            <Divider className={classes.divider} />
          </Box>
          <FilterBar filterStore={sharedStepFilters} entityType="tests" />
        </>
      )}
    </>
  );
});

export default ControlBar;
