import React, { ReactNode } from "react";
import clsx from "clsx";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    display: "flex",
    flex: "1 1 auto",
    margin: "0 auto",
    minHeight: 0,
    padding: theme.spacing(3, 3, 0, 3),
    maxWidth: 1360,
    width: "100%",
  },
}));

interface ILayoutOfContentProps {
  className?: string;
  children?: ReactNode;
}

const LayoutOfContent: React.FC<ILayoutOfContentProps> = ({
  className,
  children,
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.box, className ? className : null)}>
      {children}
    </Box>
  );
};

export default LayoutOfContent;
