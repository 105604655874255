import { FC, useEffect } from "react";
import { map } from "lodash";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CloseOutlined } from "@mui/icons-material";
import { addResourcesBundle } from "../../i18n";
import {
  IElementFilters,
  IListFilters,
  IReportFilters,
  IUserFilters,
} from "../../models";
import StyledButton from "../StyledButton";
import FilterItem from "./FilterItem";
import en from "./en.json";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  filterBarContainer: {
    backgroundColor: theme.palette.white.main,
    padding: theme.spacing(0, 2),
    width: "auto",
  },
  filterContainer: {
    padding: theme.spacing(1, 0),
  },
  clearFilter: {
    display: "flex",
    alignItems: "center",
    maxHeight: theme.spacing(6),
  },
}));

export type filterEntityType = "reports" | "elements" | "users" | "tests";
export type filterStore =
  | IReportFilters
  | IElementFilters
  | IUserFilters
  | IListFilters;

interface IFilterBar {
  filterStore: filterStore;
  entityType: filterEntityType;
}

const FilterBar: FC<IFilterBar> = observer(({ filterStore, entityType }) => {
  const classes = useStyles();
  const { t } = useTranslation(["applied_filter_bar"]);
  const { projectId = "" } = useParams<{ projectId: string }>();

  useEffect(() => {
    if (!("setProjectId" in filterStore)) return;
    if (filterStore.projectId && filterStore.projectId !== projectId) {
      filterStore.clearAllFilters!();
    }
    filterStore.setProjectId(projectId);
  }, [filterStore, projectId]);

  return (
    <Grid container item wrap="nowrap" className={classes.filterBarContainer}>
      <Grid
        container
        item
        alignItems="center"
        className={classes.filterContainer}
      >
        {map(filterStore, (value, filterKey) => {
          if (filterKey === "projectId") return null;
          return (
            <FilterItem
              key={filterKey + value}
              filterStore={filterStore}
              value={value}
              filterKey={filterKey}
              entityType={entityType}
            />
          );
        })}
      </Grid>
      <Grid item className={classes.clearFilter}>
        <StyledButton
          startIcon={<CloseOutlined />}
          onClick={filterStore.clearAllFilters}
        >
          {t("clear_filters")}
        </StyledButton>
      </Grid>
    </Grid>
  );
});

export default FilterBar;
