import { types, Instance, SnapshotOut, applySnapshot } from "mobx-state-tree";
import _ from "lodash";
import { createBrowserHistory } from "history";
import { updateUrlFilter } from "./utils";

const history = createBrowserHistory();

const UserFilters = types
  .model("User Filters", {
    nameFilter: "",
    emailFilter: "",
    roleFilter: types.optional(types.array(types.string), []),
  })
  .views((self) => ({
    get isEmpty() {
      return _.reduce(self, (r, value) => r && _.isEmpty(value), true);
    },
    get filterUrl() {
      return window.location.search;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      const filters = new URLSearchParams(window.location.search);
      self.nameFilter = filters.get("nameFilter") || "";
      self.emailFilter = filters.get("emailFilter") || "";
      applySnapshot(self.roleFilter, filters.getAll("roleFilter"));
    },
  }))
  .actions((self) => ({
    saveNameFilter(nameFilter: string) {
      self.nameFilter = nameFilter;
      updateUrlFilter("nameFilter", nameFilter);
    },
    clearNameFilter() {
      self.nameFilter = "";
      updateUrlFilter("nameFilter", "");
    },
    saveEmailFilter(emailFilter: string) {
      self.emailFilter = emailFilter;
      updateUrlFilter("emailFilter", emailFilter);
    },
    clearEmailFilter() {
      self.emailFilter = "";
      updateUrlFilter("emailFilter", "");
    },
    saveRoleFilter(roles: string[]) {
      applySnapshot(self.roleFilter, roles);
      updateUrlFilter("roleFilter", roles);
    },
    clearRoleFilter(role: string) {
      const roles = _.without(self.roleFilter, role);
      applySnapshot(self.roleFilter, roles);
      updateUrlFilter("roleFilter", roles);
    },
    clearAllFilters() {
      applySnapshot(self, {});
      //ToDo: revert once fixed https://github.com/ReactTraining/history/issues/814
      history.push({ pathname: window.location.pathname, search: "" });
    },
  }))
  .actions((self) => ({
    getUsersClearAction(filterName: string, filter: any) {
      switch (filterName) {
        case "nameFilter":
          return self.clearNameFilter;
        case "emailFilter":
          return self.clearEmailFilter;
        case "roleFilter":
          return () => self.clearRoleFilter(filter!);
        default:
          return () => null;
      }
    },
  }));
export interface IUserFilters extends Instance<typeof UserFilters> {}
export interface IUserFiltersSnapshotOut
  extends SnapshotOut<typeof UserFilters> {}

export default UserFilters;
