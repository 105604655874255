import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export default makeStyles((theme: Theme) => ({
  appBar: {
    alignItems: "center",
    alignSelf: "center",
    maxWidth: 1312,
    margin: theme.spacing(0, 3),
  },

  empty: {
    backgroundColor: "transparent",
    boxShadow: "none",
    maxWidth: 1360,
    margin: 0,
  },

  itemContentWidth: {
    flexShrink: 0,
    width: "auto",
  },

  toolbar: {
    minHeight: 80,
    alignItems: "stretch",
    padding: theme.spacing(0, 3),
    minWidth: theme.breakpoints.values.sm,
    maxWidth: 1360,
  },

  projectNameBlock: {
    marginRight: theme.spacing(2),
    cursor: "pointer",
  },

  projectNameText: {
    ...theme.typography.topHeaderTextStyle,
    marginLeft: "1rem",
  },

  button: {
    padding: theme.spacing(),
    marginRight: theme.spacing(2),
    color: theme.palette.baliHai.main,
    textTransform: "none",
    transition: "none",
    "&:hover": {
      borderRadius: 0,
      color: theme.palette.outrageousOrange.main,
      backgroundColor: theme.palette.catskillLightWhite.main,
    },
  },

  avatar: {
    zIndex: 1,
    backgroundColor: theme.palette.baliHai.main,
  },

  userNameBlock: {
    color: theme.palette.mineShaft.main,
    marginLeft: theme.spacing(-1),
    padding: theme.spacing(1, 1, 1, 2),
    "& .MuiSvgIcon-root": {
      fontSize: 24,
      color: theme.palette.baliHai.main,
    },
    "&:hover .MuiSvgIcon-root": {
      color: theme.palette.outrageousOrange.main,
    },
  },

  userNameText: {
    ...theme.typography.bodyTextStyle,
  },

  logo: {
    width: 48,
    height: 48,
  },

  badge: {
    backgroundColor: theme.palette.outrageousOrange.main,
  },
}));
