import {
  types,
  flow,
  applySnapshot,
  Instance,
  getSnapshot,
  getRoot,
  SnapshotOut,
} from "mobx-state-tree";
import { find, map } from "lodash";
import moment from "moment";
import WithSelected from "./WithSelected";
import WithSelectable from "./WithSelectable";
import ProjectInvite from "./ProjectInvite";
import { handleFetch, getFilteredUsers } from "./utils";
import { IProject, IRoot, IUserFilters } from ".";
import { SelectChangeEvent } from "@mui/material";

const ProjectUser = types.model({
  id: types.identifierNumber,
  Project: types.model({
    id: types.identifierNumber,
    name: types.string,
  }),
});

interface IProjectUser {
  id: number;
  projectIds: number[];
}

export const ALL_HINTS = [
  "add_project",
  "add_test",
  "record_test",
  "install_client",
];

export const User = types
  .compose(
    types.model("User Model", {
      id: types.number,
      maxProjects: types.number,
      firstLastName: types.maybeNull(types.string),
      email: types.string,
      website: types.maybeNull(types.string),
      githubId: types.maybeNull(types.string),
      linkedIn: types.maybeNull(types.string),
      apiKey: types.maybeNull(types.string),
      recorderExtId: types.maybeNull(types.string),
      confirmationCode: types.maybeNull(types.string),
      about: types.maybeNull(types.string),
      company: types.maybeNull(types.string),
      position: types.maybeNull(types.string),
      confirmed: types.boolean,
      OrganizationId: types.number,
      role: types.string,
      firstSignIn: types.maybeNull(types.boolean),
      lastApiCallAt: types.maybeNull(types.string),
      lastSignInAt: types.maybeNull(types.string),
      apiCallsCount: types.maybeNull(types.number),
      loginsCount: types.maybeNull(types.number),
      locked: types.maybeNull(types.boolean),
      type: types.string,
      createdAt: types.maybe(types.string),
      updatedAt: types.maybe(types.string),
      isAuthenticated: types.optional(types.boolean, true),
      ProjectUsers: types.optional(types.array(ProjectUser), []),
      hints: types.maybeNull(types.array(types.string)),
    }),
    WithSelected
  )
  .views((self) => ({
    isHintShown(hint: string) {
      return !!self.hints?.includes(hint);
    },
  }))
  .actions((self) => ({
    login: flow(function* (
      data: any,
      onSuccess: () => void,
      onFailure: (err: any) => void
    ) {
      try {
        const response = yield fetch(
          `${process.env.REACT_APP_API_URL}/auth/signin`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
            credentials: "include",
          }
        );
        if (response.ok) {
          const user = yield response.json();
          applySnapshot(self, user);
          onSuccess();
        } else {
          const res = yield response.json();
          onFailure(res);
        }
      } catch (error) {
        console.error("Failed to login", error);
        onFailure(error);
      }
    }),
    logout: flow(function* () {
      try {
        yield fetch(`${process.env.REACT_APP_API_URL}/auth/signout`);
        window.location.href = "/sign-in";
      } catch (error) {
        console.error("Failed to logout", error);
      }
    }),
    signup: flow(function* (
      data: any,
      onSuccess: () => void,
      onFailure: (msg: any) => void
    ) {
      try {
        const response = yield fetch(
          `${process.env.REACT_APP_API_URL}/auth/signup`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
            credentials: "include",
          }
        );
        if (response.ok) {
          onSuccess();
        } else {
          const error = yield response.json();
          onFailure(error?.message || "Failed to signup");
        }
      } catch (error) {
        console.error("Failed to sign up", error);
        onFailure("Failed to sign up");
      }
    }),
    confirm: flow(function* (
      data: any,
      onSuccess: () => void,
      onFailure: (msg: any, path?: string) => void,
      invite: boolean = false
    ) {
      try {
        const response = yield fetch(
          `${process.env.REACT_APP_API_URL}/auth/confirm${
            invite ? "-invite" : ""
          }`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
            credentials: "include",
          }
        );
        if (response.ok) {
          const user = yield response.json();
          applySnapshot(self, user);
          onSuccess();
        } else {
          const error = yield response.json();
          onFailure(
            error?.message || "Failed to confirm user account",
            error?.path
          );
        }
      } catch (error) {
        console.error("Failed to confirm user account", error);
        onFailure("Failed to confirm user account");
      }
    }),
    resetPassword: flow(function* (
      data: any,
      onSuccess: () => void,
      onFailure: (msg: any, path?: string) => void
    ) {
      try {
        const response = yield fetch(
          `${process.env.REACT_APP_API_URL}/auth/reset-password`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
            credentials: "include",
          }
        );
        if (response.ok) {
          const user = yield response.json();
          applySnapshot(self, user);
          onSuccess();
        } else {
          const error = yield response.json();
          onFailure(error);
        }
      } catch (error) {
        console.error("Failed to reset user password", error);
        onFailure("Failed to reset user password");
      }
    }),
    resendInvite: flow(function* () {
      try {
        yield fetch(`${process.env.REACT_APP_API_URL}/auth/invite`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ id: self.id }),
        });
      } catch (error) {
        console.error("Failed to resend user invite", error);
      }
    }),
    revokeInvite: flow(function* () {
      try {
        yield fetch(`${process.env.REACT_APP_API_URL}/auth/invite/${self.id}`, {
          method: "DELETE",
        });
      } catch (error) {
        console.error("Failed to revoke user invite", error);
      }
    }),
    onChangeFirstLastName(e: React.ChangeEvent<HTMLTextAreaElement>) {
      self.firstLastName = e.target.value;
    },
    onChangeCompany(e: React.ChangeEvent<HTMLTextAreaElement>) {
      self.company = e.target.value;
    },
    onChangeRole(e: SelectChangeEvent<unknown>) {
      if (typeof e.target.value === "string") {
        self.role = e.target.value;
      }
    },
    onChangeRecorderExtId(e: React.ChangeEvent<HTMLTextAreaElement>) {
      self.recorderExtId = e.target.value;
    },
    onChangeApiKey(e: React.ChangeEvent<HTMLTextAreaElement>) {
      self.apiKey = e.target.value;
    },
    load: flow(function* () {
      try {
        const response = yield fetch(
          `${process.env.REACT_APP_API_URL}/users/profile`
        );

        if (response.status === 401) {
          self.isAuthenticated = false;
          return;
        }

        const user = yield response.json();
        applySnapshot(self, user);
      } catch (error) {
        console.error("Failed to load users", error);
      }
    }),
    save: flow(function* () {
      try {
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/users/${self.id}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(getSnapshot(self)),
          }
        );
        const user = yield response.json();
        applySnapshot(self, user);
      } catch (error) {
        console.error("Failed to load users", error);
      }
    }),
    unauthorized() {
      self.isAuthenticated = false;
    },
    updateHints: flow(function* (hint: string | string[]) {
      if (typeof hint === "string" && self.isHintShown(hint)) return;

      try {
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/users/hints`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              hints: (self.hints || ([] as string[])).concat(hint),
            }),
          }
        );
        self.hints = yield response.json();
      } catch (error) {
        console.error("Failed to update shown hints", error);
      }
    }),
  }))
  .actions((self) => ({
    skipAllHints() {
      self.updateHints(ALL_HINTS);
    },
  }));

const Users = types
  .compose(
    types.model("Users Model", {
      users: types.optional(types.array(User), []),
      profile: types.optional(User, {
        id: 0,
        maxProjects: 10,
        firstLastName: "",
        email: "",
        website: null,
        githubId: null,
        linkedIn: null,
        apiKey: "",
        recorderExtId: null,
        confirmationCode: null,
        about: null,
        company: "",
        position: "",
        confirmed: false,
        OrganizationId: 0,
        role: "",
        firstSignIn: null,
        lastApiCallAt: null,
        lastSignInAt: "",
        apiCallsCount: null,
        loginsCount: 0,
        type: "",
        isAuthenticated: true,
        hints: [],
      }),
      sortParam: "",
      userToInvite: types.maybe(ProjectInvite),
    }),
    WithSelectable
  )
  .views((self) => ({
    get sortedUsers() {
      let sortParam: keyof IUser;
      const iteratee = (u1: IUser, u2: IUser) => {
        switch (self.sortParam) {
          case "last_added":
            sortParam = "createdAt";
            break;
          case "last_activity":
            sortParam = "lastApiCallAt";
            break;
          case "by_name":
            sortParam = "firstLastName";
            break;
          case "by_email":
            sortParam = "email";
            break;
          case "last_deactivated":
            sortParam = "updatedAt";
            break;
        }
        if (u1[sortParam] === u2[sortParam]) return 0;

        if (
          ["createdAt", "lastApiCallAt", "updatedAt"].includes(
            sortParam as string
          )
        ) {
          if (!u1[sortParam]) return 1;
          if (!u2[sortParam]) return -1;
          return moment(u1[sortParam]).isBefore(u2[sortParam]) ? -1 : 1;
        }

        if (["firstLastName", "email"].includes(sortParam as string))
          return u1[sortParam] > u2[sortParam] ? 1 : -1;

        return 0;
      };

      return self.sortParam ? self.users.slice().sort(iteratee) : self.users;
    },
  }))
  .views((self) => ({
    get filteredUsers() {
      const filters: IUserFilters = getRoot<IRoot>(self).userFilters;

      return getFilteredUsers(self.sortedUsers, filters);
    },
  }))
  .views((self) => ({
    get activeUsers() {
      return self.filteredUsers.filter(
        (user) => user.confirmed && !user.locked
      );
    },
    get pendingUsers() {
      return self.filteredUsers.filter((user) => !user.confirmed);
    },
    get deactivatedUsers() {
      return self.filteredUsers.filter((user) => user.locked);
    },
    get activeAndPendingUsers() {
      return self.sortedUsers.filter((user) => !user.locked);
    },
    get activeOwners() {
      return self.sortedUsers.filter(
        (user) => user.role === "admin" && !user.locked
      );
    },
    get activeMembers() {
      return self.sortedUsers.filter(
        (user) => user.role !== "admin" && !user.locked
      );
    },
  }))
  .actions((self) => ({
    load: flow(function* () {
      try {
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/users`
        );
        const users = yield response.json();
        applySnapshot(self.users, users);
      } catch (error) {
        console.error("Failed to load users", error);
      }
    }),
    loadProjectUsers: flow(function* (projectId) {
      try {
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/users`
        );
        const users = yield response.json();
        const projectUsers = users.filter((user: IUser) =>
          map(user.ProjectUsers, ({ Project }) => Project.id).includes(
            parseInt(projectId, 10)
          )
        );
        applySnapshot(self.users, projectUsers);
      } catch (error) {
        console.error("Failed to load users in loadProjectUsers method", error);
      }
    }),
    toggleUserLock: flow(function* ({
      userIds,
      lockState,
    }: {
      userIds: number[];
      lockState: boolean;
    }) {
      try {
        const response = yield handleFetch(self, `/api/users/toggleLock`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userIds, lockState }),
        });
        if (!response.ok && response.status === 401) {
          console.error("Unauthorized request");
        }
      } catch (error) {
        console.error("Failed to update users", error);
      }
    }),
    saveUserProjects: flow(function* (userIds: number[]) {
      try {
        const users: IProjectUser[] = map<any, IProjectUser>(userIds, (id) => {
          const { projects } = getRoot<IRoot>(self);
          const selectedProjectIds = map<any, number>(
            projects.getSelectedItems(projects.items),
            (project: IProject) => project.id
          );
          const notSelectedProjectIds = map<any, number>(
            projects.getNotSelectedItems(projects.items),
            (project: IProject) => project.id
          );
          const userProjectIds = map<any, number>(
            find(self.users, { id })?.ProjectUsers,
            (user) => user.Project.id
          );
          const projectIds = [
            ...userProjectIds.filter(
              (projectId) => !notSelectedProjectIds.includes(projectId)
            ),
            ...selectedProjectIds,
          ];
          return {
            id,
            projectIds,
          };
        });

        const response = yield handleFetch(self, `/api/users/usersProjects`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ users }),
        });
        if (!response.ok && response.status === 401) {
          console.error("Unauthorized request");
        }
      } catch (error) {
        console.error(
          "Failed to save user projects in saveUserProjects method",
          error
        );
      }
    }),
    deleteUsersFromProject: flow(function* (userIds, projectId) {
      try {
        const users = self.users.filter(({ id }) => userIds.includes(id));
        const usersWithFilteredProjects = map(users, (user) => {
          const userProjectIds = map(
            user?.ProjectUsers,
            (project) => project.Project.id
          );
          const filteredProjectIds = userProjectIds.filter(
            (id) => id !== parseInt(projectId, 10)
          );
          return {
            id: user.id,
            projectIds: filteredProjectIds,
          };
        });

        yield handleFetch(self, `/api/users/usersProjects`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            users: usersWithFilteredProjects,
          }),
        });
      } catch (error) {
        console.error(
          "Failed delete user from project in deleteUserFromProject method",
          error
        );
      }
    }),
    setUserToInvite() {
      self.userToInvite = ProjectInvite.create();
    },
    removeUserToInvite() {
      self.userToInvite = undefined;
    },
    setSortParam(sortParam: string) {
      self.sortParam = sortParam;
    },
  }));

export interface IUsers extends Instance<typeof Users> {}
export interface IUser extends Instance<typeof User> {}
export interface IUserSnapshotOut extends SnapshotOut<typeof User> {}

export default Users;
