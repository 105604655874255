import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Grid, Theme, Box } from "@mui/material";
import _ from "lodash";
import { ITestStep, IActionCookie } from "../../../models";
import { TextField } from "../../Inputs";
import StyledButton from "../../StyledButton";
import TaIcon from "../../TaIcon";

const useStyles = makeStyles((theme: Theme) => ({
  rootBlock: {
    marginBottom: theme.spacing(4),
  },
  textBlock: {
    marginTop: theme.spacing(4),
  },
  buttonBlock: {
    marginLeft: theme.spacing(-1),
    marginTop: theme.spacing(-2),
  },
}));

interface ICookieBlock {
  cookie: IActionCookie;
  testStep: ITestStep;
  disabled: boolean;
  index: number;
}

const CookieBlock: React.FC<ICookieBlock> = observer(
  ({ testStep, cookie, disabled, index }) => {
    const classes = useStyles();
    const cookiesLength = testStep.jsonbValue.length;
    const { t } = useTranslation(["actions", "common"]);
    const bounced = useCallback(
      () => _.debounce(testStep.save, 1000),
      [testStep.save]
    );

    const onCookieAdd = () => {
      testStep.addNewCookie();
      testStep.save();
    };

    const onCookieRemove = (cookie: IActionCookie) => {
      testStep.removeCookie(cookie);
      testStep.save();
    };

    const onCookieNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      if (e.target.value.length > 50) return;
      cookie.setName(e.target.value);
      bounced();
    };

    const onCookieValueChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      e.stopPropagation();
      if (e.target.value.length > 255) return;
      cookie.setValue(e.target.value);
      bounced();
    };

    return (
      <Grid item className={classes.rootBlock}>
        <TextField
          labelText={t("cookie_name")}
          disabled={disabled}
          helperTextRight={t("common:characters_left", {
            number: 50 - (cookie.name.length || 0),
          })}
          inputBaseProps={{
            fullWidth: true,
            value: cookie.name,
            onChange: onCookieNameChange,
            onBlur: testStep.save,
          }}
        />
        {testStep.type === "deleteCookies" ? null : (
          <TextField
            boxClassName={classes.textBlock}
            labelText={t(`cookie_value_${testStep.type}`)}
            helperTextRight={t("common:characters_left", {
              number: 255 - (cookie.value.length || 0),
            })}
            inputBaseProps={{
              fullWidth: true,
              rows: 5,
              maxRows: 5,
              multiline: true,
              value: cookie.value,
              onChange: onCookieValueChange,
              onBlur: testStep.save,
            }}
          />
        )}
        <Box className={classes.buttonBlock}>
          {cookiesLength > 1 ? (
            <StyledButton
              startIcon={<TaIcon iconName="RemoveCircleOutline" />}
              disabled={disabled}
              onClick={() => onCookieRemove(cookie)}
            >
              {t("remove_cookie")}
            </StyledButton>
          ) : null}
          {cookiesLength === 1 || cookiesLength === index + 1 ? (
            <StyledButton
              startIcon={<TaIcon iconName="AddCircleOutline" />}
              disabled={disabled}
              onClick={onCookieAdd}
            >
              {t("add_cookie")}
            </StyledButton>
          ) : null}
        </Box>
      </Grid>
    );
  }
);

export default CookieBlock;
