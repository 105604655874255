import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { Grid, ListItem, Theme, Typography } from "@mui/material";
import { ITestStep } from "../../../models";
import StepIcon from "../../TestSteps/StepIcon";
import Screenshot from "../../Screenshot";
import { useRootStoreContext } from "../../../hooks";

const useStyles = makeStyles((theme: Theme) => ({
  stepWrapper: {
    padding: 0,
  },
  step: {
    justifyContent: "space-between",
    minHeight: 96,
    padding: 0,
    backgroundColor: theme.palette.white.main,
  },
  titleWithIcon: {
    marginBottom: theme.spacing(1),
  },
  title: {
    ...theme.typography.subHeaderTextStyle,
    color: theme.palette.mineShaft.main,
    wordBreak: "break-all",
  },
  description: {
    ...theme.typography.descriptionTextStyle,
    color: theme.palette.doveGray.main,
    marginLeft: theme.spacing(4),
  },
  screenshot: {
    display: "flex",
    height: 48,
    width: 130,
  },
}));

interface ISharedActionBlock {
  testStep: ITestStep;
}

const SharedActionBlock: React.FC<ISharedActionBlock> = observer(
  ({ testStep }) => {
    const { actionsForSelect } = useRootStoreContext();
    const classes = useStyles();

    return (
      <Grid className={classes.stepWrapper}>
        <ListItem
          classes={{ selected: "testStepSelected" }}
          component={Grid}
          container
          className={classes.step}
          wrap="nowrap"
        >
          <Grid item container direction="column" wrap="nowrap">
            <Grid
              item
              container
              wrap="nowrap"
              className={classes.titleWithIcon}
            >
              <Grid container alignItems="center" wrap="nowrap">
                <StepIcon testStep={testStep} />
                <Typography className={classes.title}>
                  {testStep.title}
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.description}>
              {testStep.description}
            </Grid>
          </Grid>
          {actionsForSelect.isElementType(testStep.type) ? (
            <Grid item className={classes.screenshot}>
              <Screenshot alt="sharedAction" src={testStep.imgSrc} />
            </Grid>
          ) : null}
        </ListItem>
      </Grid>
    );
  }
);

export default SharedActionBlock;
