import { withStyles } from "@mui/styles";
import { IconButton, Theme } from "@mui/material";

export default withStyles((theme: Theme) => ({
  root: {
    ...theme.typography.bodyTextStyle,
    padding: theme.spacing(1),
    borderRadius: 0,
    color: theme.palette.baliHai.main,
    "&:hover": {
      color: theme.palette.paleRed.main,
    },
  },
}))(IconButton);
