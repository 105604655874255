import React from "react";
import {
  Theme,
  Radio,
  FormControlLabel,
  FormControlLabelProps,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  labelFormControl: {
    margin: 0,
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(-1),
  },
  labelFormControlText: {
    minWidth: 0,
    ...theme.typography.bodyTextStyle,
    color: theme.palette.mineShaft.main,
  },
  radio: {
    padding: theme.spacing(1),
    "&:hover, &.Mui-checked": {
      backgroundColor: "transparent",
      "& .MuiSvgIcon-root": {
        color: theme.palette.outrageousOrange.main,
      },
    },
    "&.Mui-checked:hover": {
      backgroundColor: "transparent",
      "& .MuiSvgIcon-root": {
        color: theme.palette.pomegranate.main,
      },
    },
    "& .MuiSvgIcon-root": {
      marginRight: 0,
      width: 20,
      height: 20,
      color: theme.palette.baliHai.main,
    },
  },
}));

interface IRadio extends Omit<FormControlLabelProps, "control"> {}

const SharedRadio: React.FC<IRadio> = ({
  value,
  label,
  labelPlacement = "end",
  ...props
}) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      classes={{
        root: classes.labelFormControl,
        label: classes.labelFormControlText,
      }}
      value={value}
      control={<Radio className={classes.radio} disableRipple />}
      label={label}
      labelPlacement={labelPlacement}
      {...props}
    />
  );
};

export default SharedRadio;
