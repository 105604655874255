import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import clsx from "clsx";
import _, { uniq } from "lodash";
import { makeStyles } from "@mui/styles";
import { Grid, Theme, ListItem } from "@mui/material";
import { BorderColorOutlined } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { ITest, ILabel } from "../../models";
import { useRootStoreContext } from "../../hooks";
import { ReactComponent as KeyboardReturn } from "../../icons/KeyboardReturn.svg";
import LabelMenu from "../LabelMenu";
import StyledIconButton from "../StyledIconButton";
import TestFormTextArea from "./TestFormTextArea";
import MenuForItem from "./MenuForItem";
import { LabelMenuContext } from "../../contexts";
import { useTranslation } from "react-i18next";

interface IListItemProps {
  test: ITest;
  isSingleItem?: boolean;
  useSharedSteps?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  listItemContainer: {
    position: "relative",
    backgroundColor: theme.palette.white.main,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(3, 4),
    "&:hover": {
      backgroundColor: theme.palette.catskillLightWhite.main,
      cursor: "pointer",
      "&:not(.editMode) $testControlContainer": {
        visibility: "visible",
      },
    },
    "&$selected, &$selected:hover": {
      backgroundColor: theme.palette.catskillLightWhite.main,
    },
  },
  testTitle: {
    ...theme.typography.bodyTextStyle,
    color: theme.palette.mineShaft.main,
    paddingRight: theme.spacing(7),
    wordBreak: "break-word",
  },
  testLabelContainer: {
    margin: theme.spacing(1, 0),
  },
  testLabel: {
    padding: theme.spacing(1),
    margin: theme.spacing(1, 1, 0, 0),
    border: `1px solid ${theme.palette.catskillWhite.main}`,
    ...theme.typography.labelTextStyle,
  },
  testUpdateContainer: {
    ...theme.typography.descriptionTextStyle,
    color: theme.palette.doveGray.main,
  },
  testControlContainer: {
    visibility: "hidden",
    position: "absolute",
    top: 0,
    right: theme.spacing(3),
    width: "auto",
    height: "100%",
  },
  controlBlock: {
    alignItems: "flex-start",
    padding: theme.spacing(2, 0),
    backgroundColor: theme.palette.catskillLightWhite.main,
  },
  gradientBlock: {
    padding: theme.spacing(9, 5, 0, 0),
    background: `linear-gradient(90deg, ${theme.palette.catskillLightWhite.main}00 0%, ${theme.palette.catskillLightWhite.main} 100%)`,
  },
  selected: {
    borderLeft: `4px solid ${theme.palette.outrageousOrange.main}`,
    paddingLeft: "1.75rem",
  },
}));

const TestItem: React.FC<IListItemProps> = observer(
  ({ test, isSingleItem, useSharedSteps }) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation("tests");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { tests, labels } = useRootStoreContext();

    const getDraggingItemsIds = () => {
      const itemIds = (tests.getSelectedItems(tests.items) as ITest[]).map(
        (test: ITest) => test.id
      );
      itemIds.push(test.id);
      return uniq(itemIds);
    };

    const getDragLayerText = () => {
      const itemsLength = getDraggingItemsIds().length;
      return itemsLength > 1
        ? t("move_tests", { length: itemsLength })
        : test.name;
    };

    const [{ isDragging }, drag, preview] = useDrag({
      type: "Test",
      item: {
        type: "Test",
        dragLayerText: getDragLayerText(),
        testIds: getDraggingItemsIds(),
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
        currentOffset: monitor.getSourceClientOffset(),
      }),
    });

    useEffect(() => {
      preview(getEmptyImage());
    }, [preview]);

    useEffect(() => {
      tests.toggleDraggingStateForSelectedTests(isDragging);
    }, [tests, isDragging]);

    useEffect(
      () => () => {
        tests.onCancelAddNewTest();
        tests.onCancelEditExistingTest();
        tests.toAllsetSelect(tests.items, false);
      },
      [tests]
    );

    const onTestItemClick = () => {
      if (isSingleItem) {
        return;
      }
      test.toggleSelected();
    };

    const onLabelMenuOpen = (anchor: null | HTMLElement) => {
      setAnchorEl(anchor);
    };

    const onLabelMenuClose = () => {
      labels.onMenuClose();
      setAnchorEl(null);
    };

    const renderTestBody = () => {
      // ToDo: split and move to separate components
      if (!test.id && tests.newTest) {
        return (
          <TestFormTextArea test={tests.newTest} isTest={useSharedSteps} />
        );
      }

      return (
        <>
          {tests.editTest?.id === test.id ? (
            <TestFormTextArea test={tests.editTest} isTest={useSharedSteps} />
          ) : (
            <Grid
              container
              item
              justifyContent="flex-start"
              className={classes.testTitle}
            >
              {test.name}
            </Grid>
          )}
          <Grid container item className={classes.testLabelContainer}>
            {_.map(test.Labels, (label: ILabel) => (
              <Grid item key={label.id} className={classes.testLabel}>
                {label.name}
              </Grid>
            ))}
          </Grid>
          <Grid
            container
            item
            justifyContent="space-between"
            className={classes.testUpdateContainer}
          >
            <Grid item>
              {!isSingleItem ? test.updated : test.updatedWithEmail}
            </Grid>
            <Grid item>
              {!isSingleItem ? test.changerUserEmail : test.createdWithEmail}
            </Grid>
          </Grid>
          <Grid
            container
            item
            className={classes.testControlContainer}
            wrap="nowrap"
          >
            <Grid container item className={classes.gradientBlock} />
            <Grid container item className={classes.controlBlock} wrap="nowrap">
              {!isSingleItem ? (
                <StyledIconButton
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    e.stopPropagation();
                    history.push(
                      `/projects/${test.ProjectId}/tests/suites/${test.SuiteId}/tests/${test.id}/steps`
                    );
                  }}
                >
                  <KeyboardReturn />
                </StyledIconButton>
              ) : null}
              <StyledIconButton onClick={test.onEditExistingClick}>
                <BorderColorOutlined />
              </StyledIconButton>
              <MenuForItem
                test={test}
                onLabelMenuOpen={onLabelMenuOpen}
                isSingleItem={isSingleItem}
              />
            </Grid>
          </Grid>
          <LabelMenuContext.Provider
            value={{ testIds: [test.id], suiteId: test.SuiteId }}
          >
            <LabelMenu
              anchorEl={anchorEl}
              currentLabels={getSnapshot(test).Labels}
              onClose={onLabelMenuClose}
            />
          </LabelMenuContext.Provider>
        </>
      );
    };

    return (
      <ListItem
        ref={drag}
        component={Grid}
        container
        direction="column"
        classes={{ selected: classes.selected }}
        className={clsx(classes.listItemContainer, {
          editMode: tests.editTest,
        })}
        disabled={test.testState === "dragging" || isDragging}
        onClick={onTestItemClick}
        selected={!test.id || test.selected}
      >
        {renderTestBody()}
      </ListItem>
    );
  }
);

export default TestItem;
