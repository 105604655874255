import {
  types,
  flow,
  applySnapshot,
  destroy,
  clone,
  getSnapshot,
} from "mobx-state-tree";
import _ from "lodash";
import Variable from "./Variable";
import WithSelectable from "./WithSelectable";
import { handleFetch } from "./utils";

const Variables = types
  .compose(
    types.model("Variables", {
      newVariable: types.maybeNull(Variable),
      searchTextFieldValue: "",
      items: types.optional(types.array(Variable), []),
      state: types.optional(
        types.enumeration("VariablesState", ["loading", "ready"]),
        "ready",
      ),
    }),
    WithSelectable,
  )
  .views((self) => ({
    get filteredItems() {
      const searchTextFieldValue = self.searchTextFieldValue.toLowerCase();

      return _.filter(
        self.items,
        (item) => item.name.toLowerCase().indexOf(searchTextFieldValue) > -1,
      );
    },
    parseValues(ids: number[], envId: number | undefined) {
      // find used variables in action
      const variables = _.filter(self.items, (item) =>
        ids.includes(item.id as number),
      );
      return variables.reduce((result: any, variable) => {
        // if global variable return value
        if (variable.global) {
          result[variable.name as keyof typeof result] = variable.value;
        } else {
          // if env variable find by env id and return value
          const envVar = variable.EnvironmentVariables.find(
            (envVar) => envVar.EnvironmentId === envId,
          );
          result[variable.name as keyof typeof result] = envVar!?.value;
        }
        return result;
      }, {});
    },
  }))
  .actions((self) => ({
    load: flow(function* ({
      projectId,
      selected,
    }: {
      projectId: number;
      selected?: (string | undefined)[];
    }) {
      try {
        self.state = "loading";
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${projectId}/variables`,
        );

        if (!response) return;
        const variables = yield response.json();

        if (selected)
          self.items.forEach(
            (item) => (item.selected = selected.includes(item.id?.toString())),
          );

        applySnapshot(self.items, variables);
        //TODO: Show Sucsess msg
      } catch (error) {
        //TODO: Show Error msg
        console.error("Error while load variables", error);
        applySnapshot(self.items, []);
        // throw error;
      } finally {
        self.state = "ready";
      }
    }),
    loadEnvironmentVariables: flow(function* ({
      projectId,
      environmentId,
    }: {
      projectId: number;
      environmentId?: number;
    }) {
      try {
        self.state = "loading";
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${projectId}/environments/${environmentId}/variables`,
        );

        if (!response) return;
        const variables = yield response.json();

        applySnapshot(self.items, variables);
        //TODO: Show Sucsess msg
      } catch (error) {
        //TODO: Show Error msg
        console.error("Error while load variables", error);
        throw error;
      } finally {
        self.state = "ready";
      }
    }),
    addVariable: flow(function* (projectId: string) {
      if (!self.newVariable) return;

      const variable = clone(self.newVariable);
      const newVariableSnapshot = getSnapshot(variable);

      try {
        const newVariable = yield variable.save(projectId);
        destroy(self.newVariable);
        return newVariable;
      } catch (e: any) {
        console.error(`Error while addVariable in model Variables: ${e}`);
        applySnapshot(self.newVariable, newVariableSnapshot);
        self.newVariable.setServerError(e.code || "");
        destroy(variable);
      }
    }),
    setSearchTextFieldValue(value: string) {
      self.searchTextFieldValue = value;
    },
    onVariableAdd(ProjectId: number) {
      self.newVariable = Variable.create({ ProjectId, global: true });
    },
    onCancelVariableAdd() {
      self.newVariable && destroy(self.newVariable);
    },
  }));

export default Variables;
