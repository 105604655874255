import _ from "lodash";
import { types, applySnapshot, Instance } from "mobx-state-tree";
import ActionsItems, { IActionsItems } from "./ActionsItems";
import { IAction } from "./Action";
import { IActionType } from "./utils";

const BaseActions = types.model("BaseActions", {
  searchTextFieldValue: "",
  selectedTabName: types.optional(
    types.enumeration(["element", "browser"]),
    "element",
  ),
  browserItems: types.optional(types.array(ActionsItems), []),
  elementsItems: types.optional(types.array(ActionsItems), []),
  state: types.optional(
    types.enumeration("ActionsState", ["loading", "ready"]),
    "ready",
  ),
});

const Actions = BaseActions.actions((self) => ({
  load() {
    applySnapshot(self.browserItems, [
      {
        groupKey: "default",
        items: [
          { type: "visit" },
          { type: "setCookies" },
          { type: "deleteCookies" },
          { type: "assertCookieValue" },
          { type: "pause" },
          { type: "switchWindow" },
          { type: "setWindowSize" },
          { type: "scrollTo" },
        ],
      },
    ]);
    applySnapshot(self.elementsItems, [
      {
        groupKey: "default",
        items: [
          { type: "click" },
          { type: "doubleClick" },
          { type: "setValue" },
          { type: "clearValue" },
          { type: "selectByIndex" },
          { type: "select" },
          { type: "selectByAttribute" },
          { type: "hover" },
        ],
      },
      {
        groupKey: "waits",
        items: [
          { type: "waitForClickable" },
          { type: "waitForDisplayed" },
          { type: "waitForEnabled" },
          { type: "waitForExist" },
        ],
      },
      {
        groupKey: "asserts",
        items: [
          { type: "assertValue" },
          { type: "assertText" },
          { type: "assertProperty" },
          { type: "assertAttribute" },
          { type: "assertClickable" },
          { type: "assertDisplayed" },
          { type: "assertEnabled" },
          { type: "assertExisting" },
          { type: "assertFocused" },
          { type: "assertSelected" },
        ],
      },
    ]);
  },
  setSearchTextFieldValue(value: string) {
    self.searchTextFieldValue = value;
  },
  setSelectedTabName(value: IBaseActions["selectedTabName"]) {
    self.selectedTabName = value;
  },
  selectItem(item: IAction) {
    _.each([...self.browserItems, ...self.elementsItems], (action) => {
      _.each(action.items, (actionItem: IAction) => {
        actionItem.setSelected(false);
      });
    });
    item.setSelected(true);
  },
}))
  .views((self) => ({
    get filteredItems() {
      return self.selectedTabName === "element"
        ? self.elementsItems
        : self.browserItems;
    },
    get selectedItem() {
      return _.chain([...self.browserItems, ...self.elementsItems])
        .map((action) => action.items)
        .flatten()
        .find({ selected: true })
        .value();
    },
  }))
  .views((self) => ({
    get filteredItemsLength() {
      return _.reduce(
        self.filteredItems,
        (memo: number, action: IActionsItems) =>
          memo + (action.filteredItems?.length || 0),
        0,
      );
    },
    isElementType(type: IActionType) {
      const elementTypes = _.chain(self.elementsItems)
        .flatMap(({ items }) => items)
        .map(({ type }) => type)
        .value();
      return elementTypes.includes(type);
    },
  }));

export default Actions;
export { BaseActions };
export interface IActions extends Instance<typeof Actions> {}
export interface IBaseActions extends Instance<typeof BaseActions> {}
