import React, { forwardRef } from "react";
import clsx from "clsx";
import { MenuItem, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MenuItemProps } from "@mui/material/MenuItem";

interface IMenuItem extends MenuItemProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    ...theme.typography.bodyTextStyle,
    color: theme.palette.mineShaft.main,
    padding: theme.spacing(0, 3),
    height: 40,
    "&:hover": {
      backgroundColor: theme.palette.catskillLightWhite.main,
    },
    "&>.MuiSvgIcon-root": {
      marginRight: theme.spacing(1),
      color: theme.palette.baliHai.main,
    },
  },
  menuItemDisabled: {
    color: theme.palette.dustyGray.main,
    "& .MuiSvgIcon-root": {
      color: theme.palette.geyser.main,
    },
  },
}));

const SharedMenuItem = forwardRef<HTMLLIElement, IMenuItem>((props, ref) => {
  const classes = useStyles();
  const { onClick, className, disabled, children } = props;
  const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    onClick && onClick(e);
  };
  return (
    <MenuItem
      ref={ref}
      disabled={disabled}
      disableRipple
      className={clsx(
        classes.menuItem,
        disabled && classes.menuItemDisabled,
        className
      )}
      onClick={handleClick}
    >
      {children}
    </MenuItem>
  );
});

export default SharedMenuItem;
