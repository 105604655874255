import _ from "lodash";
import { types } from "mobx-state-tree";
import { IWithSelected } from "./WithSelected";

const WithSelectable = types
  .model("WithSelectable", {})
  .views(() => ({
    getSelectedSize(items: IWithSelected[]) {
      return _.chain(items)
        .filter(
          ({ selected, indeterminate }) =>
            selected || (!selected && indeterminate)
        )
        .size()
        .value();
    },
    getSelectedItems(items: IWithSelected[]) {
      return _.chain(items).filter({ selected: true }).value();
    },
    getNotSelectedItems(items: IWithSelected[]) {
      return _.chain(items)
        .filter({ selected: false, indeterminate: false })
        .value();
    },
  }))
  .actions(() => ({
    toAllsetSelect(items: IWithSelected[], value: boolean) {
      _.each(items, (item) => {
        item.indeterminate = false;
        item.selected = value;
      });
    },
  }));

export default WithSelectable;
