import "@mui/material/styles/createPalette";
import { PaletteColor } from "@mui/material/styles/createPalette";
import { IWithHexOpacity } from "@mui/material/styles/createTheme";

const HexOpacities = {
  100: "FF",
  90: "E6",
  80: "CC",
  70: "B3",
  60: "99",
  50: "80",
  40: "66",
  30: "4D",
  20: "33",
  10: "1A",
  5: "0D",
  0: "00",
};

declare module "@mui/material/styles/createTheme" {
  type IHexOpacities = keyof typeof HexOpacities;

  type IWithHexOpacity = (
    color: PaletteColor,
    hexOpacity: IHexOpacities,
    colorType?: keyof PaletteColor
  ) => string;

  interface Theme {
    utils: {
      withHexOpacity: IWithHexOpacity;
    };
  }

  interface ThemeOptions {
    utils: {
      withHexOpacity: IWithHexOpacity;
    };
  }
}

const utils: { withHexOpacity: IWithHexOpacity } = {
  withHexOpacity: (color, hexOpacity, colorType = "main") =>
    `${color[colorType]}${HexOpacities[hexOpacity]}`,
};

export default utils;
