import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import _ from "lodash";
import { ITestStep } from "../../models";
import { NumberTextField, TextField } from "../Inputs";
import DropElement from "./DropElement";
import ErrorStrategySelect from "./ErrorStrategySelect";

const useStyles = makeStyles((theme: Theme) => ({
  inputBlock: {
    marginBottom: theme.spacing(4),
  },
}));

interface ISelectByAction {
  testStep: ITestStep;
}

const SelectByAction: React.FC<ISelectByAction> = observer(({ testStep }) => {
  const classes = useStyles();
  const { t } = useTranslation(["actions", "common"]);
  const bounced = useCallback(
    () => _.debounce(testStep.save, 1000),
    [testStep.save]
  );

  const onChangeValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (testStep.type !== "selectByIndex" && e.target.value.length > 255)
      return;
    testStep.setValue(e.target.value);
    bounced();
  };

  const onChangeAttributeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 50) return;
    testStep.setAttributeName(e.target.value);
    bounced();
  };

  return (
    <>
      {testStep.type === "selectByAttribute" ? (
        <Grid item className={classes.inputBlock}>
          <TextField
            labelText={t("attribute_name")}
            helperTextRight={t("common:characters_left", {
              number: 50 - (testStep.attributeName?.length || 0),
            })}
            inputBaseProps={{
              fullWidth: true,
              onChange: onChangeAttributeName,
              onBlur: testStep.save,
              value: testStep.attributeName || "",
            }}
          />
        </Grid>
      ) : null}
      <Grid item className={classes.inputBlock}>
        {testStep.type === "selectByIndex" ? (
          <NumberTextField
            labelText={t("index")}
            maxLength={4}
            value={testStep.value || ""}
            onChange={onChangeValue}
            onBlur={testStep.save}
          />
        ) : (
          <TextField
            labelText={
              testStep.type === "select" ? t("text") : t("attribute_value")
            }
            helperTextRight={t("common:characters_left", {
              number: 255 - (testStep.value?.length || 0),
            })}
            inputBaseProps={{
              rows: 5,
              maxRows: 5,
              fullWidth: true,
              onChange: onChangeValue,
              onBlur: testStep.save,
              value: testStep.value || "",
              multiline: true,
            }}
          />
        )}
      </Grid>
      <Grid item className={classes.inputBlock}>
        <DropElement label={t("target_element")} testStep={testStep} />
      </Grid>
      <ErrorStrategySelect testStep={testStep} />
    </>
  );
});

export default SelectByAction;
