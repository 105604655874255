import { FC } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem, Theme, SelectChangeEvent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { RemoveCircleOutline, AddCircleOutline } from "@mui/icons-material";
import { IProjectInvite, IProjectInviteErrors, role } from "../../models";
import { TextField } from "../Inputs";
import StyledButton from "../StyledButton";
import StyledSelect from "../StyledSelect";

const useStyles = makeStyles((theme: Theme) => ({
  textFieldContainer: {
    marginBottom: theme.spacing(),
  },
  textField: {
    width: "17.5rem",
  },
  selectFieldContainer: {
    width: "7.25rem",
    marginLeft: theme.spacing(3),
    color: theme.palette.doveGray.main,
    ...theme.typography.descriptionTextStyle,
  },
  selectField: {
    width: "100%",
    "& .MuiSelect-select": {
      height: "2.5rem",
      boxSizing: "border-box",
      borderColor: theme.palette.rockBlue.main,
      '&:hover, &:focus, &[aria-expanded="true"]': {
        borderColor: theme.palette.outrageousOrange.main,
      },
    },
  },
  inviteControls: {
    margin: theme.spacing(1, 0, 5),
  },
  button: {
    margin: theme.spacing(1, 3, 1, 0),
    padding: 0,
    "&:hover": {
      background: "none",
    },
  },
}));

interface IInviteUser {
  invite: IProjectInvite;
  isOnlyInvite: boolean;
  isLastInvite: boolean;
  errors?: IProjectInviteErrors;
}

const InviteUser: FC<IInviteUser> = observer(
  ({ invite, isOnlyInvite, isLastInvite, errors }) => {
    const classes = useStyles();
    const { t } = useTranslation(["project_create"]);

    const onInviteEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value?.length > 250) return;
      invite.setInviteEmail(e.target.value);
    };

    const trimInviteEmail = () => {
      invite.setInviteEmail(invite.email.trim());
    };

    const onInviteRoleChange = (e: SelectChangeEvent<unknown>) => {
      invite.setInviteRole(e.target.value as role);
    };

    const onInviteRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      invite.removeInvite();
    };

    const onInviteAdd = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      invite.addInvite();
    };

    return (
      <Grid container wrap="nowrap" direction="column">
        <Grid
          item
          container
          wrap="nowrap"
          className={classes.textFieldContainer}
        >
          <Grid item className={classes.textField}>
            <TextField
              labelText={t("invite_user")}
              inputBaseProps={{
                fullWidth: true,
                onChange: onInviteEmailChange,
                onBlur: trimInviteEmail,
                value: invite.email || "",
              }}
              errorText={errors?.email}
            />
          </Grid>
          <Grid item className={classes.selectFieldContainer}>
            <StyledSelect
              className={classes.selectField}
              onChange={onInviteRoleChange}
              value={invite.role || "user"}
              labelText={t("role")}
            >
              <MenuItem value="user">{t("roles.user")}</MenuItem>
              <MenuItem value="admin" disabled>
                {t("roles.admin")}
              </MenuItem>
              <MenuItem value="manager" disabled>
                {t("roles.manager")}
              </MenuItem>
            </StyledSelect>
          </Grid>
        </Grid>
        <Grid item container wrap="nowrap" className={classes.inviteControls}>
          {isOnlyInvite ? null : (
            <Grid item>
              <StyledButton
                startIcon={<RemoveCircleOutline />}
                className={classes.button}
                onClick={onInviteRemove}
              >
                {t("user_remove")}
              </StyledButton>
            </Grid>
          )}
          {isLastInvite ? (
            <Grid item>
              <StyledButton
                startIcon={<AddCircleOutline />}
                className={classes.button}
                onClick={onInviteAdd}
              >
                {t("user_add")}
              </StyledButton>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    );
  }
);

export default InviteUser;
