import React from "react";
import {
  Theme,
  FormLabel,
  RadioGroup,
  RadioGroupProps,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  labelBlock: {
    ...theme.typography.descriptionTextStyle,
    color: theme.palette.doveGray.main,
  },
  radioGroup: {
    justifyContent: "start",
  },
  formErrorText: {
    ...theme.typography.descriptionTextStyle,
    color: theme.palette.guardsmanRed.main,
  },
}));

interface IRadioGroup extends RadioGroupProps {
  labelText?: string;
  errorText?: string;
}

const SharedRadioGroup: React.FC<IRadioGroup> = ({
  labelText,
  value,
  onChange,
  children,
  errorText,
  ...props
}) => {
  const classes = useStyles();
  return (
    <>
      {labelText ? (
        <FormLabel className={classes.labelBlock}>{labelText}</FormLabel>
      ) : null}
      <RadioGroup
        value={value}
        onChange={onChange}
        {...props}
        className={classes.radioGroup}
      >
        {children}
      </RadioGroup>
      {errorText ? (
        <FormHelperText className={classes.formErrorText}>
          {errorText}
        </FormHelperText>
      ) : null}
    </>
  );
};

export default SharedRadioGroup;
