import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { ISuite, ITest } from "../../models";
import { useRootStoreContext } from "../../hooks";
import ValidatableTextArea from "../Inputs/ValidatableTextArea";

const useStyles = makeStyles(() => ({
  clsTooltip: {
    height: 100,
    width: "100%",
  },
}));

const GroupFormTextArea: React.FC<{ suite: ISuite }> = observer(({ suite }) => {
  const { t } = useTranslation(["suites"]);
  const classes = useStyles();
  const history = useHistory();
  const { tests } = useRootStoreContext();
  const { projectId = "", suiteId = "" } = useParams<{
    projectId: string;
    suiteId: string;
  }>();

  const onSaveClick = async () => {
    const selectedTests = tests.getSelectedItems(tests.items) as ITest[];
    const item = await suite.onSaveEditClick(selectedTests, suiteId);
    if (item && item.withTests)
      history.push(
        `/projects/${projectId}/tests/suites/${item.id}/tests`,
        "selected"
      );
  };

  return (
    <ValidatableTextArea
      autoFocus={!suite.id}
      minRows={3}
      maxRows={3}
      maxLength={255}
      itemName={suite.name}
      isValid={suite.isValid}
      onChange={suite.onChangeName}
      onSaveClick={onSaveClick}
      onCancelClick={suite.onCancelEditClick}
      placeholder={t("group_name")}
      errorText={suite.validationErrorText}
      useTooltip={true}
      placement="right-start"
      clsTooltip={classes.clsTooltip}
    />
  );
});

export default GroupFormTextArea;
