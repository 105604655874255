import {
  types,
  flow,
  applySnapshot,
  destroy,
  getSnapshot,
  getRoot,
} from "mobx-state-tree";
import _ from "lodash";
import moment from "moment";
import Element, { IElement, IElementSnapshotOut } from "./Element";
import WithSelectable from "./WithSelectable";
import { getFilteredElements, handleFetch } from "./utils";
import { IElementFilters } from "./ElementFilters";
import { IRoot } from ".";

const Elements = types
  .compose(
    types.model("Elements", {
      searchTextFieldValue: "",
      items: types.optional(types.array(Element), []),
      editElement: types.maybeNull(Element),
      sortField: "last_added",
      state: types.optional(
        types.enumeration("ElementsState", ["loading", "ready"]),
        "ready"
      ),
    }),
    WithSelectable
  )
  .views((self) => ({
    get sortedItems() {
      let sortField: keyof IElement;
      const iteratee = (u1: IElement, u2: IElement) => {
        switch (self.sortField) {
          case "last_added":
            sortField = "createdAt";
            break;
          case "last_used":
            sortField = "lastApiCallAt";
            break;
        }

        if (u1[sortField] === u2[sortField]) return 0;
        if (!u1[sortField]) return 1;
        if (!u2[sortField]) return -1;

        return moment(u1[sortField]).isBefore(u2[sortField]) ? 1 : -1;
      };

      return self.sortField ? self.items.slice().sort(iteratee) : self.items;
    },
  }))
  .views((self) => ({
    get searchedItems() {
      const searchTextFieldValue = self.searchTextFieldValue.toLowerCase();

      return _.filter(
        self.items,
        (item) => item.name.toLowerCase().indexOf(searchTextFieldValue) > -1
      );
    },
    get filteredItems() {
      const filters: IElementFilters = getRoot<IRoot>(self).elementFilters;

      return getFilteredElements(self.sortedItems, filters);
    },
  }))
  .actions((self) => ({
    setSortField: (sortField: string) => {
      self.sortField = sortField;
    },
    removeElement: flow(function* (item: IElement) {
      try {
        yield item.remove();

        destroy(item);
      } catch (e) {
        console.error(`Error while removeTest: ${e}`);
      }
    }),
    removeAllElements: flow(function* () {
      try {
        if (self.items[0]) {
          const response = yield handleFetch(
            self,
            `${process.env.REACT_APP_API_URL}/projects/${self.items[0].ProjectId}/elements`,
            {
              method: "DELETE",
            }
          );

          if (!response.ok) throw new Error("Could not do remove request");

          self.items.forEach((item: IElement) => {
            destroy(item);
          });
        }
      } catch (error) {
        throw error;
      }
    }),
    editExistingElement: flow(function* () {
      if (!self.editElement) return;
      const existingElement = _.find(self.items, { id: self.editElement.id });

      if (!existingElement) return;
      const existingElementSnap = getSnapshot(existingElement);
      const editElement = getSnapshot(self.editElement);

      try {
        applySnapshot(
          existingElement,
          Object.assign({}, editElement, { name: _.trim(editElement?.name) })
        );

        yield existingElement.save();

        destroy(self.editElement);

        //TODO: Show Success msg
      } catch (e) {
        //TODO: Show Error msg
        // TODO implement error store
        console.error("Error while editExistingTest", e);
        applySnapshot(existingElement, existingElementSnap);
      }
    }),
    removeSelectedElements: flow(function* () {
      try {
        if (self.items[0]) {
          const selectedItems = self.getSelectedItems(self.items);
          const elementsIds = selectedItems.map((el) => (el as IElement).id);

          const response = yield handleFetch(
            self,
            `${process.env.REACT_APP_API_URL}/projects/${self.items[0].ProjectId}/elements`,
            {
              method: "DELETE",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                elementsIds,
              }),
            }
          );

          if (!response.ok) throw new Error("Could not do remove request");

          self.items.forEach((item: IElement) => {
            if (item.id && elementsIds.includes(item.id)) destroy(item);
          });
        }
      } catch (error) {
        throw error;
      }
    }),
    load: flow(function* ({
      projectId,
      selected,
    }: {
      projectId: number;
      selected?: (string | undefined)[];
    }) {
      try {
        self.state = "loading";
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${projectId}/elements`
        );

        if (!response) return;
        const { elements } = yield response.json();

        if (selected)
          self.items.forEach(
            (item) => (item.selected = selected.includes(item.id?.toString()))
          );

        applySnapshot(self.items, elements);
        //TODO: Show Sucsess msg
      } catch (error) {
        //TODO: Show Error msg
        console.error("Error while load elements", error);
        throw error;
      } finally {
        self.state = "ready";
      }
    }),
    setSearchTextFieldValue(value: string) {
      self.searchTextFieldValue = value;
    },
  }))
  .actions((self) => ({
    onEditExistingElement(elementSnapshot: IElementSnapshotOut) {
      self.editElement = Element.create(elementSnapshot);
    },
    onCancelEditExistingElement() {
      self.editElement && destroy(self.editElement);
    },
  }));

export default Elements;
