import { FC, useEffect, useState } from "react";
import { map } from "lodash";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { IUser } from "../../models";
import { useRootStoreContext } from "../../hooks";
import { ReactComponent as AttributionIcon } from "../../icons/Attribution.svg";
import BulkMenus, { ActiveUsersBulkMenu } from "../BulkMenus";
import Table, { ITableColumn } from "../Table";
import ProjectMenu from "./ProjectMenu";

const ActiveUsers: FC = observer(() => {
  const { t } = useTranslation(["users"]);
  const { users } = useRootStoreContext();

  useEffect(() => {
    users.setSortParam("last_added");
  }, [users]);

  const columns: ITableColumn[] = [
    {
      type: "profile",
      label: t("name"),
      width: "4fr",
    },
    {
      key: "role",
      label: t("role"),
      width: "2fr",
    },
    {
      key: "teams",
      label: t("teams"),
      width: "2fr",
    },
    {
      type: "date",
      key: "lastSignInAt",
      label: t("last_activity"),
      width: "3fr",
    },
    {
      type: "menu",
      width: "24px",
    },
  ];

  const menuItems = (user: IUser) => [
    {
      label: t("teams"),
      iconName: "SupervisedUserCircleOutlined",
      onClick: () => console.log(user.id, "teams"),
    },
    {
      label: t("projects"),
      iconName: "DashboardOutlined",
      onClick: (anchor: null | HTMLElement) => {
        onProjectMenuOpen(anchor, user.id);
      },
      disabled: user.role === "admin",
    },
    {
      label: t("change_role"),
      icon: <AttributionIcon />,
      onClick: () => console.log(user.id, "change_role"),
    },
    {
      label: t("deactivate"),
      iconName: "ArchiveOutlined",
      onClick: async () => {
        await users.toggleUserLock({ userIds: [user.id], lockState: true });
        await users.load();
      },
      isConfirm: true,
      disabled: user.role === "admin",
      confirmText: t("common:confirm"),
    },
  ];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

  const onProjectMenuOpen = (anchor: null | HTMLElement, userId: number) => {
    setSelectedUserId(userId);
    setAnchorEl(anchor);
  };

  const onProjectMenuClose = () => {
    setAnchorEl(null);
    setSelectedUserId(null);
  };

  const userItems = map(users.activeUsers, (item) => ({
    ...item,
    toggleSelected: item.toggleSelected,
    role: t(`roles.${item.role}`),
    menuItems: menuItems(item),
  }));

  const userIds = selectedUserId ? [selectedUserId] : [];

  return (
    <>
      <Table
        columns={columns}
        rows={userItems}
        emptyListText={t("empty_message")}
      />
      {anchorEl ? (
        <ProjectMenu
          anchorEl={anchorEl}
          userIds={userIds}
          onClose={onProjectMenuClose}
        />
      ) : null}
      {users.getSelectedSize(users.activeUsers) > 1 ? (
        <BulkMenus>
          <ActiveUsersBulkMenu />
        </BulkMenus>
      ) : null}
    </>
  );
});

export default ActiveUsers;
