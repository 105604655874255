import { FC } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IProject } from "../../models";
import { Search } from "../Inputs";
import UserList from "./UserList";

const useStyles = makeStyles((theme: Theme) => ({
  emptyList: {
    marginBottom: theme.spacing(5),
  },
  memberTitleContainer: {
    width: "26.25rem",
  },
  memberTitle: {
    width: "26.25rem",
    marginBottom: theme.spacing(1),
    color: theme.palette.mineShaft.main,
    ...theme.typography.subHeaderTextStyle,
  },
  memberDescription: {
    marginBottom: theme.spacing(3),
    color: theme.palette.mineShaft.main,
    ...theme.typography.bodyTextStyle,
  },
  addedMembersCount: {
    whiteSpace: "nowrap",
  },
  searchBox: {
    width: "26.25rem",
  },
}));

interface IMembers {
  project: IProject;
}

const Members: FC<IMembers> = observer(({ project }) => {
  const classes = useStyles();
  const { t } = useTranslation(["project_create"]);
  if (!project.usersToAdd.length)
    return (
      <Grid container direction="column">
        <Grid item className={classes.memberTitle}>
          {t("members")}
        </Grid>
        <Grid item className={classes.memberDescription}>
          {t("no_users")}
        </Grid>
      </Grid>
    );
  return (
    <Grid container direction="column">
      <Grid
        item
        container
        className={classes.memberTitleContainer}
        wrap="nowrap"
        justifyContent="space-between"
      >
        <Grid item className={classes.memberTitle}>
          {t("members")}
        </Grid>
        {project.addedUsers.length ? (
          <Grid item className={classes.addedMembersCount}>
            {t("added_user_count", {
              added: project.addedUsers.length,
              all: project.allUsersCount,
            })}
          </Grid>
        ) : null}
      </Grid>
      <Grid item>
        <Search
          placeholder={t("Filter users by name or email")}
          value={project.userSearchValue}
          className={classes.searchBox}
          onChangeText={project.onUserSearchChange}
        />
      </Grid>
      <Grid item>
        <UserList
          users={project.filteredUsersToAdd}
          userActionType="add"
          onUserAction={project.onAddUser}
        />
      </Grid>
    </Grid>
  );
});

export default Members;
