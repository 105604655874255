import React from "react";
import { observer } from "mobx-react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import Test from "./Test";
import Suites from "./Suites";

const Tests: React.FC<{}> = observer(() => {
  const { path, url, isExact } = useRouteMatch();

  if (isExact) {
    return <Redirect to={`${url}/suites`} />;
  }

  return (
    <Switch>
      <Route path={`${path}/suites/:suiteId/tests/:testId`} component={Test} />
      <Route path={`${path}/suites`} component={Suites} />
    </Switch>
  );
});

export default Tests;
