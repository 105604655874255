import {
  types,
  flow,
  getSnapshot,
  Instance,
  SnapshotOut,
  getParentOfType,
  applySnapshot,
} from "mobx-state-tree";
import _ from "lodash";
import i18next from "i18next";
import {
  handleFetch,
  validateNameByLength,
  validateNameForbiddenChars,
} from "./utils";
import Suites from "./Suites";
import { ITest } from "./Test";

const isMatchSearch = (
  suite: ISuite,
  searchTextFieldValue: string
): boolean => {
  if (suite.name.toLowerCase().indexOf(searchTextFieldValue) > -1) {
    return true;
  }

  if (suite.suites) {
    return _.some(suite.suites, (item) =>
      isMatchSearch(item, searchTextFieldValue)
    );
  }

  return false;
};

const Suite = types
  .model("Suite", {
    id: "",
    name: "",
    ProjectId: types.maybe(types.number),
    UserId: types.maybe(types.number),
    createdAt: types.maybe(types.string),
    updatedAt: types.maybe(types.string),
    TestCount: 0,
    defaultSuite: types.maybeNull(types.optional(types.boolean, false)),
    parentId: types.maybeNull(types.optional(types.string, "")),
    isOpened: false,
    withTests: false,
    error: "",
    suiteState: types.optional(
      types.enumeration("SuiteState", ["ready", "loading"]),
      "ready"
    ),
  })
  .preProcessSnapshot((snapshot) => ({
    ...snapshot,
    TestCount:
      snapshot?.TestCount &&
      parseInt(snapshot.TestCount as unknown as string, 10),
  }))
  .views((self) => ({
    get suites(): ISuite[] {
      const allSuites = getParentOfType(self, Suites).items;
      if (_.isEmpty(allSuites)) {
        return [];
      }
      return _.chain(allSuites)
        .filter({ parentId: self.id })
        .sortBy("name")
        .value();
    },
    get isValid() {
      return (
        !!_.trim(self.name, ". \n") &&
        validateNameByLength(self.name, 255) &&
        validateNameForbiddenChars(self.name) &&
        !self.error
      );
    },
    get validationErrorText() {
      if (!_.trim(self.name, ". \n"))
        return i18next.t("suites:errors:required");
      if (!validateNameByLength(self.name, 255))
        return i18next.t("suites:errors:length");
      if (!validateNameForbiddenChars(self.name))
        return i18next.t("suites:errors:forbidden");
      return self.error;
    },
    get parentSuite(): ISuite | null {
      if (!self.parentId) return null;
      const suites = getParentOfType(self, Suites).items || [];
      return _.find(suites, { id: self.parentId }) as ISuite;
    },
    childDeepness(deepness: number = 1) {
      const suites = getParentOfType(self, Suites).items || [];
      const children = _.filter(suites, { parentId: self.id }) as ISuite[];
      if (!children.length) return 0;
      deepness += _.reduce(
        children,
        (memo, suite) => {
          return memo > suite.childDeepness(deepness)
            ? memo
            : suite.childDeepness(deepness);
        },
        0
      );

      return deepness;
    },
  }))
  .views((self) => ({
    get isMatchSearch(): boolean {
      const searchTextFieldValue: string = getParentOfType(
        self,
        Suites
      ).searchTextFieldValue.toLowerCase();

      return isMatchSearch(self as ISuite, searchTextFieldValue);
    },
    isChildOf(id: string): boolean {
      return self.parentSuite
        ? id === self.parentId || self.parentSuite.isChildOf(id)
        : false;
    },
  }))
  .actions((self) => ({
    save: flow(function* () {
      try {
        self.suiteState = "loading";
        self.name = _.trim(self.name, ". \n");

        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${self.ProjectId}/suites/${self.id}`,
          {
            method: self.id ? "PUT" : "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(getSnapshot(self)),
          }
        );

        if (!response.ok) throw new Error("Could not do Suite save request");
        applySnapshot(self, yield response.json());
      } catch (error) {
        self.error = i18next.t("suites:errors:duplicate");
        throw error;
      } finally {
        self.suiteState = "ready";
      }
    }),
    remove: flow(function* () {
      try {
        self.suiteState = "loading";
        yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${self.ProjectId}/suites/${self.id}`,
          {
            method: "DELETE",
          }
        );
      } catch (error) {
        console.error("Error while remove", error);
        throw error;
      } finally {
        self.suiteState = "ready";
      }
    }),
  }))
  .actions((self) => ({
    setTestCount(cnt: number) {
      self.TestCount = cnt;
    },
    onChangeName(event: React.ChangeEvent<HTMLTextAreaElement>) {
      self.name = event.target.value;
      self.error = "";
    },
    onEditClick(event: React.SyntheticEvent) {
      event.stopPropagation();
      getParentOfType(self, Suites).onEditExistingClick(getSnapshot(self));
    },
    onCancelEditClick() {
      self.id && !self.withTests
        ? getParentOfType(self, Suites).onRemoveExistingClick()
        : getParentOfType(self, Suites).onRemoveNewClick();
    },
    onSaveEditClick(tests?: ITest[], suiteId?: string): Promise<any> {
      if (self.id) return getParentOfType(self, Suites).editExistingSuite();
      if (self.withTests && tests && suiteId)
        return getParentOfType(self, Suites).addNewSuiteWithTests(
          tests,
          suiteId
        );
      return getParentOfType(self, Suites).addNewSuite();
    },
    toggleOpened() {
      self.isOpened = !self.isOpened;
    },
  }));

export interface ISuite extends Instance<typeof Suite> {}
export interface ISuiteSnapshotOut extends SnapshotOut<typeof Suite> {}

export default Suite;
