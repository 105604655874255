import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Theme,
  Typography,
  MenuItem,
  FormHelperText,
  InputAdornment,
} from "@mui/material";

import { useRootStoreContext } from "../hooks";

import { AuthButtons } from "../components/Auth";

import {
  Checkbox,
  Layout,
  LayoutOfContent,
  PageSpinner,
  StyledButton,
  StyledSelect,
  TextField,
  TaIcon,
} from "../components";

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    backgroundColor: theme.palette.white.main,
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    width: "100%",
    maxWidth: "100%",
    overflow: "auto",
  },
  root: {
    width: 410,
    marginLeft: "auto",
    marginRight: "auto",
    flexDirection: "column",
  },
  logo: {
    width: 48,
    height: 48,
    marginRight: theme.spacing(2),
  },
  header: {
    marginTop: 80,
    marginBottom: 80,
    fontSize: 18,
    fontWeight: 500,
  },
  pageTitle: {
    fontSize: 15,
    fontWeight: 700,
    marginBottom: theme.spacing(0),
  },
  fieldContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    marginTop: theme.spacing(1),
  },
  selectField: {
    width: "100%",
  },
  errorText: {
    ...theme.typography.descriptionTextStyle,
    color: theme.palette.guardsmanRed.main,
  },
  actionButtons: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(4),
    alignSelf: "flex-start",
  },
  link: {
    color: theme.palette.outrageousOrange.main,
    textTransform: "uppercase",
    marginLeft: theme.spacing(1),
    "&:hover": {
      color: theme.palette.pomegranate.main,
    },
  },
  terms: {
    color: theme.palette.outrageousOrange.main,
    "&:hover": {
      color: theme.palette.pomegranate.main,
    },
  },
  orEmail: {
    color: theme.palette.doveGray.main,
    fontSize: 12,
    fontWeight: 400,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    textAlign: "center",
  },
  eyeIcon: {
    cursor: "pointer",
    color: theme.palette.rockBlue.main,
    "&:hover": {
      color: theme.palette.outrageousOrange.main,
    },
  },
  passwordContainer: {
    marginTop: theme.spacing(4),
  },
  footer: {
    marginTop: theme.spacing(4),
  },
  checkbox: {
    marginTop: theme.spacing(1),
  },
}));

const userRoles = [
  "qa_eng",
  "qa_tl",
  "qa_head",
  "dev_eng",
  "dev_tl",
  "dev_head",
  "other",
];

interface IFormInputs {
  password: string;
  name: string;
  role: string;
}

interface IServerErrors {
  global?: string;
  name?: string;
  firstLastName?: string;
}

const Invite: FC<{}> = () => {
  const classes = useStyles();
  const { t } = useTranslation("auth");

  const {
    users: { profile },
    projects,
  } = useRootStoreContext();

  const history = useHistory();

  const {
    control,
    watch,
    trigger,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>();

  const [serverErrors, setServerErrors] = useState<IServerErrors>({});
  const [submitted, setSubmitted] = useState(false);
  const [passwordChecked, setPasswordChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState("email");
  const [serverError, setServerError] = useState(undefined);
  const [email, setEmail] = useState("");
  const [agree, setAgree] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const confirmationCode = params.get("confirmationCode") || null;

  const handleGoogle = () => console.log("Sign Up via Google");
  const handleGitHub = () => console.log("Sign Up via GitHub");
  const handleSlack = () => console.log("Sign Up via Slack");

  const handleBack = () => setStep("email");
  const handleContinue = () => setStep("password");

  const handlePassword = async () => {
    setPasswordChecked(true);
    if (!(await trigger("password"))) return;
    setStep("finish");
  };

  const handleChangeControlledField = (
    field: any,
    event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>,
    triggered: Boolean
  ) => {
    clearErrors(field.name);
    setServerError(undefined);
    field.onChange(event);
    triggered && trigger(field.name);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    if (!(await trigger())) return;

    await profile.confirm(
      { ...watch(), confirmationCode },
      () => {
        projects.load();
        history.push("/dashboard/projects");
      },
      (msg, path) =>
        path
          ? setServerErrors({ [path]: msg })
          : setServerErrors({ global: msg }),
      true
    );
  };

  const validateName = (value: string) => {
    const trimmedValue = value.trim();
    if (!trimmedValue) return t("name_required");
    if (trimmedValue.length > 50) return t("name_too_long");
    return undefined;
  };

  const validatePassword = (value: string) => {
    if (!value) return t("password_required");
    if (value.length > 50) return t("password_too_long");
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(value))
      return t("password_weak");
    return undefined;
  };

  useEffect(() => {
    try {
      fetch(
        `${process.env.REACT_APP_API_URL}/auth/validate-confirmation-code?confirmationCode=${confirmationCode}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setEmail(data.email);
            setLoading(false);
          } else {
            history.push("/sign-in");
          }
        });
    } catch (error) {
      console.error("Failed to validate password code", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <PageSpinner />;

  return (
    <Layout>
      <LayoutOfContent className={classes.layout}>
        <Grid container className={classes.root} wrap="nowrap">
          <Grid container alignItems="center" className={classes.header}>
            <img
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="logo"
              className={classes.logo}
            />
            {t("true_automation")}
          </Grid>
          {step === "email" ? (
            <>
              <Typography className={classes.pageTitle}>
                {t("sign_up")}
              </Typography>
              <AuthButtons
                authAction="sign_up"
                onGoogle={handleGoogle}
                onGitHub={handleGitHub}
                onSlack={handleSlack}
              />
              <Typography className={classes.orEmail}>
                {t("sign_up_email")}:
              </Typography>

              <Grid item>
                <TextField
                  labelText={t("email")}
                  error={!!serverError}
                  errorText={serverError}
                  inputBaseProps={{
                    placeholder: t("enter_email"),
                    fullWidth: true,
                    value: email,
                    disabled: true,
                  }}
                />
              </Grid>

              <Checkbox
                checked={agree}
                label={
                  <>
                    {t("agree")}
                    <Link to="/terms" className={classes.terms}>
                      {t("terms")}
                    </Link>
                  </>
                }
                labelPlacement="end"
                labelFormControlStyle={classes.checkbox}
                onChange={() => setAgree(!agree)}
              />
              <Grid container className={classes.actionButtons}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  disabled={!agree}
                  className={classes.button}
                  onClick={handleContinue}
                >
                  {t("continue")}
                </StyledButton>
              </Grid>
              <Grid item className={classes.footer}>
                {t("have_account")}
                <Link to="/sign-in" className={classes.link}>
                  {t("sign_in")}
                </Link>
              </Grid>
            </>
          ) : step === "password" ? (
            <>
              <Grid item>
                <TextField
                  labelText={t("email")}
                  error={!!serverError}
                  errorText={serverError}
                  inputBaseProps={{
                    placeholder: t("enter_email"),
                    fullWidth: true,
                    value: email,
                    disabled: true,
                  }}
                />
              </Grid>
              <Grid item className={classes.passwordContainer}>
                <Controller
                  name="password"
                  control={control}
                  defaultValue={""}
                  rules={{ validate: validatePassword }}
                  render={({ field }) => (
                    <TextField
                      labelText={t("password")}
                      type={showPassword ? "text" : "password"}
                      errorText={errors.password?.message}
                      inputBaseProps={{
                        ...field,
                        placeholder: t("create_your_password"),
                        fullWidth: true,
                        onChange: (event) =>
                          handleChangeControlledField(
                            field,
                            event,
                            passwordChecked
                          ),
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <TaIcon
                            className={classes.eyeIcon}
                            iconName={
                              showPassword
                                ? "VisibilityOffOutlined"
                                : "VisibilityOutlined"
                            }
                            onClick={handleClickShowPassword}
                          />
                        </InputAdornment>
                      }
                    />
                  )}
                />
              </Grid>
              <Grid container className={classes.actionButtons}>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={handleBack}
                >
                  {t("back")}
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handlePassword}
                  disabled={loading}
                >
                  {t("continue")}
                </StyledButton>
              </Grid>
              <Grid item className={classes.footer}>
                {t("have_account")}
                <Link to="/sign-in" className={classes.link}>
                  {t("sign_in")}
                </Link>
              </Grid>
            </>
          ) : (
            <>
              <Typography className={classes.pageTitle}>
                {t("finish")}
              </Typography>
              {serverErrors.global && (
                <FormHelperText className={classes.errorText}>
                  {serverErrors.global}
                </FormHelperText>
              )}
              <Grid item className={classes.fieldContainer}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={""}
                  rules={{ validate: validateName }}
                  render={({ field }) => (
                    <TextField
                      labelText={t("name")}
                      errorText={
                        serverErrors.firstLastName || errors.name?.message
                      }
                      inputBaseProps={{
                        ...field,
                        placeholder: t("enter_name"),
                        fullWidth: true,
                        onChange: (target) =>
                          handleChangeControlledField(field, target, submitted),
                        onBlur: ({ target }) =>
                          setValue(field.name, target.value.trim()),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item className={classes.fieldContainer}>
                <Controller
                  name="role"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <StyledSelect
                      displayEmpty
                      renderValue={(value) =>
                        value ? t(`roles.${value}`) : t("select_role")
                      }
                      className={classes.selectField}
                      labelText={t("role")}
                      errorText={errors.role ? t("role_required") : undefined}
                      inputProps={{
                        ...field,
                        onChange: (target) =>
                          handleChangeControlledField(field, target, submitted),
                      }}
                    >
                      {userRoles.map((role) => (
                        <MenuItem key={role} value={role}>
                          {t(`roles.${role}`)}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  )}
                />
              </Grid>
              <Grid item className={classes.buttonContainer}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  {t("submit_finish")}
                </StyledButton>
              </Grid>
            </>
          )}
        </Grid>
      </LayoutOfContent>
    </Layout>
  );
};

export default Invite;
