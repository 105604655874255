import React, { useState } from "react";
import { observer } from "mobx-react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useTranslation } from "react-i18next";
import { useRootStoreContext } from "../../hooks";
import StyledButton from "./StyledButton";
import SelectMenuItem from "./SelectMenuItem";
import { ConfirmationModal } from "../Modals";
import { makeStyles } from "@mui/styles";

interface IElementsBulkMenus {}

const useStyles = makeStyles(() => ({
  attributionButton: {
    marginRight: "auto",
  },
}));

const ElementsBulkMenus: React.FC<IElementsBulkMenus> = observer(() => {
  const { t } = useTranslation(["bulk_menus", "common"]);
  const { elements } = useRootStoreContext();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const classes = useStyles();

  const onShowDeleteConfirm = () => {
    setShowConfirmationModal(true);
  };

  const onDeleteClick = () => {
    elements.removeSelectedElements();
  };

  return (
    <>
      <StyledButton
        startIcon={<DeleteOutlineOutlinedIcon />}
        onClick={onShowDeleteConfirm}
        className={classes.attributionButton}
      >
        {t("common:delete")}
      </StyledButton>
      <SelectMenuItem
        selectedSize={elements.getSelectedSize(elements.items)}
        onSelectAll={() => elements.toAllsetSelect(elements.items, true)}
        onSelectNone={() => elements.toAllsetSelect(elements.items, false)}
      />
      <ConfirmationModal
        isOpened={showConfirmationModal}
        text={t("common:confirm")}
        onConfirm={onDeleteClick}
        onCancel={() => setShowConfirmationModal(false)}
      />
    </>
  );
});

export default ElementsBulkMenus;
