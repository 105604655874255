import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { map } from "lodash";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IProject, IUser } from "../../models";
import { useRootStoreContext } from "../../hooks";
import BulkMenus, { ProjectUsersBulkMenu } from "../BulkMenus";
import Table, { ITableColumn } from "../Table";
import AddUser from "./AddUser";

const UserList: FC = observer(() => {
  const { t } = useTranslation(["project_team"]);
  const { users, projects } = useRootStoreContext();
  const { profile } = users;
  const { projectId = "" } = useParams<{ projectId: string }>();
  const project = projects.getById(parseInt(projectId, 10)) as IProject;

  useEffect(() => {
    users.setSortParam("by_name");
    if (!projectId) return;
    users.loadProjectUsers(projectId);
    return () => {
      users.removeUserToInvite();
    };
  }, [users, projectId]);

  const columns: ITableColumn[] = [
    {
      type: "profile",
      label: t("name"),
      width: "4fr",
    },
    {
      key: "role",
      label: t("role"),
      width: "2fr",
    },
    {
      key: "teams",
      label: t("teams"),
      width: "2fr",
    },
    {
      type: "date",
      key: "lastSignInAt",
      label: t("last_activity"),
      width: "3fr",
    },
    {
      type: "menu",
      width: "24px",
    },
  ];

  const menuItems = (user: IUser) => [
    {
      label: t("delete_from_project"),
      iconName: "DeleteOutlined",
      onClick: async () => {
        await users.deleteUsersFromProject([user.id], projectId);
        await users.loadProjectUsers(projectId);
      },
      isConfirm: true,
      disabled: user.role === "admin" || profile.role === "member",
      confirmText: t("common:confirm"),
    },
  ];

  const userItems = map(users.activeAndPendingUsers, (item) => ({
    ...item,
    toggleSelected: item.toggleSelected,
    role: t(`roles.${item.role}`),
    menuItems: menuItems(item),
  }));

  const onUserInvite = async (
    usersToInvite: any[],
    setUsersToInvite: Dispatch<SetStateAction<any[]>>
  ) => {
    try {
      const addedUsers: IUser[] = usersToInvite.filter(
        (user) => user.type !== "new"
      );
      const invites = usersToInvite.filter((user) => user.type === "new");

      project.setAddedUsers(addedUsers);
      project.setInvites(invites);
      await project.inviteUsers(project.id);
      users.removeUserToInvite();
      users.loadProjectUsers(projectId);
    } catch (e: any) {
      if (e?.error === "USERS_DEACTIVATED_VALIDATION") {
        const invites = usersToInvite.map((invite) => {
          return { ...invite, isDeactivated: e?.emails.includes(invite.email) };
        });
        setUsersToInvite(invites);
      }
      console.error(`Error while user invite to the project: ${e}`);
    }
  };

  const onCloseUserInvite = () => {
    users.removeUserToInvite();
  };

  return (
    <>
      <Table
        columns={columns}
        rows={userItems}
        emptyListText={t("empty_message")}
      >
        {users.userToInvite ? (
          <AddUser
            project={project}
            onSave={onUserInvite}
            onClose={onCloseUserInvite}
          />
        ) : null}
      </Table>
      {users.getSelectedSize(users.activeAndPendingUsers) > 1 ? (
        <BulkMenus>
          <ProjectUsersBulkMenu />
        </BulkMenus>
      ) : null}
    </>
  );
});

export default UserList;
