import { types, Instance } from "mobx-state-tree";
import Actions from "./Actions";
import Elements from "./Elements";
import Environments from "./Environments";
import FilterAuthors from "./FilterAuthors";
import Labels from "./Labels";
import ElementFilters from "./ElementFilters";
import ListFilters from "./ListFilters";
import UserFilters from "./UserFilters";
import Notifications from "./Notifications";
import Projects from "./Projects";
import ReportFilters from "./ReportFilters";
import Roles from "./Roles";
import Suites from "./Suites";
import Settings from "./Settings";
import Tests from "./Tests";
import TestSteps from "./TestSteps";
import Users from "./User";
import Variables from "./Variables";

const Root = types
  .model({
    actions: types.optional(Actions, {}),
    actionsForSelect: types.optional(Actions, {}),
    elementAuthors: types.optional(FilterAuthors, {}),
    elementFilters: types.optional(ElementFilters, {}),
    elements: types.optional(Elements, {}),
    environments: types.optional(Environments, {}),
    labels: types.optional(Labels, {}),
    notifications: types.optional(Notifications, {}),
    allNotificationsList: types.optional(Notifications, {}),
    unreadNotificationsList: types.optional(Notifications, {}),
    projects: types.optional(Projects, {}),
    reportFilters: types.optional(ReportFilters, {}),
    roles: types.optional(Roles, {}),
    sharedStepAuthors: types.optional(FilterAuthors, {}),
    sharedStepFilters: types.optional(ListFilters, {}),
    settings: types.optional(Settings, {}),
    suites: types.optional(Suites, {}),
    testAuthors: types.optional(FilterAuthors, {}),
    testFilters: types.optional(ListFilters, {}),
    tests: types.optional(Tests, {}),
    testSteps: types.optional(TestSteps, {}),
    users: types.optional(Users, {}),
    userFilters: types.optional(UserFilters, {}),
    variables: types.optional(Variables, {}),
  })
  .actions((self) => ({
    async initialize(callback?: () => void) {
      self.settings.load();

      await self.users.profile.load();
      if (self.users.profile.isAuthenticated) {
        self.projects.load();
      }

      callback && callback();
    },
  }));

export interface IRoot extends Instance<typeof Root> {}

export default Root;
