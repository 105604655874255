import React from "react";
import _ from "lodash";
import { observer } from "mobx-react";
import { InputBase, InputLabel, Theme, FormHelperText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Select, { SelectProps } from "@mui/material/Select";
import { MenuProps } from "@mui/material/Menu";

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    ...theme.typography.bodyTextStyle,
    padding: theme.spacing(1.25, 2),
    borderRadius: 0,
    boxSizing: "border-box",
    maxHeight: "100%",
    backgroundColor: theme.palette.white.main,
    border: `1px solid ${theme.palette.rockBlue.main}`,
    '&:hover, &:focus, &[aria-expanded="true"]': {
      border: `1px solid ${theme.palette.outrageousOrange.main}`,
      backgroundColor: theme.palette.white.main,
      "& ~ $icon": {
        color: theme.palette.outrageousOrange.main,
      },
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const useDropdownStyles = makeStyles((theme: Theme) => ({
  paper: {
    ...theme.typography.bodyTextStyle,
    border: `1px solid ${theme.palette.outrageousOrange.main}`,
    borderRadius: 0,
    boxShadow: "none",
    boxSizing: "border-box",
    marginTop: -1,
  },
  list: {
    paddingTop: 2,
    paddingBottom: 2,
    "& li": {
      fontSize: theme.spacing(1.75),
      padding: theme.spacing(1, 2),
    },
  },
}));

const useCustomStyles = makeStyles((theme: Theme) => ({
  inputLabel: {
    ...theme.typography.bodyTextStyle,
    color: theme.palette.doveGray.main,
    fontSize: theme.spacing(1.5),
    height: theme.spacing(2) + 1,
    marginBottom: theme.spacing(0.5),
  },
  inputBase: {
    width: "100%",
  },
  formErrorText: {
    ...theme.typography.descriptionTextStyle,
    color: theme.palette.guardsmanRed.main,
  },
}));

interface IStyledSelect extends SelectProps {
  labelText?: string;
  errorText?: string;
}

const StyledSelect: React.FC<IStyledSelect> = observer((props) => {
  const classes = useStyles();
  const dropdownClasses = useDropdownStyles();
  const customClasses = useCustomStyles();
  const { labelText, errorText } = props;

  const menuProps: Partial<MenuProps> = {
    classes: {
      paper: dropdownClasses.paper,
      list: dropdownClasses.list,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
  };

  //Use standard MenuItem for children
  return (
    <>
      {labelText ? (
        <InputLabel className={customClasses.inputLabel}>
          {labelText}
        </InputLabel>
      ) : null}
      <Select
        {..._.omit(props, ["labelText", "errorText"])}
        input={<InputBase className={customClasses.inputBase} />}
        variant="standard"
        MenuProps={menuProps}
        classes={classes}
      />
      {errorText ? (
        <FormHelperText className={customClasses.formErrorText}>
          {errorText}
        </FormHelperText>
      ) : null}
    </>
  );
});

export default StyledSelect;
