import { Instance, types } from "mobx-state-tree";
import { parseCountFilterWithOption } from "./utils";

export type counterOption = "more" | "less";

const CountFilter = types
  .model("Count filter", {
    counter: "",
    option: types.optional(types.enumeration(["more", "less"]), "more"),
  })
  .actions((self) => ({
    init(value: string) {
      const [option, counter] = parseCountFilterWithOption(value);
      self.counter = `${counter}`;
      self.option = option as counterOption;
    },
    setCounter(counter: string) {
      self.counter = counter;
    },
    setOption(option: counterOption) {
      self.option = option;
    },
  }));

export interface ICountFilter extends Instance<typeof CountFilter> {}

export default CountFilter;
