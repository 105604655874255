import { types, flow, Instance } from "mobx-state-tree";
import moment, { Moment } from "moment";
import { createBrowserHistory } from "history";
import emailValidator from "validator/lib/isEmail";
import _ from "lodash";
import { ITest } from "./Test";
import { ITestStep } from "./TestStep";
import { IListFilters } from "./ListFilters";
import { IUser } from "./User";
import { IUserFilters } from "./UserFilters";
import { IElement } from "./Element";
import { IElementFilters } from "./ElementFilters";
import { IReportFilters } from "./ReportFilters";

const ActionType = types.enumeration([
  "click",
  "doubleClick",
  "setValue",
  "clearValue",
  "selectByIndex",
  "select",
  "selectByAttribute",
  "waitForClickable",
  "waitForDisplayed",
  "waitForEnabled",
  "waitForExist",
  "assertValue",
  "assertText",
  "assertProperty",
  "assertAttribute",
  "assertClickable",
  "assertDisplayed",
  "assertEnabled",
  "assertExisting",
  "assertFocused",
  "assertSelected",
  "visit",
  "setCookies",
  "deleteCookies",
  "assertCookieValue",
  "pause",
  "switchWindow",
  "setWindowSize",
  "sharedAction",
  "switchFrame",
  "switchParentFrame",
  "scrollTo",
  "hover",
]);

const PeriodFilterType = types.enumeration([
  "today",
  "yesterday",
  "thisWeek",
  "lastWeek",
  "thisMonth",
  "lastMonth",
  "lastThreeMonths",
]);

const BrowserType = ["pause", "visit", "scrollTo"];

interface IDates {
  from?: Moment;
  untill?: Moment;
}

const getDatesByPeriodFilter = (value: IPeriodFilterType) => {
  switch (value) {
    case "today":
      return { from: moment().startOf("d") };
    case "yesterday":
      return {
        from: moment().add(-1, "d").startOf("d"),
        untill: moment().startOf("d"),
      };
    case "thisWeek":
      return { from: moment().startOf("w") };
    case "lastWeek":
      return {
        from: moment().add(-1, "w").startOf("d"),
        untill: moment().startOf("w"),
      };
    case "thisMonth":
      return { from: moment().startOf("M") };
    case "lastMonth":
      return {
        from: moment().add(-1, "M").startOf("M"),
        untill: moment().startOf("M"),
      };
    case "lastThreeMonths":
      return { from: moment().add(-3, "M") };
  }
  return {};
};

const getFilteredListItems = (
  items: ITest[] | ITestStep[],
  filters: IListFilters,
) => {
  if (filters.isEmpty) return items;

  return _.filter(items, (item: ITest | ITestStep) => {
    const predictions: boolean[] = [];
    if (filters.nameFilter) {
      const name = "name" in item ? item.name : item.value;

      predictions.push(
        name.toLowerCase().indexOf(filters!.nameFilter.toLowerCase()) > -1,
      );
    }
    if (filters.creatorsFilter.length) {
      const creatorEmail =
        "creatorUserEmail" in item ? item.creatorUserEmail : item.creatorEmail;
      predictions.push(
        !!creatorEmail && filters.creatorsFilter.includes(creatorEmail),
      );
    }

    if (filters.updatersFilter.length) {
      const changerEmail =
        "changerUserEmail" in item ? item.changerUserEmail : item.changerEmail;
      predictions.push(
        !!changerEmail && filters.updatersFilter.includes(changerEmail),
      );
    }

    if (filters.periodFilter) {
      const { from, untill }: IDates = getDatesByPeriodFilter(
        filters.periodFilter as IPeriodFilterType,
      );

      predictions.push(
        !!item.updatedAt &&
          moment(item.updatedAt).isAfter(from) &&
          moment(item.updatedAt).isBefore(untill),
      );
    }

    if (filters.labelFilter.length && "Labels" in item) {
      // check if there is an intersection between test labels and
      // labels in filter
      predictions.push(
        !!item.Labels.length &&
          !!_.intersection(filters.labelFilter, _.map(item.Labels, "name"))
            .length,
      );
    }

    return predictions.some(Boolean);
  }) as ITest[] | ITestStep[];
};

const getFilteredElements = (items: IElement[], filters: IElementFilters) => {
  if (filters.isEmpty) return items;

  return _.filter(items, (item: IElement) => {
    const conditions: boolean[] = [];

    if (filters.locatorFilter) {
      const elementName = item.name || item.slug;
      conditions.push(
        elementName
          .toLowerCase()
          .indexOf(filters!.locatorFilter.toLowerCase()) > -1,
      );
    }

    if (filters.labelFilter) {
      conditions.push(
        (item.elementLabel || "")
          .toLowerCase()
          .indexOf(filters!.labelFilter.toLowerCase()) > -1,
      );
    }

    if (filters.typeFilter.length) {
      conditions.push(
        !!item.elementType && filters.typeFilter.includes(item.elementType),
      );
    }

    if (filters.periodFilter) {
      const { from, untill }: IDates = getDatesByPeriodFilter(
        filters.periodFilter,
      );

      conditions.push(
        !!item.updatedAt &&
          moment(item.updatedAt).isAfter(from) &&
          moment(item.updatedAt).isBefore(untill),
      );
    }

    if (filters.usedFilter) {
      const [option, count] = parseCountFilterWithOption(filters.usedFilter);
      conditions.push(
        !!item.usageCount &&
          (option === "more"
            ? item.usageCount > count
            : option === "less"
            ? item.usageCount < count
            : false),
      );
    }

    return conditions.every(Boolean);
  });
};

const getFilteredTestsWithReports = (
  items: ITest[],
  filters: IReportFilters,
) => {
  if (filters.isEmpty) return items;

  return _.filter(items, (item: ITest) => {
    const conditions: boolean[] = [];

    if (filters.nameFilter) {
      conditions.push(
        item.name.toLowerCase().indexOf(filters.nameFilter.toLowerCase()) > -1,
      );
    }

    if (filters.lastResultFilter.length) {
      conditions.push(filters.lastResultFilter.includes(item.result));
    }

    if (filters.periodFilter) {
      const { from, untill }: IDates = getDatesByPeriodFilter(
        filters.periodFilter,
      );

      const lastRun = _.orderBy(
        item.Reports,
        (report) => report.updatedAt,
        "desc",
      )[0];

      conditions.push(
        !!lastRun?.updatedAt &&
          moment(lastRun.updatedAt).isAfter(from) &&
          moment(lastRun.updatedAt).isBefore(untill),
      );
    }

    return conditions.every(Boolean);
  });
};

const getFilteredUsers = (items: IUser[], filters: IUserFilters) => {
  if (filters.isEmpty) return items;

  return _.filter(items, (item: IUser) => {
    const conditions: boolean[] = [];
    if (filters.nameFilter) {
      const name = item.firstLastName || "";
      conditions.push(
        name.toLowerCase().indexOf(filters.nameFilter.toLowerCase()) > -1,
      );
    }

    if (filters.emailFilter) {
      const email = item.email || "";
      conditions.push(
        email.toLowerCase().indexOf(filters.emailFilter.toLowerCase()) > -1,
      );
    }

    if (filters.roleFilter.length) {
      conditions.push(!!item.role && filters.roleFilter.includes(item.role));
    }

    return conditions.every(Boolean);
  });
};

const parseCountFilterWithOption = (value: string): [string, number] => {
  const [option, counter] = value.replace(/[<>]/, (v) => `${v} `).split(" ");
  return [option === "<" ? "less" : "more", parseInt(counter, 10)];
};

const validateNameByLength = (name: string, length: number) => {
  const cleanName = _.trim(name);
  return !cleanName || cleanName.length <= length;
};

const isEmail = (email: string) => {
  const cleanEmail = _.trim(email);
  return emailValidator(cleanEmail, { allow_utf8_local_part: false });
};

const validateNameForbiddenChars = (name: string) => {
  const forbiddenCharsRegexp = '.*(\\?|\\||\\:|\\/|\\*|"|<|>|\\\\).*';
  const cleanName = _.trim(name);
  return !cleanName || !cleanName.match(forbiddenCharsRegexp);
};

const handleFetch = flow(function* (
  target: any,
  input: RequestInfo,
  init?: RequestInit,
) {
  try {
    const response = yield fetch(input, init);
    if (response.status === 401) {
      window.location.href = "/sign-in";
    }

    if (!response.ok) {
      const res = yield response.json();

      const NotFoundMessages = ["PROJECT_NOT_FOUND", "SUITE_NOT_FOUND"];
      if (NotFoundMessages.includes(res?.message)) {
        window.location.href = "/no-match";
      } else {
        throw res;
      }
    }

    return response;
  } catch (error) {
    throw error;
  }
});

const formatDuration = (milliseconds: number): string => {
  if (milliseconds === 0) return "0";
  const days = Math.floor(
    moment.duration(milliseconds, "milliseconds").asDays(),
  );
  const hours = Math.floor(
    moment
      .duration(milliseconds - moment.duration(days, "days").asMilliseconds())
      .asHours(),
  );
  const minutes = Math.floor(
    moment
      .duration(
        milliseconds -
          moment.duration(days, "days").asMilliseconds() -
          moment.duration(hours, "hours").asMilliseconds(),
      )
      .asMinutes(),
  );
  const seconds =
    Math.round(
      moment
        .duration(
          milliseconds -
            moment.duration(days, "days").asMilliseconds() -
            moment.duration(hours, "hours").asMilliseconds() -
            moment.duration(minutes, "minutes").asMilliseconds(),
        )
        .asSeconds() * 1000,
    ) / 1000;

  return [days, hours, minutes, seconds]
    .reduce<{
      duration: string;
      hasValue: boolean;
    }>(
      (res, val, i) => {
        const timeLetters = ["d", "h", "m", "s"];
        if (val) {
          res.duration += `${val}${timeLetters[i]} `;
          res.hasValue = true;
        }
        return res;
      },
      { duration: "", hasValue: false },
    )
    .duration.slice(0, -1);
};

const history = createBrowserHistory();

const updateUrlFilter = (filterName: string, value: string | string[]) => {
  const filters = new URLSearchParams(window.location.search);
  if (!value) {
    filters.delete(filterName);
  } else if (_.isArray(value)) {
    filters.delete(filterName);
    _.each(value, (val: string) => filters.append(filterName, val));
  } else {
    filters.set(filterName, value);
  }
  history.push(`${window.location.pathname}?${filters.toString()}`);
};

const sendAsyncMessage = (extId: string, request: any) => {
  return new Promise((resolve, reject) => {
    chrome.runtime.sendMessage(extId, request, {}, (response) => {
      if (response) {
        resolve(response);
      } else {
        reject(response);
      }
    });
  });
};

const checkClientVersion = async (
  recorderExtId: string,
  onSuccess: Function,
) => {
  try {
    const response: any = await sendAsyncMessage(recorderExtId, {
      action: "checkVersionUpdate",
    });
    if (response) {
      if (
        response.isDisconnected === true ||
        response.isDisconnected === "true"
      ) {
        onSuccess("no_response");
        return "no_response";
      }

      const result = response.action;
      onSuccess(result);
      return result;
    } else {
      console.log(chrome.runtime.lastError);
    }
  } catch (e) {
    console.log(`Error sendind message to extenstion: ${e}`);
  }
};

const runTest = async (stepActions: ITestStep[], options: any) => {
  let id: number | undefined;
  let res = { errorMessage: "", result: "passed", StepActionId: id };
  let i = 0;
  while (stepActions.length > 0) {
    i = i + 1;
    const stepAction = stepActions.shift() as ITestStep;
    let resJSON;

    options = { resJSON, ...options };
    console.log("stepAction: ");
    console.log(stepAction);
    if (BrowserType.includes(stepAction.type)) {
      switch (stepAction.type) {
        case "pause":
          await asyncTimeOut(stepAction.value);
          break;
        case "visit":
        case "scrollTo":
          resJSON = { stepAction };
          await chrome.runtime.sendMessage(options.recorderExtId, {
            action: "stepAction",
            parameters: {
              ...resJSON,
            },
          });
          break;
      }
    } else {
      if (stepAction.scroll) {
        resJSON = { stepAction };
        await chrome.runtime.sendMessage(options.recorderExtId, {
          action: "scrollTo",
          parameters: {
            ...resJSON,
          },
        });
      }
      options.response = await getCoordinates(
        options.recorderExtId,
        Date.now(),
      );

      resJSON = await findStep(stepAction, options);
      if (!resJSON) {
        res.errorMessage = `We did not get a response in time from the server that it needed in order to complete the request or the operation takes longer time to complete than the default wait time provided.`;
        res.result = "failed";
        res.StepActionId = stepAction.id;
        break;
      }

      if (resJSON.address === "No appropriate element") {
        res.errorMessage = `The "${stepAction.description}" with "${stepAction.locator}" locator could not be detected by TrueAutomation.`;
        res.result = "failed";
        res.StepActionId = stepAction.id;
        break;
      }

      console.time("fireAction");
      await chrome.runtime.sendMessage(options.recorderExtId, {
        action: "stepAction",
        parameters: {
          ...resJSON,
        },
      });
      await asyncTimeOut(500);
    }

    console.timeEnd("fireAction");
  }

  closeBrowser(options.recorderExtId);
  return res;
};

const findStep = async (stepAction: ITestStep, options: any) => {
  let { projectId, suiteId, testId, response, resJSON, recorderExtId } =
    options;
  const startTime = Date.now();
  if (stepAction.Element) {
    try {
      const scrollHeight = response.height - stepAction.Element.original_height;
      const scrollCount = Math.floor(response.maxHeight / scrollHeight);
      let i = 0;

      while (Date.now() - startTime < 60 * 1000) {
        const url = `${process.env.REACT_APP_API_URL}/projects/${projectId}/suites/${suiteId}/tests/${testId}/steps/${stepAction?.id}/find`;
        const resp = await fetch(url, {
          headers: { "Content-Type": "application/json" },
          method: "POST",
          body: JSON.stringify(response),
        });

        resJSON = await resp.json();
        console.log("find element: ", resJSON);
        if (resJSON.address === "No appropriate element") {
          await chrome.runtime.sendMessage(options.recorderExtId, {
            action: "scroll",
            parameters: { scroll: (i % (scrollCount + 1)) * scrollHeight },
          });
          i++;

          response = await getCoordinates(recorderExtId, Date.now());
        } else {
          break;
        }
      }
    } catch (e) {
      resJSON = null;
    }
  }

  return resJSON;
};

const getCoordinates = async (
  recorderExtId: any,
  startTime: number,
): Promise<any> => {
  let response;
  while (Date.now() - startTime < 60 * 1000) {
    console.time("getCandidates");
    response = await chrome.runtime.sendMessage(recorderExtId, {
      action: "getCandidates",
    });

    console.log("candidates: ", response);
    console.timeEnd("getCandidates");

    if (!response) {
      response = await getCoordinates(recorderExtId, startTime);
    } else {
      break;
    }
  }

  return response;
};

const closeBrowser = async (recorderExtId: any) => {
  try {
    await chrome.runtime.sendMessage(recorderExtId, { action: "closeBrowser" });
  } catch (err) {
    console.error(err);
  }
};

const asyncTimeOut = (ms: any) =>
  new Promise((resolve) => setTimeout(resolve, parseInt(ms)));

const sendReports = async (reports: any, projectId: number | undefined) => {
  const url = `${process.env.REACT_APP_API_URL}/projects/${projectId}/reports`;
  await fetch(url, {
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify(reports),
  });
};

export type IActionType = Instance<typeof ActionType>;
export type IPeriodFilterType = Instance<typeof PeriodFilterType>;

export {
  ActionType,
  PeriodFilterType,
  getDatesByPeriodFilter,
  validateNameByLength,
  handleFetch,
  validateNameForbiddenChars,
  isEmail,
  formatDuration,
  updateUrlFilter,
  getFilteredUsers,
  getFilteredElements,
  getFilteredListItems,
  getFilteredTestsWithReports,
  parseCountFilterWithOption,
  sendAsyncMessage,
  checkClientVersion,
  runTest,
  sendReports,
};
