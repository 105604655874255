import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import HeaderTabs from "@mui/material/Tabs";
import HeaderTab from "./HeaderTab";
import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabsPanel: {
      width: "100%",
      height: "3.5rem",
      padding: "0.5rem 1rem 0",
      borderBottom: `1px solid ${theme.palette.catskillLightWhite.main}`,
    },
    tabIndicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > span": {
        backgroundColor: theme.palette.baliHai.main,
        width: "80%",
      },
    },
  })
);

const Tabs: React.FC<{
  currentTab: string;
  onChangeTab: (tab: string) => void;
}> = observer(({ currentTab, onChangeTab }) => {
  const { t } = useTranslation("header");
  const classes = useStyles();

  return (
    <HeaderTabs
      value={currentTab}
      className={classes.tabsPanel}
      onChange={(_, val) => onChangeTab(val)}
      TabIndicatorProps={{
        className: classes.tabIndicator,
        children: <span />,
      }}
    >
      <HeaderTab label={t("overview")} value="overview" />
      <HeaderTab label={t("log")} value="log" />
      <HeaderTab label={t("history")} value="history" />
    </HeaderTabs>
  );
});

export default Tabs;
