import {
  types,
  Instance,
  flow,
  SnapshotOut,
  getParentOfType,
  getSnapshot,
  applySnapshot,
} from "mobx-state-tree";
import _ from "lodash";
import i18next from "i18next";
import WithSelected from "./WithSelected";
import Elements from "./Elements";
import { handleFetch, validateNameByLength } from "./utils";

const Element = types
  .compose(
    types.model("Element", {
      ProjectId: types.maybe(types.number),
      createdAt: types.maybe(types.string),
      copied: false,
      elementLabel: types.maybeNull(types.string),
      elementType: types.maybeNull(types.string),
      id: types.maybe(types.number),
      lastApiCallAt: types.maybeNull(types.string),
      locator: types.maybeNull(types.string),
      locatorType: types.maybeNull(types.string),
      name: "",
      slug: types.string,
      screenshot: types.maybeNull(types.union(types.boolean, types.string)),
      updatedAt: types.maybeNull(types.string),
      usageCount: types.maybe(types.number),
      original_height: types.number,
    }),
    WithSelected,
  )
  .actions((self) => ({
    save: flow(function* () {
      try {
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${self.ProjectId}/elements/${self.id}`,
          {
            method: self.id ? "PUT" : "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(getSnapshot(self)),
          },
        );
        const item = yield response.json();

        if (item.status === "ERROR") throw new Error(item.message);

        applySnapshot(self, { ...item, usageCount: self.usageCount });
      } catch (error) {
        throw error;
      }
    }),
    remove: flow(function* () {
      try {
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${self.ProjectId}/elements/${self.name}`,
          {
            method: "DELETE",
          },
        );

        if (!response.ok) throw new Error("Could not do remove request");
      } catch (error) {
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    onEditExistingClick(e: React.SyntheticEvent) {
      e.stopPropagation();
      getParentOfType(self, Elements).onEditExistingElement(getSnapshot(self));
    },
    onChangeName(e: React.ChangeEvent<HTMLTextAreaElement>) {
      self.name = e.target.value;
    },
    onCancelEditClick(e: React.SyntheticEvent) {
      e.stopPropagation();
      getParentOfType(self, Elements).onCancelEditExistingElement();
    },
    onSaveEditClick(e: React.SyntheticEvent) {
      e.stopPropagation();
      if (self.name.length === 0) self.name = self.slug;
      getParentOfType(self, Elements).editExistingElement();
    },
    showCopiedNotification() {
      self.copied = true;
    },
    hideCopiedNotification() {
      self.copied = false;
    },
    copyToClipboard() {
      navigator &&
        navigator.clipboard &&
        navigator.clipboard.writeText(self.name);
    },
  }))
  .views((self) => ({
    get isValid() {
      return validateNameByLength(_.trim(self.name), 50);
    },
    get validationErrorText() {
      if (_.trim(self.name).length > 50)
        return i18next.t("elements:errors:length");
      return "";
    },
    get screenshotUrl() {
      return self.screenshot
        ? `/apiv2/projects/${self.ProjectId}/elements/${self.id}/screenshot`
        : undefined;
    },
  }));

export interface IElement extends Instance<typeof Element> {}
export interface IElementSnapshotOut extends SnapshotOut<typeof Element> {}
export default Element;
