import { createElement, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useRootStoreContext } from "../../hooks";

const PrivateRoute = ({ component, ...rest }: any) => {
  useEffect(() => {
    const id = window.setInterval(() => {
      if (document.cookie.indexOf("connect.sid") === -1) {
        window.location.href = "/sign-in";
      }
    }, 200);
    return () => {
      window.clearInterval(id);
    };
  }, []);

  const {
    users: {
      profile: { isAuthenticated },
    },
  } = useRootStoreContext();
  const routeComponent = (props: any) =>
    isAuthenticated ? (
      createElement(component, props)
    ) : (
      <Redirect to="/sign-in" />
    );
  return <Route {...rest} render={routeComponent} />;
};

export default PrivateRoute;
