import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Grid, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { StyledButton } from "..";

const useStyles = makeStyles((theme: Theme) => ({
  actionButtons: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(4),
    alignSelf: "flex-start",
  },
  stepTitle: {
    fontSize: 15,
    fontWeight: 700,
    marginBottom: theme.spacing(),
  },
  successText: {
    color: theme.palette.mineShaft.main,
    fontSize: 14,
    fontWeight: 400,
    marginBottom: theme.spacing(1),
  },
  successNote: {
    color: theme.palette.doveGray.main,
    fontSize: 12,
    fontWeight: 400,
    marginTop: theme.spacing(5),
  },
}));

interface ISignUpSuccess {
  email: String;
  onLogin: () => void;
}

const SignUpSuccess: FC<ISignUpSuccess> = ({ email, onLogin }) => {
  const { t } = useTranslation("auth");

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const handleResend = async () => {
    setIsLoading(true);
    await fetch(`${process.env.REACT_APP_API_URL}/auth/resendInstruction`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email }),
    });
    setIsLoading(false);
  };

  return (
    <>
      <Typography className={classes.stepTitle}>
        {t("success_title")}
      </Typography>
      <Typography className={classes.successText}>
        {t("email_sent", { email })}
      </Typography>
      <Grid container className={classes.actionButtons}>
        <StyledButton
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={handleResend}
          disabled={isLoading}
        >
          {t("resend_email")}
        </StyledButton>
        <StyledButton
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onLogin}
        >
          {t("take_to_login")}
        </StyledButton>
      </Grid>
      <Typography className={classes.successNote}>{t("note")}</Typography>
    </>
  );
};

export default SignUpSuccess;
