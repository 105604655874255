import { types, Instance } from "mobx-state-tree";

const WithSelected = types
  .model("WithSelected", {
    selected: false,
    indeterminate: false,
  })
  .actions((self) => ({
    toggleSelected() {
      self.indeterminate = false;
      self.selected = !self.selected;
    },
  }));

export interface IWithSelected extends Instance<typeof WithSelected> {}

export default WithSelected;
