import { types, applySnapshot, Instance } from "mobx-state-tree";
import { map } from "lodash";
import WithSelected from "./WithSelected";
import WithSelectable from "./WithSelectable";

const CheckboxFilterItem = types.compose(
  types.model("CheckboxFilterList", {
    label: "",
    value: "",
  }),
  WithSelected
);

const CheckboxFilterList = types
  .compose(
    types.model("CheckboxFilterList", {
      items: types.optional(types.array(CheckboxFilterItem), []),
    }),
    WithSelectable
  )
  .actions((self) => ({
    initItems(items: { label: string; value: string }[], selected: string[]) {
      applySnapshot(
        self.items,
        map(items, (item) => ({
          ...item,
          selected: selected.includes(item.value),
        }))
      );
    },
  }));

export interface ICheckboxFilterItem
  extends Instance<typeof CheckboxFilterItem> {}

export interface ICheckboxFilterList
  extends Instance<typeof CheckboxFilterList> {}

export default CheckboxFilterList;
