import React, { useEffect } from "react";
import _ from "lodash";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ITestStep, IActionCookie } from "../../../models";
import { Checkbox } from "../../Inputs";
import ErrorMessage from "../ErrorMessage";
import ErrorStrategySelect from "../ErrorStrategySelect";
import CookieBlock from "./CookieBlock";

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    margin: theme.spacing(-2, 0, 4, -1),
  },
}));

interface ICookiesHaveValues {
  testStep: ITestStep;
}

const CookiesHaveValuesAction: React.FC<ICookiesHaveValues> = observer(
  ({ testStep }) => {
    const classes = useStyles();
    const { t } = useTranslation(["actions", "common"]);

    useEffect(() => {
      if (!testStep.jsonbValue.length) {
        testStep.addNewCookie();
      }
    }, [testStep, testStep.type]);

    const onDeleteAllCookiesClick = (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      e.stopPropagation();
      testStep.toggleDeleteAllCookies();
      testStep.save();
    };

    return (
      <>
        {testStep.type === "deleteCookies" ? (
          <Checkbox
            className={classes.checkbox}
            checked={testStep.deleteAllCookies}
            label={t("delete_all_cookies")}
            labelPlacement="end"
            onChange={onDeleteAllCookiesClick}
          />
        ) : null}
        {_.map(testStep.jsonbValue, (cookie: IActionCookie, i: number) => (
          <CookieBlock
            key={`cookie_${i}`}
            testStep={testStep}
            disabled={testStep.deleteAllCookies}
            cookie={cookie}
            index={i}
          />
        ))}
        {testStep.type === "assertCookieValue" ? (
          <>
            <ErrorMessage testStep={testStep} />
            <ErrorStrategySelect testStep={testStep} />
          </>
        ) : null}
      </>
    );
  }
);

export default CookiesHaveValuesAction;
