import {
  types,
  getParentOfType,
  getSnapshot,
  applySnapshot,
  SnapshotOut,
  Instance,
  flow,
} from "mobx-state-tree";
import i18next from "i18next";
import _ from "lodash";
import Labels from "./Labels";
import { handleFetch, validateNameByLength } from "./utils";

const Label = types
  .model("Label", {
    id: 0,
    ProjectId: types.number,
    name: "",
    selected: false,
    indeterminate: false,
    testsCount: "0",
    groupsCount: "0",
    error: "",
    labelState: types.optional(
      types.enumeration("TestState", ["ready", "loading"]),
      "ready"
    ),
  })
  .actions((self) => ({
    save: flow(function* () {
      self.labelState = "loading";
      self.name = _.trim(self.name);
      try {
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${self.ProjectId}/labels/${
            self.id || ""
          }`,
          {
            method: self.id ? "PUT" : "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(getSnapshot(self)),
          }
        );

        if (!response.ok) throw new Error("Could not do Label save request");

        const label = yield response.json();
        applySnapshot(
          self,
          Object.assign(
            {},
            { selected: self.selected, indeterminate: self.indeterminate },
            label
          )
        );
        self.error = "";
      } catch (error) {
        // ToDo show error message
        self.error = i18next.t("labels:errors:duplicate");
        throw error;
      } finally {
        self.labelState = "ready";
      }
    }),
    remove: flow(function* () {
      self.labelState = "loading";
      try {
        yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${self.ProjectId}/labels/${self.id}`,
          {
            method: "DELETE",
          }
        );
      } catch (error) {
        // ToDo show error message
        throw error;
      } finally {
        self.labelState = "ready";
      }
    }),
  }))
  .actions((self) => ({
    onEditExistingLabelClick(e: React.SyntheticEvent) {
      e.stopPropagation();
      getParentOfType(self, Labels).onEditExistingLabel(getSnapshot(self));
    },
    onChangeName(e: React.ChangeEvent<HTMLTextAreaElement>) {
      self.name = e.target.value;
      self.error = "";
    },
    onCancelEditClick(e: React.SyntheticEvent) {
      e.stopPropagation();
      getParentOfType(self, Labels).onCancelEditExistingLabel();
    },
    onSaveExistingLabelClick(e: React.SyntheticEvent) {
      e.stopPropagation();
      getParentOfType(self, Labels).editExistingLabel();
    },
    onSaveNewLabel(e: React.SyntheticEvent) {
      e.stopPropagation();
      getParentOfType(self, Labels).addNewLabel();
    },
    onSelect(e: React.ChangeEvent<HTMLInputElement>) {
      e.stopPropagation();
      self.selected = !self.selected;
      self.indeterminate = false;
    },
  }))
  .views((self) => ({
    get isValid() {
      return validateNameByLength(_.trim(self.name), 50) && !self.error;
    },
    get validationErrorText() {
      if (!_.trim(self.name)) return i18next.t("labels:errors:required");
      if (_.trim(self.name).length > 50)
        return i18next.t("labels:errors:length");
      return self.error;
    },
  }));

export interface ILabel extends Instance<typeof Label> {}
export interface ILabelSnapshotOut extends SnapshotOut<typeof Label> {}

export default Label;
