import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem, SelectChangeEvent } from "@mui/material";
import { ErrorStrategies, ITestStep } from "../../models";
import StyledSelect from "../StyledSelect";

interface IErrorStrategySelect {
  testStep: ITestStep;
}

const ErrorStrategySelect: React.FC<IErrorStrategySelect> = observer(
  ({ testStep }) => {
    const { t } = useTranslation(["actions", "common"]);

    const onChangeError = (e: SelectChangeEvent<unknown>) => {
      testStep.setErrorStrategy(e.target.value as ErrorStrategies);
      testStep.save();
    };

    return (
      <Grid item>
        <StyledSelect
          onChange={onChangeError}
          value={testStep.errorStrategy || "stop"}
          labelText={t("step_failed_label")}
        >
          <MenuItem value="stop">{t("error_stop")}</MenuItem>
          <MenuItem value="continue">{t("error_continue")}</MenuItem>
        </StyledSelect>
      </Grid>
    );
  }
);

export default ErrorStrategySelect;
