import { types, Instance, SnapshotOut } from "mobx-state-tree";

import Environment from "./Environment";

const Initiator = types.model({
  id: types.identifierNumber,
  firstLastName: types.maybe(types.string),
  email: types.maybe(types.string),
});

const Report = types
  .model({
    id: types.identifierNumber,
    browser: types.maybe(types.string),
    result: types.maybe(types.string),
    runTime: types.maybeNull(types.number),
    errorMessage: types.maybeNull(types.string),
    EnvironmentId: types.maybeNull(types.number),
    Environment: types.maybeNull(Environment),
    User: types.maybeNull(Initiator),
    StepActionId: types.maybeNull(types.number),
    StepId: types.maybeNull(types.string),
    updatedAt: "",
    createdAt: "",
  })
  .views((self) => ({
    get baseUrl() {
      return self.Environment?.baseUrl;
    },
  }));

export interface IReport extends Instance<typeof Report> {}
export interface IReportSnapshotOut extends SnapshotOut<typeof Report> {}

export default Report;
