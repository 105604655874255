import { FC } from "react";
import clsx from "clsx";
import { map } from "lodash";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IUser } from "../../models";
import UserListItem from "./UserListItem";
import TaIcon from "../TaIcon";

const useStyles = makeStyles((theme: Theme) => ({
  listContainer: {
    width: "26.25rem",
    marginBottom: theme.spacing(3),
  },
  listHeader: {
    height: theme.spacing(6),
    borderBottom: `1px solid ${theme.palette.catskillWhite.main}`,
  },
  listHeaderCol: {
    flex: "none",
    color: theme.palette.doveGray.main,
    ...theme.typography.tableLabelTextStyle,
  },
  listColName: {
    width: "17rem",
  },
  listColRole: {
    width: "7.75rem",
  },
  listColAdd: {
    width: "1.5rem",
  },
  listEmptyContainer: {
    minHeight: "20rem",
  },
  listEmptyIcon: {
    marginRight: theme.spacing(),
    color: theme.palette.alto.main,
  },
  listEmptyTitle: {
    ...theme.typography.subHeaderTextStyle,
    color: theme.palette.doveGray.main,
  },
  listEmptyDescription: {
    ...theme.typography.bodyTextStyle,
    color: theme.palette.doveGray.main,
    marginTop: theme.spacing(),
  },
}));

interface IUserList {
  users: IUser[];
  userActionType: "add" | "remove";
  onUserAction: (user: IUser) => void;
}

const UserList: FC<IUserList> = observer(
  ({ users, userActionType, onUserAction }) => {
    const classes = useStyles();
    const { t } = useTranslation(["project_create"]);

    return (
      <Grid container direction="column" className={classes.listContainer}>
        <Grid item container className={classes.listHeader} alignItems="center">
          <Grid
            item
            className={clsx(classes.listColName, classes.listHeaderCol)}
          >
            {t("name")}
          </Grid>
          <Grid
            item
            className={clsx(classes.listColRole, classes.listHeaderCol)}
          >
            {t("role")}
          </Grid>
          <Grid
            item
            className={clsx(classes.listColAdd, classes.listHeaderCol)}
          >
            {t("add")}
          </Grid>
        </Grid>
        <Grid item container>
          {users.length ? (
            map(users, (user) => {
              return (
                <UserListItem
                  key={user.id}
                  user={user}
                  userActionType={userActionType}
                  onUserAction={onUserAction}
                />
              );
            })
          ) : (
            <Grid
              container
              wrap="nowrap"
              direction="column"
              alignItems="center"
              justifyContent="center"
              className={classes.listEmptyContainer}
            >
              <Grid item container alignItems="center" justifyContent="center">
                <TaIcon
                  iconName="InfoOutlined"
                  className={classes.listEmptyIcon}
                />
                <Grid item className={classes.listEmptyTitle}>
                  {t("no_users_title")}
                </Grid>
              </Grid>
              <Grid item className={classes.listEmptyDescription}>
                {t("no_users_description")}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
);

export default UserList;
