import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ITestStep } from "../../models";
import ValidatableTextArea from "../Inputs/ValidatableTextArea";

const NewSharedStepItem: React.FC<{ sharedStep: ITestStep }> = observer(
  ({ sharedStep }) => {
    const { t } = useTranslation(["shared_steps"]);

    const onChangeName = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      sharedStep.setValue(e.target.value);
    };

    const onSaveClick = (e: React.SyntheticEvent) => {
      e.stopPropagation();
      sharedStep.onSaveEmptySharedStepClick();
    };

    const onCancelClick = (e: React.SyntheticEvent) => {
      e.stopPropagation();
      sharedStep.onCancelSharedStepClick();
    };

    return (
      <ValidatableTextArea
        autoFocus={!sharedStep.id}
        minRows={3}
        maxRows={3}
        maxLength={255}
        itemName={sharedStep.value}
        isValid={sharedStep.isValid}
        onChange={onChangeName}
        onSaveClick={onSaveClick}
        onCancelClick={onCancelClick}
        placeholder={t("shared_step_name")}
        errorText={sharedStep.validationErrorText}
      />
    );
  }
);

export default NewSharedStepItem;
