import { FC } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem } from "../Menus";

interface IUserSortMenu {
  anchorEl: null | HTMLElement;
  onMenuClose: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  className: string | undefined;
  sort: (sortBy: string) => void;
}

const SortMenu: FC<IUserSortMenu> = observer(
  ({ anchorEl, onMenuClose, className, sort }) => {
    const { t } = useTranslation(["elements"]);
    const onSortClick = (
      sortBy: string,
      e: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      e.stopPropagation();
      sort(sortBy);
      onMenuClose(e);
    };

    return (
      <Menu
        anchorEl={anchorEl}
        onClose={onMenuClose}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        className={className}
      >
        <MenuItem onClick={(e) => onSortClick("last_added", e)}>
          {t("sort_menu.last_added")}
        </MenuItem>
        <MenuItem onClick={(e) => onSortClick("last_used", e)}>
          {t("sort_menu.last_used")}
        </MenuItem>
      </Menu>
    );
  }
);

export default SortMenu;
