import { types, Instance, SnapshotOut, applySnapshot } from "mobx-state-tree";
import { reduce, isEmpty, without } from "lodash";
import { createBrowserHistory } from "history";
import { updateUrlFilter, PeriodFilterType, IPeriodFilterType } from "./utils";

const history = createBrowserHistory();

const ElementFilters = types
  .model("Element Filters", {
    projectId: "",
    addedMethod: "",
    locatorFilter: "",
    labelFilter: "",
    typeFilter: types.optional(types.array(types.string), []),
    periodFilter: types.maybeNull(PeriodFilterType),
    usedFilter: "",
  })
  .views((self) => ({
    get isEmpty() {
      return reduce(
        self,
        (r, value, key) => (key === "projectId" ? r : r && isEmpty(value)),
        true
      );
    },
    get periodFilterTypes() {
      return [
        "today",
        "yesterday",
        "thisWeek",
        "lastWeek",
        "thisMonth",
        "lastMonth",
        "lastThreeMonths",
      ] as const;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      const filters = new URLSearchParams(window.location.search);
      self.locatorFilter = filters.get("locatorFilter") || "";
      self.labelFilter = filters.get("labelFilter") || "";
      applySnapshot(self.typeFilter, filters.getAll("typeFilter"));
      const periodFilterType = filters.get("periodFilter") as IPeriodFilterType;
      if (self.periodFilterTypes.includes(periodFilterType || "")) {
        self.periodFilter = periodFilterType;
      }
      self.usedFilter = filters.get("usedFilter") || "";
    },
  }))
  .actions((self) => ({
    setProjectId(projectId: string) {
      self.projectId = projectId;
    },
    saveLocatorFilter(locatorFilter: string) {
      self.locatorFilter = locatorFilter;
      updateUrlFilter("locatorFilter", locatorFilter);
    },
    clearLocatorFilter() {
      self.locatorFilter = "";
      updateUrlFilter("locatorFilter", "");
    },
    saveElementLabelFilter(labelFilter: string) {
      self.labelFilter = labelFilter;
      updateUrlFilter("labelFilter", labelFilter);
    },
    clearElementLabelFilter() {
      self.labelFilter = "";
      updateUrlFilter("labelFilter", "");
    },
    saveTypeFilter(types: string[]) {
      applySnapshot(self.typeFilter, types);
      updateUrlFilter("typeFilter", types);
    },
    clearTypeFilter(type: string) {
      const types = without(self.typeFilter, type);
      applySnapshot(self.typeFilter, types);
      updateUrlFilter("typeFilter", types);
    },
    savePeriodFilter(value: IPeriodFilterType) {
      self.periodFilter = value;
      updateUrlFilter("periodFilter", value);
    },
    clearPeriodFilter() {
      self.periodFilter = null;
      updateUrlFilter("periodFilter", "");
    },
    saveUsedFilter(value: string) {
      self.usedFilter = value;
      updateUrlFilter("usedFilter", value);
    },
    clearUsedFilter() {
      self.usedFilter = "";
      updateUrlFilter("usedFilter", "");
    },
    clearAllFilters() {
      applySnapshot(self, {});
      //ToDo: revert once fixed https://github.com/ReactTraining/history/issues/814
      history.push({ pathname: window.location.pathname, search: "" });
    },
  }))
  .actions((self) => ({
    getElementsClearAction(filterName: string, filter: any) {
      switch (filterName) {
        case "locatorFilter":
          return self.clearLocatorFilter;
        case "labelFilter":
          return self.clearElementLabelFilter;
        case "typeFilter":
          return () => self.clearTypeFilter(filter!);
        case "periodFilter":
          return self.clearPeriodFilter;
        case "usedFilter":
          return self.clearUsedFilter;
        default:
          return () => null;
      }
    },
  }));

export interface IElementFilters extends Instance<typeof ElementFilters> {}
export interface IElementFiltersSnapshotOut
  extends SnapshotOut<typeof ElementFilters> {}

export default ElementFilters;
