import { FC, useState } from "react";
import { map } from "lodash";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { DeleteOutlined } from "@mui/icons-material";
import { useRootStoreContext } from "../../hooks";
import { IUser } from "../../models";
import { ConfirmationModal } from "../Modals";
import StyledButton from "./StyledButton";
import SelectMenuItem from "./SelectMenuItem";

const useStyles = makeStyles(() => ({
  attributionButton: {
    marginLeft: "auto",
  },
}));

const ProjectUsersBulkMenu: FC = observer(() => {
  const { users } = useRootStoreContext();
  const classes = useStyles();
  const { t } = useTranslation(["project_team"]);
  const { projectId = "" } = useParams<{ projectId: string }>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const onDeleteConfirm = async () => {
    setShowConfirmationModal(false);
    const selectedIds = map<any, number>(
      users.getSelectedItems(users.activeAndPendingUsers),
      ({ id }: IUser) => id
    );

    await users.deleteUsersFromProject(selectedIds, projectId);
    await users.loadProjectUsers(projectId);
  };

  const isDeleteDisabled =
    users
      .getSelectedItems(users.activeAndPendingUsers)
      .filter((user) => (user as IUser).role === "admin").length > 0;

  return (
    <>
      <StyledButton
        onClick={() => setShowConfirmationModal(true)}
        startIcon={<DeleteOutlined />}
        disabled={isDeleteDisabled}
      >
        {t("delete_from_project")}
      </StyledButton>
      <SelectMenuItem
        containerClassName={classes.attributionButton}
        selectedSize={users.getSelectedSize(users.activeAndPendingUsers)}
        onSelectAll={() =>
          users.toAllsetSelect(users.activeAndPendingUsers, true)
        }
        onSelectNone={() =>
          users.toAllsetSelect(users.activeAndPendingUsers, false)
        }
      />
      <ConfirmationModal
        isOpened={showConfirmationModal}
        text={t("common:confirm")}
        onConfirm={() => onDeleteConfirm()}
        onCancel={() => setShowConfirmationModal(false)}
      />
    </>
  );
});

export default ProjectUsersBulkMenu;
