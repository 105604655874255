import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Box, Typography, IconButton, Theme } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const useStyles = makeStyles((theme: Theme) => ({
  iconButtonSearchOutlinedIcon: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(-1),
    color: theme.palette.baliHai.main,
  },
  secondaryBox: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    ...theme.typography.subHeaderTextStyle,
    color: theme.palette.black.main,
  },
  label: {
    ...theme.typography.bodyTextStyle,
    color: theme.palette.mineShaft.main,
  },
}));

interface IHeaderProps {
  onSearchClick: () => void;
  children?: ReactNode;
}
const Header: FC<IHeaderProps> = ({ onSearchClick, children }) => {
  const { t } = useTranslation([
    "side_bar_of_actions_elements_shared_steps",
    "common",
  ]);
  const classes = useStyles();

  return (
    <>
      <Box className={classes.secondaryBox}>
        <Typography className={classes.header}>{children}</Typography>
      </Box>

      <Box className={classes.secondaryBox}>
        <Typography className={classes.label}>{t("common:search")}</Typography>
        <IconButton
          className={classes.iconButtonSearchOutlinedIcon}
          onClick={onSearchClick}
          size="large"
        >
          <SearchOutlinedIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default Header;
