import { types, Instance } from "mobx-state-tree";
import WithSelected from "./WithSelected";

const EnvironmentVariable = types.compose(
  types.model("EnvironmentVariable", {
    VariableId: types.maybe(types.number),
    EnvironmentId: types.maybe(types.number),
    createdAt: types.maybe(types.string),
    id: types.maybe(types.number),
    value: types.maybeNull(types.string),
  }),
  WithSelected
);

export interface IEnvironmentVariable
  extends Instance<typeof EnvironmentVariable> {}

export default EnvironmentVariable;
