import { FC, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Box, Divider, Grid, Theme } from "@mui/material";
import {
  AddBoxOutlined,
  KeyboardArrowDownOutlined,
  FilterListOutlined,
} from "@mui/icons-material";
import { useRootStoreContext } from "../../hooks";
import StyledButton from "../StyledButton";
import SortMenu from "./SortMenu";
import UserFilterMenu from "../UserFilterMenu";
import FilterBar from "../FilterBar";

const useStyles = makeStyles((theme: Theme) => ({
  controlBarContainer: {
    height: theme.spacing(7),
    padding: "0 1rem",
    backgroundColor: theme.palette.white.main,
  },
  button: {
    marginRight: theme.spacing(2),
  },
  sortMenu: {
    margin: theme.spacing(0, 3),
  },
  dividerWrapper: {
    padding: theme.spacing(0, 4),
    backgroundColor: theme.palette.white.main,
  },
  divider: {
    backgroundColor: theme.palette.catskillWhite.main,
  },
}));

const ControlBar: FC = observer(() => {
  const { t } = useTranslation(["users"]);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null);
  const { users, userFilters } = useRootStoreContext();
  const history = useHistory();

  const onFilterMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onFilterMenuClose = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onSortMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSortAnchorEl(e.currentTarget);
  };

  const onSortMenuClose = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    setSortAnchorEl(null);
  };

  const onCreateClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    users.setUserToInvite();
    history.push("/dashboard/users/pending");
  };

  return (
    <>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        className={classes.controlBarContainer}
      >
        <Grid item container alignItems="center" justifyContent="flex-start">
          <StyledButton
            startIcon={<FilterListOutlined />}
            onClick={onFilterMenuClick}
          >
            {t("filters")}
          </StyledButton>
          <UserFilterMenu
            anchorEl={anchorEl}
            onMenuClose={onFilterMenuClose}
            filterStore={userFilters}
          />
          <StyledButton
            endIcon={<KeyboardArrowDownOutlined />}
            onClick={onSortMenuClick}
          >
            {t(`sort_menu.${users.sortParam}`)}
          </StyledButton>
          <SortMenu
            anchorEl={sortAnchorEl}
            onMenuClose={onSortMenuClose}
            className={classes.sortMenu}
            sort={(sortBy) => users.setSortParam(sortBy)}
          />
        </Grid>
        <Grid item container alignItems="center" justifyContent="flex-end">
          <StyledButton
            onClick={onCreateClick}
            startIcon={<AddBoxOutlined />}
            className={classes.button}
          >
            {t("invite_new_user")}
          </StyledButton>
        </Grid>
      </Grid>
      {userFilters.isEmpty ? null : (
        <>
          <Box className={classes.dividerWrapper}>
            <Divider className={classes.divider} />
          </Box>
          <FilterBar filterStore={userFilters} entityType="users" />
        </>
      )}
    </>
  );
});

export default ControlBar;
