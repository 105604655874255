import React, { useEffect } from "react";
import _ from "lodash";
import { observer } from "mobx-react";
import { Redirect, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import { useRootStoreContext } from "../../hooks";
import { ITestStep } from "../../models";
import BulkMenus, { SharedStepListBulkMenu } from "../BulkMenus";
import { EmptyContainer } from "../";
import SharedStepsItem from "./SharedStepsItem";
import NewSharedStepItem from "./NewSharedStepItem";

const useStyles = makeStyles((theme: Theme) => ({
  sharedStepsListContainer: {
    flex: 1,
    minHeight: 0,
    margin: theme.spacing(3, 0, 3),
  },
  listHeader: {
    ...theme.typography.tableLabelTextStyle,
    color: theme.palette.doveGray.main,
    justifyContent: "space-between",
    padding: theme.spacing(3, 4, 2),
  },
  divider: {
    margin: theme.spacing(0, 4),
    backgroundColor: theme.palette.catskillWhite.main,
  },
  sharedStepsList: {
    overflowY: "auto",
  },
  newSharedStepContainer: {
    position: "relative",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(3, 4, 3, 3.5),
    backgroundColor: theme.palette.catskillLightWhite.main,
    borderLeft: `4px solid ${theme.palette.outrageousOrange.main}`,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.catskillLightWhite.main,
    },
  },
}));

const SharedStepsList: React.FC = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation(["shared_steps"]);
  const { projectId = "" } = useParams<{ projectId: string }>();
  const {
    testSteps,
    users: { profile },
  } = useRootStoreContext();

  useEffect(() => {
    testSteps.loadSharedSteps(parseInt(projectId, 10));
    profile.load();
  }, [testSteps, projectId, profile]);

  if (!profile.isAuthenticated) return <Redirect to="/sign-in" />;

  if (!testSteps.filteredItems?.length && !testSteps.newSharedStep)
    return (
      <EmptyContainer
        title={t("empty_list_title")}
        description={t("empty_list_description")}
      />
    );

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={classes.sharedStepsListContainer}
    >
      <Grid
        item
        container
        wrap="nowrap"
        direction="column"
        className={classes.sharedStepsList}
      >
        {testSteps.newSharedStep ? (
          <Grid container className={classes.newSharedStepContainer}>
            <NewSharedStepItem sharedStep={testSteps.newSharedStep} />
          </Grid>
        ) : null}
        {_.map(
          testSteps.filteredItems as ITestStep[],
          (sharedStep: ITestStep, i: number) => {
            return (
              <SharedStepsItem
                key={sharedStep.testId}
                sharedStep={sharedStep}
              />
            );
          }
        )}
      </Grid>
      {testSteps.getSelectedSize(testSteps.filteredItems) > 1 ? (
        <BulkMenus>
          <SharedStepListBulkMenu />
        </BulkMenus>
      ) : null}
    </Grid>
  );
});

export default SharedStepsList;
