import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Theme, SvgIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";

import StyledButton from "../StyledButton";

import { addResourcesBundle } from "../../i18n";
import { ReactComponent as GoogleIcon } from "../../icons/Google.svg";
import { ReactComponent as GitHubIcon } from "../../icons/GitHub.svg";
import { ReactComponent as SlackIcon } from "../../icons/Slack.svg";

import en from "./en.json";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginBottom: theme.spacing(1),
  },
}));

interface IAuthButtons {
  authAction: "sign_in" | "sign_up";
  onGoogle: () => void;
  onGitHub: () => void;
  onSlack: () => void;
}

const AuthButtons: FC<IAuthButtons> = ({
  onGoogle,
  onGitHub,
  onSlack,
  authAction,
}) => {
  const { t } = useTranslation("auth_buttons");
  const classes = useStyles();

  return (
    <>
      <StyledButton
        variant="outlined"
        color="primary"
        className={classes.button}
        startIcon={
          <SvgIcon viewBox="0 0 24 24">
            <GoogleIcon />
          </SvgIcon>
        }
        onClick={onGoogle}
      >
        {t(`${authAction}_google`)}
      </StyledButton>

      <StyledButton
        variant="outlined"
        color="primary"
        className={classes.button}
        startIcon={
          <SvgIcon viewBox="0 0 24 24">
            <GitHubIcon />
          </SvgIcon>
        }
        onClick={onGitHub}
      >
        {t(`${authAction}_github`)}
      </StyledButton>

      <StyledButton
        variant="outlined"
        color="primary"
        className={classes.button}
        startIcon={
          <SvgIcon viewBox="0 0 24 24">
            <SlackIcon />
          </SvgIcon>
        }
        onClick={onSlack}
        disabled
      >
        {t(`${authAction}_slack`)}
      </StyledButton>
    </>
  );
};

export default AuthButtons;
