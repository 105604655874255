import React from "react";
import { observer } from "mobx-react";
import { TextField } from "../Inputs";
import StyledSelect from "../StyledSelect";
import StyledButton from "../StyledButton";
import { Avatar, Grid, MenuItem, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { IUser } from "../../models";
import { ReactComponent as UploadIcon } from "../../icons/Upload.svg";

const useStyles = makeStyles((theme: Theme) => ({
  settingsContainer: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    [theme.breakpoints.up("sm")]: {
      width: 520,
    },
    padding: theme.spacing(4),
    overflowY: "auto",
    minHeight: 0,
  },
  settingsAvatar: {
    alignItems: "center",
    padding: theme.spacing(0, 0, 3),
    width: "100%",
  },
  settingsItem: {
    padding: theme.spacing(0, 0, 3),
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(4),
    textDecoration: "none",
  },
  avatarContainer: {
    ...theme.typography.bodyTextStyle,
    margin: theme.spacing(0, 1, 0),
    width: 100,
  },
  avatarDesc: {
    padding: theme.spacing(0, 0, 0, 1),
    width: 340,
  },
  avatar: {
    width: 100,
    height: 100,
  },
  uploadText: {
    ...theme.typography.bodyTextStyle,
    color: theme.palette.mineShaft.main,
    marginBottom: theme.spacing(2),
  },
  label: {
    ...theme.typography.labelTextStyle,
    color: theme.palette.doveGray.main,
  },
}));

const GeneralSettings: React.FC<{ profile: IUser }> = observer(
  ({ profile }) => {
    const classes = useStyles();
    const { t } = useTranslation(["generalMenu"]);

    const onSaveClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      profile.save();
    };

    return (
      <Grid
        container
        wrap="nowrap"
        direction="column"
        className={classes.settingsContainer}
      >
        <Grid item container className={classes.settingsAvatar}>
          <Grid item className={classes.avatarContainer}>
            <Typography className={classes.label}>{t("yourPhoto")}</Typography>
            <Avatar
              alt={t("yourPhoto")}
              src={`${process.env.PUBLIC_URL}/logo.png`}
              className={classes.avatar}
            />
          </Grid>
          <Grid item className={classes.avatarDesc}>
            <Typography className={classes.uploadText}>
              {t("uploadText")}
            </Typography>
            <StyledButton
              variant="outlined"
              color="primary"
              className={classes.button}
              startIcon={<UploadIcon />}
            >
              {t("upload")}
            </StyledButton>
            <StyledButton color="primary" className={classes.button}>
              {t("remove")}
            </StyledButton>
          </Grid>
        </Grid>
        <Grid item className={classes.settingsItem}>
          <TextField
            labelText={t("firstLastName")}
            inputBaseProps={{
              rows: 1,
              maxRows: 1,
              value: profile.firstLastName || "",
              onChange: profile.onChangeFirstLastName,
              fullWidth: true,
            }}
          />
        </Grid>
        <Grid item className={classes.settingsItem}>
          <StyledSelect
            labelText={t("role")}
            value={profile.role}
            onChange={profile.onChangeRole}
          >
            <MenuItem value="admin">{t("ownerRole")}</MenuItem>
            <MenuItem value="user">{t("userRole")}</MenuItem>
          </StyledSelect>
        </Grid>
        <Grid item className={classes.settingsItem}>
          <TextField
            labelText={t("apiKey")}
            inputBaseProps={{
              rows: 1,
              disabled: true,
              value: profile.apiKey || "",
              onChange: profile.onChangeApiKey,
              maxRows: 1,
              fullWidth: true,
            }}
          />
        </Grid>
        {process.env.REACT_APP_ENV === "development" && (
          <Grid item className={classes.settingsItem}>
            <TextField
              labelText={t("recorderId")}
              inputBaseProps={{
                rows: 1,
                value: profile.recorderExtId || "",
                onChange: profile.onChangeRecorderExtId,
                maxRows: 1,
                fullWidth: true,
              }}
            />
          </Grid>
        )}
        <Grid item className={classes.settingsItem}>
          <StyledButton
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={onSaveClick}
          >
            {t("saveChanges")}
          </StyledButton>
        </Grid>
      </Grid>
    );
  }
);

export default GeneralSettings;
