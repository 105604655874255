import React, { useState, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import clsx from "clsx";
import _ from "lodash";
import { Box, IconButton, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TooltipProps } from "@mui/material/Tooltip";
import { ClearOutlined, DoneOutlined } from "@mui/icons-material";
import { TextField } from "../Inputs";
import { StyledTooltip } from "../../components";

interface ITestEditAreaProps {
  minRows?: number;
  maxRows?: number;
  maxLength: number;
  placeholder?: string;
  errorText?: string;
  itemName: string;
  isValid?: boolean;
  useTooltip?: boolean;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSaveClick?: (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
  ) => void;
  onCancelClick?: (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
  ) => void;
  placement?: TooltipProps["placement"];
  clsTooltip?: string;
  labelText?: string;
  autoFocus?: boolean;
  hideHelperText?: boolean;
  customClassName?: string;
  disabled?: boolean;
  multiline?: boolean;
  hideControls?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    padding: theme.spacing(0),
    width: "100%",
    minHeight: 80,
    position: "relative",
  },
  inputBaseClass: {
    backgroundColor: theme.palette.white.main,
    height: "100%",
    padding: "10px 48px 10px 40px",
  },
  inputBaseClassNoControls: {
    padding: "10px 8px",
  },
  buttonOutlined: {
    position: "absolute",
    top: 0,
  },
  iconButtonClearOutlined: {
    left: theme.spacing(2),
    padding: theme.spacing(1),
  },
  clearOutlinedIcon: {
    width: 24,
    height: 24,
  },
  iconButtonDoneOutlined: {
    right: theme.spacing(2),
    padding: theme.spacing(1),
  },
  doneOutlinedIcon: {
    width: 24,
    height: 24,
  },
}));

const ValidatableTextArea = observer(
  forwardRef<HTMLInputElement, ITestEditAreaProps>(
    (
      {
        minRows = 1,
        maxRows = 1,
        isValid = true,
        maxLength,
        placeholder,
        errorText,
        itemName,
        useTooltip,
        onChange,
        onSaveClick,
        onCancelClick,
        placement,
        clsTooltip,
        labelText,
        autoFocus,
        customClassName,
        hideHelperText = false,
        disabled = false,
        multiline = true,
        hideControls = false,
      },
      ref,
    ) => {
      const classes = useStyles();
      const { t } = useTranslation(["common"]);
      const nameLength = _.trim(itemName).length;
      const isHelperTextRightError = nameLength > maxLength;
      const [shouldBeValidated, setShouldBeValidated] = useState(false);

      const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!shouldBeValidated) setShouldBeValidated(true);
        if (!isValid) return;
        onSaveClick && onSaveClick(e);
      };

      const renderTextFieldWithControls = () => {
        const errorTextEdited = useTooltip
          ? errorText?.replace(/\w+.*/, " ")
          : errorText;
        const error = shouldBeValidated || hideControls ? errorTextEdited : "";
        const helperTextRight = isHelperTextRightError
          ? t("characters_over", { number: nameLength - maxLength })
          : t("characters_left", { number: maxLength - nameLength });

        return (
          <TextField
            autoFocus={autoFocus}
            ref={ref}
            boxClassName={clsx(classes.textField, customClassName)}
            errorText={error}
            helperTextRight={hideHelperText ? "" : helperTextRight}
            isHelperTextRightError={isHelperTextRightError}
            labelText={labelText}
            disabled={disabled}
            inputBaseProps={{
              minRows,
              maxRows,
              multiline,
              fullWidth: true,
              value: itemName,
              placeholder,
              className: clsx(classes.inputBaseClass, {
                [classes.inputBaseClassNoControls]: hideControls,
              }),
              onChange,
              onClick: (e: React.SyntheticEvent) => e.stopPropagation(),
            }}
            startAdornment={(className) =>
              hideControls ? null : (
                <IconButton
                  edge="start"
                  className={clsx(
                    className,
                    classes.iconButtonClearOutlined,
                    classes.buttonOutlined,
                  )}
                  onClick={onCancelClick}
                  disabled={disabled}
                  size="large"
                >
                  <ClearOutlined className={classes.clearOutlinedIcon} />
                </IconButton>
              )
            }
            endAdornment={(className) =>
              hideControls ? null : (
                <IconButton
                  edge="end"
                  className={clsx(
                    className,
                    classes.iconButtonDoneOutlined,
                    classes.buttonOutlined,
                  )}
                  onClick={onSubmit}
                  disabled={disabled}
                  size="large"
                >
                  <DoneOutlined className={classes.doneOutlinedIcon} />
                </IconButton>
              )
            }
          />
        );
      };

      return useTooltip ? (
        <StyledTooltip
          open={shouldBeValidated && !isValid}
          title={errorText || ""}
          placement={placement}
          type="error"
        >
          <Box className={clsTooltip}>{renderTextFieldWithControls()}</Box>
        </StyledTooltip>
      ) : (
        renderTextFieldWithControls()
      );
    },
  ),
);

export default ValidatableTextArea;
