import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import _ from "lodash";
import { ITestStep } from "../../models";
import { TextField, Checkbox } from "../Inputs";

const useStyles = makeStyles((theme: Theme) => ({
  rootBlock: {
    marginBottom: theme.spacing(4),
  },
  checkbox: {
    margin: theme.spacing(-2, 0, 0, -1),
  },
}));

interface IErrorMessage {
  testStep: ITestStep;
}

const ErrorMessage: React.FC<IErrorMessage> = observer(({ testStep }) => {
  const classes = useStyles();
  const { t } = useTranslation(["actions", "common"]);
  const bounced = useCallback(
    () => _.debounce(testStep.save, 1000),
    [testStep.save]
  );

  const onChangeErrorMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 255) return;
    testStep.setErrorMessage(e.target.value);
    bounced();
  };

  const onCustomErrorMessageClick = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.stopPropagation();
    testStep.toggleCustomErrorMessage();
    testStep.save();
  };

  return (
    <Grid item className={classes.rootBlock}>
      <TextField
        disabled={!testStep.hasErrorMessage}
        labelText={t("error_text")}
        helperTextRight={t("common:characters_left", {
          number: 255 - (testStep.options.errorMessage?.length || 0),
        })}
        inputBaseProps={{
          fullWidth: true,
          rows: 5,
          maxRows: 5,
          multiline: true,
          value: testStep.options.errorMessage || "",
          onChange: onChangeErrorMessage,
          onBlur: testStep.save,
        }}
      />
      <Checkbox
        className={classes.checkbox}
        checked={testStep.hasErrorMessage}
        label={t("custom_error_message")}
        labelPlacement="end"
        onChange={onCustomErrorMessageClick}
      />
    </Grid>
  );
});

export default ErrorMessage;
