import Tab, { TabProps } from "@mui/material/Tab";
import { Theme } from "@mui/material";
import { withStyles } from "@mui/styles";

export default withStyles((theme: Theme) => ({
  root: {
    ...theme.typography.bodyTextStyle,
    textTransform: "none",
    color: theme.palette.doveGray.main,
    padding: theme.spacing(1, 2),
    minHeight: "auto",
    minWidth: 0,
    opacity: 1,
    "&:hover&:not($selected)": {
      color: theme.palette.outrageousOrange.main,
      backgroundColor: theme.palette.catskillLightWhite.main,
    },
  },
  selected: {
    color: theme.palette.mineShaft.main,
    cursor: "default",
    "&.MuiTab-textColorPrimary": {
      color: theme.palette.mineShaft.main,
    },
  },
}))((props: TabProps) => <Tab disableRipple {...props} />);
