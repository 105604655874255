import { Instance, types } from "mobx-state-tree";

export type ErrorStrategies = "stop" | "continue" | undefined;

const TestStepOptions = types
  .model("TestStepOptions", {
    clearField: types.maybe(types.optional(types.boolean, false)),
    errorMessage: types.maybe(types.string),
    errorStrategy: types.maybe(
      types.optional(types.enumeration(["stop", "continue"]), "stop")
    ),
    reverse: types.maybe(types.optional(types.boolean, false)),
  })
  .actions((self) => ({
    toggleClearField() {
      self.clearField = !self.clearField;
    },
    setErrorMessage(value?: string) {
      self.errorMessage = value;
    },
    setErrorStrategy(value: ErrorStrategies) {
      self.errorStrategy = value;
    },
    setReverse(value: boolean) {
      self.reverse = value;
    },
  }));

export interface ITestStepOptions extends Instance<typeof TestStepOptions> {}

export default TestStepOptions;
