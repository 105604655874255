import {
  types,
  Instance,
  SnapshotOut,
  getParentOfType,
  flow,
  getRoot,
} from "mobx-state-tree";
import i18next from "i18next";
import { isEmpty, find } from "lodash";
import { IRoot } from "./Root";
import Project from "./Project";
import { handleFetch, isEmail } from "./utils";

export type role = "user" | "manager" | "admin";

export interface IProjectInviteErrors {
  email?: string;
}

const ProjectInvite = types
  .model({
    email: "",
    role: "",
    serverError: "",
  })
  .actions((self) => ({
    setInviteEmail(email: string) {
      if (self.serverError === "EXISTING_EMAIL") self.serverError = "";
      self.email = email;
    },
    setInviteRole(role: role) {
      self.role = role;
    },
    setServerError(error: string) {
      self.serverError = error;
    },
    removeInvite() {
      getParentOfType(self, Project).onRemoveInvite(self as IProjectInvite);
    },
    addInvite() {
      getParentOfType(self, Project).onAddInvite();
    },
    inviteUser: flow(function* () {
      try {
        const response = yield handleFetch(self, `/api/auth/invite`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ users: [{ email: self.email }] }),
        });
        if (!response.ok)
          throw new Error(`${response.status} ${response.statusText}`);
      } catch (error: any) {
        console.error("Error in inviteUsers method", error?.message);
        throw error;
      }
    }),
    validateEmail: flow(function* () {
      try {
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/auth/validate`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ emails: [self.email] }),
          }
        );

        const { emails: existingEmails } = yield response.json();

        self.serverError = existingEmails.includes(self.email)
          ? "EXISTING_EMAIL"
          : "";
        return self.serverError === "";
      } catch (error) {
        throw error;
      }
    }),
  }))
  .views((self) => ({
    fieldErrors(required = false, scope: Array<IProjectInviteErrors> = []) {
      const errors: IProjectInviteErrors = {};

      const email = self.email.trim();

      if (email.length === 0 && !required) return errors;

      const {
        users: { users },
      } = getRoot<IRoot>(self);

      if (email.length === 0) {
        errors.email = i18next.t("project_invite:errors:email:required");
      } else if (!isEmail(email)) {
        errors.email = i18next.t("project_invite:errors:email:invalid");
      } else if (
        scope.filter((item) => item.email?.trim() === email).length > 1
      ) {
        errors.email = i18next.t("project_invite:errors:email:duplicate");
      } else if (find(users, { email })) {
        errors.email = i18next.t("project_invite:errors:email:member");
      } else if (self.serverError === "EXISTING_EMAIL") {
        errors.email = i18next.t("project_invite:errors:email:exists");
      }

      return errors;
    },
    get isValid() {
      return isEmpty(this.fieldErrors(true));
    },
  }));

export interface IProjectInvite extends Instance<typeof ProjectInvite> {}
export interface IProjectInviteSnapshotOut
  extends SnapshotOut<typeof ProjectInvite> {}

export default ProjectInvite;
