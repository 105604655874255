import { FC } from "react";
import { observer } from "mobx-react";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TaIcon from "../TaIcon";

const useStyles = makeStyles((theme: Theme) => ({
  emptyContainer: {
    height: "100%",
  },
  emptyIcon: {
    marginRight: theme.spacing(),
    color: theme.palette.rockBlue.main,
  },
  emptyTitle: {
    ...theme.typography.subHeaderTextStyle,
    color: theme.palette.doveGray.main,
  },
  emptyDescription: {
    marginTop: theme.spacing(),
    ...theme.typography.bodyTextStyle,
    color: theme.palette.doveGray.main,
    textAlign: "center",
    whiteSpace: "pre-line",
  },
}));

interface IEmptyContainer {
  title: string;
  description?: string;
}

const EmptyContainer: FC<IEmptyContainer> = observer(
  ({ title, description }) => {
    const classes = useStyles();

    return (
      <Grid
        container
        wrap="nowrap"
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.emptyContainer}
      >
        <Grid
          item
          container
          wrap="nowrap"
          alignItems="center"
          justifyContent="center"
        >
          <TaIcon iconName="InfoOutlined" className={classes.emptyIcon} />
          <Grid item className={classes.emptyTitle}>
            {title}
          </Grid>
        </Grid>
        <Grid item className={classes.emptyDescription}>
          {description}
        </Grid>
      </Grid>
    );
  }
);

export default EmptyContainer;
