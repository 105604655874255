import { FC, SyntheticEvent } from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DoneOutlined } from "@mui/icons-material";
import { IElement } from "../../models";
import { useRootStoreContext } from "../../hooks";
import ValidatableTextArea from "../Inputs/ValidatableTextArea";
import { ReactComponent as ContentCopy } from "../../icons/ContentCopy.svg";

const useStyles = makeStyles((theme: Theme) => ({
  cellContainer: {
    position: "relative",
  },
  iconContainer: {
    position: "absolute",
    left: "-2rem",
    visibility: "hidden",
  },
  copyIcon: {
    width: "1.5rem",
    height: "1.5rem",
    fill: theme.palette.rockBlue.main,
    "&:hover": {
      fill: theme.palette.outrageousOrange.main,
    },
  },
  textField: {
    minHeight: "auto",
    maxHeight: "2.5rem",
    marginRight: theme.spacing(2),
    "& .MuiInputBase-root": {
      maxHeight: "2.5rem",
    },
  },
}));

interface IElementNameCell {
  element: IElement;
}

const ElementNameCell: FC<IElementNameCell> = observer(({ element }) => {
  const { elements } = useRootStoreContext();
  const { t } = useTranslation(["elements"]);
  const classes = useStyles();
  const onElementNameCopy = (e: SyntheticEvent) => {
    e.stopPropagation();
    element.showCopiedNotification();
    element.copyToClipboard();
  };
  const { editElement } = elements;
  if (editElement && editElement.id === element.id)
    return (
      <Grid container wrap="nowrap" alignItems="center">
        <ValidatableTextArea
          autoFocus
          hideHelperText
          minRows={1}
          maxRows={1}
          multiline={false}
          maxLength={50}
          customClassName={classes.textField}
          itemName={editElement.name}
          isValid={editElement.isValid}
          errorText={editElement.validationErrorText}
          onChange={editElement.onChangeName}
          onSaveClick={editElement.onSaveEditClick}
          onCancelClick={editElement.onCancelEditClick}
          placeholder={editElement.slug}
        />
      </Grid>
    );

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      className={classes.cellContainer}
    >
      <Grid
        item
        title={element.copied ? t("copied") : t("copy")}
        className={clsx("visibleOnHoverContent", classes.iconContainer)}
        onClick={onElementNameCopy}
      >
        {element.copied ? (
          <DoneOutlined
            className={classes.copyIcon}
            onMouseLeave={() => element.hideCopiedNotification()}
          />
        ) : (
          <ContentCopy className={classes.copyIcon} />
        )}
      </Grid>
      <Grid item>{element.name}</Grid>
    </Grid>
  );
});

export default ElementNameCell;
