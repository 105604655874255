import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { IconButton, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { TextField } from "../Inputs";

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    flex: "1 1 auto",
  },
  iconButtonClearOutlinedIcon: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(-1),
  },
  clearOutlinedIcon: {
    width: 24,
    height: 24,
  },
  iconButtonSearchOutlinedIcon: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(-1),
  },
}));

interface ISearchProps {
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeText?: (newValue: string) => void;
  onClose?: () => void;
  className?: string;
  labelText?: string;
  placeholder?: string;
}
const Search = forwardRef<HTMLInputElement, ISearchProps>(
  (
    {
      onChange,
      value,
      onChangeText,
      onClose,
      className,
      labelText,
      placeholder,
    },
    ref
  ) => {
    const { t } = useTranslation(["common"]);
    const classes = useStyles();

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event);
      onChangeText && onChangeText(event.target.value);
    };

    return (
      <TextField
        ref={ref}
        boxClassName={clsx(classes.textField, className)}
        inputBaseProps={{
          fullWidth: true,
          onChange: onInputChange,
          value: value,
          placeholder: placeholder || t("common:search"),
        }}
        labelText={labelText}
        startAdornment={
          onClose
            ? (className) => (
                <IconButton
                  edge="start"
                  className={clsx(
                    className,
                    classes.iconButtonClearOutlinedIcon
                  )}
                  onClick={onClose}
                  size="large"
                >
                  <ClearOutlinedIcon className={classes.clearOutlinedIcon} />
                </IconButton>
              )
            : null
        }
        endAdornment={(className) => (
          <IconButton
            edge="end"
            className={clsx(className, classes.iconButtonSearchOutlinedIcon)}
            size="large"
          >
            <SearchOutlinedIcon />
          </IconButton>
        )}
      />
    );
  }
);

export default Search;
