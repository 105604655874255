import React from "react";
import _ from "lodash";
import { observer } from "mobx-react";
import { List, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useRootStoreContext } from "../../hooks";
import { ITestStep } from "../../models";
import ItemDragLayer from "../ItemDragLayer";
import NoSearchResult from "../NoSearchResult";
import SharedStep from "./SharedStep";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootBox: {
      overflowY: "auto",
      margin: theme.spacing(2, 0, 0, 3),
      paddingRight: theme.spacing(3),
    },
    listItem: {
      position: "relative",
      padding: theme.spacing(0, 0, 0, 1),
      height: 40,
      display: "flex",
      justifyContent: "space-between",
      "&:hover": {
        backgroundColor: theme.palette.catskillLightWhite.main,
        "& $sharedFoldersIcon, $text": {
          color: theme.palette.outrageousOrange.main,
        },
        "& $actionsBox": {
          display: "flex",
        },
        cursor: "move",
      },
    },
    text: {
      marginLeft: theme.spacing(1),
      ...theme.typography.bodyTextStyle,
      color: theme.palette.mineShaft.main,
    },
    box: {
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
    },
    sharedFoldersIcon: {
      color: theme.palette.baliHai.main,
    },
    number: {
      ...theme.typography.descriptionTextStyle,
      color: theme.palette.doveGray.main,
    },
    actionsBox: {
      right: 0,
      position: "absolute",
      display: "none",
    },
    actionsButtonBox: {
      backgroundColor: theme.palette.catskillLightWhite.main,
      alignItems: "center",
      display: "flex",
      justifyContent: "flex-end",
    },
    actionsGradientBox: {
      width: 40,
      height: 40,
      background: `linear-gradient(90deg, ${theme.palette.catskillLightWhite.main}00 0%, ${theme.palette.catskillLightWhite.main} 100%)`,
    },
    actionsIcons: {
      color: theme.palette.baliHai.main,
      padding: theme.spacing(1),
      marginRight: theme.spacing(1),
      "&:last-child": {
        marginRight: theme.spacing(0),
      },
      "&:hover": {
        color: theme.palette.outrageousOrange.main,
      },
    },
  })
);

interface ISharedSteps {
  searchIsEmpty: boolean;
}

const SharedSteps: React.FC<ISharedSteps> = observer(({ searchIsEmpty }) => {
  const classes = useStyles();
  const { t } = useTranslation(["common"]);
  const { testSteps } = useRootStoreContext();

  return (
    <List disablePadding className={classes.rootBox}>
      {!testSteps.searchItems.length ? (
        <NoSearchResult
          isEmpty={searchIsEmpty}
          description={t("create_shared_step")}
        />
      ) : (
        _.map(testSteps.searchItems, (sharedStep: ITestStep) => (
          <SharedStep sharedStep={sharedStep} />
        ))
      )}
      <ItemDragLayer itemType="Shared Action" iconName="ShareOutlined" />
    </List>
  );
});

export default SharedSteps;
