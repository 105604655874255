import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Divider, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import { IFilterAuthors, IListFilters } from "../../../models";
import { Menu, MenuButton, MenuButtonBlock } from "../../Menus";
import { Search } from "../../Inputs";

import AuthorList from "./AuthorList";

const useStyles = makeStyles((theme: Theme) => ({
  menuPaper: {
    ...theme.typography.descriptionTextStyle,
    color: theme.palette.doveGray.main,
    padding: theme.spacing(1, 3, 0),
    width: theme.spacing(36),
  },
  searchBox: {
    margin: theme.spacing(0.5, 0, 2),
  },
}));

interface IAuthorFilter {
  title: string;
  anchorEl: null | HTMLElement;
  onMenuClose: () => void;
  filterStore: IListFilters;
  authorStore: IFilterAuthors;
  isUpdaters?: boolean;
}

const AuthorFilter: React.FC<IAuthorFilter> = observer(
  ({
    title,
    anchorEl,
    onMenuClose,
    filterStore,
    authorStore,
    isUpdaters = false,
  }) => {
    const classes = useStyles();
    const { t } = useTranslation(["test_filters", "common"]);

    useEffect(() => {
      if (anchorEl) {
        if (isUpdaters) {
          authorStore.updateSelectedUpdaters(filterStore.updatersFilter);
        } else {
          authorStore.updateSelectedCreators(filterStore.creatorsFilter);
        }
      }
      return function cleanup() {
        authorStore.onItemSearch("");
      };
    }, [
      anchorEl,
      isUpdaters,
      authorStore,
      filterStore.updatersFilter,
      filterStore.creatorsFilter,
    ]);

    const onFilterCancel = () => {
      onMenuClose();
    };

    const onFilterSave = () => {
      if (isUpdaters) {
        filterStore.saveUpdatersFilter(authorStore.selectedUpdaters);
      } else {
        filterStore.saveCreatorsFilter(authorStore.selectedCreators);
      }
      onMenuClose();
    };

    if (!anchorEl) return null;

    return (
      <Menu
        anchorEl={anchorEl}
        onClose={onMenuClose}
        PaperProps={{ className: classes.menuPaper }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Search
          labelText={title}
          value={authorStore.itemSearchValue}
          className={classes.searchBox}
          onChangeText={authorStore.onItemSearch}
        />
        <AuthorList authorStore={authorStore} isUpdaters={isUpdaters} />
        <Divider />
        <MenuButtonBlock>
          <MenuButton
            startIcon={<CancelOutlined />}
            label={t("common:cancel")}
            onClick={onFilterCancel}
          />
          <MenuButton
            startIcon={<CheckCircleOutline />}
            label={t("common:apply")}
            onClick={onFilterSave}
          />
        </MenuButtonBlock>
      </Menu>
    );
  }
);

export default AuthorFilter;
