import React from "react";
import clsx from "clsx";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import SelectAllOutlinedIcon from "@mui/icons-material/SelectAllOutlined";
import { Theme, SvgIcon, Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import StyledIconButton from "./StyledIconButton";
import { ReactComponent as SelectNoneIcon } from "../../icons/SelectNone.svg";
import { Menu, MenuItem } from "../Menus";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "fit-content",
  },
  selectedText: {
    ...theme.typography.bodyTextStyle,
    paddingTop: 10,
    color: theme.palette.white.main,
  },
}));

interface ISelectMenuItem {
  selectedSize: number;
  onSelectAll: () => void;
  onSelectNone: () => void;
  containerClassName?: string;
}

const SelectMenuItem: React.FC<ISelectMenuItem> = ({
  selectedSize,
  onSelectAll,
  onSelectNone,
  containerClassName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(["bulk_menus", "common"]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onMenuClose = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Grid
      container
      wrap="nowrap"
      className={clsx(classes.container, containerClassName)}
    >
      <Box className={classes.selectedText}>
        {t("bulk_menus:selected_items_with_count", {
          count: selectedSize,
        })}
      </Box>
      <StyledIconButton
        onClick={() => {
          onSelectNone();
          setAnchorEl(null);
        }}
      >
        <IndeterminateCheckBoxOutlinedIcon />
      </StyledIconButton>
      <StyledIconButton onClick={onMenuClick}>
        <KeyboardArrowDownOutlinedIcon />
      </StyledIconButton>
      <Menu
        anchorEl={anchorEl}
        onClose={onMenuClose}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
        anchorOrigin={{
          vertical: 40,
          horizontal: 32,
        }}
      >
        <MenuItem
          onClick={() => {
            onSelectAll();
            setAnchorEl(null);
          }}
        >
          <SelectAllOutlinedIcon /> {t("select_all")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onSelectNone();
            setAnchorEl(null);
          }}
        >
          <SvgIcon viewBox="0 0 24 24">
            <SelectNoneIcon />
          </SvgIcon>{" "}
          {t("select_none")}
        </MenuItem>
      </Menu>
    </Grid>
  );
};

export default SelectMenuItem;
