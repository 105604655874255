import React from "react";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import { MouseOutlined } from "@mui/icons-material";
import { ReactComponent as ClickIcon } from "../../icons/Click.svg";
import { ReactComponent as ClearValueIcon } from "../../icons/ClearValue.svg";
import { ReactComponent as SelectByIcon } from "../../icons/SelectBy.svg";
import { ReactComponent as WaitForIcon } from "../../icons/WaitFor.svg";
import { ReactComponent as CookieIcon } from "../../icons/Cookie.svg";
import { ReactComponent as ElementIcon } from "../../icons/Element.svg";
import { ReactComponent as VisitIcon } from "../../icons/Visit.svg";
import { ReactComponent as PauseIcon } from "../../icons/Pause.svg";
import { ReactComponent as SetValueIcon } from "../../icons/SetValue.svg";
import { ReactComponent as SwitchToWindowIcon } from "../../icons/SwitchToWindow.svg";
import { ReactComponent as SetWindowSizeIcon } from "../../icons/SetWindowSize.svg";
import { ReactComponent as SharedActionIcon } from "../../icons/SharedAction.svg";

import { ITestStep } from "../../models";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    minWidth: "1.5rem",
    minHeight: "1.5rem",
    color: theme.palette.baliHai.main,
    marginRight: theme.spacing(1),
  },
}));
interface IStepIconProps {
  testStep: ITestStep;
}

const StepIcon: React.FC<IStepIconProps> = ({ testStep }) => {
  const classes = useStyles();
  switch (testStep.type) {
    case "click":
    case "doubleClick":
      return <ClickIcon className={classes.icon} />;
    case "clearValue":
      return <ClearValueIcon className={classes.icon} />;
    case "waitForClickable":
    case "waitForDisplayed":
    case "waitForEnabled":
    case "waitForExist":
      return <WaitForIcon className={classes.icon} />;
    case "selectByIndex":
    case "select":
    case "selectByAttribute":
      return <SelectByIcon className={classes.icon} />;
    case "setCookies":
    case "deleteCookies":
    case "assertCookieValue":
      return <CookieIcon className={classes.icon} />;
    case "assertValue":
    case "assertText":
    case "assertProperty":
    case "assertAttribute":
    case "assertClickable":
    case "assertDisplayed":
    case "assertEnabled":
    case "assertExisting":
    case "assertFocused":
    case "assertSelected":
      return <ElementIcon className={classes.icon} />;
    case "visit":
      return <VisitIcon className={classes.icon} />;
    case "pause":
      return <PauseIcon className={classes.icon} />;
    case "scrollTo":
      return <MouseOutlined className={classes.icon} />;
    case "switchWindow":
      return <SwitchToWindowIcon className={classes.icon} />;
    case "setWindowSize":
      return <SetWindowSizeIcon className={classes.icon} />;
    case "setValue":
      return <SetValueIcon className={classes.icon} />;
    case "sharedAction":
      return <SharedActionIcon className={classes.icon} />;
    default:
      return <CategoryOutlinedIcon className={classes.icon} />;
  }
};

export default StepIcon;
