import { FC, useState } from "react";
import { map } from "lodash";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IProject, IProjectInvite } from "../../models";
import { useRootStoreContext } from "../../hooks";
import StyledButton from "../StyledButton";
import InviteUser from "./InviteUser";
import Members from "./Members";
import MembersAdded from "./MembersAdded";

const useStyles = makeStyles((theme: Theme) => ({
  stepSecondContainer: {
    backgroundColor: theme.palette.white.main,
    padding: theme.spacing(3, 4),
    overflowY: "auto",
  },
  projectCreateTitle: {
    marginBottom: theme.spacing(1),
    color: theme.palette.mineShaft.main,
    ...theme.typography.subHeaderTextStyle,
  },
  projectCreateDescription: {
    marginBottom: theme.spacing(3),
    color: theme.palette.mineShaft.main,
    ...theme.typography.bodyTextStyle,
  },
  inviteList: {},
  button: {
    marginRight: theme.spacing(4),
  },
  footer: {
    width: "26.25rem",
  },
  footerButtons: {},
  footerStepText: {
    color: theme.palette.doveGray.main,
    whiteSpace: "nowrap",
    ...theme.typography.descriptionTextStyle,
  },
}));

interface IStepSecond {
  project: IProject;
  setStep: (stepNumber: 1 | 2) => void;
}

const StepSecond: FC<IStepSecond> = observer(({ project, setStep }) => {
  const [submitted, setSubmitted] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation(["project_create"]);
  const { projects } = useRootStoreContext();
  const history = useHistory();

  const handleSubmit = async () => {
    try {
      await project.validateInvites();
      setSubmitted(true);

      if (!project.isValid("second")) return;

      const createdProject = await project.createProject();
      await projects.load();
      history.push(`/projects/${createdProject.id}/tests`);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      className={classes.stepSecondContainer}
    >
      <Grid item container wrap="nowrap" direction="column">
        <Grid item className={classes.projectCreateTitle}>
          {t("add_users")}
        </Grid>
        <Grid item className={classes.projectCreateDescription}>
          {t("create_project_later")}
        </Grid>
      </Grid>
      <Grid item container className={classes.inviteList}>
        {map(project.invites, (invite, i) => {
          return (
            <InviteUser
              key={`proj_invite_${i}`}
              invite={invite as IProjectInvite}
              isOnlyInvite={project.invites.length === 1}
              isLastInvite={i === project.invites.length - 1}
              errors={(submitted || undefined) && project.inviteErrors[i]}
            />
          );
        })}
      </Grid>
      <Grid item container>
        <MembersAdded project={project} />
      </Grid>
      <Grid item container>
        <Members project={project} />
      </Grid>
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        className={classes.footer}
      >
        <Grid item container className={classes.footerButtons}>
          <Grid item>
            <StyledButton
              variant="outlined"
              className={classes.button}
              onClick={() => setStep(1)}
            >
              {t("common:back")}
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleSubmit}
            >
              {t("create_project")}
            </StyledButton>
          </Grid>
        </Grid>
        <Grid item className={classes.footerStepText}>
          {t("step_two")}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default StepSecond;
