import React from "react";
import {
  useHistory,
  useLocation,
  matchPath,
  useParams,
} from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import HeaderTabs from "@mui/material/Tabs";
import HeaderTab from "./HeaderTab";
import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabsPanel: {
      width: "100%",
      height: "3.5rem",
      padding: "0.5rem 1rem 0",
      marginBottom: "1px",
      borderBottom: `1px solid ${theme.palette.catskillLightWhite.main}`,
    },
    tabIndicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > span": {
        backgroundColor: theme.palette.baliHai.main,
        width: "80%",
      },
    },
  })
);

const Tabs: React.FC<{}> = observer(() => {
  const { t } = useTranslation("header");
  const history = useHistory();
  const { pathname } = useLocation();
  const { projectId = "" } = useParams<{ projectId: string }>();
  const classes = useStyles();

  const match = matchPath(pathname, {
    path: "/projects/:projectId/reports/tests",
  });
  const value = match ? match.url : pathname;
  return (
    <HeaderTabs
      value={value}
      className={classes.tabsPanel}
      onChange={(e, val) => {
        if (val !== value) history.push(val);
      }}
      TabIndicatorProps={{
        className: classes.tabIndicator,
        children: <span />,
      }}
    >
      <HeaderTab
        label={t("separate_tests")}
        value={`/projects/${projectId}/reports/tests`}
      />
      <HeaderTab label={t("test_runs")} />
    </HeaderTabs>
  );
});

export default Tabs;
