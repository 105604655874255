import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { FilterListOutlined } from "@mui/icons-material";
import { Grid, Box, Divider, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { addResourcesBundle } from "../../i18n";
import { useRootStoreContext } from "../../hooks";

import StyledButton from "../StyledButton";
import FilterMenu from "../SeparateTestsFilterMenu";
import FilterBar from "../FilterBar";

import en from "./en.json";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  controlBarContainer: {
    height: theme.spacing(7),
    padding: theme.spacing(0, 2),
    backgroundColor: theme.palette.white.main,
  },
  sortMenu: {
    padding: theme.spacing(1, 4),
  },
  dividerWrapper: {
    padding: theme.spacing(0, 4),
    backgroundColor: theme.palette.white.main,
  },
  divider: {
    backgroundColor: theme.palette.catskillWhite.main,
  },
}));

const SeparateTestFilterRow: React.FC = observer(() => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onFilterMenuClose = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onFilterMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const { reportFilters } = useRootStoreContext();
  const { t: headerT } = useTranslation(["header"]);

  return (
    <>
      <Grid
        container
        item
        justifyContent="flex-start"
        alignItems="center"
        className={classes.controlBarContainer}
      >
        <StyledButton
          startIcon={<FilterListOutlined />}
          onClick={onFilterMenuClick}
        >
          {headerT("filters")}
        </StyledButton>
        <FilterMenu
          anchorEl={anchorEl}
          onMenuClose={onFilterMenuClose}
          filterStore={reportFilters}
        />
      </Grid>
      {reportFilters.isEmpty ? null : (
        <>
          <Box className={classes.dividerWrapper}>
            <Divider className={classes.divider} />
          </Box>
          <FilterBar filterStore={reportFilters} entityType="reports" />
        </>
      )}
    </>
  );
});

export default SeparateTestFilterRow;
