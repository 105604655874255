import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Box, Divider, Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  FilterListOutlined,
  KeyboardArrowDownOutlined,
} from "@mui/icons-material";

import { addResourcesBundle } from "../../i18n";
import { useRootStoreContext } from "../../hooks";
import ElementFilterMenu from "../ElementFilterMenu";
import StyledButton from "../StyledButton";
import FilterBar from "../FilterBar";
import en from "./en.json";
import SortMenu from "./SortMenu";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  controlBarContainer: {
    height: theme.spacing(7),
    padding: theme.spacing(0, 2),
    backgroundColor: theme.palette.white.main,
  },
  sortMenu: {
    padding: theme.spacing(1, 4),
  },
  dividerWrapper: {
    padding: theme.spacing(0, 4),
    backgroundColor: theme.palette.white.main,
  },
  divider: {
    backgroundColor: theme.palette.catskillWhite.main,
  },
}));

const ElementFilterRow: React.FC = observer(() => {
  const classes = useStyles();
  const { elements } = useRootStoreContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [sortAnchorEl, setSortAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const onFilterMenuClose = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  const onFilterMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const onSortMenuClose = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    setSortAnchorEl(null);
  };
  const onSortMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSortAnchorEl(e.currentTarget);
  };
  const { elementFilters } = useRootStoreContext();
  const { t: elementsT } = useTranslation(["elements"]);
  const { t: headerT } = useTranslation(["header"]);

  return (
    <>
      <Grid
        container
        item
        justifyContent="flex-start"
        alignItems="center"
        className={classes.controlBarContainer}
      >
        <StyledButton
          startIcon={<FilterListOutlined />}
          onClick={onFilterMenuClick}
        >
          {headerT("filters")}
        </StyledButton>
        <ElementFilterMenu
          anchorEl={anchorEl}
          onMenuClose={onFilterMenuClose}
          filterStore={elementFilters}
        />
        <StyledButton
          endIcon={<KeyboardArrowDownOutlined />}
          onClick={onSortMenuClick}
        >
          {elementsT(`sort_menu.${elements.sortField}`)}
        </StyledButton>
        <SortMenu
          anchorEl={sortAnchorEl}
          onMenuClose={onSortMenuClose}
          className={classes.sortMenu}
          sort={elements.setSortField}
        />
      </Grid>
      {elementFilters.isEmpty ? null : (
        <>
          <Box className={classes.dividerWrapper}>
            <Divider className={classes.divider} />
          </Box>
          <FilterBar filterStore={elementFilters} entityType="elements" />
        </>
      )}
    </>
  );
});

export default ElementFilterRow;
