import React from "react";
import { observer } from "mobx-react";
import { cast } from "mobx-state-tree";
import { useTranslation } from "react-i18next";
import { ITest, TestStep } from "../../models";
import ValidatableTextArea from "../Inputs/ValidatableTextArea";

const TestFormTextArea: React.FC<{ test: ITest; isTest?: boolean }> = observer(
  ({ test, isTest }) => {
    const { t } = useTranslation(["tests"]);
    const sharedStep = TestStep.create(
      cast({
        id: 0,
        type: "sharedAction",
        order: 0,
        value: test.name,
      })
    );

    return (
      <ValidatableTextArea
        autoFocus={!test.id}
        minRows={3}
        maxRows={3}
        maxLength={255}
        itemName={test.name}
        isValid={test.isValid}
        onChange={test.onChangeName}
        onSaveClick={test.onSaveEditClick}
        onCancelClick={test.onCancelEditClick}
        placeholder={isTest ? t("test_name") : t("test_steps:shared_step_name")}
        errorText={
          isTest ? test.validationErrorText : sharedStep.validationErrorText
        }
      />
    );
  }
);

export default TestFormTextArea;
