import { FC, ReactNode } from "react";
import { Container, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import en from "./en.json";
import { addResourcesBundle } from "../../i18n";
import BackdropForSmallScreen from "./BackdropForSmallScreen";

addResourcesBundle({ en });

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    backgroundColor: theme.palette.catskillWhite.main,
    height: "100%",
    overflow: "hidden",
  },
}));

interface ILayoutProps {
  children?: ReactNode;
}

const Layout: FC<ILayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <Container disableGutters maxWidth={false} className={classes.container}>
      {children}
      <BackdropForSmallScreen />
    </Container>
  );
};

export default Layout;
