import { FC } from "react";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { ReportsHeader } from "../components";
import { ReportsTests } from ".";

const Reports: FC = observer(() => {
  const { path } = useRouteMatch();

  return (
    <Grid container direction="column" wrap="nowrap">
      <ReportsHeader />
      <Switch>
        <Route path={`${path}/tests/:testId?`} component={ReportsTests} />
      </Switch>
    </Grid>
  );
});

export default Reports;
