import { types, SnapshotOut, Instance } from "mobx-state-tree";
import i18next from "i18next";

const Role = types
  .model("Role", {
    name: "",
    selected: false,
  })
  .views((self) => ({
    get label() {
      return i18next.t(`users:roles:${self.name}`);
    },
  }))
  .actions((self) => ({
    onSelect(e: React.ChangeEvent<HTMLInputElement>) {
      e.stopPropagation();
      self.selected = !self.selected;
    },
  }));

export interface IRole extends Instance<typeof Role> {}
export interface IRoleSnapshotOut extends SnapshotOut<typeof Role> {}

export default Role;
