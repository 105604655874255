import { types, Instance } from "mobx-state-tree";
import i18n from "i18next";
import { ActionType } from "./utils";

const Action = types
  .model("Action", {
    type: ActionType,
    selected: false,
  })
  .views((self) => ({
    get name() {
      return i18n.t(
        `side_bar_of_actions_elements_shared_steps:actions_types.${self.type}`
      );
    },
  }))
  .actions((self) => ({
    setSelected(selected: boolean) {
      self.selected = selected;
    },
  }));

export interface IAction extends Instance<typeof Action> {}
export default Action;
