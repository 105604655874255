import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { Box, Theme } from "@mui/material";
import Screenshot from "../Screenshot";
import { ITestStep } from "../../models";

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    ...theme.typography.descriptionTextStyle,
    color: theme.palette.doveGray.main,
    marginBottom: theme.spacing(0.5),
  },
  screenshot: {
    height: 154,
    width: "100%",
  },
}));

interface IDropElement {
  label?: string;
  testStep?: ITestStep;
}

const DropElement: React.FC<IDropElement> = observer(({ label, testStep }) => {
  const classes = useStyles();

  return (
    <>
      {label ? <Box className={classes.label}>{label}</Box> : null}
      <Box>
        <Screenshot
          alt="test"
          boxClassName={classes.screenshot}
          src={testStep?.imgSrc}
        />
      </Box>
    </>
  );
});

export default DropElement;
