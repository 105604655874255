import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { isEmail } from "../models/utils";
import { makeStyles } from "@mui/styles";
import { Grid, Theme, InputAdornment, Typography } from "@mui/material";

import {
  Checkbox,
  Layout,
  LayoutOfContent,
  StyledButton,
  TextField,
  TaIcon,
} from "../components";

import { AuthButtons, SignUpSuccess } from "../components/Auth";

import { useRootStoreContext } from "../hooks";

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    backgroundColor: theme.palette.white.main,
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    width: "100%",
    maxWidth: "100%",
    overflow: "auto",
  },
  root: {
    width: 410,
    marginLeft: "auto",
    marginRight: "auto",
    flexDirection: "column",
  },
  header: {
    marginTop: 80,
    marginBottom: 80,
    fontSize: 18,
    fontWeight: 500,
  },
  logo: {
    width: 48,
    height: 48,
    marginRight: theme.spacing(2),
  },
  actionButtons: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(4),
    alignSelf: "flex-start",
  },
  link: {
    color: theme.palette.outrageousOrange.main,
    textTransform: "uppercase",
    marginLeft: theme.spacing(1),
    "&:hover": {
      color: theme.palette.pomegranate.main,
    },
  },
  terms: {
    color: theme.palette.outrageousOrange.main,
    "&:hover": {
      color: theme.palette.pomegranate.main,
    },
  },
  orEmail: {
    color: theme.palette.doveGray.main,
    fontSize: 12,
    fontWeight: 400,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    textAlign: "center",
  },
  eyeIcon: {
    cursor: "pointer",
    color: theme.palette.rockBlue.main,
    "&:hover": {
      color: theme.palette.outrageousOrange.main,
    },
  },
  stepTitle: {
    fontSize: 15,
    fontWeight: 700,
    marginBottom: theme.spacing(),
  },
  successText: {
    color: theme.palette.mineShaft.main,
    fontSize: 14,
    fontWeight: 400,
    marginBottom: theme.spacing(1),
  },
  successNote: {
    color: theme.palette.doveGray.main,
    fontSize: 12,
    fontWeight: 400,
    marginTop: theme.spacing(5),
  },
  passwordContainer: {
    marginTop: theme.spacing(4),
  },
  footer: {
    marginTop: theme.spacing(4),
  },
  checkbox: {
    marginTop: theme.spacing(1),
  },
}));

interface IFormInputs {
  email: string;
  password: string;
}

const SignUp: FC<{}> = () => {
  const { t } = useTranslation("auth");

  const {
    users: { profile },
  } = useRootStoreContext();

  const history = useHistory();

  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState("email");
  const [serverError, setServerError] = useState(undefined);
  const [emailChecked, setEmailChecked] = useState(false);
  const [passwordChecked, setPasswordChecked] = useState(false);

  const {
    control,
    watch,
    trigger,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>();

  useEffect(() => {
    const handleOnEnter = (e: KeyboardEvent) => {
      if (e.code !== "Enter") return;
      if (step === "email" && agree) handleContinue();
      if (step === "password") handleSignUp();
    };

    document.addEventListener("keydown", handleOnEnter);
    return () => {
      document.removeEventListener("keydown", handleOnEnter);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, agree]);

  const handleGoogle = () => console.log("Sign Up via Google");
  const handleGitHub = () => console.log("Sign Up via GitHub");
  const handleSlack = () => console.log("Sign Up via Slack");

  const handleContinue = async () => {
    setEmailChecked(true);
    if (await trigger("email")) setStep("password");
  };

  const handleBack = () => setStep("email");

  const handleLogin = () => history.push("/sign-in");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSignUp = async () => {
    setPasswordChecked(true);
    if (!(await trigger("password"))) return;

    setLoading(true);
    await profile.signup(
      watch(),
      () => setStep("success"),
      (msg) => setServerError(msg)
    );
    setLoading(false);
  };

  const handleChangeControlledField = (
    field: any,
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    triggered: Boolean
  ) => {
    clearErrors(field.name);
    setServerError(undefined);
    field.onChange(event);
    triggered && trigger(field.name);
  };

  const validateEmail = (value: string) => {
    const trimmedValue = value.trim();
    if (!trimmedValue) return t("email_required");
    if (!isEmail(trimmedValue)) return t("email_invalid");
    return undefined;
  };

  const validatePassword = (value: string) => {
    if (!value) return t("password_required");
    if (value.length > 50) return t("password_too_long");
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(value))
      return t("password_weak");
    return undefined;
  };

  return (
    <Layout>
      <LayoutOfContent className={classes.layout}>
        <Grid container className={classes.root} wrap="nowrap">
          <Grid container alignItems="center" className={classes.header}>
            <img
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="logo"
              className={classes.logo}
            />
            {t("true_automation")}
          </Grid>
          {step === "email" ? (
            <>
              <Typography className={classes.stepTitle}>
                {t("sign_up")}
              </Typography>
              <AuthButtons
                authAction="sign_up"
                onGoogle={handleGoogle}
                onGitHub={handleGitHub}
                onSlack={handleSlack}
              />
              <Typography className={classes.orEmail}>
                {t("sign_up_email")}:
              </Typography>

              <Controller
                name="email"
                control={control}
                defaultValue={""}
                rules={{ validate: validateEmail }}
                render={({ field }) => (
                  <TextField
                    labelText={t("email")}
                    errorText={errors.email?.message}
                    inputBaseProps={{
                      ...field,
                      onChange: (event) =>
                        handleChangeControlledField(field, event, emailChecked),
                      onBlur: ({ target }) =>
                        setValue(field.name, target.value.trim()),
                      placeholder: t("enter_email"),
                      fullWidth: true,
                    }}
                    inputProps={{ maxLength: 254 }}
                  />
                )}
              />

              <Checkbox
                checked={agree}
                label={
                  <>
                    {t("agree")}
                    <Link to="/terms" className={classes.terms}>
                      {t("terms")}
                    </Link>
                  </>
                }
                labelPlacement="end"
                labelFormControlStyle={classes.checkbox}
                onChange={() => setAgree(!agree)}
              />
              <Grid container className={classes.actionButtons}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  disabled={!agree}
                  className={classes.button}
                  onClick={handleContinue}
                >
                  {t("continue")}
                </StyledButton>
              </Grid>
              <Grid item className={classes.footer}>
                {t("have_account")}
                <Link to="/sign-in" className={classes.link}>
                  {t("sign_in")}
                </Link>
              </Grid>
            </>
          ) : step === "password" ? (
            <>
              <Grid item>
                <TextField
                  labelText={t("email")}
                  error={!!serverError}
                  errorText={serverError}
                  inputBaseProps={{
                    placeholder: t("enter_email"),
                    fullWidth: true,
                    value: watch("email") || "",
                    disabled: true,
                  }}
                />
              </Grid>
              <Grid item className={classes.passwordContainer}>
                <Controller
                  name="password"
                  control={control}
                  defaultValue={""}
                  rules={{ validate: validatePassword }}
                  render={({ field }) => (
                    <TextField
                      labelText={t("create_password")}
                      type={showPassword ? "text" : "password"}
                      errorText={errors.password?.message}
                      inputBaseProps={{
                        ...field,
                        placeholder: t("create_your_password"),
                        fullWidth: true,
                        onChange: (event) =>
                          handleChangeControlledField(
                            field,
                            event,
                            passwordChecked
                          ),
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <TaIcon
                            className={classes.eyeIcon}
                            iconName={
                              showPassword
                                ? "VisibilityOffOutlined"
                                : "VisibilityOutlined"
                            }
                            onClick={handleClickShowPassword}
                          />
                        </InputAdornment>
                      }
                    />
                  )}
                />
              </Grid>
              <Grid container className={classes.actionButtons}>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={handleBack}
                >
                  {t("back")}
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleSignUp}
                  disabled={loading}
                >
                  {t("create_account")}
                </StyledButton>
              </Grid>
              <Grid item className={classes.footer}>
                {t("have_account")}
                <Link to="/sign-in" className={classes.link}>
                  {t("sign_in")}
                </Link>
              </Grid>
            </>
          ) : (
            <SignUpSuccess email={watch("email")} onLogin={handleLogin} />
          )}
        </Grid>
      </LayoutOfContent>
    </Layout>
  );
};

export default SignUp;
