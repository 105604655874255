import {
  types,
  flow,
  applySnapshot,
  clone,
  getSnapshot,
  destroy,
} from "mobx-state-tree";
import _ from "lodash";
import Environment from "./Environment";
import WithSelectable from "./WithSelectable";
import { handleFetch } from "./utils";

const Environments = types
  .compose(
    types.model("Environments", {
      searchTextFieldValue: "",
      isSearchFieldOpen: false,
      items: types.optional(types.array(Environment), []),
      newEnvironment: types.maybeNull(Environment),
      state: types.optional(
        types.enumeration("EnvironmentsState", ["loading", "ready"]),
        "ready"
      ),
    }),
    WithSelectable
  )
  .views((self) => ({
    get filteredItems() {
      const searchTextFieldValue = self.searchTextFieldValue.toLowerCase();

      return _.filter(
        self.items,
        (item) => item.name.toLowerCase().indexOf(searchTextFieldValue) > -1
      );
    },
    get basicEnvironment() {
      return _.find(self.items, (item) => !!item.basic);
    },
    get filteredItemsWithGlobal() {
      const searchTextFieldValue = self.searchTextFieldValue.toLowerCase();
      return _.filter(
        [{ name: "Global" }, ...self.items],
        (item) => item.name.toLowerCase().indexOf(searchTextFieldValue) > -1
      );
    },
  }))
  .actions((self) => ({
    load: flow(function* ({
      projectId,
      selected,
    }: {
      projectId: number;
      selected?: (string | undefined)[];
    }) {
      try {
        self.state = "loading";
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${projectId}/environments`
        );

        if (!response) return;
        const environments = yield response.json();

        if (selected)
          self.items.forEach(
            (item) => (item.selected = selected.includes(item.id?.toString()))
          );

        applySnapshot(self.items, environments);
        //TODO: Show Sucsess msg
      } catch (error) {
        //TODO: Show Error msg
        console.error("Error while load environments", error);
        throw error;
      } finally {
        self.state = "ready";
      }
    }),
    addEnvironment: flow(function* (projectId: string) {
      if (!self.newEnvironment) return;

      const environment = clone(self.newEnvironment);
      const newEnvironmentSnapshot = getSnapshot(environment);

      try {
        const newEnvironment = yield environment.save(projectId);
        destroy(self.newEnvironment);
        self.items.push(newEnvironment);
        return newEnvironment;
      } catch (e: any) {
        console.error(`Error while addEnvironment in model Environments: ${e}`);
        applySnapshot(self.newEnvironment, newEnvironmentSnapshot);
        self.newEnvironment.setServerError(e.code || "");
        destroy(environment);
      }
    }),
    setSearchTextFieldValue(value: string) {
      self.searchTextFieldValue = value;
    },
    onSearchOpenCloseClick() {
      self.searchTextFieldValue = "";
      self.isSearchFieldOpen = !self.isSearchFieldOpen;
    },
    onEnvironmentAdd() {
      self.newEnvironment = Environment.create({ basic: false });
    },
    onCancelEnvironmentAdd() {
      self.newEnvironment && destroy(self.newEnvironment);
    },
  }));

export default Environments;
