import { FC } from "react";
import clsx from "clsx";
import { last, map } from "lodash";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import { useRootStoreContext } from "../../hooks";

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    height: theme.spacing(7),
    marginBottom: "0.0625rem",
    padding: theme.spacing(1, 2, 0),
    backgroundColor: theme.palette.white.main,
  },
  tabLink: {
    ...theme.typography.bodyTextStyle,
    display: "block",
    padding: theme.spacing(1, 2),
    color: theme.palette.doveGray.main,
    textDecoration: "none",
    "&:hover&:not($active)": {
      color: theme.palette.outrageousOrange.main,
      backgroundColor: theme.palette.catskillLightWhite.main,
    },
  },
  active: {
    color: theme.palette.mineShaft.main,
    pointerEvents: "none",
    cursor: "default",
  },
  tabLinkContainer: {
    marginTop: "0.125rem",
    paddingBottom: theme.spacing(1),
    boxSizing: "border-box",
  },
  tabLinkContainerActive: {
    paddingBottom: "6px",
    borderBottom: `2px solid ${theme.palette.baliHai.main}`,
  },
}));

const UserHeader: FC = () => {
  const { t } = useTranslation(["users"]);
  const classes = useStyles();
  const { pathname } = useLocation();
  const { userFilters } = useRootStoreContext();
  const tabRoute = last(pathname.split("/"));

  const tabs = [
    {
      link: "active",
      label: t("active"),
    },
    {
      link: "pending",
      label: t("pending"),
    },
    {
      link: "deactivated",
      label: t("deactivated"),
    },
  ];

  const resetFilters = () => userFilters.clearAllFilters();

  return (
    <Grid container className={classes.headerContainer}>
      {map(tabs, ({ link, label }, i) => (
        <Grid
          key={`header_tab_${i}`}
          item
          className={clsx(classes.tabLinkContainer, {
            [classes.tabLinkContainerActive]: link === tabRoute,
          })}
        >
          <Link
            to={link}
            className={clsx(classes.tabLink, {
              [classes.active]: link === tabRoute,
            })}
            onClick={resetFilters}
          >
            {label}
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default UserHeader;
