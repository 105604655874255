import {
  types,
  Instance,
  SnapshotOut,
  flow,
  getParentOfType,
  getSnapshot,
  applySnapshot,
  cast,
  getRoot,
} from "mobx-state-tree";
import i18next from "i18next";
import moment from "moment";
import _ from "lodash";
import {
  handleFetch,
  validateNameByLength,
  validateNameForbiddenChars,
} from "./utils";
import Label from "./Label";
import Tests from "./Tests";
import Report from "./Report";
import WithSelected from "./WithSelected";
import TestStep from "./TestStep";
import { IRoot } from ".";

const Test = types
  .compose(
    types.model("Test", {
      id: "",
      name: "",
      Labels: types.optional(types.array(types.late(() => Label)), []),
      Reports: types.optional(types.array(Report), []),
      updatedAt: "",
      createdAt: "",
      creatorUserEmail: types.maybe(types.string),
      changerUserEmail: types.maybe(types.string),
      ProjectId: types.maybe(types.number),
      SuiteId: "",
      error: "",
      testState: types.optional(
        types.enumeration("TestState", ["ready", "loading", "dragging"]),
        "ready"
      ),
      baseUrl: "",
    }),
    WithSelected
  )
  .views((self) => ({
    get updated() {
      return moment(self.updatedAt).format(i18next.t("tests:updated"));
    },
    get updatedWithEmail() {
      return moment(self.updatedAt).format(
        i18next.t("tests:updated_with_email", { email: self.changerUserEmail })
      );
    },
    get createdWithEmail() {
      return moment(self.createdAt).format(
        i18next.t("tests:created_with_email", { email: self.creatorUserEmail })
      );
    },
    get isValid() {
      return (
        !!_.trim(self.name, ". \n") &&
        validateNameByLength(self.name, 255) &&
        validateNameForbiddenChars(self.name) &&
        !self.error
      );
    },
    get validationErrorText() {
      if (!_.trim(self.name, ". \n"))
        return i18next.t("tests:empty_name_error");
      if (!validateNameByLength(self.name, 255))
        return i18next.t("tests:name_error");
      if (!validateNameForbiddenChars(self.name))
        return i18next.t("tests:name_forbidden_chars");
      return self.error;
    },
    get result() {
      if (self.Reports.length === 0) return "not_run";
      return moment(self.updatedAt).isAfter(self.Reports[0].updatedAt)
        ? "pending"
        : self.Reports[0].result || "broken";
    },
    get lastRun() {
      if (self.Reports.length === 0) return "not_run";
      return self.Reports[0].updatedAt;
    },
    get lastReport() {
      return self.Reports.length === 0 ? undefined : self.Reports[0];
    },
  }))
  .actions((self) => ({
    touch() {
      const { users } = getRoot<IRoot>(self);
      const profile = users.profile;

      self.changerUserEmail = profile.email;
      self.updatedAt = new Date().toISOString();
    },
    load: flow(function* () {
      try {
        self.testState = "loading";
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${self.ProjectId}/suites/${self.SuiteId}/tests/${self.id}`
        );
        const item = yield response.json();

        if (item.status === "ERROR") {
          self.error = "NOT_FOUND";
          throw new Error(item.message);
        }

        applySnapshot(self, item);
      } catch (error) {
        throw error;
      } finally {
        self.testState = "ready";
      }
    }),
    save: flow(function* () {
      self.testState = "loading";
      self.name = _.trim(self.name, ". \n");

      try {
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${self.ProjectId}/suites/${self.SuiteId}/tests/${self.id}`,
          {
            method: self.id ? "PUT" : "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(getSnapshot(self)),
          }
        );
        const item = yield response.json();

        if (item.status === "ERROR") throw new Error(item.message);

        applySnapshot(self, item);
      } finally {
        self.testState = "ready";
      }
    }),
    remove: flow(function* () {
      self.testState = "loading";
      try {
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${self.ProjectId}/suites/${self.SuiteId}/tests/${self.id}`,
          {
            method: "DELETE",
          }
        );

        if (!response.ok) throw new Error("Could not do remove request");
      } catch (error) {
        throw error;
      } finally {
        self.testState = "ready";
      }
    }),
    duplicate: flow(function* () {
      self.testState = "loading";
      try {
        const response = yield handleFetch(
          self,
          `${process.env.REACT_APP_API_URL}/projects/${self.ProjectId}/suites/${self.SuiteId}/tests/${self.id}/duplicate`,
          {
            method: "POST",
          }
        );

        if (!response.ok) throw new Error("Could not duplicate the test");

        return yield response.json();
      } catch (error) {
        throw error;
      } finally {
        self.testState = "ready";
      }
    }),
    setTestState(state: "ready" | "loading" | "dragging") {
      self.testState = state;
    },
  }))
  .actions((self) => ({
    onEditExistingClick(e: React.SyntheticEvent) {
      e.stopPropagation();
      getParentOfType(self, Tests).onEditExistingTest(getSnapshot(self));
    },
    onChangeName(e: React.ChangeEvent<HTMLTextAreaElement>) {
      self.name = e.target.value;
      self.error = "";
    },
    onCancelEditClick(e: React.SyntheticEvent) {
      e.stopPropagation();
      self.id
        ? getParentOfType(self, Tests).onCancelEditExistingTest()
        : getParentOfType(self, Tests).onCancelAddNewTest();
    },
    onSaveEditClick(e: React.SyntheticEvent) {
      e.stopPropagation();
      if (!self.isValid) return;
      self.id
        ? getParentOfType(self, Tests).editExistingTest()
        : getParentOfType(self, Tests).addNewTest();
    },
    onUngroupSharedStep: async () => {
      try {
        const { testSteps } = getRoot<IRoot>(self);

        const sharedStep = TestStep.create(
          cast({
            id: 0,
            type: "sharedAction",
            order: 0,
            SharedStepId: self.id,
            projectId: self.ProjectId,
            testId: self.id,
            value: self.name,
            StepId: self.id,
            suiteId: self.SuiteId,
          })
        );

        await testSteps.ungroupSharedStep([sharedStep]);
      } catch (error) {
        console.error(`Error in onUngroupSharedStep method: ${error}`);
      }
    },
  }));

export interface ITest extends Instance<typeof Test> {}
export interface ITestSnapshotOut extends SnapshotOut<typeof Test> {}

export default Test;
