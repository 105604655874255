import React, { useEffect } from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { makeStyles } from "@mui/styles";
import { ListItem, Box, Typography, Theme } from "@mui/material";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import { IAction } from "../../models";

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    position: "relative",
    padding: theme.spacing(0, 0, 0, 1),
    height: 40,
    display: "flex",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: theme.palette.catskillLightWhite.main,
      "& $actionIcon, $text": {
        color: theme.palette.outrageousOrange.main,
      },
    },
    "&.selected": {
      backgroundColor: theme.palette.catskillLightWhite.main,
    },
  },
  box: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
  actionIcon: {
    color: theme.palette.baliHai.main,
  },
  text: {
    marginLeft: theme.spacing(1),
    ...theme.typography.bodyTextStyle,
    color: theme.palette.mineShaft.main,
  },
}));

interface IActionItem {
  item: IAction;
  selectable?: boolean;
  onClick: (e: React.MouseEvent, item: IAction) => void;
}

const ActionItem: React.FC<IActionItem> = observer(
  ({ item, selectable, onClick }) => {
    const classes = useStyles();
    const [{ isDragging }, drag, preview] = useDrag({
      type: "Action",
      item: {
        ...item,
        actionType: item.type,
        dragLayerText: item.name,
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
        currentOffset: monitor.getSourceClientOffset(),
      }),
    });

    useEffect(() => {
      preview(getEmptyImage());
    }, [preview]);

    return (
      <ListItem
        ref={selectable ? null : drag}
        component="li"
        disabled={isDragging}
        className={clsx(classes.listItem, { selected: item.selected })}
        button
        onClick={(e: React.MouseEvent) => onClick(e, item)}
      >
        <Box className={classes.box}>
          <CategoryOutlinedIcon className={classes.actionIcon} />
          <Typography className={classes.text} noWrap={true}>
            {item.name}
          </Typography>
        </Box>
      </ListItem>
    );
  }
);

export default ActionItem;
