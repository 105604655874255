import { FC } from "react";
import {
  useHistory,
  useLocation,
  matchPath,
  useParams,
} from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import HeaderTabs from "./HeaderTabs";
import HeaderTab from "./HeaderTab";

const Tabs: FC = observer(() => {
  const { t } = useTranslation(["header"]);
  const history = useHistory();
  const { pathname } = useLocation();
  const { projectId = "" } = useParams<{ projectId: string }>();

  const match =
    matchPath(pathname, { path: "/projects/:projectId/tests" }) ||
    matchPath(pathname, { path: "/projects/:projectId/shared-steps" }) ||
    matchPath(pathname, { path: "/projects/:projectId/reports/tests" }) ||
    matchPath(pathname, { path: "/projects/:projectId/settings" }) ||
    matchPath(pathname, { path: "/projects/:projectId/data" });
  const value = match ? match.url : pathname;

  return (
    <HeaderTabs
      value={value}
      onChange={(_e, val) => {
        if (val !== value) history.push(val);
      }}
    >
      <HeaderTab label={t("dashboard")} value="/dashboard/projects" />
      <HeaderTab label={t("tests")} value={`/projects/${projectId}/tests`} />
      <HeaderTab
        label={t("shared_steps")}
        value={`/projects/${projectId}/shared-steps`}
      />
      <HeaderTab
        label={t("reports")}
        value={`/projects/${projectId}/reports/tests`}
      />
      <HeaderTab label={t("schedule")} />
      <HeaderTab label={t("data")} value={`/projects/${projectId}/data`} />
      <HeaderTab
        label={t("settings")}
        value={`/projects/${projectId}/settings`}
      />
    </HeaderTabs>
  );
});

export default Tabs;
