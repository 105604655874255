import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { ILabel } from "../../models";
import ValidatableTextArea from "../Inputs/ValidatableTextArea";
import { useLabelMenuContext } from "../../hooks";

const useStyles = makeStyles(() => ({
  clsTooltip: {
    height: 60,
    width: "100%",
  },
}));

interface ILabelItemEdit {
  label: ILabel;
}

const LabelItemEdit: React.FC<ILabelItemEdit> = observer(({ label }) => {
  const { t } = useTranslation(["labels"]);
  const classes = useStyles();
  const { testIds } = useLabelMenuContext();

  const onSaveClick = (e: React.SyntheticEvent) => {
    if (label.isValid) label.onSaveExistingLabelClick(e);
  };

  const placement = testIds.length === 0 ? "right-start" : "left-start";

  return (
    <ValidatableTextArea
      maxLength={50}
      placeholder={t("label_name")}
      errorText={label.validationErrorText}
      itemName={label.name}
      isValid={label.isValid}
      useTooltip={true}
      onChange={label.onChangeName}
      onSaveClick={onSaveClick}
      onCancelClick={label.onCancelEditClick}
      placement={placement}
      clsTooltip={classes.clsTooltip}
    />
  );
});

export default LabelItemEdit;
