import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import clsx from "clsx";
import { isEmail } from "../models/utils";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Theme,
  InputAdornment,
  Typography,
  Link as MuiLink,
} from "@mui/material";

import {
  Layout,
  LayoutOfContent,
  StyledButton,
  TextField,
  TaIcon,
} from "../components";

import { AuthButtons, SignUpSuccess } from "../components/Auth";

import { useRootStoreContext } from "../hooks";

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    backgroundColor: theme.palette.white.main,
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    width: "100%",
    maxWidth: "100%",
    overflow: "auto",
  },
  root: {
    width: 410,
    marginLeft: "auto",
    marginRight: "auto",
    flexDirection: "column",
  },
  header: {
    marginTop: 80,
    marginBottom: 80,
    fontSize: 18,
    fontWeight: 500,
  },
  logo: {
    width: 48,
    height: 48,
    marginRight: theme.spacing(2),
  },
  actionButtons: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(4),
    alignSelf: "flex-start",
  },
  primary: {
    alignSelf: "flex-start",
  },
  link: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.outrageousOrange.main,
    textTransform: "uppercase",
    textDecoration: "underline",
    marginLeft: theme.spacing(1),
    "&:hover": {
      color: theme.palette.pomegranate.main,
    },
  },
  orEmail: {
    color: theme.palette.doveGray.main,
    fontSize: 12,
    fontWeight: 400,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    textAlign: "center",
  },
  forgot: {
    color: theme.palette.outrageousOrange.main,
    textTransform: "uppercase",
    marginLeft: "auto",
    alignSelf: "center",
  },
  eyeIcon: {
    cursor: "pointer",
    color: theme.palette.rockBlue.main,
    "&:hover": {
      color: theme.palette.outrageousOrange.main,
    },
  },
  title: {
    fontSize: 15,
    fontWeight: 700,
    marginBottom: theme.spacing(),
  },
  titleText: {
    color: theme.palette.mineShaft.main,
    fontSize: 14,
    fontWeight: 400,
    marginBottom: theme.spacing(1),
  },
  mb3: {
    marginBottom: theme.spacing(3),
  },
  noteText: {
    color: theme.palette.doveGray.main,
    fontSize: 12,
    fontWeight: 400,
    marginTop: theme.spacing(5),
  },
  resetEmail: {
    fontWeight: 600,
  },
  passwordContainer: {
    marginTop: theme.spacing(4),
  },
  footer: {
    marginTop: theme.spacing(4),
  },
}));

type stepType = "email" | "password" | "forgot" | "reset_sent" | "confirm";

interface IFormInputs {
  email: string;
  password: string;
  reset_email: string;
}

const SignIn: FC = () => {
  const { t } = useTranslation("auth");
  const {
    users: { profile },
    projects,
  } = useRootStoreContext();

  const history = useHistory();

  const {
    control,
    watch,
    trigger,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>();

  const classes = useStyles();
  const [emailChecked, setEmailChecked] = useState(false);
  const [resetEmailChecked, setResetEmailChecked] = useState(false);
  const [passwordChecked, setPasswordChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [serverError, setServerError] = useState<string | undefined>(undefined);
  const [step, setStep] = useState<stepType>("email");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleOnEnter = (e: KeyboardEvent) => {
      if (e.code !== "Enter") return;
      if (step === "email") handleContinue();
      if (step === "password") handleLogin();
      if (step === "forgot") handleRequestPasswordReset();
    };

    document.addEventListener("keydown", handleOnEnter);
    return () => {
      document.removeEventListener("keydown", handleOnEnter);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const handleGoogle = () => console.log("Log In via Google");
  const handleGitHub = () => console.log("Log In via GitHub");
  const handleSlack = () => console.log("Log In via Slack");

  const handleContinue = async () => {
    setEmailChecked(true);
    if (await trigger("email")) setStep("password");
  };

  const handleBack = () => setStep("email");
  const handleToForgot = () => {
    if (!watch("reset_email")) {
      setValue("reset_email", watch("email"));
      clearErrors("reset_email");
    }
    setStep("forgot");
  };

  const handleBackToPassword = () => setStep("password");

  const handleRequestPasswordReset = async () => {
    setIsLoading(true);

    setResetEmailChecked(true);
    if (!(await trigger("reset_email"))) {
      setIsLoading(false);
      return;
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/forgot-password`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: watch("reset_email") }),
      },
    );

    if (response.ok) {
      setStep("reset_sent");
    } else {
      const error = await response.json();
      setServerError(error.error);
    }

    setIsLoading(false);
  };

  const handleServerErrors = ({
    error,
    message,
  }: {
    error?: string;
    message?: string;
  }) => {
    if (error === "UNCONFIRMED") {
      setStep("confirm");
    } else if (error === "LOCKED") {
      setServerError(t("locked_error"));
    } else if (message === "Failed to fetch") {
      setServerError(t("fetch_error"));
    } else {
      setServerError(t("unauthorized_error"));
    }
  };

  const handleLogin = async () => {
    setIsLoading(true);
    setPasswordChecked(true);

    if (!(await trigger("password"))) return setIsLoading(false);
    await profile.login(
      watch(),
      () => {
        projects.load();
        history.push("/dashboard/projects");
      },
      handleServerErrors,
    );
    setIsLoading(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const SignInFooter = () => (
    <Grid item className={classes.footer}>
      {t("no_account")}
      <Link to="/sign-up" className={classes.link}>
        {t("sign_up")}
      </Link>
    </Grid>
  );

  const validateEmail = (value: string) => {
    const trimmedValue = value.trim();
    if (!trimmedValue) return t("email_required");
    if (!isEmail(trimmedValue)) return t("email_invalid");
    return undefined;
  };

  return (
    <Layout>
      <LayoutOfContent className={classes.layout}>
        <Grid container className={classes.root} wrap="nowrap">
          <Grid container alignItems="center" className={classes.header}>
            <img
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="logo"
              className={classes.logo}
            />
            {t("true_automation")}
          </Grid>
          {step === "email" ? (
            <>
              <Typography className={classes.title}>{t("sign_in")}</Typography>
              <AuthButtons
                authAction="sign_in"
                onGoogle={handleGoogle}
                onGitHub={handleGitHub}
                onSlack={handleSlack}
              />
              <Typography className={classes.orEmail}>
                {t("sign_in_email")}:
              </Typography>
              <Controller
                name="email"
                control={control}
                defaultValue={""}
                rules={{ validate: validateEmail }}
                render={({ field }) => (
                  <TextField
                    labelText={t("email")}
                    errorText={errors.email?.message}
                    inputBaseProps={{
                      ...field,
                      onChange: (target) => {
                        clearErrors(field.name);
                        setServerError(undefined);
                        field.onChange(target);
                        emailChecked && trigger(field.name);
                      },
                      onBlur: ({ target }) =>
                        setValue(field.name, target.value.trim()),
                      placeholder: t("enter_email"),
                      fullWidth: true,
                    }}
                    inputProps={{ maxLength: 254 }}
                  />
                )}
              />
              <Grid container className={classes.actionButtons}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  className={classes.primary}
                  onClick={handleContinue}
                >
                  {t("continue")}
                </StyledButton>
              </Grid>
              <SignInFooter />
            </>
          ) : step === "password" ? (
            <>
              <Grid item>
                <TextField
                  labelText={t("email")}
                  error={!!serverError}
                  inputBaseProps={{
                    placeholder: t("enter_email"),
                    fullWidth: true,
                    value: watch("email") || "",
                    disabled: true,
                  }}
                />
              </Grid>
              <Grid item className={classes.passwordContainer}>
                <Controller
                  name="password"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      labelText={t("password")}
                      type={showPassword ? "text" : "password"}
                      errorText={
                        serverError ||
                        (errors.password ? t("password_required") : "")
                      }
                      inputProps={{ maxLength: 50 }}
                      inputBaseProps={{
                        ...field,
                        placeholder: t("enter_password"),
                        fullWidth: true,
                        onChange: (target) => {
                          clearErrors(field.name);
                          setServerError(undefined);
                          field.onChange(target);
                          passwordChecked && trigger(field.name);
                        },
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <TaIcon
                            className={classes.eyeIcon}
                            iconName={
                              showPassword
                                ? "VisibilityOffOutlined"
                                : "VisibilityOutlined"
                            }
                            onClick={handleClickShowPassword}
                          />
                        </InputAdornment>
                      }
                    />
                  )}
                />
              </Grid>
              <Grid container className={classes.actionButtons}>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={handleBack}
                >
                  {t("back")}
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  className={classes.primary}
                  onClick={handleLogin}
                  disabled={isLoading}
                >
                  {t("login")}
                </StyledButton>
                <Box className={classes.forgot}>
                  <MuiLink
                    component="button"
                    onClick={handleToForgot}
                    className={classes.link}
                  >
                    {t("forgot_password")}
                  </MuiLink>
                </Box>
              </Grid>
              <SignInFooter />
            </>
          ) : step === "forgot" ? (
            <>
              <Typography className={classes.title}>
                {t("forgot_title")}
              </Typography>
              <Typography className={clsx(classes.titleText, classes.mb3)}>
                {t("forgot_text")}
              </Typography>
              <Controller
                name="reset_email"
                control={control}
                defaultValue={""}
                rules={{ validate: validateEmail }}
                render={({ field }) => (
                  <TextField
                    labelText={t("email")}
                    errorText={errors.reset_email?.message}
                    inputBaseProps={{
                      ...field,
                      onChange: (target) => {
                        clearErrors(field.name);
                        setServerError(undefined);
                        field.onChange(target);
                        resetEmailChecked && trigger(field.name);
                      },
                      onBlur: ({ target }) =>
                        setValue(field.name, target.value.trim()),
                      placeholder: t("enter_email"),
                      fullWidth: true,
                    }}
                    inputProps={{ maxLength: 254 }}
                  />
                )}
              />
              <Grid container className={classes.actionButtons}>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={handleBackToPassword}
                >
                  {t("back")}
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  className={classes.primary}
                  onClick={handleRequestPasswordReset}
                  disabled={isLoading}
                >
                  {t("request_password_reset")}
                </StyledButton>
              </Grid>
            </>
          ) : step === "reset_sent" ? (
            <>
              <Typography className={classes.title}>
                {t("reset_title")}
              </Typography>
              <Typography className={classes.titleText}>
                {t("reset_text_1")}
                <Box component="span" className={classes.resetEmail}>
                  {watch("reset_email")}
                </Box>
                <br />
                {t("reset_text_2")}
              </Typography>
              <Typography className={classes.titleText}></Typography>
              <Typography className={classes.titleText}>
                {t("reset_text_3")}
              </Typography>
              <Typography className={classes.titleText}>
                {t("reset_text_4")}
              </Typography>
              <Grid container className={classes.actionButtons}>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={handleToForgot}
                >
                  {t("back_to_resend")}
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  className={classes.primary}
                  onClick={handleBack}
                  disabled={isLoading}
                >
                  {t("take_to_login")}
                </StyledButton>
              </Grid>
              <Typography className={classes.noteText}>
                {t("reset_note")}
              </Typography>
            </>
          ) : (
            <SignUpSuccess email={watch("email")} onLogin={handleBack} />
          )}
        </Grid>
      </LayoutOfContent>
    </Layout>
  );
};

export default SignIn;
